import pptxgen from 'pptxgenjs';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas } from '../utils';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	truncateText,
	AP_LOGO_WHITE,
} from './common';

const FOOTER_CONFIG: pptxgen.TextPropsOptions = {
	w: 3.98,
	h: 0.13,
	x: 7.56,
	y: 6.36,
	fontSize: 8,
	fontFace: 'Verdana (Body)',
	valign: 'middle',
	color: 'FFFFFF',
	margin: 0,
	paraSpaceAfter: 12,
};

const LABEL_SMALL_CONFIG: pptxgen.TextPropsOptions = {
	w: 3.98,
	h: 0.2,
	x: 7.56,
	y: 2.1,
	fontSize: 12,
	bold: true,
	fontFace: 'Verdana (Body)',
	valign: 'top',
	color: 'FFFFFF',
	margin: 0,
	paraSpaceAfter: 12,
};

const VALUE_CONFIG: pptxgen.TextPropsOptions = {
	w: 3.98,
	h: 0.4,
	x: 7.56,
	y: 1.68,
	fontSize: 24,
	bold: true,
	fontFace: 'Verdana (Body)',
	valign: 'top',
	color: '5CAC31',
	margin: 0,
	paraSpaceAfter: 12,
};

const DESCRIPTION_CONFIG: pptxgen.TextPropsOptions = {
	w: 5.58,
	h: 3.84,
	x: 0.4,
	y: 1.66,
	fontSize: 12,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '383838',
	margin: [0, 0, 0, 0],
	// Enable if we need to have bulleted text here
	// bullet: { marginPt: 14.5 },
	paraSpaceAfter: 6,
};

const PEERS_LIST_CONFIG: pptxgen.TextPropsOptions = {
	w: 2.63,
	h: 2.71,
	x: 10.59,
	y: 1.68,
	fontSize: 12,
	fontFace: 'Verdana (Body)',
	margin: 0,
	align: 'left',
	valign: 'top',
	color: 'FFFFFF',
	paraSpaceAfter: 12,
};

export const appendBusinessDescriptionSlide = (
	deckInstance: pptxgen,
	companyName: string,
	description: string,
	latestYear: number,
	currencyCode: string,
	revenue: number,
	operatingIncome: number,
	totalEbitda: number,
	netIncome: number,
	keyOwnership: string,
	leadership: string,
	employees: number,
	competitorNames: string[],
) => {
	const DESCRIPTION_MAX_LENGTH = 1350;
	const MAX_PEERS_AMOUNT = 7;

	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);

	// Heading
	slide.addText('Summary', SLIDE_TITLE_CONFIG_WIDE);

	// Black square
	slide.addShape(deckInstance.ShapeType.rect, {
		x: 6.67,
		y: 0,
		w: 6.67,
		h: 7.5,
		fill: { color: '383838' },
		line: { type: 'none' },
	});

	slide.addText(
		[
			{ text: 'Source: Capital IQ ' },
			{ text: moment().format('YYYY'), options: { bold: true } },
		],
		FOOTER_CONFIG,
	);

	// Black square text
	slide.addText('KEY FACTS & FIGURES', {
		h: 0.79,
		w: 5.07,
		x: 7.56,
		y: 1.28,
		fontSize: 16,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: 'FFFFFF',
		margin: [0, 0, 0, 0],
	});

	slide.addText('RELEVANT PEERS', {
		h: 0.79,
		w: 5.07,
		x: 10.59,
		y: 1.28,
		fontSize: 16,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: 'FFFFFF',
		margin: [0, 0, 0, 0],
	});

	slide.addText('BUSINESS DESCRIPTION', {
		h: 0.43,
		w: 6.13,
		x: 0.4,
		y: 1.26,
		fontSize: 14,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText(
		`${getSymbolFromCurrency(currencyCode)}${numberWithCommas(revenue)}m`,
		VALUE_CONFIG,
	);
	slide.addText(
		`${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
			operatingIncome,
		)}m`,
		{ ...VALUE_CONFIG, y: 2.43 },
	);
	slide.addText(
		`${getSymbolFromCurrency(currencyCode)}${numberWithCommas(totalEbitda)}m`,
		{ ...VALUE_CONFIG, y: 3.18 },
	);
	slide.addText(
		`${getSymbolFromCurrency(currencyCode)}${numberWithCommas(netIncome)}m`,
		{ ...VALUE_CONFIG, y: 3.93 },
	);
	slide.addText(truncateText(leadership, 27), { ...VALUE_CONFIG, y: 4.68 });
	slide.addText(`~${numberWithCommas(employees)}`, {
		...VALUE_CONFIG,
		y: 5.43,
	});
	slide.addText(`TOTAL REVENUE (FY${latestYear})`, LABEL_SMALL_CONFIG);
	slide.addText(`OPERATING INCOME (FY${latestYear})`, {
		...LABEL_SMALL_CONFIG,
		y: 2.85,
	});
	slide.addText(`EBITDA (FY${latestYear})`, { ...LABEL_SMALL_CONFIG, y: 3.6 });
	slide.addText(`NET INCOME (FY${latestYear})`, {
		...LABEL_SMALL_CONFIG,
		y: 4.35,
	});
	slide.addText('KEY LEADERSHIP', { ...LABEL_SMALL_CONFIG, y: 5.1 });
	slide.addText('TOTAL NUMBER OF EMPLOYEES', {
		...LABEL_SMALL_CONFIG,
		y: 5.85,
	});

	const labelConfig = {
		// bold: true,
		bullet: { marginPt: 2 },
		breakLine: true,
	};

	slide.addText(
		competitorNames
			.slice(0, MAX_PEERS_AMOUNT)
			.map((item) => ({ text: truncateText(item, 47), options: labelConfig })),
		PEERS_LIST_CONFIG,
	);

	slide.addImage({
		data: AP_LOGO_WHITE,
		x: 10.91,
		y: 7.15,
		w: 1.38,
		h: 0.2,
	});

	slide.addText(
		truncateText(description ? description : 'No Data', DESCRIPTION_MAX_LENGTH),
		DESCRIPTION_CONFIG,
	);
};
