import React from 'react';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import './Sidebar.scss';

import { Filter } from '../Filter';
import { Search } from '../Search';
import { IFilter, ITag } from '../../types';

interface ISidebarProps {
	filters: IFilter[];
	tags: ITag[];
	activeFilters: IFilter[];
	activeTags: ITag[];
	isSearchLoading: boolean;
	isTrsEnabled: boolean;
	onSearchTextChange(value: string): void;
	onFilterAdd(filter: IFilter): void;
	onFilterRemove(filter: IFilter): void;
	onTagAdd(filter: IFilter): void;
	onTagRemove(filter: IFilter): void;
	onSearchClear(): void;
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
	const {
		filters,
		tags,
		activeFilters,
		activeTags,
		isSearchLoading,
		isTrsEnabled,
		onSearchTextChange,
		onFilterAdd,
		onFilterRemove,
		onTagAdd,
		onTagRemove,
		onSearchClear,
	} = props;

	return (
		<div className="sidebar">
			<Search
				onSearchTextChange={onSearchTextChange}
				onSearchClear={onSearchClear}
			/>
			<div className="sidebar__header">Filters</div>
			{isSearchLoading ? (
				<ApLoaderDefault />
			) : (
				<>
					{filters.length > 0 && (
						<Filter
							isSearchLoading={isSearchLoading}
							filters={filters}
							activeFilters={activeFilters}
							onFilterAdd={onFilterAdd}
							onFilterRemove={onFilterRemove}
							expandedByDefault
						/>
					)}
					{isTrsEnabled && tags.length > 0 && (
						<Filter
							isSearchLoading={isSearchLoading}
							filters={tags
								.filter((t) => t.name)
								.map((t) => ({
									id: `${t.tagId}`,
									type: 'Tags',
									value: t.name.toUpperCase(),
								}))}
							activeFilters={activeTags
								.filter((t) => t.name)
								.map((t) => ({
									id: `${t.tagId}`,
									type: 'Tags',
									value: t.name.toUpperCase(),
								}))}
							onFilterAdd={onTagAdd}
							onFilterRemove={onTagRemove}
							expandedByDefault
						/>
					)}
				</>
			)}
		</div>
	);
};
