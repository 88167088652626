import * as React from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { IOverviewWidget } from '../../types';
import { WidgetWrapper } from '../WidgetWrapper';

interface ICompanyOverview {
	widgetsConfig: IOverviewWidget[];
}

export const CompanyOverview: React.FC<ICompanyOverview> = ({
	widgetsConfig,
}) => {
	const mainWidgets = widgetsConfig.filter((c) => c.type === 'main');
	const sideWidgets = widgetsConfig.filter((c) => c.type === 'sidebar');

	return (
		<Container>
			<Row gutterWidth={32}>
				<Col md={8} lg={8} sm={8}>
					{mainWidgets.map((config: IOverviewWidget) => (
						<WidgetWrapper {...config} key={config.name} />
					))}
				</Col>
				<Col md={4} lg={4} sm={4}>
					{sideWidgets.map((config: IOverviewWidget) => (
						<WidgetWrapper {...config} key={config.name} />
					))}
				</Col>
			</Row>
		</Container>
	);
};
