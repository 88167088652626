import pptxgen from 'pptxgenjs';
import moment from 'moment';

import { MASTER_SLIDE_COVER_WIDE } from './common';

const NAME_CONFIG: pptxgen.TextPropsOptions = {
	h: 0.83,
	w: 9.2,
	x: 0.4,
	y: 2.92,
	fontSize: 24,
	fontFace: 'Verdana (Headings)',
	align: 'left',
	valign: 'bottom',
	bold: true,
	color: '383838',
	margin: 0,
};

const COVER_TEXT_CONFIG: pptxgen.TextPropsOptions = {
	h: 0.5,
	w: 9.2,
	x: 0.4,
	y: 3.75,
	fontSize: 18,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '808083',
	margin: [0, 0, 0, 14.5],
	paraSpaceAfter: 6,
};

const COVER_DATE_CONFIG: pptxgen.TextPropsOptions = {
	h: 0.28,
	w: 9.2,
	x: 0.4,
	y: 4.58,
	fontSize: 10,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'bottom',
	bold: true,
	color: '808083',
	margin: [0, 0, 6, 0],
};

export const appendCoverSlide = (
	deckInstance: pptxgen,
	companyName: string,
	logo?: string,
	logoWidth?: number,
	logoHeight?: number,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_COVER_WIDE.title);

	slide.addText(companyName, NAME_CONFIG);
	slide.addText('Lead Profile', COVER_TEXT_CONFIG);
	slide.addText(moment().format('MMMM YYYY'), COVER_DATE_CONFIG);

	if (logo && logoWidth && logoHeight) {
		slide.addImage({
			data: logo,
			x: 11.9,
			y: 0.35,
			w: logoWidth,
			h: logoHeight,
			sizing: {
				type: 'contain',
				w: logoWidth > 0.98 ? 0.98 : logoWidth,
				h: logoHeight > 0.98 ? 0.98 : logoHeight,
			},
		});
	}
};
