import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { IDebtAnalysisData } from '../../../types';
import { roundOneDecimal, numberWithCommas, isEmpty } from '../../../utils';
import { DebtWidget } from '../DebtWidget';
import { DebtSecondaryWidget } from '../DebtSecondaryWidget';

import './DebtSection.scss';

interface IDebtSectionProps {
	data: IDebtAnalysisData;
	companyName: string;
}

export const DebtSection: React.FC<IDebtSectionProps> = ({
	data,
	companyName,
}) => {
	if (!data || isEmpty(data)) {
		return (
			<div className="debt-section">
				<div className="debt-section__empty">No data available</div>
			</div>
		);
	}

	const currencyCode = data.currencyCode;
	const lastYearData = data.data[data.data.length - 1];
	const prevYearData = data.data[data.data.length - 2];

	const coverageValue = roundOneDecimal(data.interestCoverage.currentValue);
	let coverageValueFormatted = `${coverageValue}`;

	if (`${coverageValue}` === coverageValue.toFixed()) {
		coverageValueFormatted = `${coverageValue}.0`;
	}

	const description = `The total debt for ${companyName} ${
		lastYearData.totalDebt > prevYearData.totalDebt ? 'grew' : 'fell'
	} to ${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
		roundOneDecimal(lastYearData.totalDebt),
	)} million in ${lastYearData.fiscalYear}, culminating in a ${Math.abs(
		data.growth,
	)}% ${
		data.growth > 0 ? 'increase' : 'decrease'
	} over the last five years. Over the same period net debt ${
		lastYearData.netDebt > prevYearData.netDebt ? 'rose' : 'fell'
	} to ${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
		roundOneDecimal(lastYearData.netDebt),
	)} million in ${
		lastYearData.fiscalYear
	}, resulting in a Net Debt/EBITDA ratio of ${roundOneDecimal(
		data.netDebtOverEbitda.currentValue,
	)}x.`;

	return (
		<div className="debt-section">
			{description && (
				<div className="debt-section__description">{description}</div>
			)}
			<div className="debt-section__container">
				<div className="debt-section__wrapper">
					<div className="debt-section__chart">
						<DebtWidget data={data.data} currency={currencyCode} />
					</div>
				</div>
				<div className="debt-section__wrapper">
					<DebtSecondaryWidget
						name="Net Debt / EBITDA"
						title="Leverage"
						growth={data.netDebtOverEbitda.growth}
						value={`${roundOneDecimal(data.netDebtOverEbitda.currentValue)}X`}
					/>
					<DebtSecondaryWidget
						name="Total debt / Assets"
						title="Leverage"
						growth={data.totalDebtOverAssets.growth}
						value={`${roundOneDecimal(data.totalDebtOverAssets.currentValue)}X`}
					/>
					<DebtSecondaryWidget
						name="FCF"
						title="Coverage"
						growth={data.fcf.growth}
						value={`${
							getSymbolFromCurrency(currencyCode)
								? getSymbolFromCurrency(currencyCode)
								: currencyCode
						}${numberWithCommas(roundOneDecimal(data.fcf.currentValue))}M`}
					/>
					<DebtSecondaryWidget
						name="Interest Coverage"
						title="Coverage"
						growth={data.interestCoverage.growth}
						value={`${coverageValueFormatted}X`}
					/>
				</div>
			</div>
		</div>
	);
};
