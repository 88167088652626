import React from 'react';
import classNames from 'classnames';
import { ApButtonLink } from '@alixpartners/ui-components';

import { IPeople, IApEmployee } from '../../types';
import { getCurvePath } from '../../utils';

import { ReactComponent as TabChart } from './tab-chart.svg';
import { ReactComponent as TabTable } from './tab-table.svg';

import { ConnectionAnalyticsConsumer } from '../../components/ConnectionAnalyticsContext';

import './ConnectionsChart.scss';

// const WORKED_AT = 'WorkedAt';
// const WORKED_WITH = 'WorkedWith';
// const ENGAGEMENTED = 'Engagemented';

const formatName = (str: string) => str.toLowerCase().replace(/ /g, '_');

interface IConnectionsChartProps {
	people: IPeople[];
	name: string;
	connectionAnalyticsId: string;
}

interface IConnectionsChartState {
	vendorHoverIndex: number;
	apHoverIndex: number;
	isChartTab: boolean;
	isExpanded: boolean;
}

export class ConnectionsChart extends React.Component<
	IConnectionsChartProps,
	IConnectionsChartState
> {
	state = {
		vendorHoverIndex: -1,
		apHoverIndex: -1,
		isChartTab: true,
		isExpanded: false,
	};

	// engagementedBy: IApEmployee[];
	// workedFor: IApEmployee[];

	// constructor(props: IConnectionsChartProps) {
	// 	super(props);

	// 	const engagementedBy: IPeople | undefined = props.people.find(
	// 		(employee) => employee.relationshipType === ENGAGEMENTED,
	// 	);s

	// 	const workedFor: IPeople | undefined = props.people.find(
	// 		(employee) => employee.relationshipType === WORKED_AT,
	// 	);

	// 	this.engagementedBy = engagementedBy ? engagementedBy.relationships : [];
	// 	this.workedFor = workedFor ? workedFor.relationships : [];
	// }

	onHover = (vendorHoverIndex: number, apHoverIndex: number = -1) => {
		if (
			this.state.vendorHoverIndex !== vendorHoverIndex ||
			this.state.apHoverIndex !== apHoverIndex
		) {
			this.setState({ vendorHoverIndex, apHoverIndex });
		}
	};

	getLegendBlock = () => {
		return (
			<div className="connections__legend">
				<div className="connections__legend__item">
					<svg height="2" width="16">
						<line
							x1="0"
							x2="16"
							y1="1"
							y2="1"
							stroke="#CCCCCC"
							strokeWidth="2"
							fill="none"
						/>
					</svg>
					Worked with or for
				</div>
				<div className="connections__legend__item">
					<svg height="2" width="16">
						<line
							x1="0"
							x2="16"
							y1="1"
							y2="1"
							stroke="#CCCCCC"
							strokeWidth="2"
							fill="none"
							strokeDasharray="6,3"
						/>
					</svg>
					Involved in engagements
				</div>
			</div>
		);
	};

	getChartBlock = () => {
		const { name } = this.props;
		const { isExpanded } = this.state;

		// Filter out real employees representations (with email, name, etc.)
		const vendorEmployees = this.props.people.filter(
			(employee) => employee.name,
		);

		const apEmployees: IApEmployee[] = [
			...vendorEmployees.reduce((acc: IApEmployee[], e: IPeople) => {
				acc = [...acc, ...e.relationships];
				return acc;
			}, []),
			// ...this.engagementedBy,
		].filter(
			(thing, index, self) =>
				index === self.findIndex((t) => t.name === thing.name),
		);

		// Header height + bottom margin (48 + 16)
		const headerHeight = 64;
		// Header height + length of AP or Vendor workers * (block height + margin) - last margin
		const svgHeight =
			64 +
			Math.max(vendorEmployees.length, apEmployees.length) * (100 + 24) -
			24;
		// const svgWidth = 105;
		const svgWidth = 210;
		const bottomMargin = 24;
		// Employee block height
		const height = 100;

		let linesList: React.ReactElement[] = [];

		// Hover logic
		const { vendorHoverIndex, apHoverIndex } = this.state;
		const hoveredLinesList: React.ReactElement[] = [];
		const hoveredVendorEmployee = vendorEmployees[vendorHoverIndex];
		const hoveredApEmployee = apEmployees[apHoverIndex];

		// Draw all grey lines

		vendorEmployees.forEach((vendorEmployee, vendorIndex) => {
			if (vendorEmployee.relationships.length > 0) {
				const startX = 0;
				const startY =
					headerHeight + vendorIndex * (height + bottomMargin) + height / 2;

				vendorEmployee.relationships.forEach((apEmployee) => {
					const apIndex: number = apEmployees.findIndex(
						(employee) => employee.name === apEmployee.name,
					);
					const endX = svgWidth;
					const endY =
						headerHeight + apIndex * (height + bottomMargin) + height / 2;

					const path = getCurvePath(startX, startY, endX, endY);

					linesList.push(
						<g
							key={`${formatName(vendorEmployee.name)}-${formatName(
								apEmployee.name,
							)}`}
							onMouseOver={() => this.onHover(vendorIndex, apIndex)}
							onMouseLeave={() => this.onHover(-1, -1)}
							className="relationship-line"
						>
							<path d={path} stroke="#eee" strokeWidth="2" fill="none" />
							<path
								d={path}
								stroke="transparent"
								strokeWidth="10"
								fill="none"
							/>
						</g>,
					);
				});
			}
		});

		// Add engagement lines

		// this.engagementedBy.forEach((apEmpl) => {
		// 	const apIndex: number = apEmployees.findIndex(
		// 		(employee) => employee.id === apEmpl.id,
		// 	);

		// 	// if (apIndex) {
		// 	const startX = 24; // Header right offset to employee block
		// 	const startY = 24; // Header height / 2

		// 	const endX = svgWidth;
		// 	const endY =
		// 		headerHeight + apIndex * (height + bottomMargin) + height / 2;

		// 	const path = getCurvePath(startX, startY, endX, endY);

		// 	linesList.push(
		// 		<path
		// 			d={path}
		// 			stroke="#CCCCCC"
		// 			strokeWidth="2"
		// 			fill="none"
		// 			strokeDasharray="10,5"
		// 			key={`engagement-by-${apEmpl.id}`}
		// 			className="relationship-line"
		// 			style={{ pointerEvents: 'none', opacity: '0.5' }}
		// 		/>,
		// 	);
		// 	// }
		// });

		// Hover logic

		// Hide all lines when we hover on specific
		if (hoveredApEmployee || hoveredVendorEmployee) {
			linesList = [];
			vendorEmployees.forEach((vendorEmployee, vendorIndex) => {
				if (vendorEmployee.relationships.length > 0) {
					const startX = 0;
					const startY =
						headerHeight + vendorIndex * (height + bottomMargin) + height / 2;

					vendorEmployee.relationships.forEach((apEmployee) => {
						const apIndex: number = apEmployees.findIndex(
							(employee) => employee.name === apEmployee.name,
						);
						const endX = svgWidth;
						const endY =
							headerHeight + apIndex * (height + bottomMargin) + height / 2;

						const path = getCurvePath(startX, startY, endX, endY);

						linesList.push(
							<g
								key={`${formatName(vendorEmployee.name)}-${formatName(
									apEmployee.name,
								)}`}
								onMouseOver={() => this.onHover(vendorIndex, apIndex)}
								onMouseLeave={() => this.onHover(-1, -1)}
								className="relationship-line"
							>
								<path d={path} stroke="#fafafa" strokeWidth="2" fill="none" />
								<path
									d={path}
									stroke="transparent"
									strokeWidth="10"
									fill="none"
								/>
							</g>,
						);
					});
				}
			});
		}

		if (hoveredVendorEmployee && hoveredApEmployee) {
			const hoveredStartX = 0;
			const hoveredStartY =
				headerHeight + vendorHoverIndex * (height + bottomMargin) + height / 2;

			const hoveredEndX = svgWidth;
			const hoveredEndY =
				headerHeight + apHoverIndex * (height + bottomMargin) + height / 2;

			const path = getCurvePath(
				hoveredStartX,
				hoveredStartY,
				hoveredEndX,
				hoveredEndY,
			);

			hoveredLinesList.push(
				<path
					d={path}
					stroke="#6BABC7"
					strokeWidth="2"
					fill="none"
					key={`${formatName(hoveredVendorEmployee.name)}-${formatName(
						hoveredApEmployee.name,
					)}-hovered`}
				/>,
			);
		} else if (hoveredVendorEmployee) {
			const hoveredStartX = 0;
			const hoveredStartY =
				headerHeight + vendorHoverIndex * (height + bottomMargin) + height / 2;

			hoveredVendorEmployee.relationships.forEach((apEmpl) => {
				const apIndex: number = apEmployees.findIndex(
					(employee) => employee.name === apEmpl.name,
				);
				const hoveredEndX = svgWidth;
				const hoveredEndY =
					headerHeight + apIndex * (height + bottomMargin) + height / 2;

				const path = getCurvePath(
					hoveredStartX,
					hoveredStartY,
					hoveredEndX,
					hoveredEndY,
				);

				hoveredLinesList.push(
					<path
						d={path}
						stroke="#6BABC7"
						strokeWidth="2"
						fill="none"
						key={`${formatName(hoveredVendorEmployee.name)}-${formatName(
							apEmpl.name,
						)}-hovered`}
					/>,
				);
			});
		} else if (hoveredApEmployee) {
			const hoveredEndX = svgWidth;
			const hoveredEndY =
				headerHeight + apHoverIndex * (height + bottomMargin) + height / 2;

			vendorEmployees.forEach((vendorEmpl, vendorIndex) => {
				if (
					vendorEmpl.relationships.find(
						(employee) => employee.name === hoveredApEmployee.name,
					)
				) {
					const hoveredStartX = 0;
					const hoveredStartY =
						headerHeight + vendorIndex * (height + bottomMargin) + height / 2;

					const path = getCurvePath(
						hoveredStartX,
						hoveredStartY,
						hoveredEndX,
						hoveredEndY,
					);

					hoveredLinesList.push(
						<path
							d={path}
							stroke="#6BABC7"
							strokeWidth="2"
							fill="none"
							key={`${formatName(vendorEmpl.name)}-${formatName(
								hoveredApEmployee.name,
							)}-hovered`}
						/>,
					);
				}
			});
		}

		return (
			<>
				{/* {this.getLegendBlock()} */}
				<div
					className={classNames('connections__chart', {
						'connections__chart--expanded': isExpanded,
					})}
				>
					<div className="connections__chart__vendor">
						<div className="connections__chart__wrapper">
							<div className="connections__chart__header connections__chart__header--vendor">
								{name}
								{/* {this.engagementedBy.length > 0 && (
									<svg height="12" width="12" className="engagement-connector">
										<circle
											cx="6"
											cy="6"
											r="5"
											fill="#CCCCCC"
											stroke="#ffffff"
											strokeWidth="2"
										></circle>
									</svg>
								)} */}
							</div>
						</div>
						{vendorEmployees.map((employee, index) => {
							return (
								<div
									key={formatName(employee.name)}
									className={classNames('employee', {
										'employee--hovered':
											index === vendorHoverIndex ||
											(!vendorEmployees[vendorHoverIndex] &&
												apEmployees[apHoverIndex] &&
												employee.relationships.find(
													(e) => e.name === apEmployees[apHoverIndex].name,
												)) ||
											(vendorEmployees[vendorHoverIndex] &&
												apEmployees[apHoverIndex] &&
												vendorHoverIndex === index),
									})}
									onMouseOver={() => this.onHover(index)}
									onMouseLeave={() => this.onHover(-1)}
								>
									<div className="employee__name">{employee.name}</div>
									<div className="employee__role">{employee.role}</div>
									<div className="employee__email">
										<a
											rel="noopener noreferrer"
											href={`mailto:${employee.email}`}
											target="_blank"
										>
											{employee.email}
										</a>
									</div>
									{employee.relationships.length > 0 && (
										<svg
											height="12"
											width="12"
											className={classNames(
												'employee__connector',
												'employee__connector--right',
												{
													'employee__connector--hovered':
														index === vendorHoverIndex,
												},
											)}
										>
											<circle
												cx="6"
												cy="6"
												r="5"
												fill="#CCCCCC"
												stroke="#ffffff"
												strokeWidth="2"
											></circle>
										</svg>
									)}
								</div>
							);
						})}
					</div>
					<div className="connections__chart__relationship">
						<svg height={svgHeight} width={svgWidth}>
							{linesList.map((line) => {
								return line;
							})}
						</svg>
						{hoveredLinesList.length > 0 && (
							<svg
								height={svgHeight}
								width={svgWidth}
								className="hovered"
								style={{ pointerEvents: 'none' }}
							>
								{hoveredLinesList.map((line) => line)}
							</svg>
						)}
					</div>
					<div className="connections__chart__ap">
						<div className="connections__chart__wrapper">
							<div className="connections__chart__header connections__chart__header--ap">
								Alixpartners
							</div>
						</div>
						{apEmployees.map((employee, index) => {
							return (
								<div
									key={formatName(employee.name)}
									className={classNames('employee', {
										'employee--hovered':
											index === apHoverIndex ||
											(!apEmployees[apHoverIndex] &&
												vendorEmployees[vendorHoverIndex] &&
												vendorEmployees[vendorHoverIndex].relationships.find(
													(e) => e.name === employee.name,
												)) ||
											(apEmployees[apHoverIndex] &&
												vendorEmployees[vendorHoverIndex] &&
												apHoverIndex === index),
									})}
									onMouseOver={() => this.onHover(-1, index)}
									onMouseLeave={() => this.onHover(-1, -1)}
								>
									<div className="employee__name">{employee.name}</div>
									<div className="employee__role">{employee.role}</div>
									<div className="employee__email">
										<a
											rel="noopener noreferrer"
											href={`mailto:${employee.email}`}
											target="_blank"
										>
											{employee.email}
										</a>
									</div>
									<svg
										height="12"
										width="12"
										className="employee__connector employee__connector--left"
									>
										<circle
											cx="6"
											cy="6"
											r="5"
											fill="#CCCCCC"
											stroke="#ffffff"
											strokeWidth="2"
										></circle>
									</svg>
								</div>
							);
						})}
					</div>
				</div>
				{this.props.people.length === 0 && <span>No connections data.</span>}
				{/* {isExpanded && (
				<div className="connections__load-more">
					<ApButtonSecondary
						onClick={() => {
							this.setState((prevState) => ({
								isExpanded: !prevState.isExpanded,
							}));
						}}
					>
						{`${isExpanded ? 'Load Less' : 'Load More'}`}
					</ApButtonSecondary>
				</div>
				)} */}
			</>
		);
	};

	getApEmplopyeeRelationsBlock = (apEmployee: IApEmployee) => {
		const relationsList: React.ReactElement[] = [];

		const vendorEmployees = this.props.people.filter(
			(employee) => employee.name,
		);

		vendorEmployees.forEach((vendorEmpl) => {
			if (vendorEmpl.relationships.find((e) => e.name === apEmployee.name)) {
				relationsList.push(
					<div
						className="connections__table__relation"
						key={formatName(vendorEmpl.name)}
					>
						Knows <strong>{vendorEmpl.name}</strong> ({vendorEmpl.role})
					</div>,
				);
			}
		});

		// if (this.engagementedBy.find((apEmpl) => apEmpl.id === apEmployee.id)) {
		// 	relationsList.push(
		// 		<div
		// 			className="connections__table__relation"
		// 			key={`engagemented-by-${apEmployee.id}`}
		// 		>
		// 			Done an engagement with
		// 		</div>,
		// 	);
		// }

		// if (this.workedFor.find((apEmpl) => apEmpl.id === apEmployee.id)) {
		// 	relationsList.push(
		// 		<div
		// 			className="connections__table__relation"
		// 			key={`worked-for-${apEmployee.id}`}
		// 		>
		// 			Worked at
		// 		</div>,
		// 	);
		// }

		return relationsList;
	};

	getTableBlock = () => {
		const vendorEmployees = this.props.people.filter(
			(employee) => employee.name,
		);

		const allApEmployees: IApEmployee[] = [
			...vendorEmployees.reduce((acc: IApEmployee[], e: IPeople) => {
				acc = [...acc, ...e.relationships];
				return acc;
			}, []),
			// ...this.engagementedBy,
			// ...this.workedFor,
		].filter(
			(thing, index, self) =>
				index === self.findIndex((t) => t.name === thing.name),
		);

		return (
			<div className="connections__table">
				<div className="connections__table__relationship">
					<div className="connections__table__header-wrapper">
						<div className="connections__table__header">
							AlixPartners employee
						</div>
						<div className="connections__table__header">Relationship</div>
					</div>
					{allApEmployees.map((employee) => {
						return (
							<div
								className="connections__table__ap-employee"
								key={formatName(employee.name)}
							>
								<div>
									<strong>{employee.name}</strong> ({employee.role})
								</div>
								<div>{this.getApEmplopyeeRelationsBlock(employee)}</div>
							</div>
						);
					})}
				</div>
				{/* <div className="connections__table__vendor">
					<div className="connections__table__header">Client Name</div>
					<div className="connections__table__vendor-name">
						<div>{name}</div>
					</div>
				</div> */}
			</div>
		);
	};

	openTableTab = () => {
		this.setState({ isChartTab: false });
	};

	openChartTab = () => {
		this.setState({ isChartTab: true });
	};

	render() {
		const { isChartTab } = this.state;
		const { connectionAnalyticsId } = this.props;

		return (
			<ConnectionAnalyticsConsumer>
				{({ webAppUrl }) => {
					return (
						<div className="connections">
							<div className="connections__title-wrapper">
								<div className="connections__title">Connections</div>

								<div className="connections__controls">
									<div
										className={classNames('connections__controls__chart', {
											'connections__controls__chart--active': isChartTab,
										})}
										onClick={this.openChartTab}
									>
										<TabChart />
									</div>
									<div
										className={classNames('connections__controls__table', {
											'connections__controls__table--active': !isChartTab,
										})}
										onClick={this.openTableTab}
									>
										<TabTable />
									</div>
								</div>
							</div>
							<div className="connections__link">
								<ApButtonLink
									href={
										connectionAnalyticsId
											? `${webAppUrl}/Graph/pna?entityType=2&entityId=${connectionAnalyticsId}&userOrigin=BICCompanyProfilePlatform`
											: webAppUrl
									}
									target="_blank"
								>
									Click to view more detail in Connection Analytics
								</ApButtonLink>
							</div>
							{isChartTab ? this.getChartBlock() : this.getTableBlock()}
						</div>
					);
				}}
			</ConnectionAnalyticsConsumer>
		);
	}
}
