import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ApIcon } from '@alixpartners/ui-components';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas, roundOneDecimal } from '../../../utils';

import './ProfitLossWidget.scss';

interface IProfitLossChart {
	name: string;
	growth: number;
	chartColor?: string;
	data: any[];
	currency: string;
}

export const ProfitLossWidget: React.FC<IProfitLossChart> = ({
	name,
	growth,
	chartColor = '#0696A6',
	data,
	currency,
}) => {
	const chartOptions: Highcharts.Options = {
		chart: {
			height: 140,
			marginLeft: 0,
			marginRight: 0,
			marginTop: 25,
			// margin: 0,
			// width: 512,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: {
			visible: false,
		},
		legend: {
			enabled: false,
		},
		// @ts-ignore
		xAxis: {
			labels: {
				formatter: function () {
					if (this.value && data[this.pos].margin !== 0) {
						return `<div class="chart-blue"><i>${roundOneDecimal(
							data[this.pos].margin,
						)}%</i></div><div><span>${this.value}</span></div>`;
					} else {
						return this.value;
					}
				},
				useHTML: true,
				style: {
					color: '#737373',
					fontFamily: 'Roboto',
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			lineColor: '#CCCCCC',
			// tickLength: 0,
			// gridLineWidth: 0,
			// tickInterval: 1,
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			column: {
				pointWidth: 62,
			},
			series: {
				// color: '#0696A6',
				dataLabels: {
					// useHTML: true,
					allowOverlap: false,
					inside: true,
					verticalAlign: 'top',
					crop: false,
					overflow: 'allow',
					y: -25,
					enabled: true,
					formatter: function () {
						if (this.y) {
							return `${numberWithCommas(roundOneDecimal(this.y))}M`;
						}
					},
					style: {
						color: '#1A1A1A',
						fontFamily: 'Roboto',
						fontSize: '16px',
						lineHeight: '24px',
						fontWeight: 'normal',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};
	const { currentValue, fiscalYear } = data[data.length - 1];
	const options: Highcharts.Options = {
		...chartOptions,
		xAxis: {
			...chartOptions.xAxis,
			categories: data.map((d) => d.fiscalYear.toString()),
		},
		series: [
			{
				data: data.map((d) => d.currentValue),
				type: 'column',
				color: chartColor,
			},
		],
	};

	return (
		<div className="pl-widget">
			<div className="pl-widget__title">{`${name} ${
				currency ? '(' + getSymbolFromCurrency(currency) + 'M)' : ''
			}`}</div>
			<div className="pl-widget__data">
				<strong>{name}</strong>
				{` ${growth > 0 ? 'grew' : 'decreased'} to ${getSymbolFromCurrency(
					currency,
				)}${numberWithCommas(
					roundOneDecimal(currentValue),
				)}M in ${fiscalYear}, resulting in ${
					growth > 0 ? 'growth' : 'decrease'
				} of ${growth}% year on year`}
			</div>
			<div className="pl-widget__change">
				<ApIcon
					className={`pl-widget__change-icon${
						growth < 0 ? ' pl-widget__change-icon--negative' : ''
					}`}
					iconName="trending_flat"
					iconColor="#5CB335"
				/>
				<div>{`${growth}% Y/Y`}</div>
			</div>
			<div className="pl-widget__separator"></div>
			<HighchartsReact
				style={{ width: '100%', margin: '0 auto' }}
				highcharts={Highcharts}
				options={options}
			/>
			{!data.every((d) => d.margin === 0) && (
				<p className="pl-widget__legend">
					<i>XX%</i> &#65309; Margin values
				</p>
			)}
		</div>
	);
};
