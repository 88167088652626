import pptxgen from 'pptxgenjs';

import { FinancialType } from '../types';

import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
	STICKER_CONFIG,
} from './common';

export const appendRevenueMarginSlide = (
	deckInstance: pptxgen,
	companyName: string,
	currencyCode: string,
	revenueChartsDataLast5: any,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`FINANCIAL ANALYSIS`, SLIDE_TOP_LABEL);
	slide.addText(`${companyName} Revenue Margin Charts`, SLIDE_TITLE_CONFIG);

	// Years labels
	const labels = revenueChartsDataLast5[FinancialType.YEARS];

	slide.addChart(
		[
			{
				type: 'bar',
				data: [
					{
						name: 'Revenue',
						labels,
						values: revenueChartsDataLast5[FinancialType.REVENUE],
					},
				],
				options: {
					chartColors: ['032E45'],
					dataLabelPosition: 'outEnd',
					// dataLabelFormatCode: '#\\,###',
				},
			},
			{
				type: 'line',
				data: [
					{
						name: 'EBITDA',
						labels,
						values: revenueChartsDataLast5[FinancialType.EBITDA],
					},
				],
				options: {
					chartColors: ['FF600F'],
					dataLabelColor: '9e9e9e',
					dataLabelPosition: 't',
					// dataLabelFormatCode: '#\\,###',
				},
			},
			{
				type: 'line',
				data: [
					{
						name: 'Net Income',
						labels,
						values: revenueChartsDataLast5[FinancialType.NET_INCOME],
					},
				],
				options: {
					chartColors: ['5CB335'],
					dataLabelColor: '9e9e9e',
					dataLabelPosition: 'b',
					// dataLabelFormatCode: '#\\,###',
				},
			},
		],
		{
			// @ts-ignore
			x: 0.81,
			y: 1.68,
			h: 2.52,
			w: 6.11,
			border: { pt: 0, color: 'ffffff' },
			valGridLine: { style: 'none' },
			serAxisHidden: true,
			valAxisHidden: true,
			valAxisLineShow: false,
			serAxisLineShow: false,
			showLeaderLines: false,
			barGapWidthPct: 80,
			showValue: true,
			catAxisHidden: true,
			catAxisMajorTickMark: 'none',
			catAxisMinorTickMark: 'none',
			// @ts-ignore
			axisLineColor: '666666',

			// Data labels
			// dataLabelColor: '9e9e9e',
			dataLabelFontFace: 'Verdana (Body)',
			dataLabelFontSize: 10,

			// Legend
			showLegend: true,
			legendPos: 't',
			legendFontSize: 10,
			legendFontFace: 'Verdana (Body)',
			legendColor: '383838',
		},
	);

	const ebitdaMargins: number[] = revenueChartsDataLast5[
		FinancialType.EBITDA
	].map((v: number, i: number) => {
		const corespondingRevenueValue =
			revenueChartsDataLast5[FinancialType.REVENUE][i];

		return Math.round((v / corespondingRevenueValue) * 100);
	});

	const netIncomeMargins: number[] = revenueChartsDataLast5[
		FinancialType.NET_INCOME
	].map((v: number, i: number) => {
		const corespondingRevenueValue =
			revenueChartsDataLast5[FinancialType.REVENUE][i];

		return Math.round((v / corespondingRevenueValue) * 100);
	});

	const rows = [
		labels,
		[
			...ebitdaMargins.map((v) => ({
				text: `${v}%`,
				options: { color: 'FF600F' },
			})),
		],
		[
			...netIncomeMargins.map((v) => ({
				text: `${v}%`,
				options: { color: '5CB335' },
			})),
		],
	];

	slide.addTable(rows, {
		x: 0.91,
		y: 4.22,
		w: 5.88,
		border: { type: 'none' },
		fontFace: 'Verdana (Body)',
		fontSize: 9,
		align: 'center',
	});

	slide.addChart(
		'line',
		[
			{
				name: 'SG&A % Revenue',
				labels,
				values: revenueChartsDataLast5[FinancialType.SG_AND_A].map(
					(item: number, index: number) => {
						const result =
							item / revenueChartsDataLast5[FinancialType.REVENUE][index];

						return isFinite(result) ? Math.round(result * 100) : 0;
					},
				),
			},
			{
				name: 'COGS % Revenue',
				labels,
				values: revenueChartsDataLast5[FinancialType.COGS].map(
					(item: number, index: number) => {
						const result =
							item / revenueChartsDataLast5[FinancialType.REVENUE][index];

						return isFinite(result) ? Math.round(result * 100) : 0;
					},
				),
			},
		],
		{
			x: 0.81,
			y: 5.09,
			h: 1.92,
			w: 6.11,
			chartColors: ['FF3130', '5CB335'],
			lineDataSymbolSize: 10,
			shadow: { type: 'none' },
			border: { pt: 0, color: 'ffffff' },
			valGridLine: { style: 'none' },
			serAxisHidden: true,
			valAxisHidden: true,
			valAxisLineShow: false,
			serAxisLineShow: false,
			showLeaderLines: false,
			barGapWidthPct: 80,
			showValue: true,
			catAxisMajorTickMark: 'none',
			catAxisMinorTickMark: 'none',
			catAxisLabelColor: '383838',
			catAxisLabelFontFace: 'Verdana (Body)',
			catAxisLabelFontSize: 10,
			// @ts-ignore
			axisLineColor: '666666',

			// Data labels
			dataLabelFontFace: 'Verdana (Body)',
			dataLabelFontSize: 10,
			dataLabelPosition: 't',

			// Legend
			showLegend: true,
			legendPos: 't',
			legendFontSize: 10,
			legendFontFace: 'Verdana (Body)',
			legendColor: '383838',
		},
	);

	slide.addText(
		`Revenue (${currencyCode}m), revenue growth and profitability margins (%)`,
		{
			...SLIDE_SECONDARY_TITLE_CONFIG,
			x: 0.31,
			y: 1.26 + 0.2,
			w: 6.25,
			h: 0,
			shape: deckInstance.ShapeType.line,
		},
	);

	slide.addText('Key highlights', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		x: 6.7,
		y: 1.26 + 0.2,
		w: 3,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText([{ text: 'Revenues & Margin', options: { bold: true } }], {
		x: -1.33,
		y: 3.13,
		w: 3.72,
		h: 0.46,
		shape: 'rect',
		fill: { type: 'solid', color: '032E45' },
		rotate: 270,
		fontSize: 10,
		fontFace: 'Verdana (Body)',
		align: 'center',
		valign: 'middle',
		color: 'FFFFFF',
		margin: [0, 0, 6, 0],
	});

	slide.addText(
		[{ text: 'SG&A and COGS % Revenues', options: { bold: true } }],
		{
			x: -0.24,
			y: 5.82,
			w: 1.54,
			h: 0.46,
			shape: 'rect',
			fill: { type: 'solid', color: '006176' },
			rotate: 270,
			fontSize: 10,
			fontFace: 'Verdana (Body)',
			align: 'center',
			valign: 'middle',
			color: 'FFFFFF',
			margin: [0, 0, 6, 0],
		},
	);

	slide.addText('Add additional commentary here', {
		x: 6.7,
		y: 1.63,
		w: 2.6,
		h: 2.6,
		...STICKER_CONFIG,
	});
};
