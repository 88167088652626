import React from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { ScreenClassProvider } from 'react-grid-system';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ApButtonFilter,
	ApButtonGroup,
	ApButtonMain,
	ApButtonSecondary,
	ApButtonTab,
	ApChip,
	ApIcon,
	ApInput,
	ApLoaderDefault,
	ApModal,
	ApSimpleDropdown,
	IOption,
	snack,
} from '@alixpartners/ui-components';

import { insert } from '../../utils';
import { MultiSelect } from '../MultiSelect';

import {
	AgGridColumnItem,
	ICompanyListItem,
	ICompanyTimeline,
	IWatchlist,
	IWatchlistListItem,
} from '../../types';
import {
	createWatchlist,
	deleteWatchlist,
	getWatchlistIndustries,
	getWatchlists,
} from '../../api';

import './WatchlistsContainer.scss';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { AgGridReact } from '@ag-grid-community/react';

import { MultiSelectCreatable } from '../MultiSelectCreatable';

const COMMUNITIES_OPTIONS = [
	{
		label: 'Digital',
		value: '1',
	},
	{
		label: 'EI',
		value: '2',
	},
	{
		label: 'FAS',
		value: '3',
	},
	{
		label: 'TRS',
		value: '4',
	},
];

const NEW_WATCHLIST: IWatchlist = {
	createdOn: '',
	description: '',
	prospects: [],
	status: '',
	subscribers: [],
	watchlistId: -1,
	watchlistName: '',
	companyTimeline: {} as ICompanyTimeline,
	communities: [],
	industries: [],
};

interface IWatchlistsContainerProps extends RouteComponentProps {
	isSuperAdmin: boolean;
}

export enum TabName {
	AllWatchlists = 'allw',
	AllCompanies = 'allc',
	MyCompanies = 'myc',
}

interface IWatchlistsContainerState {
	isLoading: boolean;
	isUpdating: boolean;
	isAddWatchlistOpen: boolean;
	isManageColumnsOpen: boolean;
	isDeleteWatchlistOpen: boolean;
	currentWatchlistItem: IWatchlistListItem;
	currentList: string;
	customColumnName: string;
	selectedNewColumn: IOption;
	currentTab: TabName;
	watchlists: {
		otherWatchlist: IWatchlistListItem[];
		watchlistAssignedToUser: IWatchlistListItem[];
		companiesAssignedToUser: ICompanyListItem[];
		allWatchlistCompanies: ICompanyListItem[];
	};
	watchlistsCopy: {
		otherWatchlist: IWatchlistListItem[];
		watchlistAssignedToUser: IWatchlistListItem[];
		companiesAssignedToUser: ICompanyListItem[];
		allWatchlistCompanies: ICompanyListItem[];
	};
	newWatchlist: IWatchlist;
	industriesOptions: IOption[];
	allCompaniesSearchValue: string;
	myCompaniesSearchValue: string;
	filteredAllCompanies: ICompanyListItem[];
	filteredMyCompanies: ICompanyListItem[];

	myIndustryFilters: IOption[];
	mySelectedIndustryFilters: IOption[];

	allIndustryFilters: IOption[];
	allSelectedIndustryFilters: IOption[];
	watchlistGridApi: any;
	watchlistGridColumnApi: any;

	otherWatchlistGridApi: any;
	otherWatchlistGridColumnApi: any;

	companyGridApi: any;
	companyGridColumnApi: any;

	columnNamesCompanies: any[];
	columnNamesWatchlist: any[];
	customColumnNamesWatchlist: any[];

	otherColumnNamesWatchlist: any[];
	defaultColumnFields: any[];

	selectedColumnsFiltered: any;
	showManageColumns: boolean;
	alignValues: string[];
	selectedAlignValue: string;
	increment: boolean;
	resize: number;
	rowHeights: string[];
	selectedRowHeight: string;
}

class WatchlistsContainerComponent extends React.Component<
	IWatchlistsContainerProps,
	IWatchlistsContainerState
> {
	constructor(props: any) {
		super(props);

		const customColumnNamesWatchlistStorage = localStorage.getItem(
			'customColumnNamesWatchlist',
		);
		const customColumnNamesWatchlist = customColumnNamesWatchlistStorage
			? JSON.parse(customColumnNamesWatchlistStorage)
			: [];
		const defaultColumnFields = [
			{ label: 'Name', value: 'watchlistName' },
			{ label: 'Creation date', value: 'createdOn' },
			{ label: '# of Companies ', value: 'numberOfCompanies' },
			{ label: '# in Pursuit', value: 'propsectStatusCount.pursuit' },
			{ label: '# in Monitoring', value: 'propsectStatusCount.monitor' },
		];

		this.state = {
			isLoading: true,
			isUpdating: false,
			isAddWatchlistOpen: false,
			isManageColumnsOpen: false,
			isDeleteWatchlistOpen: false,
			currentWatchlistItem: {} as IWatchlistListItem,
			currentList: '',
			currentTab: TabName.AllWatchlists,
			watchlists: {
				otherWatchlist: [] as IWatchlistListItem[],
				watchlistAssignedToUser: [] as IWatchlistListItem[],
				companiesAssignedToUser: [] as ICompanyListItem[],
				allWatchlistCompanies: [] as ICompanyListItem[],
			},
			watchlistsCopy: {
				otherWatchlist: [] as IWatchlistListItem[],
				watchlistAssignedToUser: [] as IWatchlistListItem[],
				companiesAssignedToUser: [] as ICompanyListItem[],
				allWatchlistCompanies: [] as ICompanyListItem[],
			},
			newWatchlist: NEW_WATCHLIST,
			customColumnName: '',
			selectedNewColumn: {} as IOption,
			industriesOptions: [],
			allCompaniesSearchValue: '',
			myCompaniesSearchValue: '',
			filteredAllCompanies: [] as ICompanyListItem[],
			filteredMyCompanies: [] as ICompanyListItem[],

			myIndustryFilters: [] as IOption[],
			mySelectedIndustryFilters: [] as IOption[],

			allIndustryFilters: [] as IOption[],
			allSelectedIndustryFilters: [] as IOption[],

			otherColumnNamesWatchlist: [
				{
					headerName: 'Name',
					field: 'watchlistName',
					minWidth: 250,
					pinned: 'left',
					cellRenderer: this.agCellWatchlist,
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: 'Creation date',
					field: 'createdOn',
					minWidth: 150,
					cellRenderer: this.agCellDate,
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '# of companies',
					field: 'numberOfCompanies',
					type: 'numericColumn',
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '# in Pursuit',
					field: 'propsectStatusCount.pursuit',
					type: 'numericColumn',
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '# in Monitoring',
					field: 'propsectStatusCount.monitor',
					type: 'numericColumn',
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '',
					field: '',
					colId: 'actionColumn',
					cellRenderer: this.agCellAction,
					width: 100,
					...this.defaultColProps,
					sortable: false,
					filter: false,
				},
			] as AgGridColumnItem[],
			columnNamesWatchlist: [
				{
					headerName: 'Name',
					field: 'watchlistName',
					minWidth: 250,
					pinned: 'left',
					headerComponentFramework: this.onChangeHeader,
					cellRenderer: this.agCellWatchlist,
					...this.defaultColProps,
				},
				{
					headerName: 'Creation date',
					field: 'createdOn',
					minWidth: 150,
					headerComponentFramework: this.onChangeHeader,
					cellRenderer: this.agCellDate,
					...this.defaultColProps,
				},
				{
					headerName: '# of companies',
					field: 'numberOfCompanies',
					type: 'numericColumn',
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '# in Pursuit',
					field: 'propsectStatusCount.pursuit',
					type: 'numericColumn',
					headerComponentFramework: this.onChangeHeader,
					...this.defaultColProps,
				},
				{
					headerName: '# in Monitoring',
					field: 'propsectStatusCount.monitor',
					headerComponentFramework: this.onChangeHeader,
					type: 'numericColumn',
					...this.defaultColProps,
				},
				{
					headerName: '',
					field: '',
					colId: 'actionColumn',
					cellRenderer: this.agCellAction,
					width: 100,
					...this.defaultColProps,
					headerComponentFramework: this.onChangeHeader,
					sortable: false,
					filter: false,
				},
				...customColumnNamesWatchlist,
			] as AgGridColumnItem[],

			customColumnNamesWatchlist,

			columnNamesCompanies: [
				{
					headerName: 'Name',
					field: 'companyName',
					minWidth: 150,
					pinned: 'left',
					cellRenderer: this.agCellCompanyName,
					...this.defaultColProps,
				},
				{
					headerName: 'Industries',
					field: 'industry',
					...this.defaultColProps,
				},
				{
					headerName: 'Watchlist',
					field: 'watchlists',
					cellRenderer: this.agCellCompanyWatchlist,
					wrapText: true,
					autoHeight: true,
					...this.defaultColProps,
				},
				{
					headerName: 'Status',
					field: 'watchlists.status',
					wrapText: true,
					autoHeight: true,
					cellRenderer: this.agCellCompanyStatus,
					...this.defaultColProps,
				},
				{
					headerName: 'No of Tasks',
					field: 'numberOfTasks',
					cellRenderer: this.agCellCompanyNoOfTasks,
					...this.defaultColProps,
				},
			] as AgGridColumnItem[],

			watchlistGridApi: null,
			watchlistGridColumnApi: null,

			otherWatchlistGridApi: null,
			otherWatchlistGridColumnApi: null,

			companyGridApi: null,
			companyGridColumnApi: null,

			selectedColumnsFiltered: defaultColumnFields,
			defaultColumnFields,

			showManageColumns: false,
			alignValues: ['left', 'center', 'right'],
			selectedAlignValue: 'left',

			increment: true,
			resize: 0,

			rowHeights: ['small', 'medium', 'large'],

			selectedRowHeight: 'small',
		};

		this.onGridReady = this.onGridReady.bind(this);
	}

	defaultColProps = {
		sortable: true,
		filter: true,
		resizable: true,
		singleClickEdit: false,
		cellStyle: { justifyContent: 'flex-start' },
	};

	agCellWatchlist = (params: any) => {
		const { watchlistId, watchlistName } = params.data;
		return (
			<a className="cell-link" href={`/watchlists/${watchlistId}`}>
				{watchlistName}
			</a>
		);
	};

	agCellDate = (params: any) => {
		const { createdOn } = params.data;
		return (
			<div className="cell-wrapper">
				{moment(createdOn).format('Do MMMM YYYY')}
			</div>
		);
	};

	agCellAction = (params: any) => {
		const isMyWatchlist = params.eParentOfValue.closest('.my-watchlist');

		if (isMyWatchlist) {
			return (
				<div
					className="cell-action-wrapper"
					onClick={() => this.handleOpenDeleteModal('your', params.data)}
				>
					<ApIcon iconColor="#999999" iconName="delete_outline" />
				</div>
			);
		}

		const { isSuperAdmin } = this.props;
		return (
			<>
				{!isSuperAdmin ? (
					<div className="cell-action-wrapper cell-action-wrapper--empty"></div>
				) : (
					<div
						className="cell-action-wrapper"
						onClick={() => this.handleOpenDeleteModal('other', params.data)}
					>
						<ApIcon iconColor="#999999" iconName="delete_outline" />
					</div>
				)}
			</>
		);
	};

	agCellCompanyName = (params: any) => {
		const { companyId, companyName } = params.data;
		return (
			<a className="cell-link" href={`/prospects/${companyId}`}>
				<Highlighter
					highlightClassName="cell-highlight"
					searchWords={[this.state.allCompaniesSearchValue]}
					autoEscape={true}
					textToHighlight={companyName}
				/>
			</a>
		);
	};

	agCellCompanyWatchlist = (params: any) => {
		const { watchlists } = params.data;
		return (
			<div>
				{watchlists.map(
					(w: {
						companyStatus: string;
						numberOfUsers: number;
						watchlistId: number;
						watchlistName: string;
					}) => (
						<div key={w.watchlistId}>
							<a className="cell-link" href={`/watchlists/${w.watchlistId}`}>
								{w.watchlistName}
							</a>
						</div>
					),
				)}
			</div>
		);
	};

	agCellCompanyStatus = (params: any) => {
		const { watchlists } = params.data;
		return (
			<div>
				{watchlists.map(
					(w: {
						companyStatus: string;
						numberOfUsers: number;
						watchlistId: number;
						watchlistName: string;
					}) => (
						<div key={w.watchlistId}>{w.companyStatus}</div>
					),
				)}
			</div>
		);
	};

	agCellCompanyNoOfTasks = (params: any) => {
		const { numberOfTasks } = params.data;
		return <span>{numberOfTasks}</span>;
	};

	async componentDidMount() {
		window.scrollTo(0, 0);

		try {
			const [
				{ data: watchlistsData },
				{ data: industriesData },
			] = await Promise.all([getWatchlists(), getWatchlistIndustries()]);
			if (watchlistsData && industriesData) {
				this.setState({
					filteredAllCompanies: watchlistsData.allWatchlistCompanies,
					filteredMyCompanies: watchlistsData.companiesAssignedToUser,
					watchlists: watchlistsData,
					watchlistsCopy: watchlistsData,
					industriesOptions: industriesData.map((d) => ({
						label: d.name,
						value: d.id + '',
					})),
					isLoading: false,
					myIndustryFilters: watchlistsData.companiesAssignedToUser.reduce(
						(acc: IOption[], next: ICompanyListItem) => {
							if (!acc.find((o) => o.value === next.industry)) {
								acc.push({ label: next.industry, value: next.industry });
							}
							return acc;
						},
						[],
					),
					allIndustryFilters: watchlistsData.allWatchlistCompanies.reduce(
						(acc: IOption[], next: ICompanyListItem) => {
							if (!acc.find((o) => o.value === next.industry)) {
								acc.push({ label: next.industry, value: next.industry });
							}
							return acc;
						},
						[],
					),
				});
			} else {
				throw new Error('Error fetching watchlists data');
			}
		} catch (error) {
			this.setState({ isLoading: false });
			console.log(error);
		}

		const savedColumnsWidth = localStorage.getItem('companiesColumnsWidth');
		let newColumnNames;
		if (savedColumnsWidth) {
			const parsedWidths = JSON.parse(savedColumnsWidth);
			newColumnNames = this.state.columnNamesCompanies.map((column: any) => {
				const savedColumn = parsedWidths.find(
					(saved: any) => saved.field === column.field,
				);
				return {
					...column,
					width: savedColumn?.width || column,
				};
			});

			this.setState({ columnNamesCompanies: newColumnNames });
		}

		const savedWatchlistColumnsWidth = localStorage.getItem(
			'myWatchlistColumnsWidth',
		);
		let newWatchlistColumnNames;
		if (savedWatchlistColumnsWidth) {
			const parsedWidths = JSON.parse(savedWatchlistColumnsWidth);
			newWatchlistColumnNames = this.state.columnNamesWatchlist.map(
				(column: any) => {
					const savedColumn = parsedWidths.find(
						(saved: any) => saved.field === column.field,
					);
					return {
						...column,
						width: savedColumn?.width || column,
					};
				},
			);

			this.setState({ columnNamesWatchlist: newWatchlistColumnNames });
		}

		const savedOtherWatchlistColumnsWidth = localStorage.getItem(
			'otherWatchlistColumnsWidth',
		);
		let newOtherWatchlistColumnNames;
		if (savedOtherWatchlistColumnsWidth) {
			const parsedWidths = JSON.parse(savedOtherWatchlistColumnsWidth);
			newOtherWatchlistColumnNames = this.state.columnNamesWatchlist.map(
				(column: any) => {
					const savedColumn = parsedWidths.find(
						(saved: any) => saved.field === column.field,
					);
					return {
						...column,
						width: savedColumn?.width || column,
					};
				},
			);

			this.setState({
				otherColumnNamesWatchlist: newOtherWatchlistColumnNames,
			});
		}

		const filteredColumns: any = localStorage.getItem(
			'watchlistColumnsFiltered',
		);

		if (filteredColumns) {
			const mappedColumns: any = savedWatchlistColumnsWidth
				? newWatchlistColumnNames
				: this.state.columnNamesWatchlist;

			const filteredColumnsObj = JSON.parse(filteredColumns);

			const updatedColumNames = mappedColumns.map((column: any) => {
				const matchingFilter = filteredColumnsObj.filter(
					(filteredColumn: any) => filteredColumn.value == column.field,
				);

				if (matchingFilter.length == 0) {
					return { ...column, hide: true };
				} else {
					return column;
				}
			});

			this.setState({
				selectedColumnsFiltered: filteredColumnsObj,
				columnNamesWatchlist: updatedColumNames,
			});
		}
	}

	handleCloseModal = () => {
		this.setState({ isAddWatchlistOpen: false, newWatchlist: NEW_WATCHLIST });
	};

	handleOpenModal = () => {
		this.setState({ isAddWatchlistOpen: true });
	};

	handleOpenDeleteModal = (
		currentList: string,
		currentWatchlistItem: IWatchlistListItem,
	) => {
		this.setState({
			isDeleteWatchlistOpen: true,
			currentList,
			currentWatchlistItem,
		});
	};

	handleCloseDeleteModal = () => {
		this.setState({
			isDeleteWatchlistOpen: false,
			currentWatchlistItem: {} as IWatchlistListItem,
			currentList: '',
		});
	};

	handleDeleteWatchlist = async () => {
		const {
			currentList,
			currentWatchlistItem,
			watchlists: { watchlistAssignedToUser, otherWatchlist },
		} = this.state;

		const currentWatchlistIndex =
			currentList === 'yours'
				? watchlistAssignedToUser.findIndex(
						(w) => w.watchlistId === currentWatchlistItem.watchlistId,
				  )
				: otherWatchlist.findIndex(
						(w) => w.watchlistId === currentWatchlistItem.watchlistId,
				  );

		try {
			this.setState((prevState) => {
				return {
					isDeleteWatchlistOpen: false,
					watchlists: {
						...prevState.watchlists,
						watchlistAssignedToUser:
							currentList === 'yours'
								? prevState.watchlists.watchlistAssignedToUser.filter(
										(w) => w.watchlistId !== currentWatchlistItem.watchlistId,
								  )
								: prevState.watchlists.watchlistAssignedToUser,
						otherWatchlist:
							currentList === 'other'
								? prevState.watchlists.otherWatchlist.filter(
										(w) => w.watchlistId !== currentWatchlistItem.watchlistId,
								  )
								: prevState.watchlists.otherWatchlist,
					},
				};
			});

			await deleteWatchlist(currentWatchlistItem.watchlistId);

			snack.success({
				message: `Wathchlist ${currentWatchlistItem.watchlistName} is removed`,
			});

			this.setState({
				currentWatchlistItem: {} as IWatchlistListItem,
				currentList: '',
			});
		} catch (error) {
			snack.error({
				hideAfter: 10,
				message:
					error.message && error.message.replace('Error: ', '')
						? error.message.replace('Error: ', '').replace(/[[\]'"]+/g, '')
						: `Error while deleting ${currentWatchlistItem.watchlistName} watchlist`,
			});

			this.setState((prevState) => {
				return {
					watchlists: {
						...prevState.watchlists,
						watchlistAssignedToUser:
							currentList === 'yours'
								? insert(
										prevState.watchlists.watchlistAssignedToUser,
										currentWatchlistIndex,
										currentWatchlistItem,
								  )
								: prevState.watchlists.watchlistAssignedToUser,
						otherWatchlist:
							currentList === 'other'
								? insert(
										prevState.watchlists.otherWatchlist,
										currentWatchlistIndex,
										currentWatchlistItem,
								  )
								: prevState.watchlists.otherWatchlist,
					},
				};
			});

			this.setState({
				currentWatchlistItem: {} as IWatchlistListItem,
				currentList: '',
			});
		}
	};

	handleAddNewWatchlist = async () => {
		console.log(this.state.newWatchlist);

		const {
			watchlistId,
			watchlistName,
			description,
			communities,
			industries,
		} = this.state.newWatchlist;

		this.setState({
			isUpdating: true,
			isAddWatchlistOpen: false,
			newWatchlist: NEW_WATCHLIST,
		});

		try {
			this.setState((prevState) => {
				return {
					isUpdating: false,
					watchlists: {
						...prevState.watchlists,
						watchlistAssignedToUser: [
							...prevState.watchlists.watchlistAssignedToUser,
							{
								createdOn: moment().format('YYYY-MM-DD') + 'T00:00:00.000Z',
								numberOfCompanies: 0,
								numberOfSubscribers: 1,
								status: 1,
								watchlistName,
								watchlistId,
								propsectStatusCount: {
									drop: 0,
									monitor: 0,
									pursuit: 0,
								},
							},
						],
					},
				};
			});

			const { data } = await createWatchlist(
				watchlistName.trim(),
				description.trim(),
				communities.map((c) => c.id),
				industries.map((i) => i.id),
			);

			this.setState((prevState) => {
				return {
					watchlists: {
						...prevState.watchlists,
						watchlistAssignedToUser: prevState.watchlists.watchlistAssignedToUser.map(
							(w) => {
								if (w.watchlistId === -1) {
									return {
										...w,
										watchlistId: data,
									};
								} else {
									return w;
								}
							},
						),
					},
				};
			});

			snack.success({
				message: `Wathchlist '${watchlistName}' was added successfully`,
			});
		} catch (error) {
			this.setState((prevState) => {
				return {
					isUpdating: false,
					isAddWatchlistOpen: true,
					watchlists: {
						...prevState.watchlists,
						watchlistAssignedToUser: prevState.watchlists.watchlistAssignedToUser.filter(
							(w) => w.watchlistId !== -1,
						),
					},
				};
			});

			snack.error({
				message:
					error.message && error.message.replace('Error: ', '')
						? error.message.replace('Error: ', '').replace(/[[\]'"]+/g, '')
						: `Error creating watchlist '${watchlistName}'`,
			});
		}
	};

	handleNameChange = (value: string) => {
		this.setState((prevState) => {
			return {
				newWatchlist: { ...prevState.newWatchlist, watchlistName: value },
			};
		});
	};

	handleCustomColumnNameChange = (value: string) => {
		this.setState({
			customColumnName: value,
		});
	};

	handleCommunitiesChange = (options: IOption[]) => {
		this.setState((prevState) => {
			return {
				newWatchlist: {
					...prevState.newWatchlist,
					communities: options.map((o) => ({ id: +o.value, name: o.label })),
				},
			};
		});
	};

	handleIndustriesChange = (options: IOption[]) => {
		this.setState((prevState) => {
			return {
				newWatchlist: {
					...prevState.newWatchlist,
					industries: options.map((o) => ({ id: +o.value, name: o.label })),
				},
			};
		});
	};

	handleDescriptionChange = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { value } = event.target as HTMLInputElement;
		this.setState((prevState) => {
			return {
				newWatchlist: { ...prevState.newWatchlist, description: value },
			};
		});
	};

	handleAllCompaniesSearchChange = async (value: string) => {
		const { allSelectedIndustryFilters } = this.state;

		this.setState({ allCompaniesSearchValue: value });
		const formattedSearchString = value.toLowerCase().trim();

		this.setState((prevState) => {
			const currentFilteredCompanies =
				allSelectedIndustryFilters.length === 0
					? prevState.watchlists.allWatchlistCompanies
					: prevState.watchlists.allWatchlistCompanies.filter((c) =>
							allSelectedIndustryFilters
								.map((o) => o.value)
								.includes(c.industry),
					  );

			const filteredAllCompanies = currentFilteredCompanies.filter((c) =>
				c.companyName.toLowerCase().includes(formattedSearchString),
			);

			return {
				filteredAllCompanies,
				allIndustryFilters: filteredAllCompanies.reduce(
					(acc: IOption[], next: ICompanyListItem) => {
						if (!acc.find((o) => o.value === next.industry)) {
							acc.push({ label: next.industry, value: next.industry });
						}
						return acc;
					},
					[],
				),
			};
		});
	};

	handleMyCompaniesSearchChange = async (value: string) => {
		const { mySelectedIndustryFilters } = this.state;

		this.setState({ myCompaniesSearchValue: value });
		const formattedSearchString = value.toLowerCase().trim();

		this.setState((prevState) => {
			const currentFilteredCompanies =
				mySelectedIndustryFilters.length === 0
					? prevState.watchlists.companiesAssignedToUser
					: prevState.watchlists.companiesAssignedToUser.filter((c) =>
							mySelectedIndustryFilters
								.map((o) => o.value)
								.includes(c.industry),
					  );

			const filteredMyCompanies = currentFilteredCompanies.filter((c) =>
				c.companyName.toLowerCase().includes(formattedSearchString),
			);

			return {
				filteredMyCompanies,
				myIndustryFilters: filteredMyCompanies.reduce(
					(acc: IOption[], next: ICompanyListItem) => {
						if (!acc.find((o) => o.value === next.industry)) {
							acc.push({ label: next.industry, value: next.industry });
						}
						return acc;
					},
					[],
				),
			};
		});
	};

	handleApplyMyFilters = () => {
		const {
			mySelectedIndustryFilters,
			myCompaniesSearchValue,
			watchlists: { companiesAssignedToUser },
		} = this.state;

		let currentFilteredCompanies =
			myCompaniesSearchValue === ''
				? companiesAssignedToUser
				: companiesAssignedToUser.filter((c) =>
						c.companyName
							.toLowerCase()
							.includes(myCompaniesSearchValue.toLowerCase().trim()),
				  );

		if (mySelectedIndustryFilters.length > 0) {
			currentFilteredCompanies = currentFilteredCompanies.filter((c) =>
				mySelectedIndustryFilters.map((o) => o.value).includes(c.industry),
			);
		} else {
			this.setState({
				myIndustryFilters: companiesAssignedToUser.reduce(
					(acc: IOption[], next: ICompanyListItem) => {
						if (!acc.find((o) => o.value === next.industry)) {
							acc.push({ label: next.industry, value: next.industry });
						}
						return acc;
					},
					[],
				),
			});
		}

		this.setState({
			filteredMyCompanies: currentFilteredCompanies,
		});
	};

	handleApplyAllFilters = () => {
		const {
			allSelectedIndustryFilters,
			allCompaniesSearchValue,
			watchlists: { allWatchlistCompanies },
		} = this.state;

		let currentFilteredCompanies =
			allCompaniesSearchValue === ''
				? allWatchlistCompanies
				: allWatchlistCompanies.filter((c) =>
						c.companyName
							.toLowerCase()
							.includes(allCompaniesSearchValue.toLowerCase().trim()),
				  );

		if (allSelectedIndustryFilters.length > 0) {
			currentFilteredCompanies = currentFilteredCompanies.filter((c) =>
				allSelectedIndustryFilters.map((o) => o.value).includes(c.industry),
			);
		} else {
			this.setState({
				myIndustryFilters: allWatchlistCompanies.reduce(
					(acc: IOption[], next: ICompanyListItem) => {
						if (!acc.find((o) => o.value === next.industry)) {
							acc.push({ label: next.industry, value: next.industry });
						}
						return acc;
					},
					[],
				),
			});
		}

		this.setState({
			filteredAllCompanies: currentFilteredCompanies,
		});
	};

	handleSelectMyIndustryFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				mySelectedIndustryFilters: options as IOption[],
			}),
			this.handleApplyMyFilters,
		);
	};

	handleSelectAllIndustryFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				allSelectedIndustryFilters: options as IOption[],
			}),
			this.handleApplyAllFilters,
		);
	};

	handleClearMyFilters = () => {
		this.setState({
			mySelectedIndustryFilters: [],
			myCompaniesSearchValue: '',
			filteredMyCompanies: this.state.watchlists.companiesAssignedToUser,
			myIndustryFilters: this.state.watchlists.companiesAssignedToUser.reduce(
				(acc: IOption[], next: ICompanyListItem) => {
					if (!acc.find((o) => o.value === next.industry)) {
						acc.push({ label: next.industry, value: next.industry });
					}
					return acc;
				},
				[],
			),
		});
	};

	handleClearAllFilters = () => {
		this.setState({
			allSelectedIndustryFilters: [],
			allCompaniesSearchValue: '',
			filteredAllCompanies: this.state.watchlists.allWatchlistCompanies,
			allIndustryFilters: this.state.watchlists.allWatchlistCompanies.reduce(
				(acc: IOption[], next: ICompanyListItem) => {
					if (!acc.find((o) => o.value === next.industry)) {
						acc.push({ label: next.industry, value: next.industry });
					}
					return acc;
				},
				[],
			),
		});
	};

	onWatchlistColumnResized(params: any) {
		const { columnApi } = params;
		const columns = columnApi.getAllColumns();
		const newColumnsWidth = columns.map((column: any) => ({
			field: column.colId,
			width: column.getActualWidth(),
		}));
		localStorage.setItem(
			'myWatchlistColumnsWidth',
			JSON.stringify(newColumnsWidth),
		);
	}

	onOtherWatchlistColumnResized(params: any) {
		const { columnApi } = params;
		const columns = columnApi.getAllColumns();
		const newColumnsWidth = columns.map((column: any) => ({
			field: column.colId,
			width: column.getActualWidth(),
		}));
		localStorage.setItem(
			'otherWatchlistColumnsWidth',
			JSON.stringify(newColumnsWidth),
		);
	}

	onCompanyColumnResized(params: any) {
		const { columnApi } = params;
		const columns = columnApi.getAllColumns();
		const newColumnsWidth = columns.map((column: any) => ({
			field: column.colId,
			width: column.getActualWidth(),
		}));
		localStorage.setItem(
			'companiesColumnsWidth',
			JSON.stringify(newColumnsWidth),
		);
	}

	handleSelectedColumns = (options: IOption | IOption[]) => {
		this.setState(
			{
				selectedColumnsFiltered: options as IOption[],
			},
			() => {
				localStorage.setItem(
					'watchlistColumnsFiltered',
					JSON.stringify(this.state.selectedColumnsFiltered),
				);
			},
		);

		this.toggleColumn(options);
	};

	toggleColumn = (options: any) => {
		if (this.state.watchlistGridApi && this.state.watchlistGridColumnApi) {
			const columnsToShow = options.map((option: any) => option.value);

			if (columnsToShow.length === 0) {
				this.state.watchlistGridColumnApi.setColumnsVisible(
					this.state.columnNamesWatchlist.map((column: any) => column.field),
					false,
				);
			} else {
				this.state.watchlistGridColumnApi
					.getAllColumns()
					.forEach((column: any) => {
						const isVisible = columnsToShow.includes(column.colId);

						this.state.watchlistGridColumnApi.setColumnVisible(
							column.colId,
							isVisible,
						);
					});
			}
		}
	};

	onGridReady(params: any) {
		this.setState({
			watchlistGridApi: params.api,
			watchlistGridColumnApi: params.columnApi,
		});
	}

	handleAddNewColumn = () => {
		const selectedColumnsFiltered = JSON.parse(
			JSON.stringify(this.state.selectedColumnsFiltered),
		);

		let columnNamesWatchlist = this.state.columnNamesWatchlist;
		let customColumnNamesWatchlist = this.state.customColumnNamesWatchlist;

		if (this.state.customColumnName !== '') {
			selectedColumnsFiltered.push({
				label: this.state.customColumnName,
				value: this.state.customColumnName,
			});

			columnNamesWatchlist = [
				...this.state.columnNamesWatchlist,
				{
					headerName: this.state.customColumnName,
					field: this.state.customColumnName,
					...this.defaultColProps,
				},
			];

			customColumnNamesWatchlist = [
				...this.state.customColumnNamesWatchlist,
				{
					headerName: this.state.customColumnName,
					field: this.state.customColumnName,
					...this.defaultColProps,
				},
			];
		} else {
			selectedColumnsFiltered.push(this.state.selectedNewColumn);
		}

		this.setState(
			{
				selectedColumnsFiltered,
				columnNamesWatchlist,
				customColumnNamesWatchlist,
				isManageColumnsOpen: false,
				customColumnName: '',
				selectedNewColumn: {} as IOption,
			},
			() => {
				localStorage.setItem(
					'watchlistColumnsFiltered',
					JSON.stringify(this.state.selectedColumnsFiltered),
				);

				localStorage.setItem(
					'customColumnNamesWatchlist',
					JSON.stringify(this.state.customColumnNamesWatchlist),
				);
			},
		);

		this.toggleColumn(selectedColumnsFiltered);
	};

	handleCloseColumnsModal = () => {
		this.setState({
			isManageColumnsOpen: false,
			customColumnName: '',
			selectedNewColumn: {} as IOption,
		});
	};

	onSelectColumnChange = (value: any) => {
		this.setState({
			selectedNewColumn: value,
		});
	};

	handleRemoveColumn = (column: any) => {
		const selectedColumnsFiltered = this.state.selectedColumnsFiltered;
		const customColumnNamesWatchlist = this.state.customColumnNamesWatchlist;
		const columnIndex = selectedColumnsFiltered.findIndex(
			(x: IOption) => x === column,
		);
		const customColumnNamesWatchlistIndex = customColumnNamesWatchlist.findIndex(
			(x: IOption) => x === column,
		);

		if (columnIndex >= 0) {
			selectedColumnsFiltered.splice(columnIndex, 1);
			this.setState(
				{
					selectedColumnsFiltered,
				},
				() => {
					localStorage.setItem(
						'watchlistColumnsFiltered',
						JSON.stringify(this.state.selectedColumnsFiltered),
					);
				},
			);

			if (customColumnNamesWatchlistIndex >= 0) {
				customColumnNamesWatchlist.splice(customColumnNamesWatchlistIndex, 1);
				this.setState(
					{
						customColumnNamesWatchlist,
					},
					() => {
						localStorage.setItem(
							'customColumnNamesWatchlist',
							JSON.stringify(this.state.customColumnNamesWatchlist),
						);
					},
				);
			}

			this.toggleColumn(selectedColumnsFiltered);
		}
	};

	getDistinctSelectedColumns = () => {
		return this.state.defaultColumnFields.filter(
			(field) =>
				!this.state.selectedColumnsFiltered.some(
					(filteredColumn: IOption) => filteredColumn.value === field.value,
				),
		);
	};

	onChangeAlignment = (alignment: string) => {
		const updatedOtherColumns = this.state.otherColumnNamesWatchlist.map(
			(column) => {
				let justifyContentValue;

				switch (alignment) {
					case 'left':
						justifyContentValue = 'flex-start';
						break;
					case 'center':
						justifyContentValue = 'center';
						break;
					case 'right':
						justifyContentValue = 'flex-end';
						break;
					default:
						justifyContentValue = 'flex-end';
				}

				return {
					...column,
					cellStyle: {
						justifyContent: justifyContentValue,
					},
				};
			},
		);

		const updatedColumns = this.state.columnNamesWatchlist.map((column) => {
			let justifyContentValue;

			switch (alignment) {
				case 'left':
					justifyContentValue = 'flex-start';
					break;
				case 'center':
					justifyContentValue = 'center';
					break;
				case 'right':
					justifyContentValue = 'flex-end';
					break;
				default:
					justifyContentValue = 'flex-end';
			}

			return {
				...column,
				cellStyle: {
					justifyContent: justifyContentValue,
				},
			};
		});

		this.setState({
			otherColumnNamesWatchlist: updatedOtherColumns,
			columnNamesWatchlist: updatedColumns,
			selectedAlignValue: alignment,
		});
	};

	onChangeHeader = (props: any) => {
		const { displayName } = props;
		const margin = '0 auto';
		const marginLeft = 'auto';
		const marginRight = 'auto';

		if (this.state.selectedAlignValue == 'right') {
			return <div style={{ marginLeft }}>{displayName}</div>;
		} else if (this.state.selectedAlignValue == 'center') {
			return <div style={{ margin }}>{displayName}</div>;
		}

		return <div style={{ marginRight }}>{displayName}</div>;
	};

	toggleManageColumns = () => {
		this.setState((prevState) => ({
			showManageColumns: !prevState.showManageColumns,
		}));
	};

	onHandleHeight = (value: string) => {
		let newResize = 0;

		if (value === 'small') {
			newResize = 0;
		} else if (value === 'medium') {
			newResize = 20;
		} else if (value === 'large') {
			newResize = 40;
		}

		this.setState({ selectedRowHeight: value, resize: newResize }, () => {
			if (this.state.watchlistGridApi) {
				this.state.watchlistGridApi.resetRowHeights();
			}

			if (this.state.otherWatchlistGridApi) {
				this.state.otherWatchlistGridApi.resetRowHeights();
			}
		});
	};

	getRowHeight = (params: any) => {
		const { selectedRowHeight, resize } = this.state;

		if (selectedRowHeight === 'small') {
			return 40 + resize;
		} else if (selectedRowHeight === 'medium') {
			return 60 + resize;
		} else if (selectedRowHeight === 'large') {
			return 80 + resize;
		}

		return 40;
	};

	render() {
		const {
			isLoading,
			isUpdating,
			currentTab,
			isAddWatchlistOpen,
			isDeleteWatchlistOpen,
			currentWatchlistItem,
			watchlists: {
				otherWatchlist,
				watchlistAssignedToUser,
				companiesAssignedToUser,
				allWatchlistCompanies,
			},
			newWatchlist,
			industriesOptions,
			allCompaniesSearchValue,
			myCompaniesSearchValue,
			filteredAllCompanies,
			filteredMyCompanies,
			myIndustryFilters,
			allIndustryFilters,
			mySelectedIndustryFilters,
			allSelectedIndustryFilters,
			customColumnName,
			selectedNewColumn,
		} = this.state;

		const isWatchlistConfigured =
			newWatchlist.watchlistName && newWatchlist.description;

		return (
			<ScreenClassProvider>
				<div className="secondary-nav">
					<div className="wrapper">
						<div className="max-width">
							<div className="watchlist-nav">Watchlists</div>
						</div>
					</div>
				</div>
				{isLoading ? (
					<>
						<br />
						<br />
						<br />
						<br />
						<ApLoaderDefault />
					</>
				) : (
					<div className="container">
						<div className="wrapper">
							<div className="max-width">
								<div className="watchlists-container">
									<div className="dashboard__tabs">
										<ApButtonGroup justifyContent="initial" spacingSize={0}>
											<ApButtonTab
												isSelected={currentTab === TabName.AllWatchlists}
												onClick={() => {
													this.setState({ currentTab: TabName.AllWatchlists });
												}}
											>
												Watchlists
											</ApButtonTab>
											<ApButtonTab
												isSelected={currentTab === TabName.AllCompanies}
												onClick={() => {
													this.setState({ currentTab: TabName.AllCompanies });
												}}
											>
												All Companies
											</ApButtonTab>
											<ApButtonTab
												isSelected={currentTab === TabName.MyCompanies}
												onClick={() => {
													this.setState({ currentTab: TabName.MyCompanies });
												}}
											>
												{`My Companies (${companiesAssignedToUser.length})`}
											</ApButtonTab>
										</ApButtonGroup>
									</div>

									{currentTab === TabName.AllWatchlists ? (
										<>
											<div className="watchlists-container__heading-wrapper">
												<div className="watchlists-container__h1">{`My Watchlists (${watchlistAssignedToUser.length})`}</div>

												<div className="watchlist-header">
													<ApButtonMain
														onClick={this.toggleManageColumns}
														style={{ marginRight: '20px' }}
													>
														<div>
															{!this.state.showManageColumns
																? 'Manage Columns'
																: 'Close Manage Columns'}
														</div>
													</ApButtonMain>
													<ApButtonMain
														onClick={this.handleOpenModal}
														disabled={isUpdating}
													>
														<div className="button-wrapper">
															<ApIcon
																iconName="baseline_add"
																iconColor="#ffffff"
															></ApIcon>
															<div>Create New Watchlist</div>
														</div>
													</ApButtonMain>
												</div>
											</div>

											{this.state.showManageColumns && (
												<div className="manage-columns">
													<div className="manage-columns__item">
														<span className="manage-columns__item--title">
															Align values:
														</span>
														<div>
															{this.state.alignValues.map((value) => {
																return (
																	<ApButtonFilter
																		style={{ height: '30px' }}
																		key={value}
																		isSelected={
																			value === this.state.selectedAlignValue
																		}
																		className="news__category"
																		onClick={() =>
																			this.onChangeAlignment(value)
																		}
																	>
																		{value}
																	</ApButtonFilter>
																);
															})}
														</div>
													</div>

													<div className="manage-columns__item">
														<span className="manage-columns__item--title">
															Row Height:
														</span>
														<div>
															{this.state.rowHeights.map((value) => {
																return (
																	<ApButtonFilter
																		style={{ height: '30px' }}
																		key={value}
																		isSelected={
																			value === this.state.selectedRowHeight
																		}
																		className="news__category"
																		onClick={() => this.onHandleHeight(value)}
																	>
																		{value}
																	</ApButtonFilter>
																);
															})}
														</div>
													</div>
												</div>
											)}
											<div className="watchlists-container__list">
												<div
													style={{
														height:
															watchlistAssignedToUser.length > 0
																? '100%'
																: '250px',
														width: '100%',
														minHeight: '250px',
													}}
													className="table-panel__grid ag-theme-balham my-watchlist"
												>
													{this.state.showManageColumns && (
														<div className="manage-columns-wrapper">
															<div
																className="prospect-widget"
																style={{ width: '100%' }}
															>
																<div className="prospect-widget__title">
																	<div>Columns</div>
																	<div>
																		<ApButtonSecondary
																			onClick={() => {
																				this.setState({
																					isManageColumnsOpen: true,
																				});
																			}}
																		>
																			<div className="button-wrapper">
																				<ApIcon
																					iconName="baseline_add"
																					iconColor="#333333"
																				></ApIcon>
																				<div>Add New Column</div>
																			</div>
																		</ApButtonSecondary>
																	</div>
																</div>
																<div className="prospect-widget__content">
																	{this.state.selectedColumnsFiltered.map(
																		(t: any) => {
																			return (
																				<ApChip
																					onClick={() =>
																						this.handleRemoveColumn(t)
																					}
																					className="prospect-widget__tag"
																					key={t.value}
																					iconName="outline_cancel"
																				>
																					{t.label}
																				</ApChip>
																			);
																		},
																	)}
																</div>
															</div>
														</div>
													)}
													<AgGridReact
														columnDefs={this.state.columnNamesWatchlist}
														rowData={watchlistAssignedToUser}
														modules={AllCommunityModules}
														rowHeight={36}
														onColumnResized={this.onWatchlistColumnResized}
														onGridReady={this.onGridReady}
														getRowHeight={this.getRowHeight}
													/>
												</div>
											</div>
											<div className="watchlists-container__heading-wrapper">
												<div className="watchlists-container__h1">{`Other watchlists (${otherWatchlist.length})`}</div>
											</div>
											<div className="watchlists-container__list">
												<div
													style={{
														height:
															otherWatchlist.length > 0 ? '100%' : '250px',
														width: '100%',
														minHeight: '250px',
													}}
													className="table-panel__grid ag-theme-balham other-watchlist"
												>
													<AgGridReact
														columnDefs={this.state.otherColumnNamesWatchlist}
														rowData={otherWatchlist}
														modules={AllCommunityModules}
														rowHeight={36}
														onColumnResized={this.onOtherWatchlistColumnResized}
														onGridReady={(params) => {
															this.setState({
																otherWatchlistGridApi: params.api,
																otherWatchlistGridColumnApi: params.columnApi,
															});
														}}
														getRowHeight={this.getRowHeight}
													/>
												</div>
											</div>
										</>
									) : currentTab === TabName.MyCompanies ? (
										<>
											<div className="watchlists-container__heading-wrapper">
												<div className="watchlists-container__search-wrapper">
													<div className="watchlists-container__h1">{`My Companies (${companiesAssignedToUser.length})`}</div>
													<div className="watchlists-container__filter">
														<ApInput
															value={myCompaniesSearchValue}
															onChange={this.handleMyCompaniesSearchChange}
															placeholder="Search"
															icon="baseline_search"
														/>
														<ApSimpleDropdown
															multiple
															// disabled={prospects.length <= 1}
															label={
																mySelectedIndustryFilters.length
																	? `${mySelectedIndustryFilters.length} industry(ies)`
																	: 'All industries'
															}
															onChange={this.handleSelectMyIndustryFilters}
															options={myIndustryFilters}
															selected={mySelectedIndustryFilters}
														/>
														<ApButtonSecondary
															className="watchlists-container__clear"
															onClick={this.handleClearMyFilters}
															disabled={
																myCompaniesSearchValue === '' &&
																mySelectedIndustryFilters.length === 0
															}
														>
															Clear
														</ApButtonSecondary>
													</div>
												</div>
											</div>

											<div className="watchlists-container__list">
												<div
													style={{
														height:
															filteredMyCompanies.length > 0 ? '100%' : '250px',
														width: '100%',
														minHeight: '250px',
													}}
													className="table-panel__grid ag-theme-balham all-companies"
												>
													<AgGridReact
														columnDefs={this.state.columnNamesCompanies}
														rowData={filteredMyCompanies}
														modules={AllCommunityModules}
														rowBuffer={filteredMyCompanies.length}
														onColumnResized={this.onCompanyColumnResized}
														onGridReady={(params) => {
															this.setState({
																companyGridApi: params.api,
																companyGridColumnApi: params.columnApi,
															});
														}}
													/>
												</div>
											</div>
										</>
									) : (
										<>
											<div className="watchlists-container__heading-wrapper">
												<div className="watchlists-container__search-wrapper">
													<div className="watchlists-container__h1">
														{`All Companies (${allWatchlistCompanies.length})`}
													</div>
													<div className="watchlists-container__filter">
														<ApInput
															value={allCompaniesSearchValue}
															onChange={this.handleAllCompaniesSearchChange}
															placeholder="Search"
															icon="baseline_search"
														/>
														<ApSimpleDropdown
															multiple
															// disabled={prospects.length <= 1}
															label={
																allSelectedIndustryFilters.length
																	? `${allSelectedIndustryFilters.length} industry(ies)`
																	: 'All industries'
															}
															onChange={this.handleSelectAllIndustryFilters}
															options={allIndustryFilters}
															selected={allSelectedIndustryFilters}
														/>
														<ApButtonSecondary
															className="watchlists-container__clear"
															onClick={this.handleClearAllFilters}
															disabled={
																allCompaniesSearchValue === '' &&
																allSelectedIndustryFilters.length === 0
															}
														>
															Clear
														</ApButtonSecondary>
													</div>
												</div>
											</div>
											<div className="watchlists-container__list">
												<div
													style={{
														height:
															filteredAllCompanies.length > 0
																? '100%'
																: '250px',
														width: '100%',
														minHeight: '250px',
													}}
													className="table-panel__grid ag-theme-balham all-companies"
												>
													<AgGridReact
														columnDefs={this.state.columnNamesCompanies}
														rowData={filteredAllCompanies}
														rowBuffer={filteredAllCompanies.length}
														modules={AllCommunityModules}
														onColumnResized={this.onCompanyColumnResized}
														onGridReady={(params) => {
															this.setState({
																companyGridApi: params.api,
																companyGridColumnApi: params.columnApi,
															});
														}}
													/>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
				<ApModal
					isDismissible
					className="modal-add"
					isOpen={isAddWatchlistOpen}
					hasClosed={this.handleCloseModal}
					header="Add new Watchlist"
					footer={() => (
						<div className="modal__footer">
							<ApButtonSecondary onClick={this.handleCloseModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								disabled={!isWatchlistConfigured}
								onClick={this.handleAddNewWatchlist}
							>
								Add
							</ApButtonMain>
						</div>
					)}
				>
					<div className="modal__content">
						<div className="modal__label">Watchlist name *</div>
						<ApInput
							name="watchlist-name"
							onChange={this.handleNameChange}
							value={newWatchlist.watchlistName}
						/>
						<br />
						<div className="modal__label">Communities</div>
						<MultiSelect
							options={COMMUNITIES_OPTIONS}
							value={newWatchlist.communities.map((c) => ({
								value: c.id + '',
								label: c.name,
							}))}
							onChange={this.handleCommunitiesChange}
						/>
						<br />
						<div className="modal__label">Industries</div>
						<MultiSelect
							options={industriesOptions}
							value={newWatchlist.industries.map((i) => ({
								value: i.id + '',
								label: i.name,
							}))}
							onChange={this.handleIndustriesChange}
						/>
						<br />
						<div className="modal__label">
							<div>Watchlist description *</div>
							<div>{`${newWatchlist.description.length}/150`}</div>
						</div>
						<textarea
							maxLength={150}
							name="notes"
							rows={5}
							className="modal__description"
							value={newWatchlist.description}
							onChange={this.handleDescriptionChange}
						/>
					</div>
				</ApModal>
				<ApModal
					className="modal-delete"
					isOpen={isDeleteWatchlistOpen}
					hasClosed={this.handleCloseDeleteModal}
					footer={() => (
						<div className="modal__footer">
							<ApButtonSecondary onClick={this.handleCloseDeleteModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								onClick={this.handleDeleteWatchlist}
								disabled={isUpdating}
							>
								Delete
							</ApButtonMain>
						</div>
					)}
				>
					<div className="modal__content">{`Are you sure you want to delete ${
						currentWatchlistItem ? currentWatchlistItem.watchlistName : ''
					} watchlist?`}</div>
				</ApModal>

				<ApModal
					isDismissible
					className="prospect-widget__tag-modal"
					isOpen={this.state.isManageColumnsOpen}
					hasClosed={this.handleCloseColumnsModal}
					header="Manage Columns"
				>
					<div className="modal__content">
						<div className="input-form">
							<label className="comment-modal__label">Columns</label>
							<MultiSelectCreatable
								options={[
									...this.getDistinctSelectedColumns(),
									{ label: 'Custom Column', value: 'custom' },
								]}
								onCreateOption={this.onSelectColumnChange}
								onChange={this.onSelectColumnChange}
							/>
						</div>
						<br />
						{selectedNewColumn.value === 'custom' && (
							<div className="input-form">
								<label className="comment-modal__label">Custom column</label>
								<ApInput
									name="custom-column-name"
									placeholder="Custom Column"
									onChange={this.handleCustomColumnNameChange}
									value={customColumnName}
								/>
							</div>
						)}
					</div>
					<br />
					<br />
					<div className="modal__footer">
						<ApButtonSecondary onClick={this.handleCloseColumnsModal}>
							Cancel
						</ApButtonSecondary>
						<ApButtonMain
							disabled={
								selectedNewColumn.value === 'custom' && !customColumnName
							}
							onClick={this.handleAddNewColumn}
						>
							Add
						</ApButtonMain>
					</div>
				</ApModal>
			</ScreenClassProvider>
		);
	}
}

export const WatchlistsContainer = withRouter(WatchlistsContainerComponent);
