import React from 'react';
import { WordsHighlight } from 'react-words-highlight';
import onClickOutside from 'react-onclickoutside';
import { ApInput, ApButtonSecondary } from '@alixpartners/ui-components';

import { ITag } from '../../types';
import { triggerInputChange } from '../../utils';

import './TagsSearch.scss';

interface ITagsSearchProps {
	tags: ITag[];
	onTagSelect(tagString: string): void;
	onChange?: (tagString: string) => void;
	showSelectedValue?: boolean;
	showAddButton?: boolean;
	autoFocus?: boolean;
	placeholder?: string;
}

interface ITagsSearchState {
	value: string;
	results: ITag[];
	isFocused: boolean;
}

class TagsSearchComponent extends React.Component<
	ITagsSearchProps,
	ITagsSearchState
> {
	state = {
		value: '',
		results: this.props.tags,
		isFocused: false,
	};

	componentDidUpdate(prevProps: ITagsSearchProps) {
		if (prevProps.tags.length !== this.props.tags.length) {
			this.setState({ results: this.props.tags });
		}
	}

	handleSearchChange = async (value: string) => {
		const { tags, onChange } = this.props;

		if (value.length > 15) {
			return null;
		}

		if (onChange) {
			onChange(value);
		}

		this.setState({
			value,
			results: tags.filter((t) =>
				t.name.toLowerCase().includes(value.toLowerCase()),
			),
		});
	};

	handleClickOutside = () => {
		this.setState({ isFocused: false });
	};

	handleSelectTag = (tag: ITag) => {
		const { onTagSelect, showSelectedValue, tags } = this.props;

		this.setState(
			{
				isFocused: false,
				results: tags,
				value: showSelectedValue ? tag.name : '',
			},
			() => {
				onTagSelect(tag.name);
			},
		);
	};

	handleAddNewTag = () => {
		const { value } = this.state;

		// Hack to clear iinput
		triggerInputChange(document.getElementById('tags-search-input'), '');
		this.setState((prevState) => {
			return {
				value: '',
				isFocused: false,
			};
		});

		this.props.onTagSelect(value);
	};

	render() {
		const { autoFocus, showAddButton, tags, placeholder } = this.props;
		const { value, results, isFocused } = this.state;

		const allTags = tags.map((t) => t.name);

		return (
			<div className="tags-search">
				<div className="tags-search__wrapper">
					<ApInput
						/*
						// @ts-ignore */
						id="tags-search-input"
						autoFocus={autoFocus}
						value={value}
						onChange={this.handleSearchChange}
						placeholder={
							placeholder
								? placeholder
								: 'Select from existing Tags or Add New Tag'
						}
						onFocus={() => {
							this.setState({ isFocused: true });
						}}
					/>

					{results.length > 0 && isFocused && (
						<div className="results">
							<ul>
								{results.map((t, index) => (
									<li
										key={t.tagId}
										onClick={(e) => {
											this.handleSelectTag(t);
										}}
									>
										<WordsHighlight
											text={t.name}
											query={value}
											highlightTag="strong"
										/>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
				{showAddButton && (
					<ApButtonSecondary
						className="tags-search__new-tag"
						disabled={!value || allTags.includes(value)}
						onClick={this.handleAddNewTag}
					>
						Add Tag
					</ApButtonSecondary>
				)}
			</div>
		);
	}
}

export const TagsSearch = onClickOutside(TagsSearchComponent);
