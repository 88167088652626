import React from 'react';
import moment from 'moment';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ICompetitor } from '../../types';

interface IDeckSharePriceChartProps {
	companyId: number;
	name: string;
	competitorsData: ICompetitor[];
}

export class DeckSharePriceChart extends React.Component<
	IDeckSharePriceChartProps,
	unknown
> {
	render() {
		const { name, competitorsData } = this.props;

		if (competitorsData.length === 0) {
			return null;
		}

		let longestDatesRangeIndex = 0;
		for (let i = 0; i < competitorsData.length; i++) {
			if (
				competitorsData[i].sharePriceData.length >
				competitorsData[longestDatesRangeIndex].sharePriceData.length
			) {
				longestDatesRangeIndex = i;
			}
		}

		// const categories = competitorsData[
		// 	longestDatesRangeIndex
		// ].sharePriceData.map((d) => d.date);

		const chartOptions: Highcharts.Options = {
			chart: {
				animation: false,
				height: 700,
				width: 1200,
				marginRight: 40,
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			yAxis: {
				title: {
					text: '',
				},
				gridLineColor: '#E6E6E6',
				labels: {
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '16px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
			},
			legend: {
				enabled: false,
			},
			// @ts-ignore
			xAxis: {
				// categories,
				labels: {
					useHTML: true,
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '14px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
					enabled: true,
					// step: 60,
					formatter: function () {
						if (this.value) {
							return moment(this.value).format('MMM YY');
						}
					},
					// formatter: function () {
					// 	if (this.value && typeof this.value === 'string') {
					// 		return moment(this.value * 1000).format('MMM YY');
					// 	}
					// },
				},
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				series: {
					states: {
						inactive: {
							opacity: 1,
						},
						hover: {
							enabled: false,
						},
					},
					animation: false,
				},
			},
		};

		// // @ts-ignore
		// const { sharePriceData } = competitorsData.find(
		// 	(c) => c.companyId === companyId,
		// );

		// const colors = [
		// 	'131, 175, 188',
		// 	'190, 191, 250',
		// 	'166, 214, 172',
		// 	'246, 202, 155',
		// 	'235, 70, 60',
		// 	'141, 58, 100',
		// 	'96, 204, 250',
		// ];

		const colors = [
			'209,40,36',
			'255,145,15',
			'21,114,59',
			'14,5,61',
			'0,66,153',
			'157,156,156',
			'242, 215, 21',
		];

		const series = competitorsData.map((d, i) => ({
			name: d.name,
			type: 'line',
			data: d.sharePriceData.map((d) => ({
				x: parseInt(d.date) * 1000,
				y: d.indexPrice,
			})),
			color: `rgba(${colors[i]})`,
		}));

		const options: Highcharts.Options = {
			...chartOptions,
			// @ts-ignore
			series,
		};

		return (
			<div
				className={`deck-${name
					.toLowerCase()
					.replace(/ /g, '-')
					.replace('&', 'and')}-chart deck-chart`}
			>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</div>
		);
	}
}
