import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';

import { FinancialType, ICompetitorFinancialData } from '../types';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	AP_LOGO,
} from './common';

const CHART_LABELS_CONFIG: pptxgen.TextPropsOptions = {
	shape: 'rect',
	rotate: 270,
	fontSize: 8,
	fontFace: 'Verdana (Body)',
	align: 'center',
	valign: 'middle',
	color: '383838',
	margin: [0, 0, 6, 0],
	lineSize: 1,
};

export const appendRevenueSlide = (
	deckInstance: pptxgen,
	companyName: string,
	currencyCode: string,
	// revenueChartsPercentage: any,
	revenueChartsDataLast5: any,
	financialData: ICompetitorFinancialData[],
) => {
	const {
		ebitdaGrowth5Y,
		ebitdacagR5Y: ebitdaCagr5Y,
		grossProfitGrowth5Y,
		grossProfitCAGR5Y: grossProfitCagr5Y,
		revenueGrowth5Y,
		revenueCAGR5Y: revenueCagr5Y,
		sgaGrowth5Y,
		sgacagR5Y: sgaCagr5Y,
	} = financialData[financialData.length - 1];

	const latestYear =
		revenueChartsDataLast5[FinancialType.YEARS][
			revenueChartsDataLast5[FinancialType.YEARS].length - 1
		];

	const ebitdaMargins = revenueChartsDataLast5[FinancialType.EBITDA].map(
		(v: number, i: number) => {
			const corespondingRevenueValue =
				revenueChartsDataLast5[FinancialType.REVENUE][i];

			return Math.round((v / corespondingRevenueValue) * 100);
		},
	);

	const latestEbitdaMargin = ebitdaMargins[ebitdaMargins.length - 1];
	const lastYear =
		revenueChartsDataLast5[FinancialType.YEARS][
			revenueChartsDataLast5[FinancialType.YEARS].length - 1
		];

	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addShape(deckInstance.ShapeType.rect, {
		x: 9.98,
		y: 0,
		w: 3.35,
		h: 7.5,
		fill: { color: 'ECECEE' },
		line: { type: 'none' },
	});
	slide.addText(`FINANCIAL ANALYSIS`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(`5-year P&L Analysis`, SLIDE_TITLE_CONFIG_WIDE);

	slide.addText(
		[
			{
				text: `${lastYear - 4}-${lastYear} CAGR`,
				options: { bold: true, breakLine: true },
			},
			// { text: 'CAGR', options: { bold: true } },
		],
		{
			x: 8.51,
			y: 1.64,
			w: 1.08,
			h: 0.38,
			...CHART_LABELS_CONFIG,
			rotate: 0,
		},
	);

	slide.addTable(
		[
			revenueChartsDataLast5[FinancialType.YEARS].map((y: string) => ({
				text: y,
			})),
		],
		{
			x: 1.03,
			y: 1.65,
			w: 7.32,
			fontFace: 'Verdana (Body)',
			fontSize: 9,
			color: '5CB335',
			bold: true,
			align: 'center',
		},
	);

	slide.addText(
		[
			{ text: 'OP. REVENUE', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M)` },
		],
		{
			x: 0.03,
			y: 2.2,
			w: 1.2,
			h: 0.46,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'GROSS PROFIT', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: 0.03,
			y: 3.46,
			w: 1.2,
			h: 0.46,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'SG&A', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: 0.03,
			y: 4.71,
			w: 1.2,
			h: 0.46,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'EBITDA', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: 0.03,
			y: 5.97,
			w: 1.2,
			h: 0.46,
			...CHART_LABELS_CONFIG,
		},
	);

	const analysisList: any[] = [
		{
			text: `Over the period (${lastYear - 4}-${lastYear}):`,
			options: { breakLine: true },
		},
	];
	const analysisConfig = {
		bullet: { marginPt: 2 },
		breakLine: true,
	};

	/**
	 * Charts common
	 */
	const addChart = (
		slide: pptxgen.Slide,
		x: number,
		y: number,
		h: number,
		w: number,
		labels: number[],
		values: number[],
	) => {
		slide.addChart(
			'bar',
			[
				{
					name: 'Revenue',
					labels,
					values,
				},
			],
			{
				showDataTableHorzBorder: false,
				showDataTableVertBorder: false,
				x,
				y,
				h,
				w,
				border: { pt: 0, color: 'ffffff' },
				chartColors: ['032E45', '032E45', '032E45', '032E45', '0696A6'],
				valGridLine: { style: 'none' },
				serAxisHidden: true,
				valAxisHidden: true,
				valAxisLineShow: false,
				serAxisLineShow: false,
				showLeaderLines: false,
				barGapWidthPct: 60, // ?
				catAxisHidden: true,

				showValue: true,
				catAxisMajorTickMark: 'none',
				catAxisMinorTickMark: 'none',

				// @ts-ignore
				axisLineColor: 'ffffff',
				dataLabelColor: '383838',
				dataLabelFontFace: 'Verdana (Body)',
				dataLabelFontSize: 10,
				dataLabelPosition: 'outEnd',
				// dataLabelFormatCode: '#\\,###',
				catAxisLabelColor: '383838',
				catAxisLabelFontFace: 'Verdana (Body)',
				catAxisLabelFontSize: 10,
			},
		);
	};

	const addPercentage = (
		dataLast5: number[],
		revenueLast5: number[],
		y: number,
	) => {
		const margins: number[] = dataLast5.map((v: number, i: number) => {
			const corespondingRevenueValue = revenueLast5[i];

			return Math.round((v / corespondingRevenueValue) * 100);
		});

		const rows = [
			[
				...margins.map((v) => ({
					text: `${v}%`,
				})),
			],
		];

		slide.addTable(rows, {
			x: 1.02,
			y,
			w: 7.32,
			border: { type: 'none' },
			fontFace: 'Verdana (Body)',
			fontSize: 9,
			color: '808083',
			align: 'center',
			fill: { color: 'ECECEE' },
		});
	};

	/**
	 * Chart One
	 */
	if (revenueChartsDataLast5[FinancialType.REVENUE].every((v: number) => !!v)) {
		addChart(
			slide,
			0.86,
			2.05,
			0.98,
			7.68,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.REVENUE],
		);

		analysisList.push({
			text: `Operating Revenue CAGR achieved was ${revenueCagr5Y}%, amounting to ${Math.abs(
				revenueGrowth5Y,
			)}% cumulative ${revenueGrowth5Y > 0 ? 'growth' : 'decline'}.`,
			options: analysisConfig,
		});

		slide.addText(`${revenueCagr5Y}%`, {
			shape: 'rect',
			x: 8.35,
			y: 2.28,
			w: 1.5,
			h: 0.31,
			color: '383838',
			align: 'center',
			fontSize: 14,
			bold: true,
			fontFace: 'Verdana (Body)',
		});
	} else {
		slide.addText('No data', {
			shape: 'rect',
			x: 1.28,
			y: 1.87,
			w: 6.82,
			h: 1.15,
			fill: {
				type: 'solid',
				color: 'ADAFB2',
			},
			align: 'center',
			fontSize: 10,
		});
	}

	/**
	 * Chart Two
	 */
	if (
		revenueChartsDataLast5[FinancialType.GROSS_PROFIT].every((v: number) => !!v)
	) {
		addChart(
			slide,
			0.86,
			3.09,
			0.98,
			7.68,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.GROSS_PROFIT],
		);

		slide.addTable(
			[
				revenueChartsDataLast5[FinancialType.YEARS].map((y: string) => ({
					text: y,
				})),
			],
			{
				x: 1.03,
				y: 1.65,
				w: 7.32,
				fontFace: 'Verdana (Body)',
				fontSize: 9,
				color: '5CB335',
				bold: true,
				align: 'center',
			},
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.GROSS_PROFIT],
			revenueChartsDataLast5[FinancialType.REVENUE],
			4.04,
		);

		analysisList.push({
			text: `Gross Profit CAGR amounted to ${grossProfitCagr5Y}%, resulting in a ${Math.abs(
				grossProfitGrowth5Y,
			)}% cumulative ${grossProfitGrowth5Y > 0 ? 'growth' : 'decline'}.`,
			options: analysisConfig,
		});

		slide.addText(`${grossProfitCagr5Y}%`, {
			shape: 'rect',
			x: 8.35,
			y: 3.53,
			w: 1.5,
			h: 0.31,
			color: '383838',
			align: 'center',
			fontSize: 14,
			bold: true,
			fontFace: 'Verdana (Body)',
		});
	} else {
		slide.addText('No data', {
			shape: 'rect',
			x: 1.28,
			y: 3.03,
			w: 6.82,
			h: 1.25,
			fill: {
				type: 'solid',
				color: 'ADAFB2',
			},
			align: 'center',
			fontSize: 10,
		});
	}

	/**
	 * Chart Three
	 */
	if (
		revenueChartsDataLast5[FinancialType.SG_AND_A].every((v: number) => !!v)
	) {
		addChart(
			slide,
			0.86,
			4.37,
			0.98,
			7.68,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.SG_AND_A],
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.SG_AND_A],
			revenueChartsDataLast5[FinancialType.REVENUE],
			5.29,
		);

		analysisList.push({
			text: `SG&A ${
				sgaCagr5Y > 0 ? 'grew' : 'fell'
			} by a CAGR of ${sgaCagr5Y}%, a cumulative ${
				sgaGrowth5Y > 0 ? 'increase' : 'decline'
			} in expenses  of ${Math.abs(sgaGrowth5Y)}%.`,
			options: analysisConfig,
		});

		slide.addText(`${sgaCagr5Y}%`, {
			shape: 'rect',
			x: 8.35,
			y: 4.75,
			w: 1.5,
			h: 0.31,
			color: '383838',
			align: 'center',
			fontSize: 14,
			bold: true,
			fontFace: 'Verdana (Body)',
		});
	} else {
		slide.addText('No data', {
			shape: 'rect',
			x: 1.28,
			y: 4.28,
			w: 6.82,
			h: 1.24,
			fill: {
				type: 'solid',
				color: 'ADAFB2',
			},
			align: 'center',
			fontSize: 10,
		});
	}

	/**
	 * Chart Four
	 */
	if (revenueChartsDataLast5[FinancialType.EBITDA].every((v: number) => !!v)) {
		addChart(
			slide,
			0.86,
			5.6,
			0.98,
			7.68,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.EBITDA],
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.EBITDA],
			revenueChartsDataLast5[FinancialType.REVENUE],
			6.57,
		);

		analysisList.push({
			text: `The resulting EBITDA ${
				ebitdaCagr5Y > 0 ? 'grew' : 'fell'
			} by a CAGR of ${Math.abs(ebitdaCagr5Y)}%. This amounted to a ${Math.abs(
				ebitdaGrowth5Y,
			)}% cumulative ${
				ebitdaGrowth5Y > 0 ? 'growth' : 'decline'
			} over the period, having achieved an EBITDA margin of ${latestEbitdaMargin}% in ${latestYear}.`,
			options: analysisConfig,
		});

		slide.addText(`${ebitdaCagr5Y}%`, {
			shape: 'rect',
			x: 8.35,
			y: 6.04,
			w: 1.5,
			h: 0.31,
			color: '383838',
			align: 'center',
			fontSize: 14,
			bold: true,
			fontFace: 'Verdana (Body)',
		});
	} else {
		slide.addText('No data', {
			shape: 'rect',
			x: 1.28,
			y: 5.53,
			w: 6.82,
			h: 1.27,
			fill: {
				type: 'solid',
				color: 'ADAFB2',
			},
			align: 'center',
			fontSize: 10,
		});
	}

	slide.addImage({
		data: AP_LOGO,
		x: 10.91,
		y: 7.15,
		w: 1.4,
		h: 0.21,
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 1.87,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 3.03,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 4.28,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 5.53,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 6.81,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 1.03,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 2.49,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 3.96,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 5.42,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 6.88,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 8.35,
		y: 1.65,
		w: 0,
		h: 5.15,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addText(analysisList, {
		w: 2.7,
		x: 10.22,
		y: 2.22,
		fontSize: 10,
		fontFace: 'Verdana (Body)',
		margin: 0,
		align: 'left',
		valign: 'top',
		paraSpaceAfter: 12,
		color: '383838',
	});
};
