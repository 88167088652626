import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { IFinancialSummary } from '../../../types';
import {
	addBigNumberSign,
	addZeroes,
	numberWithCommas,
	roundOneDecimal,
} from '../../../utils';

interface IFinancialWidgetProps {
	financialData: IFinancialSummary;
	currencyCode: string;
}

export const FinancialWidget: React.FC<IFinancialWidgetProps> = ({
	financialData,
	currencyCode,
}) => {
	return (
		<>
			<div className="financial__row">
				<div>
					<div className="financial__label">Market Cap</div>
					<div className="financial__value">
						{financialData.marketCap ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{numberWithCommas(financialData.marketCap)}
								</span>
							</>
						) : (
							'No data'
						)}
						<span className="numeric">
							{addBigNumberSign(financialData.marketCap, 'M')}
						</span>
					</div>
				</div>
				<div>
					<div className="financial__label">Revenue Growth</div>
					<div className="financial__value">
						{financialData.revenueGrowth
							? `${roundOneDecimal(financialData.revenueGrowth)}%`
							: 'No data'}
					</div>
				</div>
			</div>
			<div className="financial__row">
				<div>
					<div className="financial__label">Cash</div>
					<div className="financial__value">
						{financialData.cash ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{numberWithCommas(financialData.cash)}
								</span>
							</>
						) : (
							'No data'
						)}
						<span className="numeric">
							{addBigNumberSign(financialData.cash, 'M')}
						</span>
					</div>
				</div>
				<div>
					<div className="financial__label">EBITDA Growth</div>
					<div className="financial__value">
						{financialData.ebitdaGrowth
							? `${roundOneDecimal(financialData.ebitdaGrowth)}%`
							: 'No data'}
					</div>
				</div>
			</div>
			<div className="financial__row">
				<div>
					<div className="financial__label">Total Debt</div>
					<div className="financial__value">
						{financialData.totalDebt ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{numberWithCommas(financialData.totalDebt)}
								</span>
							</>
						) : (
							'No data'
						)}
						<span className="numeric">
							{addBigNumberSign(financialData.totalDebt, 'M')}
						</span>
					</div>
				</div>
				<div>
					<div className="financial__label">Total Debt/EBITDA</div>
					<div className="financial__value">
						{financialData.totalDebtOverEBITDA ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{addZeroes(`${financialData.totalDebtOverEBITDA}`)}
								</span>
							</>
						) : (
							'No data'
						)}
					</div>
				</div>
			</div>
			<div className="financial__row">
				<div>
					<div className="financial__label">Total Revenue</div>
					<div className="financial__value">
						{financialData.totalRevenue ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{numberWithCommas(financialData.totalRevenue)}
								</span>
							</>
						) : (
							'No data'
						)}
						<span className="numeric">
							{addBigNumberSign(financialData.totalRevenue, 'M')}
						</span>
					</div>
				</div>
				<div>
					<div className="financial__label">Interest Coverage</div>
					<div className="financial__value">
						{financialData.interestCoverage ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{addZeroes(`${financialData.interestCoverage}`)}
								</span>
							</>
						) : (
							'No data'
						)}
					</div>
				</div>
			</div>
			<div className="financial__row">
				<div>
					<div className="financial__label">Total EBITDA</div>
					<div className="financial__value">
						{financialData.totalEBITDA ? (
							<>
								<span>
									{getSymbolFromCurrency(currencyCode) || currencyCode}
								</span>
								<span className="numeric">
									{numberWithCommas(financialData.totalEBITDA)}
								</span>
							</>
						) : (
							'No data'
						)}
						<span className="numeric">
							{addBigNumberSign(financialData.totalEBITDA, 'M')}
						</span>
					</div>
				</div>
				<div></div>
			</div>
		</>
	);
};
