/**
 * a collection of formulas for general use
 */

/**
 * Work out the CAGR number
 * @param values {numbers[]} - An array of numbers to work out the CAGR
 */
export const cagr = (values: number[]): number => {
	if (values[values.length - 1] < 0 || values[0] < 0) {
		return 0;
	}
	const total =
		Math.round(
			(Math.pow(
				values[values.length - 1] / values[0],
				1 / (values.length - 1),
			) -
				1) *
				10000,
		) / 100;
	return total;
};

/**
 * Work out the average difference between every number in an array
 * @param values {numbers[]} - An array of numbers, a range
 */
export const changeAverage = (values: number[]): number => {
	const totalChangeDifference = values.reduce(
		(acc: number, curr: number, i: number, arr: number[]) => {
			const diff = curr - arr[i - 1 || 0] || 0;
			return acc + diff;
		},
		0,
	);
	const changeAverage = totalChangeDifference / (values.length - 1);
	return changeAverage;
};

/**
 * Work out the average percentage difference between a range of numbers
 * @param values {numbers[]} - An array of numbers, a range
 */
export const changeAveragePercentage = (values: number[]): number => {
	const totalChangeDifference = values.reduce(
		(acc: number, curr: number, i: number, arr: number[]) => {
			const diff = curr - arr[i - 1 || 0] || 0;
			const diffPerc = (diff / curr) * 100;
			return acc + diffPerc;
		},
		0,
	);
	const changeAverage = totalChangeDifference / (values.length - 1);
	return changeAverage;
};
