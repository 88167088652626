import React from 'react';
import './CovidMsg.scss';

interface ICovidMsgProps {
    title: string;
    msg: string;    
}

export const CovidMsg: React.FC<ICovidMsgProps> = ({ title, msg }) => {
    return (
        <div className = 'covid-msg'>
            <div className = 'title'>{ title }</div>
            <span className = 'hr'></span>
            <div className = 'msg'>{ msg }</div>
        </div>      
    );
};