import React from 'react';

import './Classification.scss';

interface IClassificationProps {
	industry: string;
	industryGroup: string;
	sector: string;
	subIndustry: string;
}

export const Classification: React.FC<IClassificationProps> = (props) => {
	const { industry, industryGroup, sector, subIndustry } = props;

	return (
		<div className="ap-classification">
			<div className="ap-classification__title">GICS:</div>
			<div>
				<div className="ap-classification__row">
					<span className="ap-classification__level">L1:</span>
					{sector}
				</div>
				<div className="ap-classification__row">
					<span className="ap-classification__level">L2:</span>
					{industryGroup}
				</div>
				<div className="ap-classification__row">
					<span className="ap-classification__level">L3:</span>
					{industry}
				</div>
				<div className="ap-classification__row">
					<span className="ap-classification__level">L4:</span>
					<strong>{subIndustry}</strong>
				</div>
			</div>
		</div>
	);
};
