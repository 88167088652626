import React from 'react';

import { ApContextualPopup, ApButtonIcon } from '@alixpartners/ui-components';

interface IInfoTooltipProps {
	message: string;
	id: string;
}

export const InfoTooltip: React.FC<IInfoTooltipProps> = ({ message, id }) => (
	<ApContextualPopup
		id={id}
		tooltip={{
			event: 'click',
		}}
		trigger={() => (
			<ApButtonIcon
				autoFocus={false}
				disabled={false}
				iconName="outline_info"
				iconProps={{ iconSize: '16px', iconColor: '#737373' }}
				theme="light"
			/>
		)}
	>
		{() => (
			<div
				style={{
					padding: 16,
				}}
			>
				{`${message}`}
			</div>
		)}
	</ApContextualPopup>
);
