import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { IPeersRevenueWidget } from '../../../types';

import './RevenueWidget.scss';

interface IRevenueWidgetProps {
	companyId: number;
	peersRevenueData: IPeersRevenueWidget;
	companyColors: string[];
	currentCompanyColor: string;
}

export const RevenueWidget: React.FC<IRevenueWidgetProps> = ({
	companyId,
	peersRevenueData,
	companyColors,
	currentCompanyColor,
}) => {
	const {
		ebitAverage,
		ebitMedian,
		revenueGrowthAverage,
		revenueGrowthMedian,
		data,
	} = peersRevenueData;

	const series = data.map((d, index) => {
		return {
			name: d.companyName,
			x: d.ebitMargin,
			y: d.revenueGrowth,
			z: d.revenueUSD,
			legendColor:
				d.companyId === companyId ? currentCompanyColor : companyColors[index],
			color: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: [
					[
						0,
						d.companyId === companyId
							? currentCompanyColor
							: companyColors[index],
					],
					[
						1,
						d.companyId === companyId
							? currentCompanyColor
							: companyColors[index],
					],
				],
			},
		};
	});

	const chartOptions: Highcharts.Options = {
		chart: {
			type: 'bubble',
			animation: false,
			height: 600,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: {
			plotLines: [
				{
					color: '#C00000',
					dashStyle: 'LongDash',
					value: revenueGrowthMedian,
					label: {
						align: 'right',
						style: {
							fontStyle: 'italic',
						},
						text: 'Median',
					},
					zIndex: 31,
				},
				{
					color: '#7AB800',
					dashStyle: 'LongDash',
					value: revenueGrowthAverage,
					label: {
						style: {
							fontStyle: 'italic',
						},
						text: 'Average',
					},
					zIndex: 31,
				},
			],
			gridLineWidth: 1,
			minorGridLineWidth: 0,
			title: {
				text: 'Revenue Growth (USD)',
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			labels: {
				format: '{value}%',
				useHTML: true,
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			lineColor: '#ADAFB2',
			lineWidth: 1,
			tickLength: 0,
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			plotLines: [
				{
					color: '#C00000',
					dashStyle: 'LongDash',
					value: ebitMedian,
					label: {
						style: {
							fontStyle: 'italic',
						},
						text: 'Median',
					},
					zIndex: 31,
				},
				{
					color: '#7AB800',
					dashStyle: 'LongDash',
					value: ebitAverage,
					label: {
						verticalAlign: 'bottom',
						y: -55,
						style: {
							fontStyle: 'italic',
						},
						text: 'Average',
					},
					zIndex: 31,
				},
			],
			title: {
				text: 'EBIT Margin',
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			labels: {
				format: '{value:.2f}%',
				useHTML: true,
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			lineColor: '#ADAFB2',
			tickLength: 0,
		},
		tooltip: {
			enabled: false,
		},
		// @ts-ignore
		plotOptions: {
			bubble: {
				zMin: 1,
			},
			column: {
				pointWidth: 130,
			},
			series: {
				dataLabels: {
					inside: false,
					crop: false,
					overflow: false,
					enabled: true,
					// format: '{point.name}',
					formatter: function () {
						return this.point.name ? this.point.name.toUpperCase() : '';
					},
					y: -10,
					style: {
						fontFamily: 'Roboto',
						fontSize: '13px',
						fontWeight: '500',
						lineHeight: '40px',
						color: '#4D4D4D',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};

	const options: Highcharts.Options = {
		...chartOptions,
		// @ts-ignore
		series: [
			{
				data: series,
				sizeBy: 'width',
			},
		],
	};

	return (
		<div className="peers-share-price">
			<div className="peers-share-price__chart">
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					immutable
					updateArgs={[true, false, false]}
				/>
			</div>
			<div className="peers-share-price__legend">
				{series.map((s, index) => {
					return (
						<div className="peers-share-price__legend__item" key={index}>
							<div
								className="peers-share-price__legend__mark"
								// @ts-ignore
								style={{ backgroundColor: s.legendColor }}
							/>
							<div className="peers-share-price__legend__value">{s.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
