import React from 'react';

import { setPowerBiParameters } from '../../api';

export const { Provider, Consumer } = React.createContext<{}>({});

class PowerBiParamsContext extends React.Component<{}, {}> {
	async componentDidMount() {
		try {
			await setPowerBiParameters();
		} catch (error) {
			console.log('Error', error);
		}
	}

	render() {
		return <Provider value={{}}>{this.props.children}</Provider>;
	}
}

export default PowerBiParamsContext;
