import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import { RouteComponentProps } from 'react-router-dom';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import { Company } from '../Company';
import { PermissionsConsumer } from '../PermissionsContext';
import { getCompanyData } from '../../api/';
import { ICompanyData } from '../../types';

import './CompanyContainer.scss';

interface IMatchParams {
	companyId: string;
}
// interface ICompanyContainerProps extends RouteComponentProps<IMatchParams> {}
interface ICompanyContainerState {
	isLoading: boolean;
	companyData: ICompanyData | null;
}

export class CompanyContainer extends React.Component<
	RouteComponentProps<IMatchParams>,
	ICompanyContainerState
> {
	state = {
		isLoading: true,
		companyData: null,
	};

	handleBackButton = () => {
		this.props.history.push('/search');
	};

	async componentDidMount() {
		window.scrollTo(0, 0);
		const {
			match: {
				params: { companyId },
			},
		} = this.props;

		try {
			const { data: companyData } = await getCompanyData(+companyId);
			this.setState({
				isLoading: false,
				companyData,
			});
		} catch (error) {
			this.setState({ isLoading: false });
			console.log(error);
		}
	}

	render() {
		const { isLoading, companyData } = this.state;

		if (isLoading) {
			return (
				<>
					<br />
					<br />
					<ApLoaderDefault />
				</>
			);
		}

		return (
			<ScreenClassProvider>
				{!companyData ? (
					<>
						<br />
						<div>No company data</div>
					</>
				) : (
					<PermissionsConsumer>
						{({ watchlistDashboard }) => {
							return (
								<Company
									{...companyData}
									isTrsEnabled={watchlistDashboard}
									onBackClick={this.handleBackButton}
								/>
							);
						}}
					</PermissionsConsumer>
				)}
			</ScreenClassProvider>
		);
	}
}
