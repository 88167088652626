import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { ApLoaderDefault, ApInput } from '@alixpartners/ui-components';

import { debounce } from '../../utils';
import { ICompanySearchResult } from '../../types';
import { search } from '../../api/';

import './PeerSearch.scss';

interface IPeerSearchProps {
	placeholder?: string;
	onPeerSelect(company: ICompanySearchResult): void;
	onChange?: () => void;
	showSelectedValue?: boolean;
	companiesToExclude?: number[];
	showIcon?: boolean;
	disabled?: boolean;
}

interface IPeerSearchState {
	value: string;
	results: ICompanySearchResult[];
	isLoading: boolean;
	isFocused: boolean;
}

class PeerSearchComponent extends React.Component<
	IPeerSearchProps,
	IPeerSearchState
> {
	state = {
		value: '',
		results: [] as ICompanySearchResult[],
		isLoading: false,
		isFocused: false,
	};

	performSearch = async () => {
		const { companiesToExclude } = this.props;
		const { value } = this.state;

		const toExclude = companiesToExclude ? companiesToExclude : [];

		try {
			const { data } = await search(value, [], [], {
				pageNumber: 1,
				pageSize: 10,
			});

			this.setState({
				results:
					data && value
						? data.results.filter((d) => !toExclude.includes(d.companyId))
						: [],
				isLoading: false,
			});
		} catch (error) {
			this.setState({ isLoading: false });
			console.log(error);
		}
	};

	performSearchDebounced = debounce(this.performSearch, 1000);

	handleSearchChange = async (value: string) => {
		const { onChange } = this.props;

		if (onChange) {
			onChange();
		}

		this.setState({ value });

		if (value) {
			this.setState({ isLoading: true });
			this.performSearchDebounced();
		} else {
			this.setState({ results: [] });
		}
	};

	handleClickOutside = () => {
		this.setState({ isFocused: false });
	};

	handleSelectPeer = (company: ICompanySearchResult) => {
		const { onPeerSelect, showSelectedValue } = this.props;

		this.setState(
			{
				isFocused: false,
				results: [],
				value: showSelectedValue ? company.name : '',
			},
			() => {
				onPeerSelect(company);
			},
		);
	};

	render() {
		const { placeholder, showIcon, disabled } = this.props;
		const { value, results, isLoading, isFocused } = this.state;

		return (
			<div className="peers-search">
				<ApInput
					/* 
						// @ts-ignore */
					id="peers-search-input"
					className="peers-search__input"
					value={value}
					onChange={this.handleSearchChange}
					placeholder={placeholder}
					onFocus={() => {
						this.setState({ isFocused: true });
					}}
					customIcon
					icon={showIcon ? 'baseline_search' : undefined}
					disabled={disabled}
				/>
				{(isLoading || results.length > 0) && isFocused && (
					<div className="results">
						{isLoading ? (
							<ApLoaderDefault className="results__spinner" loaderSize="sm" />
						) : (
							<ul>
								{results.map((r) => (
									<li
										key={r.companyId}
										onClick={(e) => {
											this.handleSelectPeer(r);
										}}
									>
										{r.name}
									</li>
								))}
							</ul>
						)}
					</div>
				)}
			</div>
		);
	}
}

export const PeerSearch = onClickOutside(PeerSearchComponent);
