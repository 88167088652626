import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';

import { FinancialType } from '../types';
import { roundTwoDecimal } from '../utils';

import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	AP_LOGO,
} from './common';

export const appendRevenueMarginSlide = (
	deckInstance: pptxgen,
	companyName: string,
	currencyCode: string,
	revenueChartsDataLast5: any,
) => {
	// console.log(revenueChartsDataLast5);

	// revenueChartsDataLast5 = {
	// 	COGS: [742.822, 865.234, 1079.593, 0, 1901.5519],
	// 	EBITDA: [150.428, 158.159, 125.592, 371.609, 383.5194],
	// 	'Gross Profit': [599.182, 660.79, 788.316, 0, 1306.5311],
	// 	'Net Income': [-0.915, 18.196, -119.842, 0, -134.962],
	// 	Revenue: [1342.004, 1526.024, 1867.909, 0, 3208.083],
	// 	'SG & A': [446.354, 502.631, 602.106, 999.789, 902.0597],
	// 	YEARS: ['2015', '2016', '2017', '2018', '2019'],
	// };

	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addShape(deckInstance.ShapeType.rect, {
		x: 9.98,
		y: 0,
		w: 3.35,
		h: 7.5,
		fill: { color: 'ECECEE' },
		line: { type: 'none' },
	});
	slide.addImage({
		data: AP_LOGO,
		x: 10.91,
		y: 7.15,
		w: 1.4,
		h: 0.21,
	});
	slide.addText(`FINANCIAL ANALYSIS`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(`Revenue Margin Charts`, SLIDE_TITLE_CONFIG_WIDE);

	// Years labels
	const labels = revenueChartsDataLast5[FinancialType.YEARS];

	slide.addTable(
		[
			labels.map((y: string) => ({
				text: y,
			})),
		],
		{
			x: 1.03,
			y: 1.65,
			w: 7.32,
			fontFace: 'Verdana (Body)',
			fontSize: 9,
			color: '5CB335',
			bold: true,
			align: 'center',
		},
	);

	if (revenueChartsDataLast5[FinancialType.YEARS].length) {
		const lastYear =
			revenueChartsDataLast5[FinancialType.YEARS][
				revenueChartsDataLast5[FinancialType.YEARS].length - 1
			];

		const analysisList: any[] = [
			{
				text: `Year on year performance (${lastYear - 1}-${lastYear}):`,
				options: { breakLine: true },
			},
		];
		const analysisConfig = {
			bullet: { marginPt: 2 },
			breakLine: true,
		};

		if (revenueChartsDataLast5[FinancialType.REVENUE].length) {
			const valuesList = revenueChartsDataLast5[FinancialType.REVENUE];
			const lastYearValue = valuesList[valuesList.length - 1];
			const lastLastYearValue = valuesList[valuesList.length - 2];
			const isIncrease = lastYearValue > lastLastYearValue;
			const percentageValue = isIncrease
				? Math.abs(
						((lastYearValue - lastLastYearValue) / lastLastYearValue) * 100,
				  )
				: Math.abs(
						((lastLastYearValue - lastYearValue) / lastLastYearValue) * 100,
				  );

			analysisList.push({
				text: `Revenue ${isIncrease ? 'grew' : 'declined'} by ${
					!isFinite(percentageValue) || isNaN(percentageValue)
						? '-- '
						: roundTwoDecimal(percentageValue)
				}%.`,
				options: analysisConfig,
			});
		}

		if (revenueChartsDataLast5[FinancialType.SG_AND_A].length) {
			const valuesList = revenueChartsDataLast5[FinancialType.SG_AND_A].map(
				(item: number, index: number) => {
					return Math.round(
						(item / revenueChartsDataLast5[FinancialType.REVENUE][index]) * 100,
					);
				},
			);
			const lastYearValue = valuesList[valuesList.length - 1];
			const lastLastYearValue = valuesList[valuesList.length - 2];
			const isIncrease = lastYearValue > lastLastYearValue;
			const percentageValue = isIncrease
				? Math.abs(
						((lastYearValue - lastLastYearValue) / lastLastYearValue) * 100,
				  )
				: Math.abs(
						((lastLastYearValue - lastYearValue) / lastLastYearValue) * 100,
				  );

			if (percentageValue === 0) {
				analysisList.push({
					text: 'SG&A % of Revenue remained constant',
					options: analysisConfig,
				});
			} else {
				analysisList.push({
					text: `SG&A % of Revenue ${isIncrease ? 'grew' : 'declined'} by ${
						!isFinite(percentageValue) || isNaN(percentageValue)
							? '-- '
							: roundTwoDecimal(percentageValue)
					}%.`,
					options: analysisConfig,
				});
			}
		}

		if (revenueChartsDataLast5[FinancialType.COGS].length) {
			const valuesList = revenueChartsDataLast5[FinancialType.COGS].map(
				(item: number, index: number) => {
					return Math.round(
						(item / revenueChartsDataLast5[FinancialType.REVENUE][index]) * 100,
					);
				},
			);
			const lastYearValue = valuesList[valuesList.length - 1];
			const lastLastYearValue = valuesList[valuesList.length - 2];
			const isIncrease = lastYearValue > lastLastYearValue;
			const percentageValue = isIncrease
				? Math.abs(
						((lastYearValue - lastLastYearValue) / lastLastYearValue) * 100,
				  )
				: Math.abs(
						((lastLastYearValue - lastYearValue) / lastLastYearValue) * 100,
				  );

			if (percentageValue === 0) {
				analysisList.push({
					text: 'COGS % of Revenue remained constant',
					options: analysisConfig,
				});
			} else {
				analysisList.push({
					text: `COGS % of Revenue ${isIncrease ? 'grew' : 'declined'} by ${
						!isFinite(percentageValue) || isNaN(percentageValue)
							? '-- '
							: roundTwoDecimal(percentageValue)
					}%.`,
					options: analysisConfig,
				});
			}
		}

		slide.addText(analysisList, {
			w: 2.7,
			x: 10.22,
			y: 3.14,
			fontSize: 10,
			fontFace: 'Verdana (Body)',
			margin: 0,
			align: 'left',
			valign: 'top',
			paraSpaceAfter: 12,
			color: '383838',
		});
	}

	slide.addChart(
		[
			{
				type: 'bar',
				data: [
					{
						name: 'Revenue',
						labels,
						values: revenueChartsDataLast5[FinancialType.REVENUE],
					},
				],
				options: {
					chartColors: ['032E45'],
					dataLabelPosition: 'outEnd',
					// dataLabelFormatCode: '#\\,###',
					// dataLabelFormatCode: '#.###',
				},
			},
			{
				type: 'line',
				data: [
					{
						name: 'EBITDA',
						labels,
						values: revenueChartsDataLast5[FinancialType.EBITDA],
					},
				],
				options: {
					chartColors: ['FF600F'],
					// dataLabelColor: '9e9e9e',
					dataLabelColor: 'FF600F',
					dataLabelPosition: 't',
					// dataLabelFormatCode: '#\\,###',
					// dataLabelFormatCode: '#.##0;-#.##0',
				},
			},
			{
				type: 'line',
				data: [
					{
						name: 'Net Income',
						labels,
						values: revenueChartsDataLast5[FinancialType.NET_INCOME],
					},
				],
				options: {
					chartColors: ['5CB335'],
					dataLabelColor: '5CB335',
					margin: [100, 100, 100, 100],
					// dataLabelColor: '9e9e9e',
					dataLabelPosition: 'b',
					// dataLabelFormatCode: '#\\,###',
					// dataLabelFormatCode: '#.##0;-#.##0',
				},
			},
		],
		{
			// @ts-ignore
			x: 0.86,
			y: 1.81,
			// h: 2.5,
			h: 2.43,
			w: 9.02,
			border: { pt: 0, color: 'ffffff' },
			valGridLine: { style: 'none' },
			serAxisHidden: true,
			valAxisHidden: true,
			valAxisLineShow: false,
			serAxisLineShow: false,
			showLeaderLines: false,
			barGapWidthPct: 80,
			showValue: true,
			catAxisHidden: true,
			catAxisMajorTickMark: 'none',
			catAxisMinorTickMark: 'none',
			// @ts-ignore
			axisLineColor: '666666',

			// Data labels
			// dataLabelColor: '9e9e9e',
			dataLabelFontFace: 'Verdana (Body)',
			dataLabelFontSize: 10,

			// Legend
			showLegend: true,
			legendPos: 'r',
			legendFontSize: 10,
			legendFontFace: 'Verdana (Body)',
			legendColor: '383838',
		},
	);

	const ebitdaMargins: number[] = revenueChartsDataLast5[
		FinancialType.EBITDA
	].map((v: number, i: number) => {
		const corespondingRevenueValue =
			revenueChartsDataLast5[FinancialType.REVENUE][i];

		return Math.round((v / corespondingRevenueValue) * 100);
	});

	const netIncomeMargins: number[] = revenueChartsDataLast5[
		FinancialType.NET_INCOME
	].map((v: number, i: number) => {
		const corespondingRevenueValue =
			revenueChartsDataLast5[FinancialType.REVENUE][i];

		return Math.round((v / corespondingRevenueValue) * 100);
	});

	const rows = [
		[
			...ebitdaMargins.map((v) => ({
				text: `${!isFinite(v) || isNaN(v) ? '-- ' : v}%`,
				options: { color: 'FF600F' },
			})),
		],
		[
			...netIncomeMargins.map((v) => ({
				text: `${!isFinite(v) || isNaN(v) ? '-- ' : v}%`,
				options: { color: '5CB335' },
			})),
		],
	];

	slide.addTable(rows, {
		x: 1.03,
		y: 4.23,
		w: 7.32,
		border: { type: 'none' },
		fill: { color: 'ECECEE' },
		fontFace: 'Verdana (Body)',
		fontSize: 9,
		align: 'center',
	});

	slide.addChart(
		'line',
		[
			{
				name: 'SG&A % Rev.',
				labels,
				values: revenueChartsDataLast5[FinancialType.SG_AND_A].map(
					(item: number, index: number) => {
						const result =
							item / revenueChartsDataLast5[FinancialType.REVENUE][index];

						return isFinite(result) ? Math.round(result * 100) : 0;
					},
				),
			},
			{
				name: 'COGS % Rev.',
				labels,
				values: revenueChartsDataLast5[FinancialType.COGS].map(
					(item: number, index: number) => {
						const result =
							item / revenueChartsDataLast5[FinancialType.REVENUE][index];

						return isFinite(result) ? Math.round(result * 100) : 0;
					},
				),
			},
		],
		{
			x: 0.86,
			y: 4.9,
			h: 1.3,
			w: 9.2,
			chartColors: ['FF3130', '5CB335'],
			lineDataSymbolSize: 10,
			shadow: { type: 'none' },
			border: { pt: 0, color: 'ffffff' },
			valGridLine: { style: 'none' },
			serAxisHidden: true,
			valAxisHidden: true,
			valAxisLineShow: false,
			serAxisLineShow: false,
			showLeaderLines: false,
			barGapWidthPct: 80,
			showValue: true,
			catAxisMajorTickMark: 'none',
			catAxisMinorTickMark: 'none',
			catAxisLabelColor: '383838',
			catAxisLabelFontFace: 'Verdana (Body)',
			catAxisLabelFontSize: 10,
			// @ts-ignore
			axisLineColor: '666666',

			catAxisHidden: true,

			// Data labels
			dataLabelFontFace: 'Verdana (Body)',
			dataLabelFontSize: 10,
			dataLabelPosition: 't',

			// Legend
			showLegend: true,
			legendPos: 'r',
			legendFontSize: 10,
			legendFontFace: 'Verdana (Body)',
			legendColor: '383838',
		},
	);

	slide.addText(
		[
			{ text: 'REVENUES & MARGIN', options: { bold: true, breakLine: true } },
			{
				text: `(${getSymbolFromCurrency(currencyCode) || currencyCode}M)`,
				options: { bold: true },
			},
		],
		{
			x: -0.89,
			y: 2.96,
			w: 3.04,
			h: 0.46,
			shape: 'rect',
			rotate: 270,
			fontSize: 8,
			fontFace: 'Verdana (Body)',
			align: 'center',
			valign: 'middle',
			color: '383838',
			margin: [0, 0, 6, 0],
			lineSize: 1,
		},
	);

	slide.addText(
		[
			{ text: 'SG&A and COGS %', options: { bold: true, breakLine: true } },
			{ text: 'REVENUES', options: { bold: true } },
		],
		{
			x: -0.14,
			y: 5.29,
			w: 1.54,
			h: 0.46,
			shape: 'rect',
			rotate: 270,
			fontSize: 8,
			fontFace: 'Verdana (Body)',
			align: 'center',
			valign: 'middle',
			color: '383838',
			margin: [0, 0, 6, 0],
			lineSize: 1,
		},
	);

	slide.addShape(deckInstance.ShapeType.line, {
		x: 1.03,
		y: 4.46,
		w: 7.31,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 1.87,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 4.7,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 6.28,
		w: 7.94,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 1.03,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 2.49,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 3.96,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 5.42,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 6.88,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
	slide.addShape(deckInstance.ShapeType.line, {
		x: 8.35,
		y: 1.65,
		w: 0,
		h: 4.63,
		line: { color: 'DEDFE0', width: 0.75 },
	});
};
