import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getMyPermissions } from '../../api';

export const { Provider, Consumer } = React.createContext<IPermissionsContext>({
	permissions: [],
	isLoading: true,
	isOk: true,
	creatingComment: false,
	updatingComment: false,
	deletingComment: false,
	deletingCommentTag: false,
	markCommentObsolete: false,
	controlPanelEnabled: false,
	watchlistDashboard: false,
});

interface IPermissionsContextProps extends RouteComponentProps {
	children: any;
}

interface IPermissionsContext {
	permissions: string[];
	isLoading: boolean;
	isOk: boolean;
	creatingComment: boolean;
	updatingComment: boolean;
	deletingComment: boolean;
	deletingCommentTag: boolean;
	markCommentObsolete: boolean;
	controlPanelEnabled: boolean;
	watchlistDashboard: boolean;
}

interface IPermissionsContextState {
	permissions: string[];
	isLoading: boolean;
	creatingComment: boolean;
	updatingComment: boolean;
	deletingComment: boolean;
	deletingCommentTag: boolean;
	markCommentObsolete: boolean;
	controlPanelEnabled: boolean;
	watchlistDashboard: boolean;
}

class PermissionsContext extends React.Component<
	IPermissionsContextProps,
	IPermissionsContextState
> {
	state = {
		permissions: [],
		isLoading: true,
		isOk: true,
		creatingComment: false,
		updatingComment: false,
		deletingComment: false,
		deletingCommentTag: false,
		markCommentObsolete: false,
		controlPanelEnabled: false,
		watchlistDashboard: false,
	};

	async componentDidMount() {
		try {
			const response = await getMyPermissions();
			const p = response.data;
			this.setState({
				permissions: p,
				isLoading: false,
				creatingComment: p.includes('postAnalysisComment'),
				updatingComment: p.includes('editAnalysisComment'),
				deletingComment: p.includes('deleteAnalysisComment'),
				markCommentObsolete: p.includes('markAnalysisCommentObsolete'),
				deletingCommentTag: p.includes('removeTagFromComment'),
				controlPanelEnabled: p.includes('getControlPanelParameters'),
				watchlistDashboard: p.includes('watchlistDashboard'),
			});

			const newState = {
				permissions: p,
				isLoading: false,
				isOk: true,
				creatingComment: p.includes('postAnalysisComment'),
				updatingComment: p.includes('editAnalysisComment'),
				deletingComment: p.includes('deleteAnalysisComment'),
				markCommentObsolete: p.includes('markAnalysisCommentObsolete'),
				deletingCommentTag: p.includes('removeTagFromComment'),
				controlPanelEnabled: p.includes('getControlPanelParameters'),
				watchlistDashboard: p.includes('watchlistDashboard'),
			};

			if (!p || p.length === 0 || !p.includes('postSearch')) {
				newState.isOk = false;
				this.setState(newState);
				this.props.history.push(`/accessdenied`);
			} else {
				this.setState(newState);
			}
		} catch (error) {
			console.log('Error', error);
		}
	}

	render() {
		return <Provider value={{ ...this.state }}>{this.props.children}</Provider>;
	}
}

export default withRouter(PermissionsContext);
