import * as React from 'react';
import moment from 'moment';
import {
	ApChip,
	ApModal,
	ApButtonMain,
	ApButtonSecondary,
	ApButtonGroup,
	ApIcon,
	ApRadio,
	ApInput,
	ApSimpleDropdown,
	IOption,
	// ApButtonTab,
	snack,
} from '@alixpartners/ui-components';

import { PermissionsConsumer } from '../PermissionsContext';
import { TagsSearch } from '../TagsSearch';
import avatar from './avatar.svg';
import commentsCount from './comment.svg';
import { isEmpty } from '../../utils';
import {
	IAnalysisComment,
	ITag,
	CommentType,
	SectionType,
	ICurrentUser,
} from '../../types';
import {
	postAnalysisComments,
	editAnalysisComment,
	deleteAnalysisComment,
	markAnalysisCommentObsolete,
} from '../../api';

import './SectionAnalysis.scss';

// TODO: change to IComment
interface INewAnalysisComment {
	companyId: number;
	title: string;
	analysis: string;
	type: string;
	sectionType: string;
	tags: string[];
}

interface ISectionAnalysisProps {
	companyId: number;
	currentUser: ICurrentUser;
	sectionType?: string;
	analysisComments: IAnalysisComment[];
	analysisTags: ITag[];
}

interface IComment extends Omit<IAnalysisComment, 'tags'> {
	tags: string[];
}

interface ISectionAnalysisState {
	isAddingComment: boolean;
	isAddEditModalOpen: boolean;
	isRemoveModalOpen: boolean;
	analysisComments: IComment[];
	analysisCommentsCopy: IComment[];
	filteredAnalysisComments: IComment[];
	newComment: INewAnalysisComment;
	commentToEdit: IComment;
	commentToDeleteId: number;
	analysisTags: ITag[];
	// Tags to be used in Add/Edit modal
	tempTags: string[];

	// Filters
	selectedTagFilters: IOption[];
	selectedSectionFilters: IOption[];
	selectedTypeFilters: IOption[];
	selectedAuthorFilters: IOption[];
}

export class SectionAnalysis extends React.Component<
	ISectionAnalysisProps,
	ISectionAnalysisState
> {
	newComment: INewAnalysisComment = {
		companyId: this.props.companyId,
		title: '',
		analysis: '',
		type: this.props.sectionType ? CommentType.SECTION : CommentType.EXECUTIVE,
		sectionType: this.props.sectionType
			? this.props.sectionType
			: SectionType.NONE,
		tags: [] as string[],
	};

	state = {
		// isLoading: true,
		isAddingComment: false,
		isAddEditModalOpen: false,
		isRemoveModalOpen: false,
		analysisComments: this.props.analysisComments.map((c) => {
			return { ...c, tags: c.tags.map((t) => t.name) };
		}),
		analysisCommentsCopy: this.props.analysisComments.map((c) => {
			return { ...c, tags: c.tags.map((t) => t.name) };
		}),
		filteredAnalysisComments: this.props.analysisComments.map((c) => {
			return { ...c, tags: c.tags.map((t) => t.name) };
		}),
		newComment: this.newComment,
		commentToEdit: {} as IComment,
		commentToDeleteId: -1,
		analysisTags: this.props.analysisTags,
		tempTags: [] as string[],

		// Filters
		selectedTagFilters: [] as IOption[],
		selectedSectionFilters: [] as IOption[],
		selectedTypeFilters: [] as IOption[],
		selectedAuthorFilters: [] as IOption[],
	};

	sectionTypes = [
		SectionType.NONE,
		SectionType.SUMMARY,
		SectionType.PROFIT_AND_LOSS,
		SectionType.DEBT,
		SectionType.CASHPOSITION_AND_CASH_FLOW,
		SectionType.PROFIT_AND_LOSS_OVERVIEW,
		SectionType.BALANCE_SHEET,
		SectionType.CASHFLOW,
		SectionType.PEERS_SHARE_PRICE,
		SectionType.PEERS_REVENUE_COMPARISON,
		SectionType.PEERS_FINANCIAL_DATA,
	];

	handleCloseAddEditModal = () => {
		this.setState((prevState) => {
			return {
				isAddEditModalOpen: false,
				newComment: this.newComment,
				commentToEdit: {} as IComment,
				tempTags: [] as string[],
			};
		});
	};

	handleCloseDeleteModal = () => {
		this.setState({ isRemoveModalOpen: false, commentToDeleteId: -1 });
	};

	handleOpenDeleteModal = (id: number) => {
		this.setState({ isRemoveModalOpen: true, commentToDeleteId: id });
	};

	handleOpenAddModal = () => {
		this.setState({ isAddEditModalOpen: true });
	};

	handleOpenEditModal = (commentToEdit: IComment) => {
		this.setState({
			isAddEditModalOpen: true,
			commentToEdit,
			tempTags: [...commentToEdit.tags],
		});
	};

	handleRemoveAnalysisComment = async () => {
		const { commentToDeleteId } = this.state;
		const id = commentToDeleteId;
		try {
			this.setState(
				(prevState) => {
					return {
						commentToDeleteId: -1,
						isRemoveModalOpen: false,
						analysisComments: [
							...prevState.analysisComments.filter((c) => c.id !== id),
						],
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await deleteAnalysisComment(id);
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isRemoveModalOpen: false,
						analysisComments: [...prevState.analysisCommentsCopy],
					};
				},
				() => {
					snack.error({
						message: 'There was an error while removing comment',
					});
				},
			);
		}
	};

	handleAddNewAnalysisComment = async () => {
		const {
			newComment: { analysis, title, type, sectionType },
			analysisComments,
			tempTags,
		} = this.state;

		const tags = [...tempTags];

		const maxCommentId =
			Math.max(...analysisComments.map((c) => c.id)) * 10 + 1;
		const { currentUser } = this.props;
		const newCommentDate = moment.utc().format();
		const newComment: IComment = {
			id: maxCommentId,
			title,
			createdBy: currentUser,
			companyId: 1,
			analysis,
			createdOn: newCommentDate,
			updatedOn: newCommentDate,
			type,
			sectionType,
			tags,
			isObsolete: false,
		};

		try {
			this.setState(
				(prevState) => {
					return {
						isAddingComment: true,
						isAddEditModalOpen: false,
						analysisComments: [newComment, ...prevState.analysisComments],
						newComment: this.newComment,
						tempTags: [] as string[],
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			const { data } = await postAnalysisComments(
				this.props.companyId,
				title,
				analysis,
				type,
				sectionType,
				tags,
			);

			this.setState(
				(prevState) => {
					return {
						isAddingComment: false,
						analysisComments: prevState.analysisComments.map((c) => {
							return c.id === maxCommentId
								? {
										...c,
										id: data,
								  }
								: c;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			// Update tags list with new tags if document update was successful
			this.syncAnalysisTags(tags);
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddEditModalOpen: false,
						isAddingComment: false,
						analysisComments: [...prevState.analysisCommentsCopy],
					};
				},
				() => {
					snack.error({
						message: 'There was an error while adding comment',
					});
				},
			);
		}
	};

	handleSelectSectionType = (option: IOption | IOption[]) => {
		this.setState((prevState) => {
			return {
				newComment: {
					...prevState.newComment,
					sectionType: (option as IOption).value,
				},
			};
		});
	};

	handleSelectTagFilter = (options: IOption | IOption[]) => {
		this.setState(
			(prevState) => ({
				selectedTagFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectSectionFilter = (options: IOption | IOption[]) => {
		this.setState(
			(prevState) => ({
				selectedSectionFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectTypeFilter = (options: IOption | IOption[]) => {
		this.setState(
			(prevState) => ({
				selectedTypeFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectAuthorFilter = (options: IOption | IOption[]) => {
		this.setState(
			(prevState) => ({
				selectedAuthorFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleApplyFilters = () => {
		const {
			analysisComments,
			selectedTagFilters,
			selectedSectionFilters,
			selectedTypeFilters,
			selectedAuthorFilters,
		} = this.state;

		let filteredAnalysisComments = [...analysisComments];

		if (selectedTagFilters.length) {
			filteredAnalysisComments = filteredAnalysisComments.filter((c) =>
				c.tags.some((t) => selectedTagFilters.map((t) => t.value).includes(t)),
			);
		}

		if (selectedSectionFilters.length) {
			filteredAnalysisComments = filteredAnalysisComments.filter((c) =>
				selectedSectionFilters.map((sf) => sf.value).includes(c.sectionType),
			);
		}

		if (selectedTypeFilters.length) {
			filteredAnalysisComments = filteredAnalysisComments.filter((c) =>
				selectedTypeFilters.map((tf) => tf.value).includes(c.type),
			);
		}

		if (selectedAuthorFilters.length) {
			filteredAnalysisComments = filteredAnalysisComments.filter((c) =>
				selectedAuthorFilters
					.map((af) => af.value)
					.includes(c.createdBy.userId),
			);
		}

		this.setState({
			filteredAnalysisComments,
		});
	};

	syncAnalysisTags = (tags: string[]) => {
		const { analysisTags } = this.state;

		const newTags = tags.filter(
			(e) => !analysisTags.map((t) => t.name).includes(e),
		);

		const maxTagId = Math.max(...analysisTags.map((t) => t.tagId));

		// Reformat tags to combine with Analysis Tags (ITag[])
		const newTagsFormatted: ITag[] = newTags.map((t, i) => {
			return {
				tagId: maxTagId * 10 + i,
				name: t,
			};
		});

		this.setState((prevState) => {
			return {
				analysisTags: [...prevState.analysisTags, ...newTagsFormatted],
			};
		});
	};

	handleEditAnalysisComment = async () => {
		const { commentToEdit, tempTags } = this.state;

		const tags = [...tempTags];

		try {
			this.setState(
				(prevState) => {
					return {
						isAddEditModalOpen: false,
						commentToEdit: {} as IComment,
						tempTags: [] as string[],
						analysisComments: prevState.analysisComments.map((c) => {
							return c.id === commentToEdit.id ? { ...commentToEdit, tags } : c;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await editAnalysisComment(
				commentToEdit.id,
				commentToEdit.analysis,
				commentToEdit.title,
				commentToEdit.isObsolete,
				tags,
			);

			// Update tags list with new tags if document update was successful
			this.syncAnalysisTags(tags);
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddEditModalOpen: false,
						commentToEdit: {} as IComment,
						tempTags: [] as string[],
						analysisComments: [...prevState.analysisCommentsCopy],
					};
				},
				() => {
					snack.error({
						message: 'There was an error while saving comment',
					});
				},
			);
		}
	};

	handleAddTempTag = (tagName: string) => {
		this.setState((prevState) => {
			return {
				tempTags: [...prevState.tempTags, tagName],
			};
		});
	};

	handleRemoveTempTag = (tagName: string) => {
		this.setState((prevState) => {
			return {
				tempTags: prevState.tempTags.filter((t) => t !== tagName),
			};
		});
	};

	handleChangeCommentAnalysis = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { commentToEdit } = this.state;
		const { value } = event.target as HTMLInputElement;

		if (isEmpty(commentToEdit)) {
			this.setState((prevState) => {
				return {
					newComment: { ...prevState.newComment, analysis: value },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					commentToEdit: { ...prevState.commentToEdit, analysis: value },
				};
			});
		}
	};

	handleChangeCommentTitle = (title: string) => {
		const { commentToEdit } = this.state;

		if (isEmpty(commentToEdit)) {
			this.setState((prevState) => {
				return {
					newComment: { ...prevState.newComment, title },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					commentToEdit: { ...prevState.commentToEdit, title },
				};
			});
		}
	};

	handleChangeCommentType = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { value } = event.target as HTMLInputElement;

		this.setState((prevState) => {
			return {
				newComment: { ...prevState.newComment, type: value },
			};
		});
	};

	handleMarkAsObsolete = async (commentId: number) => {
		try {
			this.setState(
				(prevState) => {
					return {
						analysisComments: prevState.analysisComments.map((c) => {
							return c.id === commentId
								? { ...c, isObsolete: !c.isObsolete }
								: c;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await markAnalysisCommentObsolete(commentId);
		} catch (error) {
			snack.error({
				message: 'There was an error while updating comment',
			});
		}
	};

	generateCurrentFilters = () => {
		const { analysisComments } = this.state;
		return analysisComments.reduce(
			(
				acc: {
					currentSectionFilters: IOption[];
					currentTagFilters: IOption[];
					currentTypeFilters: IOption[];
					currentAuthorFilters: IOption[];
				},
				currentItem,
			) => {
				currentItem.tags.forEach((t) => {
					if (!acc.currentTagFilters.map((f) => f.value).includes(t)) {
						acc.currentTagFilters.push({
							label: t,
							value: t,
						});
					}
				});
				if (
					!acc.currentSectionFilters
						.map((f) => f.value)
						.includes(currentItem.sectionType)
				) {
					acc.currentSectionFilters.push({
						label: currentItem.sectionType.replace(/([a-z])([A-Z])/g, '$1 $2'),
						value: currentItem.sectionType,
					});
				}
				if (
					!acc.currentTypeFilters.map((f) => f.value).includes(currentItem.type)
				) {
					acc.currentTypeFilters.push({
						label: currentItem.type,
						value: currentItem.type,
					});
				}
				if (
					!acc.currentAuthorFilters
						.map((f) => f.value)
						.includes(currentItem.createdBy.userId)
				) {
					acc.currentAuthorFilters.push({
						label: `${currentItem.createdBy.firstName} ${currentItem.createdBy.lastName}`,
						value: currentItem.createdBy.userId,
					});
				}
				return acc;
			},
			{
				currentSectionFilters: [],
				currentTagFilters: [],
				currentTypeFilters: [],
				currentAuthorFilters: [],
			},
		);
	};

	render() {
		const {
			// isLoading,
			isAddingComment,
			isAddEditModalOpen,
			isRemoveModalOpen,
			analysisComments,
			filteredAnalysisComments,
			newComment,
			commentToEdit,
			analysisTags,
			tempTags,
			selectedTagFilters,
			selectedSectionFilters,
			selectedTypeFilters,
			selectedAuthorFilters,
		} = this.state;

		const { currentUser, sectionType } = this.props;

		// const uniqueAuthors = analysisComments.reduce(
		// 	(uniqueAuthors: ICurrentUser[], comment: IComment) => {
		// 		if (!uniqueAuthors.find((a) => a.userId === comment.createdBy.userId)) {
		// 			uniqueAuthors.push(comment.createdBy);
		// 		}
		// 		return uniqueAuthors;
		// 	},
		// 	[],
		// );

		const {
			currentSectionFilters,
			currentTagFilters,
			currentTypeFilters,
			currentAuthorFilters,
		} = this.generateCurrentFilters();
		const isEditMode = !isEmpty(commentToEdit);
		const comment = isEditMode ? commentToEdit : newComment;

		return (
			<PermissionsConsumer>
				{({
					creatingComment,
					updatingComment,
					deletingComment,
					markCommentObsolete,
					deletingCommentTag,
				}) => {
					return (
						<>
							<div className="analysis">
								<div
									className={`analysis__comments ${
										sectionType
											? ' analysis__comments--section'
											: ' analysis__comments--all-comments'
									}`}
								>
									{!sectionType ? (
										<>
											<div className="analysis__header">
												<div>Comments ({filteredAnalysisComments.length})</div>
												<ApSimpleDropdown
													multiple
													disabled={
														!currentTagFilters.length ||
														!analysisComments.length
													}
													label={
														selectedTagFilters.length
															? `${selectedTagFilters.length} tag(s)`
															: 'All tags'
													}
													onChange={this.handleSelectTagFilter}
													options={currentTagFilters}
													selected={selectedTagFilters}
												/>
												<ApSimpleDropdown
													multiple
													disabled={
														!currentSectionFilters.length ||
														!analysisComments.length
													}
													label={
														selectedSectionFilters.length
															? `${selectedSectionFilters.length} section(s)`
															: 'All sections'
													}
													onChange={this.handleSelectSectionFilter}
													options={currentSectionFilters}
													selected={selectedSectionFilters}
												/>
												<ApSimpleDropdown
													multiple
													disabled={
														!currentTypeFilters.length ||
														!analysisComments.length
													}
													label={
														selectedTypeFilters.length
															? `${selectedTypeFilters.length} type(s)`
															: 'All types'
													}
													onChange={this.handleSelectTypeFilter}
													options={currentTypeFilters}
													selected={selectedTypeFilters}
												/>
												<ApSimpleDropdown
													multiple
													disabled={
														!currentAuthorFilters.length ||
														!analysisComments.length
													}
													label={
														selectedAuthorFilters.length
															? `${selectedAuthorFilters.length} author(s)`
															: 'All authors'
													}
													onChange={this.handleSelectAuthorFilter}
													options={currentAuthorFilters}
													selected={selectedAuthorFilters}
												/>
											</div>
											<div className="analysis__controls">
												<div>
													{/* <ApButtonGroup
														justifyContent="initial"
														spacingSize={0}
													>
														<ApButtonTab
															isSelected={true}
															onClick={() => {
																// this.setState({ isRevenueYoYTab: true });
															}}
														>
															All
														</ApButtonTab>
														<ApButtonTab
															isSelected={false}
															onClick={() => {
																// this.setState({ isRevenueYoYTab: false });
															}}
														>
															Obsolete
														</ApButtonTab>
													</ApButtonGroup> */}
												</div>
												<div>
													{creatingComment && (
														<ApButtonSecondary
															onClick={this.handleOpenAddModal}
															iconName="baseline_add"
														>
															Add Analysis
														</ApButtonSecondary>
													)}
												</div>
											</div>
										</>
									) : (
										<div className="analysis__section-header__wrapper">
											<div className="analysis__section-header__sub-title">
												AlixPartners Analysis
											</div>
											<div className="analysis__section-header__comments-count">
												<img src={commentsCount} alt="Comment" />
												&nbsp;
												{filteredAnalysisComments.length}
											</div>
										</div>
									)}

									<div className="analysis__comments-list">
										{filteredAnalysisComments.length ? (
											[...filteredAnalysisComments].map((c) => {
												const utcDate = moment.utc(c.createdOn).toDate();
												const localDate = moment(utcDate)
													.local()
													.format('Do MMM YYYY, HH:mm');

												return (
													<div
														className={`analysis__comment${
															c.isObsolete ? ' analysis__comment--obsolete' : ''
														}`}
														key={c.id}
													>
														<div className="analysis__comment__avatar">
															<img src={avatar} alt="Avatar" />
														</div>
														<div className="analysis__comment__content">
															<div className="analysis__comment__wrapper">
																<div className="analysis__comment__title">
																	<strong>{c.title}</strong>
																	{` - ${c.createdBy.firstName} ${c.createdBy.lastName}`}
																</div>
																<div className="analysis__comment__date">
																	{localDate}
																	{/* {moment(c.createdOn).format(
																		'Do MMM YYYY, HH:mm',
																	)} */}
																</div>
																<div className="analysis__comment__actions">
																	{!isAddingComment && updatingComment && (
																		<div
																			onClick={() => {
																				this.handleOpenEditModal(c);
																			}}
																		>
																			<ApIcon
																				iconName="outline_edit"
																				iconColor="#000000"
																			/>
																		</div>
																	)}
																	{c.createdBy.userId === currentUser.userId &&
																		!isAddingComment &&
																		deletingComment && (
																			<div
																				onClick={() => {
																					this.handleOpenDeleteModal(c.id);
																				}}
																			>
																				<ApIcon
																					iconName="delete_outline"
																					iconColor="#000000"
																				/>
																			</div>
																		)}
																	{!isAddingComment && markCommentObsolete && (
																		<div
																			onClick={() => {
																				this.handleMarkAsObsolete(c.id);
																			}}
																		>
																			<ApIcon
																				iconName="outline_visability_off"
																				iconColor="#000000"
																			/>
																		</div>
																	)}
																</div>
															</div>
															<div className="analysis__comment__text">
																{c.analysis}
															</div>
															{c.tags.length > 0 && (
																<div className="analysis__comment__tags">
																	<span className="analysis__comment__date">
																		Tags:&nbsp;&nbsp;
																	</span>
																	{c.tags.map((t) => {
																		return (
																			<ApChip
																				className="analysis__comment__tag"
																				small
																				key={t}
																			>
																				{t}
																			</ApChip>
																		);
																	})}
																</div>
															)}
														</div>
													</div>
												);
											})
										) : (
											<div className="analysis__comment__no-comments">
												No comments
											</div>
										)}
									</div>
									{sectionType && creatingComment && (
										<div className="analysis__comments__add-button-sections">
											<ApButtonSecondary
												onClick={this.handleOpenAddModal}
												iconName="baseline_add"
											>
												Add Analysis
											</ApButtonSecondary>
										</div>
									)}
								</div>
							</div>
							<ApModal
								className="analysis-modal"
								isOpen={isAddEditModalOpen}
								hasClosed={this.handleCloseAddEditModal}
								header="Add Analysis"
								footer={() => (
									<ApButtonGroup justifyContent="flex-end">
										<ApButtonSecondary onClick={this.handleCloseAddEditModal}>
											Cancel
										</ApButtonSecondary>
										<ApButtonMain
											onClick={
												isEditMode
													? this.handleEditAnalysisComment
													: this.handleAddNewAnalysisComment
											}
											// TODO: Add check is changed
											disabled={
												comment.analysis === '' ||
												comment.title === '' ||
												(comment.type === CommentType.SECTION &&
													comment.sectionType === SectionType.NONE)
											}
										>
											{isEditMode ? 'Update Analysis' : 'Add Analysis'}
										</ApButtonMain>
									</ApButtonGroup>
								)}
							>
								<div className="analysis-modal__body">
									<label className="analysis-modal__label analysis-modal__label--no-padding">
										Type
									</label>
									<div className="analysis-modal__types">
										<ApRadio
											id="executive-summary"
											value={CommentType.EXECUTIVE}
											type="radio"
											disabled={isEditMode || !!sectionType}
											checked={comment.type === CommentType.EXECUTIVE}
											onChange={this.handleChangeCommentType}
										>
											Executive
										</ApRadio>
										<ApRadio
											id="other-analyis"
											value={CommentType.OTHER}
											type="radio"
											disabled={isEditMode || !!sectionType}
											checked={comment.type === CommentType.OTHER}
											onChange={this.handleChangeCommentType}
										>
											Other
										</ApRadio>
										<ApRadio
											id="section"
											value={CommentType.SECTION}
											type="radio"
											disabled={isEditMode || !!sectionType}
											checked={comment.type === CommentType.SECTION}
											onChange={this.handleChangeCommentType}
										>
											Section:
										</ApRadio>

										{comment.type === CommentType.SECTION && (
											<>
												{isEditMode || !!sectionType ? (
													<div className="analysis-modal__section-type">
														{comment.sectionType
															.replace(/([a-z])([A-Z])/g, '$1 $2')
															.toUpperCase()}
													</div>
												) : (
													<ApSimpleDropdown
														disabled={isEditMode}
														onChange={this.handleSelectSectionType}
														options={this.sectionTypes.map((t) => ({
															label: t.replace(/([a-z])([A-Z])/g, '$1 $2'),
															value: t,
															disabled: isEditMode,
														}))}
														selected={{
															label: comment.sectionType
																.replace(/([a-z])([A-Z])/g, '$1 $2')
																.toUpperCase(),
															value: comment.sectionType,
														}}
													/>
												)}
											</>
										)}
									</div>

									<label
										className="analysis-modal__label"
										htmlFor="comment-title"
									>
										Title
									</label>
									<div className="analysis-modal__title">
										<ApInput
											// @ts-ignore
											tabIndex={1}
											autoFocus
											id="comment-title"
											name="title-input"
											onChange={this.handleChangeCommentTitle}
											value={comment.title}
										/>
									</div>
									<label
										className="analysis-modal__label"
										htmlFor="analysis-comment"
									>
										Analysis
									</label>
									<div className="analysis-modal__analysis">
										<textarea
											id="analysis-comment"
											maxLength={2000}
											tabIndex={2}
											rows={8}
											value={comment.analysis}
											onChange={this.handleChangeCommentAnalysis}
											onFocus={(e) => {
												const val = e.target.value;
												e.target.value = '';
												e.target.value = val;
											}}
										/>
									</div>
									<label className="analysis-modal__label">Tags</label>
									<div className="analysis-modal__tagging">
										<div className="analysis-modal__tagging__search">
											<TagsSearch
												tags={analysisTags.filter(
													(t) => !tempTags.includes(t.name),
												)}
												onTagSelect={this.handleAddTempTag}
												showAddButton
												placeholder={'Type Tag Name...'}
											/>
										</div>
										<div className="analysis-modal__tagging__tags">
											{tempTags.map((t) => {
												return (
													<ApChip
														// small
														key={t}
														iconName="outline_cancel"
														onClick={() => this.handleRemoveTempTag(t)}
													>
														{t}
													</ApChip>
												);
											})}
										</div>
									</div>
								</div>
							</ApModal>
							<ApModal
								className="analysis-modal"
								isOpen={isRemoveModalOpen}
								hasClosed={this.handleCloseDeleteModal}
								footer={() => (
									<ApButtonGroup justifyContent="flex-end">
										<ApButtonSecondary onClick={this.handleCloseDeleteModal}>
											Cancel
										</ApButtonSecondary>
										<ApButtonMain onClick={this.handleRemoveAnalysisComment}>
											Delete Analysis
										</ApButtonMain>
									</ApButtonGroup>
								)}
							>
								<h5>Are you sure you want to delete analysis comment?</h5>
							</ApModal>
						</>
					);
				}}
			</PermissionsConsumer>
		);
	}
}
