import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
} from './common';
import { numberWithCommas, roundOneDecimal } from '../utils';
import { IBalanceOverview } from '../types';

interface IMapChild {
	name: string;
	apiField: string;
	data: any[];
}

interface IFieldMapItem {
	hasChildren: boolean;
	apiField: string;
	children?: IMapChild[];
	data: any[];
}

interface IFieldsMap {
	'Non-current Assets': IFieldMapItem;
	'Current Assets': IFieldMapItem;
	'Total Assets': IFieldMapItem;
	'Total Equity': IFieldMapItem;
	'Non-current Liabilities': IFieldMapItem;
	'Current Liabilities': IFieldMapItem;
	'Total Liabilities & Equity': IFieldMapItem;
}

const displayOrderMap: string[] = [
	'Non-current Assets',
	'Current Assets',
	'Total Assets',
	'Total Equity',
	'Non-current Liabilities',
	'Current Liabilities',
	'Total Liabilities & Equity',
];

const highLightRowsMap: string[] = [
	'Total Assets',
	'Total Liabilities & Equity',
];

const fieldsMap: IFieldsMap = {
	'Non-current Assets': {
		hasChildren: true,
		apiField: 'nonCurrentAssets',
		children: [
			{ name: 'Goodwill', apiField: 'goodwill', data: [] },
			{
				name: 'Net property, plant and equipment',
				apiField: 'netPropertyPlanAndEquipment',
				data: [],
			},
			{
				name: 'Other non-current assets',
				apiField: 'otherNonCurrentAssets',
				data: [],
			},
		],
		data: [],
	},
	'Current Assets': {
		hasChildren: true,
		apiField: 'currentAssets',
		children: [
			{
				name: 'Cash and cash equivalent',
				apiField: 'cashAndEquivalents',
				data: [],
			},
			{ name: 'Inventories', apiField: 'inventory', data: [] },
			{ name: 'Accounts receivables', apiField: 'accountReceivable', data: [] },
			{
				name: 'Other current assets',
				apiField: 'otherCurrentAssets',
				data: [],
			},
		],
		data: [],
	},
	'Total Assets': {
		hasChildren: false,
		apiField: 'totalAssets',
		data: [],
	},
	'Total Equity': {
		hasChildren: false,
		apiField: 'totalEquity',
		data: [],
	},
	'Non-current Liabilities': {
		hasChildren: true,
		apiField: '',
		children: [
			{ name: 'Long-Tem Debt', apiField: 'longTermDebt', data: [] },
			{
				name: 'Other non-current liabilities',
				apiField: 'otherNonCurrentLiabilities',
				data: [],
			},
		],
		data: [],
	},
	'Current Liabilities': {
		hasChildren: true,
		apiField: '',
		children: [
			{ name: 'Accounts Payable', apiField: 'accountPayable', data: [] },
			{
				name: 'Current Portion Of LT Debt',
				apiField: 'currentPortionOfLongTermDebt',
				data: [],
			},
			{
				name: 'Other current liabilities',
				apiField: 'otherCurrentLiabilitis',
				data: [],
			},
		],
		data: [],
	},
	'Total Liabilities & Equity': {
		hasChildren: false,
		apiField: 'totalLiabilitiesAndEquity',
		data: [],
	},
};

const getYearsList = (data: any[]) => {
	const yearsList: number[] = [];
	data.map((row) => {
		return yearsList.push(row.fiscalYear);
	});
	return yearsList.sort((a, b) => a - b);
};

const processRespData = (respData: IBalanceOverview[], fieldsMap: any) => {
	for (const prop in fieldsMap) {
		if (Object.prototype.hasOwnProperty.call(fieldsMap, prop)) {
			const rowProps: any = fieldsMap[prop];
			if (!rowProps.hasChildren) {
				rowProps.data = respData.map((row: any) => row[rowProps.apiField]);
			} else {
				rowProps.children.map((child: IMapChild) => {
					return (child.data = respData.map((row: any) => row[child.apiField]));
				});
				const childsData = rowProps.children.map(
					(child: IMapChild) => child.data,
				);
				if (rowProps.apiField) {
					rowProps.data = respData.map((row: any) => row[rowProps.apiField]);
				} else {
					rowProps.data = childsData.reduce(function (
						r: number[],
						a: number[],
					) {
						a.forEach(function (b: number, i: number) {
							r[i] = (r[i] || 0) + (b || 0);
						});
						return r;
					},
					[]);
				}
			}
		}
	}
};

const prepareTableData = (fieldsMap: any) => {
	const tableData: any[] = [];
	displayOrderMap.forEach((keyName) => {
		const mainRowOptions: any = { bold: true };
		if (highLightRowsMap.includes(keyName)) {
			mainRowOptions['fill'] = 'ADAFB2';
			mainRowOptions['color'] = 'FFFFFF';
			mainRowOptions['bold'] = true;
		}
		const fieldArray = fieldsMap[keyName].data.map((value: any) => {
			return {
				text: value ? numberWithCommas(roundOneDecimal(value)) : '0',
				options: { ...mainRowOptions, align: 'right', margin: [0, 7, 0, 7] },
			};
		});
		fieldArray.unshift({ text: keyName, options: mainRowOptions });

		tableData.push(fieldArray);
		if (fieldsMap[keyName].hasChildren) {
			fieldsMap[keyName].children.forEach((child: IMapChild) => {
				const childData = child.data.map((value: any) => {
					return {
						text: value ? numberWithCommas(roundOneDecimal(value)) : '0',
						options: { align: 'right', margin: [0, 7, 0, 7] },
					};
				});
				// @ts-ignore
				childData.unshift({ text: `- ${child.name}` });
				tableData.push(childData);
			});
		}
	});

	return tableData;
};

export const appendBalanceSheetOverviewSlide = async (
	deckInstance: pptxgen,
	companyId: number,
	currencyCode: string,
	respData: IBalanceOverview[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`Appendix: Balance Sheet Overview`, SLIDE_TITLE_CONFIG_WIDE);
	slide.addText(`Appendix`, SLIDE_TOP_LABEL_WIDE);

	const sortedData = respData.sort((a, b) => a.fiscalYear - b.fiscalYear);
	processRespData(sortedData, fieldsMap);

	const headerConfig = {
		fill: '006176',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
		align: 'right',
	};

	const yearsList = getYearsList(respData);
	const headersRow = yearsList.map((item) => {
		return { text: item.toString(), options: headerConfig };
	});
	headersRow.unshift({
		text: `${getSymbolFromCurrency(currencyCode)}M`,
		options: { ...headerConfig, align: 'left' },
	});
	const tableRows = prepareTableData(fieldsMap);

	// calculate dynamically size of columns
	const colW = [4.17];
	const colsCount = headersRow.length - 1;
	for (let i = 0; i < colsCount; i++) {
		colW.push(Math.round((8.3 / colsCount) * 100) / 100);
	}

	slide.addTable([headersRow, ...tableRows], {
		x: 0.4,
		y: 1.46,
		w: 12.53,
		// border: { type: 'solid', pt: 1, color: 'FFFFFF' },
		border: [
			{ type: 'solid', pt: 0, color: 'FFFFFF' },
			{ type: 'solid', pt: 1.5, color: 'FFFFFF' },
			{ type: 'dash', pt: 0.5, color: 'B3B3B5' },
			{ type: 'solid', pt: 1.5, color: 'FFFFFF' },
		],
		colW: colW,
		// @ts-ignore
		rowH: [0.27],
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		color: '383838',
		margin: [3, 0, 3, 7], // Adjusted to fit long roles
		valign: 'middle',
		align: 'left',
	});
};
