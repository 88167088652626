import React from 'react';
import moment from 'moment';

import { ApCheckbox } from '@alixpartners/ui-components';

import {
	IWatchlistReportCompany,
	IWatchlistComment,
	WatchlistCommentType,
} from '../../types';

import './WatchlistReport.scss';

// TODO: remove it or move to types
interface IComment extends IWatchlistComment {
	isSelected: boolean;
}

interface ICompany extends IWatchlistReportCompany {
	isSelected: boolean;
	comments: IComment[];
}

interface IWatchlistReportProps {
	companies: ICompany[];
	selectedCompanyId: number;
	onCompanyClick(companyId: number): void;
	onCompanySelect(e: React.ChangeEvent<HTMLInputElement>): void;
	onCommentSelect(e: React.ChangeEvent<HTMLInputElement>): void;
}

const sortByDate = (a: IComment, b: IComment) => {
	const dateA = new Date(a.reviewedOn).getTime();
	const dateB = new Date(b.reviewedOn).getTime();
	return dateA > dateB ? -1 : 1;
};

const toLocalDate = (date: string) =>
	moment(moment.utc(date).toDate()).local().format('Do MMM YYYY, HH:mm');

export class WatchlistReport extends React.Component<
	IWatchlistReportProps,
	unknown
> {
	renderCommentBlock = (comment: IComment) => {
		return (
			<div key={comment.id}>
				<ApCheckbox
					id={`${comment.id}`}
					name={`${comment.id}-${comment.companyId}`}
					type="checkbox"
					checked={comment.isSelected}
					onChange={this.props.onCommentSelect}
				>
					<div className="report-comment">
						<div>
							<span className="report-comment__author">{`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}</span>
							<span className="report-comment__date">
								{`${toLocalDate(comment.createdOn)}`}
							</span>
						</div>

						<div className="report-comment__text">{comment.analysis}</div>
					</div>
				</ApCheckbox>
			</div>
		);
	};

	render() {
		const { companies, selectedCompanyId } = this.props;
		const company = companies.find((c) => c.companyId === selectedCompanyId);
		if (!company) return;

		const apViewComments = company.comments
			.filter((c) => c.type === WatchlistCommentType.AP_VIEW)
			.sort(sortByDate);

		const currentSituationComments = company.comments
			.filter((c) => c.type === WatchlistCommentType.CURRENT_SITUATION)
			.sort(sortByDate);

		return (
			<div className="watchlist-report">
				<div className="watchlist-report__companies">
					{companies.map((c) => {
						const selectedCommentsCount = c.comments.filter(
							(comment) => comment.isSelected,
						).length;
						return (
							<div key={c.companyId} className="report-company">
								<div className="report-company__checkbox">
									<ApCheckbox
										id={c.companyName}
										type="checkbox"
										name={`${c.companyId}`}
										checked={c.isSelected}
										onChange={this.props.onCompanySelect}
									/>
								</div>
								<div
									className={`report-company__label${
										selectedCompanyId === c.companyId
											? ' report-company__label--selected'
											: ''
									}`}
									onClick={(e: React.MouseEvent<HTMLDivElement>) => {
										this.props.onCompanyClick(c.companyId);
									}}
								>
									<span>{`${c.companyName} (${
										selectedCommentsCount > 0 ? selectedCommentsCount + '/' : ''
									}${c.comments.length})`}</span>
								</div>
							</div>
						);
					})}
				</div>
				<div className="watchlist-report__comments">
					<div>
						{currentSituationComments.length > 0 && (
							<div className="watchlist-report__label">
								Current situation ({currentSituationComments.length})
							</div>
						)}
						{currentSituationComments.map((c) => {
							return this.renderCommentBlock(c);
						})}
					</div>
					<div>
						{apViewComments.length > 0 && (
							<div className="watchlist-report__label">
								AlixPartners view ({apViewComments.length})
							</div>
						)}
						{apViewComments.map((c) => {
							return this.renderCommentBlock(c);
						})}
					</div>
				</div>
			</div>
		);
	}
}
