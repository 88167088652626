import React from 'react';
// import * as Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

import './CompanyCountWidget.scss';

interface IData {
	date: string;
	numberOfCompanies: number;
}

interface ICompanyCountWidgetProps {
	// chartData: IData[];
	numberOfCompanies: number;
	companiesRemoved: number;
	companiesAdded: number;

	totalPursuitCount: number;
	totalMonitorCount: number;
	// totalDropCount: number;
}

// const processData = (chartData: IData[]) => {
// 	const monthNames = [
// 		'J',
// 		'F',
// 		'M',
// 		'A',
// 		'M',
// 		'J',
// 		'J',
// 		'A',
// 		'S',
// 		'O',
// 		'N',
// 		'D',
// 	];

// 	const monthMap = chartData.reduce(
// 		(acc: { [month: number]: number }, item) => {
// 			const month = new Date(item.date).getMonth();
// 			acc[month] = item.numberOfCompanies;
// 			return acc;
// 		},
// 		{},
// 	);

// 	const months = Object.keys(monthMap);
// 	const firstMonth = +months[0];
// 	const lastMonth = +months[months.length - 1];

// 	const result: { categories: string[]; data: number[] } = {
// 		categories: [],
// 		data: [],
// 	};
// 	let lastNumber = monthMap[0];
// 	for (let i = firstMonth; i <= lastMonth; i++) {
// 		const currentNumber = monthMap[i];

// 		if (currentNumber) {
// 			result.categories.push(monthNames[i]);
// 			result.data.push(currentNumber);
// 			lastNumber = currentNumber;
// 		} else {
// 			result.categories.push(monthNames[i]);
// 			result.data.push(lastNumber);
// 		}
// 	}

// 	return result;
// };

export const CompanyCountWidget: React.FC<ICompanyCountWidgetProps> = ({
	// chartData,
	numberOfCompanies,
	companiesRemoved,
	companiesAdded,
	totalPursuitCount,
	totalMonitorCount,
	// totalDropCount,
}) => {
	// const tempData = [
	// 	{ date: '2020-02-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-03-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-04-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-05-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-06-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-07-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-08-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-09-17T18:57:20.083', numberOfCompanies: 7 },
	// 	// { date: '2020-10-17T18:57:20.083', 	numberOfCompanies: 7 },
	// 	// { date: '2020-11-17T18:57:20.083', numberOfCompanies: 7 },
	// 	{ date: '2020-12-17T18:57:20.083', numberOfCompanies: 7 },
	// 	{ date: '2021-01-17T18:57:20.083', numberOfCompanies: 7 },
	// 	{ date: '2021-01-17T19:57:20.083', numberOfCompanies: 7 },
	// 	{ date: '2021-03-17T18:57:20.083', numberOfCompanies: 7 },
	// 	{ date: '2021-04-17T18:57:35.297', numberOfCompanies: 8 },
	// 	{ date: '2021-05-18T08:15:40.057', numberOfCompanies: 6 },
	// 	{ date: '2021-12-18T08:15:40.057', numberOfCompanies: 2 },
	// ];

	// const { currentYearData, prevYearData } = chartData.reduce(
	// 	(acc: { currentYearData: IData[]; prevYearData: IData[] }, item) => {
	// 		const currentYear = new Date().getFullYear();
	// 		if (new Date(item.date).getFullYear() === currentYear) {
	// 			acc.currentYearData.push(item);
	// 		} else {
	// 			acc.prevYearData.push(item);
	// 		}

	// 		return acc;
	// 	},
	// 	{ currentYearData: [], prevYearData: [] },
	// );

	// let { categories } = processData(currentYearData);
	// const { categories: prevCategories, data: prevData } = processData(
	// 	prevYearData,
	// );

	// if (categories.length < 12) {
	// 	const delta = (12 - categories.length) * -1;
	// 	const requiredPrevCategories = prevCategories.slice(delta);
	// 	const requiredPrevData = prevData.slice(delta);

	// 	categories = [...requiredPrevCategories, ...categories];
	// 	data = [...requiredPrevData, ...data];
	// }

	// const max = Math.max(...data) + 1;

	// const chartOptions: Highcharts.Options = {
	// 	chart: {
	// 		height: '90px',
	// 	},
	// 	title: {
	// 		text: '',
	// 	},
	// 	credits: {
	// 		enabled: false,
	// 	},
	// 	xAxis: {
	// 		categories,
	// 		lineColor: '#1A1A1A',
	// 		lineWidth: 1,
	// 		labels: {
	// 			useHTML: true,
	// 			style: {
	// 				fontFamily: 'Roboto',
	// 				fontSize: '11px',
	// 				fontWeight: '500',
	// 				lineHeight: '13px',
	// 				color: '#737373',
	// 			},
	// 		},
	// 	},
	// 	yAxis: {
	// 		allowDecimals: false,
	// 		max,
	// 		gridLineWidth: 0,
	// 		tickAmount: 3,
	// 		title: {
	// 			text: '',
	// 		},
	// 		lineColor: '#1A1A1A',
	// 		lineWidth: 1,
	// 		labels: {
	// 			useHTML: true,
	// 			style: {
	// 				fontFamily: 'Roboto',
	// 				fontSize: '11px',
	// 				fontWeight: '500',
	// 				lineHeight: '13px',
	// 				color: '#737373',
	// 			},
	// 		},
	// 	},
	// 	tooltip: {
	// 		enabled: false,
	// 	},
	// 	legend: {
	// 		enabled: false,
	// 	},
	// 	plotOptions: {
	// 		line: {
	// 			color: '#0696A6',
	// 			marker: {
	// 				enabled: categories.length === 1,
	// 			},
	// 		},
	// 		series: {
	// 			dataLabels: {
	// 				enabled: false,
	// 			},
	// 			states: {
	// 				inactive: {
	// 					opacity: 1,
	// 				},
	// 				hover: {
	// 					enabled: false,
	// 				},
	// 			},
	// 			animation: false,
	// 		},
	// 	},
	// 	// @ts-ignore
	// 	series: [
	// 		{
	// 			name: 'numberOfCompanies',
	// 			data,
	// 		},
	// 	],
	// };

	return (
		<div className="company-count">
			<div className="company-count__title">
				Company count and movement over last 30 days
			</div>
			<div className="company-count__data">
				<div>
					<div className="company-count__data__label">Current number</div>
					<div className="company-count__data__value">{numberOfCompanies}</div>
				</div>
				<div>
					<div className="company-count__data__label">Added</div>
					<div className="company-count__data__value">{companiesAdded}</div>
				</div>
				<div>
					<div className="company-count__data__label">Removed</div>
					<div className="company-count__data__value">{companiesRemoved}</div>
				</div>
			</div>
			<div className="company-count__title">
				Total number of companies by status
			</div>
			<div className="company-count__data">
				<div>
					<div className="company-count__data__label">Pursuit count</div>
					<div className="company-count__data__value">{totalPursuitCount}</div>
				</div>
				<div>
					<div className="company-count__data__label">Monitor count</div>
					<div className="company-count__data__value">{totalMonitorCount}</div>
				</div>
				<div>
					{/* <div className="company-count__data__label">Drop count</div>
					<div className="company-count__data__value">{totalDropCount}</div> */}
				</div>
			</div>
			{/* <HighchartsReact highcharts={Highcharts} options={chartOptions} /> */}
		</div>
	);
};
