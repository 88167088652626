import React from 'react';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import './Section.scss';

interface ISectionProps {
	title: string;
	isLoading: boolean;
	children: React.ReactNode;
}

export const Section: React.FC<ISectionProps> = ({
	title,
	isLoading,
	children,
}) => {
	if (isLoading) {
		return (
			<div className="dashboard-section">
				<ApLoaderDefault className="dashboard-section__spinner" />
			</div>
		);
	}

	return (
		<div className="dashboard-section">
			<div className="dashboard-section__title">{title}</div>
			<div className="dashboard-section__content">{children}</div>
		</div>
	);
};
