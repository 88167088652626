import React from 'react';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	withRouter,
} from 'react-router-dom';
import html2canvas from 'html2canvas';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReadMoreAndLess from 'react-read-more-less';
import {
	ApAuthenticationContextConsumer,
	Telemetry,
} from '@alixpartners/ui-utils';

import {
	ApButtonFilter,
	ApButtonGroup,
	ApButtonMain,
	ApButtonSecondary,
	ApButtonTab,
	ApIcon,
	ApLoaderDefault,
	ApLoaderText,
	ApModal,
} from '@alixpartners/ui-components';

import { exportDeck, IDeckChartImagesMap, IDeckConfig } from '../../deck';
import {
	getImageDataUrl,
	getImageSizeInInches,
	getLogoUrl,
	omit,
	retry,
} from '../../utils';
import { truncateText } from '../../deck/common';
import {
	FinancialType,
	IChart,
	ICompany,
	ICompanySearchResult,
	ICompetitor,
	ICompetitorMeta,
	IGeography,
	IOverviewWidget,
	IPeersFinancialWidget,
	IPeersRevenueWidget,
	IPeersRevenueYoYWidget,
	IPeople,
	ISummary,
	IWatchlistListItem,
	IWatchlistMeta,
	IWatchlistWidgetData,
	WidgetType,
} from '../../types';
import { FinancialsChart } from '../FinancialsChart';
import { SharePriceChart } from '../SharePriceChart';
import { GeographyChart } from '../GeographyChart';
import { People } from '../People';
import { ConnectionsChart } from '../ConnectionsChart';
import { DeckGrowthChart } from '../DeckGrowthChart';
import { DeckGrowthChartLastYear } from '../DeckGrowthChartLastYear';
import { DeckSharePriceChart } from '../DeckSharePriceChart';
import { Logo } from '../Logo';
import { Summary } from '../Summary';
import { News } from '../News';
import { Classification } from '../Classification';
import { Engagements } from '../Engagements';
import { PeerSearch } from '../PeerSearch';
import { CompanyWatchlists } from '../CompanyWatchlists';

import { CompanyOverview } from '../CompanyOverview';
import { CompanyDashboard } from '../CompanyDashboard';
import { CompanyComparison } from '../CompanyComparison';
import { CompanyAnalysis } from '../CompanyAnalysis';

import './Company.scss';

import {
	getCompanyNews,
	getComparisonFinancialData,
	getComparisonRevenue,
	getComparisonRevenueYoY,
	getComparisonSharePrice,
	getCompetitorsData,
	getConnectionsData,
	getFinancialReport,
	getFinancialsData,
	getPeopleData,
	getSharePriceData,
	getSummaryData,
	getWatchlists,
} from '../../api/';

interface ICompanyProps extends RouteComponentProps {
	company: ICompany;
	people: IPeople[];
	connectionsData: IPeople[];
	acquisitions: string[];
	subsidiaries: string[];
	charts: IChart[];
	geography: IGeography;
	watchlists: IWatchlistWidgetData[];
	summary: ISummary;
	competitors: ICompetitorMeta[];
	isTrsEnabled: boolean;

	onBackClick(): void;
}

interface ICompanyState {
	isGeneratingDeck: boolean;
	isLoadingPptRequiredData: boolean;
	isPptRequiredDataLoaded: boolean;
	logoError: boolean;
	cachedCompanyData: {
		sharePriceChartData: IChart | null;
		financialChartData: IChart | null;
		peopleData: IPeople[] | null;
		connectionsData: IPeople[] | null;
		watchlistsData: IWatchlistMeta[] | null;
		summaryData: ISummary | null;
	};
	// cachedDashboardData: IDashboardWidgetCache;
	financialData: {
		years: string[];
		revenue: number[];
		cogs: number[];
		grossProfit: number[];
		sga: number[];
		ebitda: number[];
		operatingIncome: number[];
		totalDebt: number[];
		netIncome: number[];
	};
	isPeersModalOpen: boolean;
	isWatchlistModalOpen: boolean;
	isSelectedPeersDataFetched: boolean;
	// selectedPeers: number[];
	selectedPeers: ICompetitorMeta[];
	customPeers: ICompetitorMeta[];
	selectedCompetitorsData: ICompetitor[];
	companyWatchlists: IWatchlistWidgetData[];

	// PPT Peers bubble charts data
	peersRevenueData: IPeersRevenueWidget;
	peersRevenueYoYData: IPeersRevenueYoYWidget;
	peersFinancialData: IPeersFinancialWidget[];
}

class CompanyComponent extends React.PureComponent<
	ICompanyProps,
	ICompanyState
> {
	navigationEl: HTMLDivElement | null = null;
	headerEl: HTMLDivElement | null = null;

	selectFirst5Peers = (): ICompetitorMeta[] => {
		const {
			competitors,
			company: { companyId },
		} = this.props;

		// Filter out current company from competitors list
		const onlyCompetitors = competitors.filter(
			(c) => c.companyId !== companyId,
		);

		const selectedPeers = sessionStorage.getItem('selectedPeers');

		if (selectedPeers) {
			return JSON.parse(selectedPeers);
		} else {
			return onlyCompetitors.length > 5
				? onlyCompetitors.slice(0, 5)
				: onlyCompetitors;
		}
	};

	getCustomPeers = (): ICompetitorMeta[] => {
		const { competitors } = this.props;

		const selectedPeers = sessionStorage.getItem('selectedPeers');

		if (selectedPeers) {
			return (JSON.parse(selectedPeers) as ICompetitorMeta[]).filter(
				(p) => !competitors.map((c) => c.companyId).includes(p.companyId),
			);
		} else {
			return [] as ICompetitorMeta[];
		}
	};

	state = {
		isGeneratingDeck: false,
		isLoadingPptRequiredData: true,
		isPptRequiredDataLoaded: false,
		logoError: false,
		cachedCompanyData: {
			sharePriceChartData: null,
			financialChartData: null,
			peopleData: null,
			connectionsData: null,
			watchlistsData: null,
			summaryData: null,
		},
		// cachedDashboardData: {
		// 	netSales: null,
		// 	operatingIncome: null,
		// 	sga: null,
		// 	ebitda: null,
		// 	operationalRevenuePl: null,
		// 	ebitdaPl: null,
		// 	sgaPl: null,
		// 	grossProfitPl: null,
		// },
		financialData: {
			years: [],
			revenue: [],
			cogs: [],
			grossProfit: [],
			sga: [],
			ebitda: [],
			operatingIncome: [],
			totalDebt: [],
			netIncome: [],
		},
		isPeersModalOpen: false,
		isWatchlistModalOpen: false,
		isSelectedPeersDataFetched: false,
		selectedPeers: this.selectFirst5Peers(),
		selectedCompetitorsData: [] as ICompetitor[],
		customPeers: this.getCustomPeers(),
		companyWatchlists: this.props.watchlists || [],
		peersRevenueData: {} as IPeersRevenueWidget,
		peersRevenueYoYData: {} as IPeersRevenueYoYWidget,
		peersFinancialData: [] as IPeersFinancialWidget[],
	};

	handleAddCompetitor = (competitor: ICompetitorMeta) => {
		const { competitors } = this.props;

		this.setState((prevState) => {
			return {
				selectedPeers: [...prevState.selectedPeers, competitor],
				customPeers: competitors.find(
					(c) => c.companyId === competitor.companyId,
				)
					? prevState.customPeers
					: [...prevState.customPeers, competitor],
			};
		});
	};

	isPptDataLoaded = () => {
		return Object.values(
			omit(this.state.cachedCompanyData, 'watchlistsData'),
		).every((v) => !!v);
	};

	handleRemoveCompetitor = (competitor: ICompetitorMeta) => {
		this.setState((prevState) => {
			return {
				selectedPeers: prevState.selectedPeers.filter(
					(p) => p.companyId !== competitor.companyId,
				),
			};
		});
	};

	handleScroll = () => {
		if (this.navigationEl && this.headerEl) {
			const offset = window.pageYOffset;

			const stickyThreshold = this.headerEl.offsetHeight;
			if (offset > stickyThreshold) {
				this.navigationEl.classList.add('company-page__navigation--sticky');
			} else {
				this.navigationEl.classList.remove('company-page__navigation--sticky');
			}
		}
	};

	handleClosePeersModal = () => {
		this.setState({
			isPeersModalOpen: false,
			selectedPeers: this.selectFirst5Peers(),
		});
	};

	handleOpenPeersModal = () => {
		this.setState({
			isPeersModalOpen: true,
		});
	};

	handleCloseWatchlistModal = () => {
		this.setState({
			isWatchlistModalOpen: false,
		});
	};

	handleOpenWatchlistModal = () => {
		this.setState({
			isWatchlistModalOpen: true,
		});
	};

	// handleCacheDashboardData = (
	// 	widgetId: string,
	// 	data: IDashboardSymmaryWidget,
	// ) => {
	// 	this.setState((prevState: ICompanyState) => {
	// 		return {
	// 			cachedDashboardData: {
	// 				...prevState.cachedDashboardData,
	// 				[widgetId]: data,
	// 			},
	// 		};
	// 	});
	// };

	handleWatchlistSelect = (watchlistData: IWatchlistWidgetData | null) => {
		if (watchlistData) {
			this.setState((prevState) => {
				return {
					companyWatchlists: [...prevState.companyWatchlists, watchlistData],
					isWatchlistModalOpen: false,
				};
			});
		} else {
			this.setState({ isWatchlistModalOpen: false });
		}
	};

	handleSelectNewPeer = (c: ICompetitorMeta) => {
		this.setState((prevState) => {
			let selectedPeers;

			if (
				prevState.selectedPeers.map((p) => p.companyId).includes(c.companyId)
			) {
				selectedPeers = prevState.selectedPeers.filter(
					(p) => p.companyId !== c.companyId,
				);
			} else {
				if (prevState.selectedPeers.length > 4) {
					prevState.selectedPeers.shift();
				}
				selectedPeers = [...prevState.selectedPeers, c];
			}

			sessionStorage.setItem('selectedPeers', JSON.stringify(selectedPeers));
			return {
				selectedPeers,
			};
		});
	};

	handleClearSelectedPeers = () => {
		this.setState({ selectedPeers: [] as ICompetitorMeta[] });
		sessionStorage.setItem('selectedPeers', JSON.stringify([]));
	};

	handleDownloadDeck = async (isWidescreen = false) => {
		const {
			company: { companyId },
		} = this.props;
		const { selectedPeers, customPeers } = this.state;

		this.setState({ isPeersModalOpen: false, isGeneratingDeck: true });

		const allPeersIds = [
			companyId,
			...selectedPeers.map((p) => p.companyId),
			...customPeers
				.filter((cp) =>
					selectedPeers.map((p) => p.companyId).includes(cp.companyId),
				)
				.map((cp) => cp.companyId),
		];

		const [
			{ data: peersGeneralData },
			{ data: peersSharePriceData },
			{ data: peersRevenueYoYData },
			{ data: peersRevenueData },
			{ data: peersFinancialData },
		] = await Promise.all([
			getCompetitorsData(companyId, allPeersIds),
			getComparisonSharePrice(allPeersIds),
			getComparisonRevenueYoY(allPeersIds),
			getComparisonRevenue(allPeersIds),
			getComparisonFinancialData(allPeersIds),
		]);

		const selectedCompetitorsData = peersGeneralData.map((d) => {
			const sharePrice = peersSharePriceData.find(
				(p) => p.companyId === d.companyId,
			);

			return {
				...d,
				sharePriceGrowth: sharePrice ? sharePrice.growth : null,
				sharePriceData: sharePrice ? sharePrice.sharePrice : [],
			};
		});

		this.setState({
			peersRevenueData,
			peersRevenueYoYData,
			peersFinancialData,
			selectedCompetitorsData,
			isSelectedPeersDataFetched: true,
		});

		// try {
		await retry(2, () => this.handleDeckExport(isWidescreen));
		this.setState({
			isGeneratingDeck: false,
			isSelectedPeersDataFetched: false,
			selectedPeers: this.selectFirst5Peers(),
		});
		// } catch (error) {
		// 	console.log('Error exporting lead profile deck');
		// 	this.setState({ isGeneratingDeck: false });
		// }
	};

	getAllDeckCharts = async () => {
		const html2canvasConfig = {
			scrollX: 0,
			scrollY: -window.scrollY,
			logging: false,
		};

		const getChartDomNode = (chartType: string): HTMLElement => {
			// @ts-ignore
			return document.querySelector(
				`.deck-${chartType
					.toLowerCase()
					.replace(/ /g, '-')
					.replace('&', 'and')}-chart`,
			);
		};

		return Promise.all([
			html2canvas(getChartDomNode('growsProfitability'), html2canvasConfig),
			html2canvas(
				getChartDomNode('growsProfitabilityLastYear'),
				html2canvasConfig,
			),
			html2canvas(getChartDomNode('sharePrice'), html2canvasConfig),
		]);
	};

	handleCachePptDataData = async () => {
		const {
			company: { companyId },
		} = this.props;

		try {
			const results = await Promise.all([
				getSharePriceData(companyId),
				getFinancialsData(companyId),
				getPeopleData(companyId),
				getConnectionsData(companyId),
				getSummaryData(companyId),
			]);

			this.setState((prevState) => {
				const formattedChartData = results[1].data.series.data.reduce(
					(acc: { [key: string]: number[] }, v: any) => {
						if (Object.keys(acc).includes(v.name)) {
							acc[v.name] = v.data;
						}
						return acc;
					},
					{
						[FinancialType.REVENUE]: [],
						[FinancialType.GROSS_PROFIT]: [],
						[FinancialType.SG_AND_A]: [],
						[FinancialType.OPERATING_INCOME]: [],
						[FinancialType.EBITDA]: [],
						[FinancialType.TOTAL_DEBT]: [],
						[FinancialType.NET_INCOME]: [],
						[FinancialType.COGS]: [],
					},
				);

				return {
					cachedCompanyData: {
						...prevState.cachedCompanyData,
						sharePriceChartData: results[0].data,
						financialChartData: results[1].data,
						peopleData: results[2].data,
						connectionsData: results[3].data,
						summaryData: results[4].data,
					},
					financialData: {
						years: results[1].data.xAxis.categories,
						revenue: formattedChartData[FinancialType.REVENUE],
						cogs: formattedChartData[FinancialType.COGS],
						grossProfit: formattedChartData[FinancialType.GROSS_PROFIT],
						sga: formattedChartData[FinancialType.SG_AND_A],
						ebitda: formattedChartData[FinancialType.EBITDA],
						operatingIncome: formattedChartData[FinancialType.OPERATING_INCOME],
						totalDebt: formattedChartData[FinancialType.TOTAL_DEBT],
						netIncome: formattedChartData[FinancialType.NET_INCOME],
					},
					isLoadingPptRequiredData: false,
				};
			});

			// console.log(results);
		} catch (error) {}
	};

	handleDeckExport = async (isWidescreen: boolean) => {
		const {
			company: {
				companyId,
				name,
				description,
				city,
				country,
				yearFounded,
				currencyCode,
				exchangeName,
				website,
			},
			geography: { countries },
		} = this.props;

		const {
			cachedCompanyData: {
				financialChartData,
				summaryData,
				peopleData,
				connectionsData,
			},
			financialData,
			selectedCompetitorsData,
			peersFinancialData,
		} = this.state;

		// TODO: this piece
		if (!financialChartData) return;

		// @ts-ignore
		const chartData = financialChartData as IChart;
		const currentYearIndex = chartData.xAxis.categories.findIndex(
			// @ts-ignore
			(y) => +y === summaryData.latestYear,
		);

		const revenueSeriesLast5 = financialData.revenue.slice(
			Math.max(financialData.revenue.length - 5, 0),
		);
		const grossProfitSeriesLast5 = financialData.grossProfit.slice(
			Math.max(financialData.grossProfit.length - 5, 0),
		);
		const sgaSeriesLast5 = financialData.sga.slice(
			Math.max(financialData.sga.length - 5, 0),
		);
		const ebitdaSeriesLast5 = financialData.ebitda.slice(
			Math.max(financialData.ebitda.length - 5, 0),
		);
		const netIncomeSeriesLast5 = financialData.netIncome.slice(
			Math.max(financialData.netIncome.length - 5, 0),
		);
		const cogsSeriesLast5 = financialData.cogs.slice(
			Math.max(financialData.cogs.length - 5, 0),
		);
		const yearsLast5 = chartData.xAxis.categories.slice(
			Math.max(chartData.xAxis.categories.length - 5, 0),
		);

		// Calculate percentage for deck revenue chart

		const getPercentageLast5 = (series: number[]) => {
			const original = series[0];
			const last = series[series.length - 1];

			let percentage = Math.round(
				Math.abs(((original - last) / original) * 100),
			);

			percentage = percentage === Infinity ? 100 : percentage;
			return original - last < 0 ? percentage * -1 : percentage;
		};

		const revenueChartsPercentage = {
			[FinancialType.REVENUE]: getPercentageLast5(revenueSeriesLast5),
			[FinancialType.GROSS_PROFIT]: getPercentageLast5(grossProfitSeriesLast5),
			[FinancialType.SG_AND_A]: getPercentageLast5(sgaSeriesLast5),
			[FinancialType.EBITDA]: getPercentageLast5(ebitdaSeriesLast5),
		};

		const revenueChartsDataLast5 = {
			[FinancialType.REVENUE]: revenueSeriesLast5,
			[FinancialType.GROSS_PROFIT]: grossProfitSeriesLast5,
			[FinancialType.SG_AND_A]: sgaSeriesLast5,
			[FinancialType.EBITDA]: ebitdaSeriesLast5,
			[FinancialType.NET_INCOME]: netIncomeSeriesLast5,
			[FinancialType.COGS]: cogsSeriesLast5,
			[FinancialType.YEARS]: yearsLast5,
		};

		const [
			growthProfitabilityCanvas,
			growthProfitabilityLastYearCanvas,
			sharePriceCanvas,
		] = await this.getAllDeckCharts();

		const deckChartImages: IDeckChartImagesMap = {
			growsProfitability: growthProfitabilityCanvas.toDataURL(),
			growsProfitabilityLastYear: growthProfitabilityLastYearCanvas.toDataURL(),
			sharePrice: sharePriceCanvas.toDataURL(),
		};

		const balanceData = await getFinancialReport(companyId, 0);
		const cashFlowData = await getFinancialReport(companyId, 1);

		const deckConfig: IDeckConfig = {
			// @ts-ignore
			...summaryData,
			companyId,
			name,
			description,
			currencyCode,
			prevEbitda: financialData.ebitda[currentYearIndex - 1],
			prevRevenue: financialData.revenue[currentYearIndex - 1],
			prevGrossProfit: financialData.grossProfit[currentYearIndex - 1],
			competitors: selectedCompetitorsData,
			exchangeName,
			yearFounded,
			country,
			city,
			countries,
			// @ts-ignore
			keyPeople: peopleData
				.filter(
					(p: IPeople) => p.name && p.role && typeof p.proRank !== 'undefined',
				)
				.sort((a: IPeople, b: IPeople) => {
					// @ts-ignore
					return a.proRank - b.proRank;
				}),
			// @ts-ignore
			connectionsData,
			deckChartImages,
			revenueChartsPercentage,
			revenueChartsDataLast5,
			chartData: chartData,
			balanceData: balanceData.data,
			cashFlowData: cashFlowData.data,
			peersFinancialData,
		};

		// Process company logo
		if (website) {
			try {
				const logo = await getImageDataUrl(getLogoUrl(website));
				const { width, height } = await getImageSizeInInches(logo);

				deckConfig.logo = logo;
				deckConfig.logoWidth = width;
				deckConfig.logoHeight = height;
			} catch (error) {
				console.log('Error processing logo image');
			}
		}

		exportDeck(deckConfig, isWidescreen);
		Telemetry.trackEvent(`Downloaded Lead Profile - ${name}`);
		console.log(`Downloaded Lead Profile - ${name}`);
	};

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(prevProps: ICompanyProps, prevState: ICompanyState) {
		if (
			Object.values(omit(this.state.cachedCompanyData, 'watchlistsData')).every(
				(v) => !!v,
			) &&
			prevState.isLoadingPptRequiredData
		) {
			this.setState({ isLoadingPptRequiredData: false });
		}
	}

	handleCustomPeerSelect = (c: ICompanySearchResult) => {
		const { competitors } = this.props;

		this.setState((prevState) => {
			let selectedPeers = prevState.selectedPeers;

			if (
				!prevState.selectedPeers.map((p) => p.companyId).includes(c.companyId)
			) {
				if (prevState.selectedPeers.length > 4) {
					prevState.selectedPeers.shift();
				}
				selectedPeers = [
					...prevState.selectedPeers,
					{ companyId: c.companyId, name: c.name },
				];
			}

			const isAlreadySelected = [...prevState.customPeers, ...competitors].find(
				(peer) => peer.companyId === c.companyId,
			);

			return {
				customPeers: !isAlreadySelected
					? [...prevState.customPeers, c]
					: prevState.customPeers,
				selectedPeers,
			};
		});
	};

	render() {
		const {
			isGeneratingDeck,
			isLoadingPptRequiredData,
			isPeersModalOpen,
			isWatchlistModalOpen,
			companyWatchlists,
			selectedPeers,
			customPeers,
			isSelectedPeersDataFetched,
			selectedCompetitorsData,
			cachedCompanyData,
			peersRevenueYoYData,
			peersRevenueData,
		} = this.state;
		const {
			isTrsEnabled,
			subsidiaries,
			acquisitions,
			geography: { countries, keyCountries },
			company: {
				companyId,
				additionalNames,
				classification,
				country,
				description,
				name,
				currencyCode,
				tickerSymbol,
				website,
				engagements,
			},
			competitors,
			onBackClick,
			match: { path, url },
		} = this.props;

		const widgetsConfig: IOverviewWidget[] = [
			{
				name: WidgetType.SHARE_PRICE,
				component: SharePriceChart,
				type: 'main',
				minHeight: 228,
				processData: (data) => {
					return data.hasOwnProperty('xAxis') &&
						data.xAxis.categories.length === 0
						? null
						: { chartData: data };
				},
				api: () => {
					return getSharePriceData(companyId);
				},
				props: {
					tickerSymbol,
					currencySymbol: getSymbolFromCurrency(currencyCode),
				},
				cachedData: cachedCompanyData.sharePriceChartData,
				onDataLoaded: (sharePriceChartData: IChart) => {
					this.setState((prevState) => {
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								sharePriceChartData,
							},
						};
					});
				},
			},
			{
				name: WidgetType.FINANCIALS,
				component: FinancialsChart,
				type: 'main',
				minHeight: 472,
				processData: (data) => ({
					chartData: data,
				}),
				api: () => {
					return getFinancialsData(companyId);
				},
				cachedData: cachedCompanyData.financialChartData,
				onDataLoaded: (data: IChart) => {
					this.setState((prevState) => {
						const formattedChartData = data.series.data.reduce(
							(acc: { [key: string]: number[] }, v: any) => {
								if (Object.keys(acc).includes(v.name)) {
									acc[v.name] = v.data;
								}
								return acc;
							},
							{
								[FinancialType.REVENUE]: [],
								[FinancialType.GROSS_PROFIT]: [],
								[FinancialType.SG_AND_A]: [],
								[FinancialType.OPERATING_INCOME]: [],
								[FinancialType.EBITDA]: [],
								[FinancialType.TOTAL_DEBT]: [],
								[FinancialType.NET_INCOME]: [],
								[FinancialType.COGS]: [],
							},
						);
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								financialChartData: data,
							},
							financialData: {
								years: data.xAxis.categories,
								revenue: formattedChartData[FinancialType.REVENUE],
								cogs: formattedChartData[FinancialType.COGS],
								grossProfit: formattedChartData[FinancialType.GROSS_PROFIT],
								sga: formattedChartData[FinancialType.SG_AND_A],
								ebitda: formattedChartData[FinancialType.EBITDA],
								operatingIncome:
									formattedChartData[FinancialType.OPERATING_INCOME],
								totalDebt: formattedChartData[FinancialType.TOTAL_DEBT],
								netIncome: formattedChartData[FinancialType.NET_INCOME],
							},
						};
					});
				},
				props: {
					currencySymbol: getSymbolFromCurrency(currencyCode),
				},
			},
			{
				name: WidgetType.GEOGRAPHY,
				component: GeographyChart,
				type: 'main',
				minHeight: 664,
				props: {
					name,
					countries,
					keyCountries,
					headOffice: country,
					additionalNames,
					subsidiaries,
					acquisitions,
				},
			},
			{
				name: WidgetType.PEOPLE,
				component: People,
				disableExport: true,
				minHeight: 545,
				type: 'main',
				api: () => {
					return getPeopleData(companyId);
				},
				processData: (data: IPeople[]) => {
					return data
						? {
								people: data,
						  }
						: null;
				},
				props: {
					name,
				},
				cachedData: cachedCompanyData.peopleData,
				onDataLoaded: (data: IPeople[]) => {
					this.setState((prevState) => {
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								peopleData: data,
							},
						};
					});
				},
			},
			{
				name: WidgetType.CONNECTIONS,
				component: ConnectionsChart,
				type: 'main',
				minHeight: 208,
				disableExport: true,
				api: () => {
					return getConnectionsData(companyId);
				},
				processData: (data: IPeople[]) => ({
					people: data
						.sort((a: IPeople, b: IPeople) => {
							return a.proRank - b.proRank;
						})
						.sort((a: IPeople, b: IPeople) => {
							if (a.relationships.length < b.relationships.length) {
								return 1;
							}
							if (a.relationships.length > b.relationships.length) {
								return -1;
							}
							return 0;
						}),
				}),
				props: {
					name,
					connectionAnalyticsId:
						engagements.length > 0 ? engagements[0].caId : null,
				},
				cachedData: cachedCompanyData.connectionsData,
				onDataLoaded: (data: IPeople[]) => {
					this.setState((prevState) => {
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								connectionsData: data,
							},
						};
					});
				},
			},
			{
				name: WidgetType.WATCHLISTS,
				component: CompanyWatchlists,
				type: 'sidebar',
				minHeight: 280,
				// api: getWatchlistMeta,
				api: getWatchlists,
				disableExport: true,
				isHidden: !isTrsEnabled,
				processData: (data: any) => {
					const { watchlistAssignedToUser } = data;
					return {
						watchlists: (watchlistAssignedToUser as IWatchlistListItem[]).map(
							(w) => ({
								watchlistId: w.watchlistId,
								watchlistName: w.watchlistName,
							}),
						),
					};
				},
				cachedData: cachedCompanyData.watchlistsData,
				onDataLoaded: (data: any) => {
					this.setState((prevState) => {
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								watchlistsData: data,
							},
						};
					});
				},
				props: {
					companyId,
					companyWatchlists,
					onAddToWatchlist: this.handleOpenWatchlistModal,
					isModalOpen: isWatchlistModalOpen,
					onModalClose: this.handleCloseWatchlistModal,
					onWatchlistSelect: this.handleWatchlistSelect,
				},
			},
			{
				name: WidgetType.SUMMARY,
				component: Summary,
				type: 'sidebar',
				minHeight: 470,
				api: () => {
					return getSummaryData(companyId);
				},
				cachedData: cachedCompanyData.summaryData,
				onDataLoaded: (data: ISummary) => {
					this.setState((prevState) => {
						return {
							cachedCompanyData: {
								...prevState.cachedCompanyData,
								summaryData: data,
							},
						};
					});
				},
				props: {
					currencySymbol: getSymbolFromCurrency(currencyCode),
				},
			},
			{
				name: WidgetType.ENGAGEMENTS,
				component: Engagements,
				type: 'sidebar',
				props: engagements.length > 0 ? { engagements } : null,
			},
			{
				name: WidgetType.CLASSIFICATION,
				component: Classification,
				type: 'sidebar',
				props: Object.values(classification).every((p: string) => p)
					? classification
					: null,
			},
			{
				name: WidgetType.NEWS,
				component: News,
				type: 'sidebar',
				disableExport: true,
				api: () => {
					return getCompanyNews(name);
				},
				processData: (data) => ({
					news: data.value,
				}),
			},
		];

		return (
			<>
				<div className="company-page">
					{isGeneratingDeck && (
						<div className="loading-overlay">
							<ApLoaderDefault
								theme="light"
								loaderText={() => {
									return (
										<ApLoaderText
											frames={['It will take a few moments, please wait...']}
										/>
									);
								}}
							/>
						</div>
					)}
					<div
						className="company-page__header"
						ref={(el) => {
							this.headerEl = el;
						}}
					>
						<div className="wrapper">
							<div className="max-width">
								<div className="company-page__header__wrapper">
									<div className="company-desc">
										<div>
											<ApButtonSecondary
												iconName="baseline_arrow_back"
												onClick={onBackClick}
											>
												Back
											</ApButtonSecondary>
										</div>
										<div className="company-desc__wrapper">
											<div>
												<Logo url={website} />
											</div>
											<div>
												<div className="company-desc__name">{name}</div>
												{description && (
													<div className="company-desc__about">
														<ReadMoreAndLess
															charLimit={430}
															readMoreText=" Read more"
															readLessText=" Read less"
														>
															{description}
														</ReadMoreAndLess>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="company-actions">
										<div>
											<ApButtonMain
												className="company-profile-download"
												onClick={this.handleOpenPeersModal}
												disabled={
													// !this.isPptDataLoaded() ||
													isGeneratingDeck || isLoadingPptRequiredData
												}
												iconName="outline_save_alt"
											>
												<span>
													{isGeneratingDeck
														? 'Downloading Lead Profile...'
														: 'Download Lead Profile'}
												</span>
											</ApButtonMain>
										</div>
										<br />
										<div>
											<ApButtonSecondary
												onClick={() => {
													window.open(`/screening/${companyId}`);
												}}
											>
												<span>Open AlixScreen</span>
											</ApButtonSecondary>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="company-page__navigation"
						ref={(el) => {
							this.navigationEl = el;
						}}
					>
						<div className="wrapper">
							<div className="max-width">
								<div className="company-page__navigation__back-button">
									<ApButtonSecondary
										left={() => (
											<ApIcon
												iconName="baseline_arrow_back"
												iconSize={24}
												iconColor="#333333"
											/>
										)}
										right={() => <span>Back</span>}
										onClick={onBackClick}
									/>
								</div>
								<ApButtonGroup justifyContent="center">
									{/* <div style={{ height: '36px' }}></div> */}
									{/* // TODO: hidden for prod release */}
									<ApButtonTab
										isSelected={window.location.href.includes('/overview')}
										onClick={() => {
											this.props.history.push(`${url}/overview`);
										}}
									>
										Overview
									</ApButtonTab>
									<ApButtonTab
										// disabled={isLoadingPptRequiredData}
										isSelected={window.location.href.includes('/dashboard')}
										onClick={() => {
											this.props.history.push(`${url}/dashboard`);
										}}
										theme="light"
									>
										Dashboard
									</ApButtonTab>
									<ApButtonTab
										// disabled={isLoadingPptRequiredData}
										isSelected={window.location.href.includes('/comparison')}
										onClick={() => {
											this.props.history.push(`${url}/comparison`);
										}}
										theme="light"
									>
										Comparison
									</ApButtonTab>
									<ApButtonTab
										// disabled={isLoadingPptRequiredData}
										isSelected={window.location.href.includes('/analysis')}
										onClick={() => {
											this.props.history.push(`${url}/analysis`);
										}}
										theme="light"
									>
										Analysis
									</ApButtonTab>
								</ApButtonGroup>
							</div>
						</div>
					</div>
					<div className="company-page__content">
						<div className="wrapper">
							<div className="max-width">
								<ApAuthenticationContextConsumer>
									{({ user }) => {
										const currentUser = {
											userId: user!.profile.oid,
											firstName: user!.profile.given_name,
											lastName: user!.profile.family_name,
											email: user!.userName,
										};
										return (
											<Switch>
												<Route
													path={`${path}/overview`}
													exact
													render={() => {
														return (
															<CompanyOverview widgetsConfig={widgetsConfig} />
														);
													}}
												/>
												<Route
													path={`${path}/dashboard`}
													exact
													render={() => {
														return (
															<CompanyDashboard
																companyId={companyId}
																companyName={name}
																currentUser={currentUser}
																onPptDataLoaded={this.handleCachePptDataData}
																isPptDataLoaded={!isLoadingPptRequiredData}
															/>
														);
													}}
												/>
												<Route
													path={`${path}/comparison`}
													exact
													render={() => {
														return (
															<CompanyComparison
																companyId={companyId}
																currentUser={currentUser}
																// latestYear={latestYear}
																companyName={name}
																currencySymbol={getSymbolFromCurrency(
																	currencyCode,
																)}
																companyPeers={competitors.slice(0, 5)}
																onAddPeer={this.handleAddCompetitor}
																onRemovePeer={this.handleRemoveCompetitor}
																onPptDataLoaded={this.handleCachePptDataData}
																isPptDataLoaded={!isLoadingPptRequiredData}
															/>
														);
													}}
												/>
												<Route
													path={`${path}/analysis`}
													exact
													render={() => {
														return (
															<CompanyAnalysis
																companyId={companyId}
																currentUser={currentUser}
																onPptDataLoaded={this.handleCachePptDataData}
																isPptDataLoaded={!isLoadingPptRequiredData}
															/>
														);
													}}
												/>
												<Redirect
													from="/companies/:companyId"
													to="/companies/:companyId/overview"
												/>
												<Redirect
													from="/companies/:companyId/*"
													to="/companies/:companyId/overview"
												/>
											</Switch>
										);
									}}
								</ApAuthenticationContextConsumer>
							</div>
						</div>
					</div>
				</div>
				<ApModal
					className="peers-modal"
					isOpen={isPeersModalOpen}
					hasClosed={this.handleClosePeersModal}
					header={
						<div className="peers-modal__header">
							<div>Select Competitors or search for a custom:</div>
							<PeerSearch
								onPeerSelect={this.handleCustomPeerSelect}
								companiesToExclude={[companyId]}
							/>
							<ApButtonSecondary onClick={this.handleClearSelectedPeers}>
								Clear
							</ApButtonSecondary>
						</div>
					}
					footer={() => (
						<div className="peers-modal__footer">
							<ApButtonSecondary onClick={this.handleClosePeersModal}>
								Cancel
							</ApButtonSecondary>
							{/* <div>
								<ApButtonMain
									onClick={() => this.handleDownloadDeck()}
									disabled={selectedPeers.length === 0}
								>
									Download
								</ApButtonMain>
							</div> */}
							<div>
								<ApButtonSecondary
									onClick={() => this.handleDownloadDeck()}
									disabled={selectedPeers.length === 0}
								>
									Download regular
								</ApButtonSecondary>
								<ApButtonMain
									onClick={() => this.handleDownloadDeck(true)}
									disabled={selectedPeers.length === 0}
								>
									Download widescreen
								</ApButtonMain>
							</div>
						</div>
					)}
				>
					<div className="peers-modal__content">
						{[
							...competitors,
							...customPeers.map((c) => ({
								name: c.name,
								companyId: c.companyId,
							})),
						].map((c) => {
							return (
								<div
									key={c.companyId}
									className={`peers-modal__item${
										selectedPeers.map((p) => p.companyId).includes(c.companyId)
											? ' peers-modal__item--selected'
											: ''
									}`}
								>
									<ApButtonFilter
										autoFocus={false}
										disabled={false}
										isSelected={selectedPeers
											.map((p) => p.companyId)
											.includes(c.companyId)}
										onClick={() => this.handleSelectNewPeer(c)}
									>
										{truncateText(c.name, 28)}
									</ApButtonFilter>
								</div>
							);
						})}
					</div>
				</ApModal>
				{isSelectedPeersDataFetched && (
					<div className="hidden-charts">
						<DeckGrowthChart
							name="growsProfitability"
							companyId={companyId}
							competitorsData={peersRevenueData}
						/>
						<DeckGrowthChartLastYear
							name="growsProfitabilityLastYear"
							companyId={companyId}
							competitorsData={peersRevenueYoYData}
						/>
						<DeckSharePriceChart
							name="sharePrice"
							companyId={companyId}
							competitorsData={selectedCompetitorsData}
						/>
					</div>
				)}
			</>
		);
	}
}

export const Company = withRouter(CompanyComponent);
