import React from 'react';
import { ApSimpleSearch } from '@alixpartners/ui-components';

import './Search.scss';

interface ISearchProps {
	onSearchTextChange(value: string): void;
	onSearchClear(): void;
}

export const Search: React.FC<ISearchProps> = ({
	onSearchTextChange,
	onSearchClear,
}) => {
	return (
		<div className="search">
			<ApSimpleSearch
				id="company-search"
				placeholder="Search"
				autoFocus
				// @ts-ignore
				onChange={onSearchTextChange}
				onClear={onSearchClear}
			/>
		</div>
	);
};
