import React from 'react';
import moment from 'moment';
import ReadMoreAndLess from 'react-read-more-less';
import { ScreenClassProvider } from 'react-grid-system';
import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';
import {
	Switch,
	Route,
	Redirect,
	RouteComponentProps,
	withRouter,
} from 'react-router-dom';
import {
	ApLoaderDefault,
	ApButtonSecondary,
	ApButtonGroup,
	ApButtonTab,
} from '@alixpartners/ui-components';
import { Logo } from '../Logo';
import { Overview } from './Overview';
import { CompanyTasks } from './CompanyTasks';

import { getProspectById } from '../../api';
import {
	IUser,
	IWatchlistProspect,
	IPerson,
	IFinancialSummary,
} from '../../types';

import './Prospect.scss';

interface IRouteMatchParams {
	watchlistId: string;
	id: string;
}

interface IProspectProps extends RouteComponentProps<IRouteMatchParams> {
	currentUserId: string;
	users: IUser[];
	isUserListLoading: boolean;
	isSuperAdmin: boolean;
}

interface IProspectState {
	isLoading: boolean;
	prospect: IWatchlistProspect;
	status: string;
}

const EMPTY_FINANCIALS_SUMMARY: IFinancialSummary = {
	calendarYear: parseInt(moment().format('YYYY')),
	cash: 0,
	companyId: 0,
	ebitdaGrowth: 0,
	interestCoverage: 0,
	marketCap: 0,
	revenueGrowth: 0,
	totalDebt: 0,
	totalDebtOverEBITDA: 0,
	totalEBITDA: 0,
	totalRevenue: 0,
	materialDebt: [],
	isManualyEdited: false,
	currencyCode: 'USD',
	periodEndDate: moment().format(),
};

export class ProspectComponent extends React.Component<
	IProspectProps,
	IProspectState
> {
	state = {
		isLoading: true,
		prospect: {} as IWatchlistProspect,
		status: '',
	};

	async componentDidMount() {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		window.scrollTo(0, 0);

		try {
			const prospectResponse = await getProspectById(id);

			if (prospectResponse.data) {
				this.setState({
					prospect: prospectResponse.data,
					isLoading: false,
					status: prospectResponse.data.status,
				});
			} else {
				throw new Error('Incorrect watchlist/company ID');
			}
		} catch (error) {
			console.log('Error loading company data');
		}
	}

	handleAddOwner = (user: IPerson) => {
		this.setState((prevState) => {
			return {
				prospect: {
					...prevState.prospect,
					owners: [...prevState.prospect.owners, user],
				},
			};
		});
	};

	handleRemoveOwner = (user: IPerson) => {
		const { userId } = user;

		this.setState((prevState) => {
			return {
				prospect: {
					...prevState.prospect,
					owners: prevState.prospect.owners.filter((o) => o.userId !== userId),
				},
			};
		});
	};

	render() {
		const {
			currentUserId,
			users,
			isUserListLoading,
			isSuperAdmin,
			match: { path, url },
			history,
		} = this.props;
		const {
			prospect: {
				companyName,
				notes,
				companyId,
				website,
				subscribers,
				owners,
				documents,
				watchlists,
				financialSummary,
				isExistInBIC,
				linkedFiles,
			},
			status,
			isLoading,
		} = this.state;

		const isOwner = owners
			? owners.map((o) => o.userId).includes(currentUserId)
			: false;

		const isEditable = isOwner || isSuperAdmin;

		let watchlistId: number | null = null;
		let watchlistName: string | null = null;

		if (history.location.state) {
			const historyState = history.location.state as {
				watchlistId: number;
				watchlistName: string;
			};

			watchlistId = historyState.watchlistId;
			watchlistName = historyState.watchlistName;
		}

		return (
			<ScreenClassProvider>
				<div className="secondary-nav">
					<div className="wrapper">
						<div className="max-width">
							<div className="watchlist-nav">Watchlists</div>
						</div>
					</div>
				</div>
				{isLoading || isUserListLoading ? (
					<>
						<br />
						<br />
						<br />
						<br />
						<ApLoaderDefault />
					</>
				) : (
					<div className="container">
						<div className="wrapper">
							<div className="max-width">
								<div className="prospect">
									<div className="prospect__title">
										<span
											className="prospect__title__link"
											onClick={() => {
												this.props.history.push('/watchlists', {
													state: watchlistName ? null : { companyTab: true },
												});
											}}
										>
											Dashboard
										</span>
										<span> / </span>
										{watchlistName && (
											<>
												<span
													className="prospect__title__link"
													onClick={() => {
														this.props.history.push(
															`/watchlists/${watchlistId}`,
														);
													}}
												>
													{watchlistName}
												</span>
												<span> / </span>
											</>
										)}
										{/* {subscribers
											.map((s) => s.userId)
											.includes(currentUserId) ? (
											<>
												<span
													className="prospect__title__link"
													onClick={() => {
														this.props.history.push('/watchlists');
													}}
												>
													Assigned to me
												</span>
												<span> / </span>
											</>
										) : (
											<>
												<span
													className="prospect__title__link"
													onClick={() => {
														this.props.history.push('/watchlists');
													}}
												>
													Other watchlists
												</span>
												<span> / </span>
											</>
										)} */}
										{/* <span
											className="prospect__title__link"
											onClick={() => {
												this.props.history.push(`/watchlists/${watchlistId}`);
											}}
										>
											{watchlistName}
										</span> */}
										{/* <span> / </span> */}
										<span className="prospect__name">{companyName}</span>
									</div>
									<div className="prospect__wrapper">
										<div className="prospect__description">
											<div className="prospect__logo">
												<Logo url={website} />
											</div>
											<div className="prospect__notes">
												<div className="prospect__name-wrapper">
													<div>
														<h1>{companyName}</h1>
													</div>
													<div />
												</div>
												<ReadMoreAndLess
													charLimit={430}
													readMoreText=" Read more"
													readLessText=" Read less"
												>
													{notes || ''}
												</ReadMoreAndLess>
											</div>
										</div>
										<div className="prospect__actions">
											{isExistInBIC && (
												<ApButtonSecondary
													onClick={() => {
														window.open(`/companies/${companyId}`, '_blank');
													}}
												>
													Go to bic company profile
												</ApButtonSecondary>
											)}
										</div>
									</div>
									<ApButtonGroup justifyContent="center">
										<ApButtonTab
											isSelected={window.location.href.includes('/overview')}
											onClick={() => {
												if (!window.location.href.includes('/overview')) {
													this.props.history.push(`${url}/overview`);
												}
											}}
										>
											Overview
										</ApButtonTab>
										<ApButtonTab
											isSelected={window.location.href.includes('/tasks')}
											onClick={() => {
												this.props.history.push(`${url}/tasks`);
											}}
										>
											Task list
										</ApButtonTab>
									</ApButtonGroup>
								</div>
								<div className="prospect-content">
									<ApAuthenticationContextConsumer>
										{({ user }) => {
											const currentUser = {
												userId: user!.profile.oid,
												firstName: user!.profile.given_name,
												lastName: user!.profile.family_name,
												email: user!.userName,
											};

											return (
												<Switch>
													<Route
														path={`${path}/overview`}
														exact
														render={() => {
															return (
																<Overview
																	companyId={companyId}
																	companyName={companyName}
																	subscribers={subscribers}
																	owners={owners}
																	currentUser={currentUser}
																	isSuperAdmin={isSuperAdmin}
																	onWatchlistClick={(id) => {
																		this.props.history.push(
																			`/watchlists/${id}`,
																		);
																	}}
																	documents={documents}
																	watchlists={watchlists}
																	users={users}
																	status={status}
																	onOwnerRemove={this.handleRemoveOwner}
																	onOwnerAdd={this.handleAddOwner}
																	financialSummary={
																		financialSummary
																			? financialSummary
																			: EMPTY_FINANCIALS_SUMMARY
																	}
																	isExistInBIC={isExistInBIC}
																	linkedFiles={linkedFiles}
																/>
															);
														}}
													/>
													<Route
														path={`${path}/tasks`}
														exact
														render={() => {
															return (
																<CompanyTasks
																	companyId={companyId}
																	companyName={companyName}
																	users={users}
																	currentUser={currentUser}
																	isCompanyOwner={isEditable}
																/>
															);
														}}
													/>
													<Redirect
														from="/prospects/:prospectId"
														to="/prospects/:prospectId/overview"
													/>
													<Redirect
														from="/prospects/:prospectId/*"
														to="/prospects/:prospectId/overview"
													/>
												</Switch>
											);
										}}
									</ApAuthenticationContextConsumer>
								</div>
							</div>
						</div>
					</div>
				)}
			</ScreenClassProvider>
		);
	}
}

export const Prospect = withRouter(ProspectComponent);
