export const defaultProps = {
	totalSpend: 11.9,
	spendData: [
		['Industry name 1', 2.8],
		['Industry name 2', 2.6],
		['Industry name 3', 2.4],
		['Industry name 4', 1.6],
		['Industry name 5', 1.4],
		['Industry name 6', 1.2],
		['Industry name 7', 1],
		['Industry name 8', 1],
	],
};
