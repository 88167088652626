import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getChartOptions } from './chartOptions';
import { IChart, FinancialType } from '../../types';

import './FinancialsChart.scss';

interface IFinancialsChartProps {
	chartData: IChart;
	currencySymbol: string;
}

export class FinancialsChart extends React.PureComponent<
	IFinancialsChartProps,
	{}
> {
	render() {
		const { chartData, currencySymbol } = this.props;

		const formattedChartData = chartData.series.data.reduce(
			(acc: { [key: string]: number[] | null }, v) => {
				if (
					Object.keys(acc).includes(v.name) &&
					!v.data.every((item) => item === 0)
				) {
					acc[v.name] = v.data;
				}
				return acc;
			},
			{
				[FinancialType.REVENUE]: null,
				[FinancialType.GROSS_PROFIT]: null,
				[FinancialType.SG_AND_A]: null,
				[FinancialType.OPERATING_INCOME]: null,
				[FinancialType.EBITDA]: null,
				[FinancialType.TOTAL_DEBT]: null,
			},
		);

		let series: Highcharts.SeriesOptionsType[] = [];

		if (formattedChartData[FinancialType.REVENUE]) {
			series.push({
				type: 'column',
				name: 'RevenueCopy',
				color: '#25B3C0',
				data: formattedChartData[
					FinancialType.REVENUE
				] as Highcharts.XrangePointOptionsObject[],
				enableMouseTracking: false,
				animation: false,
			});
			series.push({
				type: 'line',
				name: FinancialType.REVENUE,
				color: 'transparent',
				data: formattedChartData[
					FinancialType.REVENUE
				] as Highcharts.XrangePointOptionsObject[],
				marker: {
					fillColor: 'transparent',
					lineColor: 'transparent',
				},
			});
		}

		if (formattedChartData[FinancialType.TOTAL_DEBT]) {
			series.push({
				type: 'line',
				name: FinancialType.TOTAL_DEBT,
				color: '#C76B7A',
				data: formattedChartData[
					FinancialType.TOTAL_DEBT
				] as Highcharts.XrangePointOptionsObject[],
			});
		}

		if (formattedChartData[FinancialType.GROSS_PROFIT]) {
			series.push({
				type: 'line',
				name: FinancialType.GROSS_PROFIT,
				color: '#7053C5',
				data: formattedChartData[
					FinancialType.GROSS_PROFIT
				] as Highcharts.XrangePointOptionsObject[],
			});
		}

		if (formattedChartData[FinancialType.SG_AND_A])
			series.push({
				type: 'line',
				name: FinancialType.SG_AND_A,
				color: '#2D93D2',
				data: formattedChartData[
					FinancialType.SG_AND_A
				] as Highcharts.XrangePointOptionsObject[],
			});

		if (formattedChartData[FinancialType.OPERATING_INCOME]) {
			series.push({
				type: 'line',
				name: FinancialType.OPERATING_INCOME,
				color: '#6BC7B6',
				data: formattedChartData[
					FinancialType.OPERATING_INCOME
				] as Highcharts.XrangePointOptionsObject[],
			});
		}

		if (formattedChartData[FinancialType.EBITDA]) {
			series.push({
				type: 'line',
				name: FinancialType.EBITDA,
				color: '#A7C76B',
				data: formattedChartData[
					FinancialType.EBITDA
				] as Highcharts.XrangePointOptionsObject[],
			});
		}

		// @ts-ignore
		const totalRevenue: number = formattedChartData[
			FinancialType.REVENUE
		].reduce((a, b) => a + b, 0);
		const chartOptions = getChartOptions(totalRevenue, currencySymbol);
		const options: Highcharts.Options = {
			...chartOptions,
			xAxis: { ...chartOptions.xAxis, ...chartData.xAxis },
			series,
		};

		// Update crosshair to sit between two series (under lines and above revenue columns)
		// Highcharts.removeEvent(Highcharts.Axis, 'afterDrawCrosshair');
		// Highcharts.addEvent(Highcharts.Axis, 'afterDrawCrosshair', (event) => {
		// 	if (this.chart && this.chart.chart) {
		// 		const xAxisCross = this.chart.chart.axes[0].cross;
		// 		const revenueColumnsGroup = this.chart.chart.series[0].group;

		// 		const insertAfter = (newNode: any, referenceNode: any) => {
		// 			referenceNode.parentNode.insertBefore(
		// 				newNode,
		// 				referenceNode.nextSibling,
		// 			);
		// 		};

		// 		if (xAxisCross) {
		// 			xAxisCross.element.parentNode.removeChild(xAxisCross.element);
		// 			insertAfter(xAxisCross.element, revenueColumnsGroup.element);
		// 		}
		// 	}
		// });

		return (
			<div className="financials">
				<div className="financials__title">Financials</div>
				<div className="financials__chart">
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
						immutable
						updateArgs={[false, false, false]}
					/>
				</div>
				<div className="share-price__note">Data sourced from CAPIQ</div>
			</div>
		);
	}
}
