import React from 'react';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	ApInput,
	ApIcon,
	IOption,
	ApSimpleDropdown,
} from '@alixpartners/ui-components';

import {
	IFinancialSummary,
	IWatchlistCurrency,
	monthsMap,
} from '../../../types';
import { range } from '../../../utils';
import { MultiSelect } from '../../MultiSelect';
import './FinancialModal.scss';

interface IFinancialModalProps {
	financialDataTemp: IFinancialSummary;
	allCurrencies: IWatchlistCurrency[];
	handleChangeFinancial(value: number | string, name: string): void;
}

const YEARS_RANGE = 30;

export const FinancialModal: React.FC<IFinancialModalProps> = ({
	financialDataTemp,
	allCurrencies,
	handleChangeFinancial,
}) => {
	const { currencyCode, calendarYear, periodEndDate } = financialDataTemp;
	const currencySymbol: string = getSymbolFromCurrency(currencyCode);
	const fyMonth = moment(periodEndDate).format('MMMM');

	const currentYear = parseInt(moment().format('YYYY'));
	const currentMonth = new Date().getMonth();
	const yearsOptions = range(currentYear - YEARS_RANGE, currentYear, 1).map(
		(y) => ({
			label: `${y}`,
			value: `${y}`,
		}),
	);

	const onNumericValueChange = (
		value: string,
		e: React.ChangeEvent<HTMLInputElement> | undefined,
	) => {
		// @ts-ignore
		if (isNaN(value) || value.length > 20) {
			return;
		}

		if (e) {
			const { name } = e.currentTarget;
			// handleChangeFinancial(value ? parseInt(value) : 0, name);
			handleChangeFinancial(value, name);
		}
	};

	const monthsOptions =
		currentYear === calendarYear
			? monthsMap.filter((o) => {
					return parseInt(o.value) <= currentMonth;
			  })
			: monthsMap;

	return (
		<div className="financials-form">
			<div className="financials-form__title">Edit Financial Summary</div>
			<div className="financials-form__alert">
				<ApIcon iconName="baseline_info" />
				Changes will be applied globally. Your name will be associated with
				these changes.
			</div>
			<div className="financials-form__form">
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Currency</div>
						<div className="financials-form__dropdown">
							<MultiSelect
								isSingle
								placeholder=""
								options={allCurrencies.map((c) => {
									const currentCurrencySymbol = getSymbolFromCurrency(
										c.currencyCode,
									);
									return {
										label: `${c.currencyCode} ${
											currentCurrencySymbol
												? '(' + currentCurrencySymbol + ')'
												: ''
										}`,
										value: c.currencyCode,
									};
								})}
								value={
									currencyCode
										? [
												{
													label: `${currencyCode} ${
														currencySymbol ? '(' + currencySymbol + ')' : ''
													}`,
													value: currencyCode,
												},
										  ]
										: [
												{
													label: `USD ${getSymbolFromCurrency('USD')}`,
													value: 'USD',
												},
										  ]
								}
								onChange={(option) => {
									const { value } = option as IOption;

									handleChangeFinancial(value, 'currencyCode');
								}}
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">Financial Year Month</div>
						<div className="financials-form__dropdown">
							<ApSimpleDropdown
								onChange={(option) => {
									const { value } = option as IOption;
									console.log(value);
									const newPeriodEndDate = moment(periodEndDate)
										.set('month', parseInt(value))
										.format();

									handleChangeFinancial(newPeriodEndDate, 'periodEndDate');
								}}
								options={monthsOptions}
								selected={
									periodEndDate
										? monthsOptions.find((o) => o.label === fyMonth)
										: {
												label: moment().format('MMMM'),
												value: `${new Date().getMonth()}`,
										  }
								}
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">Financial Year</div>
						<div className="financials-form__dropdown">
							<ApSimpleDropdown
								onChange={(option) => {
									const { value } = option as IOption;
									handleChangeFinancial(parseInt(value), 'calendarYear');

									// If we select current year we need to update a month to be in the past
									if (
										parseInt(value) === currentYear &&
										parseInt(
											monthsOptions.find((o) => o.label === fyMonth)!.value,
										) > currentMonth
									) {
										const newPeriodEndDate = moment(periodEndDate)
											.set('month', currentMonth)
											.format();
										handleChangeFinancial(newPeriodEndDate, 'periodEndDate');
									}
								}}
								options={yearsOptions.reverse()}
								selected={
									calendarYear
										? {
												label: `${calendarYear}`,
												value: `${calendarYear}`,
										  }
										: {
												label: `${currentYear}`,
												value: `${currentYear}`,
										  }
								}
							/>
						</div>
					</div>
				</div>
				<div className="financials-form__form">
					<div className="financials-form__separator" />
				</div>
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Material Cap</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.marketCap !== undefined &&
									financialDataTemp.marketCap !== null
										? financialDataTemp.marketCap.toString()
										: ''
								}
								name="marketCap"
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">Revenue Growth</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={'%'}
								value={
									financialDataTemp.revenueGrowth !== undefined &&
									financialDataTemp.revenueGrowth !== null
										? financialDataTemp.revenueGrowth.toString()
										: ''
								}
								name="revenueGrowth"
							/>
						</div>
					</div>
				</div>
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Cash</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.cash !== undefined &&
									financialDataTemp.cash !== null
										? financialDataTemp.cash.toString()
										: ''
								}
								name="cash"
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">EBITDA Growth</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={'%'}
								value={
									financialDataTemp.ebitdaGrowth !== undefined &&
									financialDataTemp.ebitdaGrowth !== null
										? financialDataTemp.ebitdaGrowth.toString()
										: ''
								}
								name="ebitdaGrowth"
							/>
						</div>
					</div>
				</div>
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Total Debt</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.totalDebt !== undefined &&
									financialDataTemp.totalDebt !== null
										? financialDataTemp.totalDebt.toString()
										: ''
								}
								name="totalDebt"
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">Total Debt/EBITDA</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.totalDebtOverEBITDA !== undefined &&
									financialDataTemp.totalDebtOverEBITDA !== null
										? financialDataTemp.totalDebtOverEBITDA.toString()
										: ''
								}
								name="totalDebtOverEBITDA"
							/>
						</div>
					</div>
				</div>
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Total Revenue</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.totalRevenue !== undefined &&
									financialDataTemp.totalRevenue !== null
										? financialDataTemp.totalRevenue.toString()
										: ''
								}
								name="totalRevenue"
							/>
						</div>
					</div>
					<div>
						<div className="financials-form__label">Interest Coverage</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.interestCoverage !== undefined &&
									financialDataTemp.interestCoverage !== null
										? financialDataTemp.interestCoverage.toString()
										: ''
								}
								name="interestCoverage"
							/>
						</div>
					</div>
				</div>
				<div className="financials-form__row">
					<div>
						<div className="financials-form__label">Total EBITDA</div>
						<div>
							<ApInput
								onChange={onNumericValueChange}
								symbol={currencySymbol || currencyCode || ''}
								value={
									financialDataTemp.totalEBITDA !== undefined &&
									financialDataTemp.totalEBITDA !== null
										? financialDataTemp.totalEBITDA.toString()
										: ''
								}
								name="totalEBITDA"
							/>
						</div>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
};
