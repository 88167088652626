import React from 'react';

import { addBigNumberSign, numberWithCommas } from '../../utils';

import './Summary.scss';

interface ISummaryProps {
	sharePrice: number;
	sharePrice52WeekHigh: number;
	country: string;
	industry: string;
	totalDebt: number;
	netDebt: number;
	revenue: number;
	leadership: string;
	leadershipTitle: string;
	totalEbitda: number;
	marketCap: number;
	tickerSymbol: string;
	employees: number;
	website: string;
	currencySymbol: string;
}

export const Summary: React.FC<ISummaryProps> = (props) => {
	const {
		sharePrice,
		sharePrice52WeekHigh,
		country,
		industry,
		totalDebt,
		netDebt,
		revenue,
		leadership,
		leadershipTitle,
		totalEbitda,
		marketCap,
		tickerSymbol,
		employees,
		website,
		currencySymbol,
	} = props;

	const isValue = (value: any) => value !== null && value !== undefined;

	return (
		<div className="summary">
			<div className="summary__title">Summary:</div>
			<div className="summary__wrapper">
				{isValue(tickerSymbol) && (
					<div>
						<div className="summary__label">Ticker:</div>
						<div className="summary__value">{tickerSymbol}</div>
					</div>
				)}
				{isValue(totalDebt) && (
					<div>
						<div className="summary__label">Total Debt:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>
								{numberWithCommas(totalDebt)}
								{addBigNumberSign(totalDebt, 'M')}
							</span>
						</div>
					</div>
				)}
				{isValue(sharePrice) && (
					<div>
						<div className="summary__label">Share Price:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>{numberWithCommas(sharePrice)}</span>
						</div>
					</div>
				)}
				{isValue(netDebt) && (
					<div>
						<div className="summary__label">Net Debt:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>
								{numberWithCommas(netDebt)}
								{addBigNumberSign(netDebt, 'M')}
							</span>
						</div>
					</div>
				)}
				{isValue(marketCap) && (
					<div>
						<div className="summary__label">Market Cap:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>
								{numberWithCommas(
									Math.round((marketCap + Number.EPSILON) * 100) / 100,
								)}
							</span>
						</div>
					</div>
				)}
				{isValue(industry) && (
					<div>
						<div className="summary__label">Industry:</div>
						<div className="summary__value">{industry}</div>
					</div>
				)}
				{isValue(sharePrice52WeekHigh) && (
					<div>
						<div className="summary__label">52 week high:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>{sharePrice52WeekHigh}</span>
						</div>
					</div>
				)}
				{isValue(country) && (
					<div>
						<div className="summary__label">Head Office:</div>
						<div className="summary__value">{country}</div>
					</div>
				)}
				{isValue(revenue) && (
					<div>
						<div className="summary__label">Revenue:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>
								{numberWithCommas(revenue)}
								{addBigNumberSign(revenue, 'M')}
							</span>
						</div>
					</div>
				)}
				{isValue(leadership) && (
					<div>
						<div className="summary__label">Leadership:</div>
						<div className="summary__value">
							{`${leadership} ${
								isValue(leadershipTitle) ? ` (${leadershipTitle})` : ''
							}`}
						</div>
					</div>
				)}
				{isValue(totalEbitda) && (
					<div>
						<div className="summary__label">EBITDA:</div>
						<div className="summary__value">
							{currencySymbol}
							<span>
								{numberWithCommas(totalEbitda)}
								{addBigNumberSign(totalEbitda, 'M')}
							</span>
						</div>
					</div>
				)}
				{isValue(employees) && (
					<div>
						<div className="summary__label">Number of Employees:</div>
						<div className="summary__value">{employees}</div>
					</div>
				)}
				{isValue(website) && (
					<div>
						<div className="summary__label">Website:</div>
						<a
							href={`https://${website}`}
							target="_blank"
							rel="noreferrer"
							className="summary__value"
						>
							{website}
						</a>
					</div>
				)}
			</div>
		</div>
	);
};
