import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';

import { FinancialType } from '../types';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	SLIDE_SUMMARY_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
	STICKER_CONFIG,
	NO_DATA_CONFIG,
} from './common';

const CHART_LABELS_CONFIG: pptxgen.TextPropsOptions = {
	shape: 'rect',
	fill: { type: 'solid', color: 'BFBFBF' },
	rotate: 270,
	fontSize: 10,
	fontFace: 'Verdana (Body)',
	align: 'center',
	valign: 'middle',
	color: '383838',
	margin: [0, 0, 6, 0],
	lineSize: 1,
	line: { color: '7F7F7F' },
};

export const appendRevenueSlide = (
	deckInstance: pptxgen,
	companyName: string,
	currencyCode: string,
	// revenueChart: string,
	// grossProfitChart: string,
	// sgAndAChart: string,
	// ebitdaChart: string,
	revenueChartsPercentage: any,
	revenueChartsDataLast5: any,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText('Financial analysis', SLIDE_TOP_LABEL);
	slide.addText(`${companyName} 5 year P&L Analysis`, SLIDE_TITLE_CONFIG);
	slide.addText(`${companyName} 5 year P&L Analysis`, {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		bold: true,
		x: 0.3,
		y: 1.2 + 0.2,
		w: 9.4,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(
		[
			{ text: 'Op. Revenue', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M)` },
		],
		{
			x: -0.05,
			y: 1.96,
			w: 1.2,
			h: 0.5,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'Gross Profit', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: -0.05,
			y: 3.35,
			w: 1.2,
			h: 0.5,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'SG&A', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: -0.05,
			y: 4.73,
			w: 1.2,
			h: 0.5,
			...CHART_LABELS_CONFIG,
		},
	);

	slide.addText(
		[
			{ text: 'EBITDA', options: { bold: true } },
			{ text: `(${getSymbolFromCurrency(currencyCode)}M, Margin)` },
		],
		{
			x: -0.05,
			y: 6.12,
			w: 1.2,
			h: 0.5,
			...CHART_LABELS_CONFIG,
		},
	);

	/**
	 * Charts common
	 */
	const addChart = (
		slide: pptxgen.Slide,
		x: number,
		y: number,
		h: number,
		w: number,
		labels: number[],
		values: number[],
	) => {
		slide.addChart(
			'bar',
			[
				{
					name: 'Revenue',
					labels,
					values,
				},
			],
			{
				showDataTableVertBorder: false,
				x,
				y,
				h,
				w,
				border: { pt: 0, color: 'ffffff' },
				chartColors: ['032E45', '032E45', '032E45', '032E45', '006176'],
				valGridLine: { style: 'none' },
				serAxisHidden: true,
				valAxisHidden: true,
				valAxisLineShow: false,
				serAxisLineShow: false,
				showLeaderLines: false,
				barGapWidthPct: 80,

				showValue: true,
				catAxisMajorTickMark: 'none',
				catAxisMinorTickMark: 'none',

				// @ts-ignore
				axisLineColor: '666666',
				dataLabelColor: '383838',
				dataLabelFontFace: 'Verdana (Body)',
				dataLabelFontSize: 10,
				dataLabelPosition: 'outEnd',
				// dataLabelFormatCode: '#\\,###',
				catAxisLabelColor: '383838',
				catAxisLabelFontFace: 'Verdana (Body)',
				catAxisLabelFontSize: 10,
			},
		);
	};

	const addPercentage = (
		dataLast5: number[],
		revenueLast5: number[],
		y: number,
	) => {
		const margins: number[] = dataLast5.map((v: number, i: number) => {
			const corespondingRevenueValue = revenueLast5[i];

			return Math.round((v / corespondingRevenueValue) * 100);
		});

		// slide.addText(
		// 	[
		// 		{
		// 			text: 'Margin:',
		// 		},
		// 	],
		// 	{
		// 		x: 1.13,
		// 		y,
		// 		w: 0.55,
		// 		h: 0.19,
		// 		fontSize: 9,
		// 		fontFace: 'Verdana (Body)',
		// 		align: 'center',
		// 		valign: 'middle',
		// 		color: '383838',
		// 		margin: 0,
		// 		paraSpaceAfter: 0,
		// 	},
		// );

		// margins.forEach((v: number, i: number) => {
		// 	slide.addText(
		// 		[
		// 			{
		// 				text: `${v}%`,
		// 			},
		// 		],
		// 		{
		// 			x: 1.8 + i * 0.83,
		// 			y,
		// 			w: 0.55,
		// 			h: 0.19,
		// 			fontSize: 9,
		// 			fontFace: 'Verdana (Body)',
		// 			align: 'center',
		// 			valign: 'middle',
		// 			color: '383838',
		// 			margin: 0,
		// 			paraSpaceAfter: 0,
		// 			italic: true,
		// 		},
		// 	);
		// });

		const rows = [
			[
				...margins.map((v) => ({
					text: `${v}%`,
				})),
			],
		];

		slide.addTable(rows, {
			x: 1.59,
			y,
			w: 4.22,
			border: { type: 'none' },
			fontFace: 'Verdana (Body)',
			fontSize: 9,
			align: 'center',
		});
	};

	/**
	 * Chart One
	 */
	if (revenueChartsDataLast5[FinancialType.REVENUE].every((v: number) => !!v)) {
		// slide.addImage({
		// 	data: revenueChart,
		// 	x: 1.48,
		// 	y: 1.95,
		// 	h: 0.89,
		// 	w: 4.44,
		// });

		addChart(
			slide,
			1.48,
			1.84,
			1.05,
			4.44,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.REVENUE],
		);

		slide.addText(
			[
				{
					text: `Operating Revenue ${
						0 - revenueChartsPercentage[FinancialType.REVENUE] > 0
							? 'increased'
							: 'decreased'
					} by ${Math.abs(
						revenueChartsPercentage[FinancialType.REVENUE],
					)}% over the whole period.`,
					options: { bullet: { marginPt: 14.5 } },
				},
			],
			{
				...SLIDE_SUMMARY_CONFIG,
				x: 6.8,
				y: 1.95,
				w: 3,
				h: 1.06,
			},
		);
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 1.48, y: 1.95 });
	}

	/**
	 * Chart Two
	 */
	if (
		revenueChartsDataLast5[FinancialType.GROSS_PROFIT].every((v: number) => !!v)
	) {
		// slide.addImage({
		// 	data: grossProfitChart,
		// 	x: 1.48,
		// 	y: 3.35,
		// 	h: 0.89,
		// 	w: 4.44,
		// });

		addChart(
			slide,
			1.48,
			3.35,
			0.89,
			4.44,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.GROSS_PROFIT],
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.GROSS_PROFIT],
			revenueChartsDataLast5[FinancialType.REVENUE],
			3.15,
		);

		slide.addText(
			[
				{
					text: `Gross Profit ${
						0 - revenueChartsPercentage[FinancialType.GROSS_PROFIT] > 0
							? 'increased'
							: 'decreased'
					} by ${Math.abs(
						revenueChartsPercentage[FinancialType.GROSS_PROFIT],
					)}% over the whole period.`,
					options: { bullet: { marginPt: 14.5 } },
				},
			],
			{
				...SLIDE_SUMMARY_CONFIG,
				x: 6.8,
				y: 3.35,
				w: 3,
				h: 1.06,
			},
		);
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 1.48, y: 3.35 });
	}

	/**
	 * Chart Three
	 */
	if (
		revenueChartsDataLast5[FinancialType.SG_AND_A].every((v: number) => !!v)
	) {
		// slide.addImage({
		// 	data: sgAndAChart,
		// 	x: 1.48,
		// 	y: 4.73,
		// 	h: 0.89,
		// 	w: 4.44,
		// });

		addChart(
			slide,
			1.48,
			4.73,
			0.89,
			4.44,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.SG_AND_A],
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.SG_AND_A],
			revenueChartsDataLast5[FinancialType.REVENUE],
			4.57,
		);

		slide.addText(
			[
				{
					text: `SG&A Expenses ${
						0 - revenueChartsPercentage[FinancialType.SG_AND_A] > 0
							? 'increased'
							: 'decreased'
					} by ${Math.abs(
						revenueChartsPercentage[FinancialType.SG_AND_A],
					)}% over the whole period.`,
					options: { bullet: { marginPt: 14.5 } },
				},
			],
			{
				...SLIDE_SUMMARY_CONFIG,
				x: 6.8,
				y: 4.73,
				w: 3,
				h: 1.06,
			},
		);
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 1.48, y: 4.73 });
	}

	/**
	 * Chart Four
	 */
	if (revenueChartsDataLast5[FinancialType.EBITDA].every((v: number) => !!v)) {
		// slide.addImage({
		// 	data: ebitdaChart,
		// 	x: 1.48,
		// 	y: 6.12,
		// 	h: 0.89,
		// 	w: 4.44,
		// });

		addChart(
			slide,
			1.48,
			6.12,
			0.89,
			4.44,
			revenueChartsDataLast5[FinancialType.YEARS],
			revenueChartsDataLast5[FinancialType.EBITDA],
		);

		addPercentage(
			revenueChartsDataLast5[FinancialType.EBITDA],
			revenueChartsDataLast5[FinancialType.REVENUE],
			5.96,
		);

		slide.addText(
			[
				{
					text: `EBITDA ${
						0 - revenueChartsPercentage[FinancialType.EBITDA] > 0
							? 'increased'
							: 'decreased'
					} by ${Math.abs(
						revenueChartsPercentage[FinancialType.EBITDA],
					)}% over the whole period.`,
					options: { bullet: { marginPt: 14.5 } },
				},
			],
			{
				...SLIDE_SUMMARY_CONFIG,
				x: 6.8,
				y: 6.12,
				w: 3,
				h: 1.06,
			},
		);
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 1.48, y: 6.12 });
	}

	const addPercentageLine = (
		lineX: number,
		lineY: number,
		percentage: number,
		slide: pptxgen.Slide,
	) => {
		if (!percentage) {
			return;
		}

		slide.addShape('line', {
			x: lineX,
			y: lineY,
			w: 4.22,
			h: 0,
			rotate: percentage < 0 ? 358 : 2,
			line: { color: percentage < 0 ? '5CB335' : 'FF3130' },
			lineSize: 1,
			lineTail: 'triangle',
		});

		slide.addText(`${Math.abs(percentage)}%`, {
			shape: 'ellipse',
			x: lineX + 1.64,
			y: lineY - 0.14,
			w: 1,
			h: 0.29,
			line: { color: percentage < 0 ? '5CB335' : 'FF3130' },
			fill: {
				type: 'solid',
				color: 'FFFFFF',
			},
			align: 'center',
			fontSize: 10,
			bold: true,
		});
	};

	addPercentageLine(
		1.5,
		1.7,
		revenueChartsPercentage[FinancialType.REVENUE],
		slide,
	);

	addPercentageLine(
		1.5,
		3,
		revenueChartsPercentage[FinancialType.GROSS_PROFIT],
		slide,
	);

	addPercentageLine(
		1.5,
		4.4,
		revenueChartsPercentage[FinancialType.SG_AND_A],
		slide,
	);

	addPercentageLine(
		1.5,
		5.8,
		revenueChartsPercentage[FinancialType.EBITDA],
		slide,
	);

	slide.addText('Add additional commentary and update heading', {
		x: 6.86,
		y: 0.41,
		w: 2.48,
		h: 0.89,
		...STICKER_CONFIG,
	});
};
