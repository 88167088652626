import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';

import { Sidebar } from '../Sidebar';
import { Content } from '../Content';

import { IFilter, ICompanySearchResult, ITag } from '../../types';
import { search } from '../../api';
import { debounce } from '../../utils';

import './SearchContainer.scss';

interface ISearchContainerProps {}
interface ISearchContainerState {
	searchText: string;
	filters: IFilter[];
	tags: ITag[];
	activeTags: ITag[];
	searchResults: ICompanySearchResult[];
	activeFilters: IFilter[];
	isLoading: boolean;
	isLoadingMore: boolean;
	isLoadMoreHidden: boolean;
	pageNumber: number;
}

const PAGE_SIZE_LIMIT: number = 50;

export class SearchContainer extends React.Component<
	ISearchContainerProps,
	ISearchContainerState
> {
	state = {
		searchText: '',
		filters: [],
		tags: [],
		searchResults: [],
		activeFilters: [],
		activeTags: [],
		isLoading: true,
		isLoadingMore: false,
		isLoadMoreHidden: false,
		pageNumber: 1,
	};

	async componentDidMount() {
		this.handleSearch();
	}

	handleSearch = async (updateFiltersList = true, updateTagsList = true) => {
		const {
			pageNumber,
			searchText,
			activeFilters,
			activeTags,
			// filters: allFilters,
		} = this.state;
		try {
			const {
				data: { results, filters, tags },
			} = await search(searchText, activeFilters, activeTags, {
				pageNumber,
				pageSize: PAGE_SIZE_LIMIT,
			});

			const newState: any = {
				searchResults: results ? results : [],
				isLoading: false,
				isLoadMoreHidden: this.isLoadMoreHidden(results),
			};

			if (updateFiltersList) {
				newState.filters = filters ? filters : [];
			}

			if (updateTagsList) {
				newState.tags = tags ? tags : [];
			}

			this.setState(newState);
			// this.setState({
			// 	searchResults: results ? results : [],
			// 	filters: filters ? filters : [],
			// 	// filters: allFilters.length ? allFilters : filters || [],
			// 	isLoading: false,
			// });
		} catch (error) {
			this.setState({ searchResults: [], isLoading: false });
			console.log(error);
		}
	};

	loadMoreResults = async () => {
		// TODO: check filters logic
		const { pageNumber, searchText, activeFilters, activeTags } = this.state;

		this.setState({ isLoadingMore: true });

		try {
			const {
				data: { results, filters },
			} = await search(searchText, activeFilters, activeTags, {
				pageNumber: pageNumber + 1,
				pageSize: PAGE_SIZE_LIMIT,
			});

			if (!results) {
				this.setState({ isLoadingMore: false });

				return;
			}

			this.setState((prevState) => {
				return {
					pageNumber: prevState.pageNumber + 1,
					isLoadingMore: false,
					isLoadMoreHidden: this.isLoadMoreHidden(results),
					searchResults: [...prevState.searchResults, ...results],
					filters: [...prevState.filters, ...filters].filter(
						(filter, index, self) =>
							index === self.findIndex((f) => f.id === filter.id),
					),
				};
			});
		} catch (error) {
			console.log(error);
			this.setState((prevState) => {
				return {
					pageNumber: prevState.pageNumber + 1,
					isLoadingMore: false,
				};
			});
		}
	};

	handleSearchTextChange = (value: string) => {
		this.setState(
			{ searchText: value, pageNumber: 1, activeFilters: [] },
			() => {
				this.handleSearch();
			},
		);
	};

	handleSearchTextChangeDebounced = debounce(this.handleSearchTextChange, 1000);

	handleRemoveFilter = (filter: IFilter) => {
		this.setState(
			(prevState) => ({
				activeFilters: prevState.activeFilters.filter(
					(f) => f.id !== filter.id,
				),
				isLoading: true,
				pageNumber: 1,
			}),
			() => {
				this.handleSearch(false, false);
			},
		);
	};

	handleAddTag = (tag: IFilter) => {
		const newTag: ITag = {
			tagId: +tag.id,
			name: tag.value.toLowerCase(),
		};

		this.setState(
			(prevState) => {
				const existingTagIndex = prevState.activeTags.findIndex(
					(t) => t.tagId === newTag.tagId,
				);

				let updatedActiveTags = [...prevState.activeTags, newTag];

				if (existingTagIndex >= 0) {
					updatedActiveTags = [...prevState.activeTags];
					updatedActiveTags[existingTagIndex] = newTag;
				}

				return {
					activeTags: updatedActiveTags,
					isLoading: true,
					pageNumber: 1,
				};
			},
			() => {
				console.log(this.state.activeTags);
				this.handleSearch(false, false);
			},
		);
	};

	handleRemoveTag = (tag: IFilter) => {
		const tagToRemove: ITag = { tagId: +tag.id, name: tag.value };

		this.setState(
			(prevState) => ({
				activeTags: prevState.activeTags.filter(
					(t) => t.tagId !== tagToRemove.tagId,
				),
				isLoading: true,
				pageNumber: 1,
			}),
			() => {
				console.log(this.state.activeTags);
				this.handleSearch(false, false);
			},
		);
	};

	handleSearchClear = () => {
		this.setState(
			{
				searchText: '',
				isLoading: true,
				pageNumber: 1,
				activeFilters: [],
			},
			() => {
				this.handleSearch();
			},
		);
	};

	handleAddFilter = (filter: IFilter) => {
		this.setState(
			(prevState) => {
				const existingFilterIndex = prevState.activeFilters.findIndex(
					(f) => f.id === filter.id,
				);

				let updatedActiveFilters = [...prevState.activeFilters, filter];

				if (existingFilterIndex >= 0) {
					updatedActiveFilters = [...prevState.activeFilters];
					updatedActiveFilters[existingFilterIndex] = filter;
				}

				return {
					activeFilters: updatedActiveFilters,
					isLoading: true,
					pageNumber: 1,
				};
			},
			// this.handleSearch,
			() => {
				this.handleSearch(false, false);
			},
		);
	};

	isLoadMoreHidden = (results: ICompanySearchResult[]): boolean => {
		return results && results.length < PAGE_SIZE_LIMIT;
	};

	render() {
		const {
			filters,
			tags,
			activeFilters,
			activeTags,
			isLoading,
			isLoadingMore,
			isLoadMoreHidden,
			searchResults,
		} = this.state;

		const { REACT_APP_TRS_ENABLED } = process.env;

		return (
			<ScreenClassProvider>
				<div className="wrapper wrapper--search">
					<section className="search-container max-width">
						<div className="search-container__sidebar">
							<Sidebar
								filters={filters}
								tags={tags}
								activeFilters={activeFilters}
								activeTags={activeTags}
								onSearchTextChange={(value) => {
									this.setState({ isLoading: true });
									this.handleSearchTextChangeDebounced(value);
								}}
								onSearchClear={this.handleSearchClear}
								onFilterAdd={this.handleAddFilter}
								onFilterRemove={this.handleRemoveFilter}
								onTagAdd={this.handleAddTag}
								onTagRemove={this.handleRemoveTag}
								isSearchLoading={isLoading || isLoadingMore}
								isTrsEnabled={REACT_APP_TRS_ENABLED!.toLowerCase() === 'true'}
							/>
						</div>
						<div className="search-container__content">
							<Content
								searchResults={searchResults}
								activeFilters={activeFilters}
								onFilterRemove={this.handleRemoveFilter}
								onLoadMore={this.loadMoreResults}
								isLoading={isLoading}
								isLoadingMore={isLoadingMore}
								isLoadMoreHidden={isLoadMoreHidden}
							/>
						</div>
					</section>
				</div>
			</ScreenClassProvider>
		);
	}
}
