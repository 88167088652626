import React from 'react';
import { ApTextInput } from '@alixpartners/ui-components';
import classNames from 'classnames';

import { ICategory } from '../../types';
import './CategoryWeighting.scss';

interface ICategoryWeightingProps {
	title: string;
	categories: ICategory[];
	isValid: boolean;
	invalidDifference: number; // If !isValid how much the difference from 100 is
	onChange(e: any): void;
}

const categoryToClassName = (category: ICategory): string => {
	return category.categoryName.trim().replace(' ', '-').toLowerCase();
};

export const CategoryWeighting: React.FC<ICategoryWeightingProps> = ({
	title,
	categories,
	isValid,
	invalidDifference,
	onChange,
}) => {
	return (
		<div className="category-margins">
			<div className="category-margins__title">{title}</div>
			<span className="category-margins__hr"></span>
			<div className="category-margins__info">
				Category Margins dictate how much weighting is given to each of the
				below categories, this then determines the final score that will be
				calculated by the Screening Dashboard. These values should always total
				to 100.
			</div>
			<div className="category-margins__graph">
				{categories.slice(1).map((c: ICategory) => {
					const catClassName = categoryToClassName(c);

					return (
						<div
							key={c.categoryName}
							className={`category-margins__margin category-margins__margin-${catClassName}`}
							style={{ width: `${c.categoryWeighting}%` }}
						></div>
					);
				})}
				<div
					className="category-margins__margin category-margins__margin-difference"
					style={{
						width: (invalidDifference > 0 ? invalidDifference : 0) + '%',
					}}
				></div>
			</div>
			<div className="category-margins__form">
				{categories.slice(1).map((c: ICategory) => {
					const catClassName = categoryToClassName(c);

					return (
						<div key={c.categoryName}>
							<div
								className={classNames('category-margins__label', {
									'category-margins__label--invalid': !isValid,
								})}
							>
								<span
									className={`color-block category-margins__label-box-${catClassName}`}
								></span>
								<span>{`${c.categoryName} (%)`}</span>
							</div>
							<div>
								<ApTextInput
									className={classNames('category-margins__input', {
										'category-margins__input--invalid': !isValid,
									})}
									id={c.categoryName}
									value={c.categoryWeighting}
									onChange={onChange}
								/>
							</div>
						</div>
					);
				})}
			</div>
			{!isValid && (
				<div className="category-margins__error">
					<div>
						All category margin values must add up to 100%.
						<strong>
							&nbsp;Please {invalidDifference > 0 ? ' ADD ' : ' MINUS '}
							{Math.abs(invalidDifference)}.
						</strong>
					</div>
				</div>
			)}
		</div>
	);
};
