import pptxgen from 'pptxgenjs';
import moment from 'moment';

import { MASTER_SLIDE_FINAL } from './common';

const FINAL_DATE_CONFIG: pptxgen.TextPropsOptions = {
	h: 0.13,
	w: 2.73,
	x: 3.64,
	y: 7.11,
	fontSize: 8,
	fontFace: 'Verdana (Body)',
	align: 'center',
	color: '383839',
};

export const appendFinalSlide = (deckInstance: pptxgen) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_FINAL.title);

	slide.addText(
		`©${moment().format('YYYY')} AlixPartners, LLP.`,
		FINAL_DATE_CONFIG,
	);
};
