import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';
import { PermissionsConsumer } from '../PermissionsContext';

import './NavBar.scss';

const NavBarComponent: React.FC<RouteComponentProps> = (props) => {
	const currentUrl = window.location.href;
	const { history } = props;

	return (
		<ApAuthenticationContextConsumer>
			{({ user }) => {
				return (
					<PermissionsConsumer>
						{({ controlPanelEnabled, watchlistDashboard }) => {
							return (
								<nav className="homepage-navbar">
									<ul className="homepage-navbar__list">
										<li className="homepage-navbar__item">
											<a
												href="/search"
												className={`homepage-navbar__link ${
													currentUrl.includes('/search') ||
													currentUrl.includes('/companies/')
														? 'active'
														: ''
												}`}
												onClick={(e) => {
													e.preventDefault();
													history.push('/search');
												}}
											>
												Company Search
											</a>
										</li>
										{controlPanelEnabled && (
											<li className="homepage-navbar__item">
												<a
													href="/screening"
													className={`homepage-navbar__link ${
														currentUrl.includes('/screening') ||
														currentUrl.includes('/control-panel')
															? 'active'
															: ''
													}`}
													onClick={(e) => {
														e.preventDefault();
														history.push('/screening');
													}}
												>
													Screening Tools
												</a>
											</li>
										)}
										{watchlistDashboard && (
											<li className="homepage-navbar__item">
												<a
													href="/watchlists"
													className={`homepage-navbar__link ${
														currentUrl.includes('/watchlists') ||
														currentUrl.includes('/prospects')
															? 'active'
															: ''
													}`}
													onClick={(e) => {
														e.preventDefault();
														history.push('/watchlists');
													}}
												>
													Watchlists
												</a>
											</li>
										)}
									</ul>
								</nav>
							);
						}}
					</PermissionsConsumer>
				);
			}}
		</ApAuthenticationContextConsumer>
	);
};

export const NavBar = withRouter(NavBarComponent);
