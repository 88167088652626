import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {
	AllCommunityModules,
	CellEditingStoppedEvent,
	RowNode,
	ValueParserParams,
} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import { ICategory, IGridCategory, IKpi } from '../../types';

import './KPIPanel.scss';

interface IKPIPanelProps {
	title: string;
	data: ICategory[];

	intro?(): React.ReactNode;

	onChange(categories: IGridCategory[]): void;

	onValidate(isValid: boolean): void;
}

const customValueSetter = (
	params: ValueParserParams,
	// categories: ICategory[],
) => {
	const {
		newValue,
		data,
		colDef: { field },
	} = params;

	// For Debt/EBITDA, Current Ratio we can set any value in Hurdle Rate cell
	if (
		field === 'hurdleRate' &&
		['Debt/EBITDA', 'Current ratio'].includes(data.description)
	) {
		// @ts-ignore
		data[field] = +newValue;
		return true;
	}

	// For all categories except Profitability and Solvency we restrict editing
	if (
		field === 'hurdleRate' &&
		!['Profitability', 'Solvency'].includes(data.categoryName)
	) {
		return false;
	}

	if (isNaN(+newValue) || newValue > 100 || newValue < -100) {
		return false;
	} else {
		// @ts-ignore
		data[field] = +newValue;
		return true;
	}
};

const defaultColProps = {
	sortable: true,
	filter: true,
	resizable: true,
	singleClickEdit: true,
	valueSetter: customValueSetter,
};

const kpiColumnNames = [
	{
		headerName: 'Category Name',
		field: 'categoryName',
		pinned: 'left',
		...defaultColProps,
	},
	{
		headerName: 'Description',
		field: 'description',
		...defaultColProps,
	},
	{
		headerName: 'KPI Weighting (%)',
		field: 'kpiWeighting',
		...defaultColProps,
		editable: true,
		type: 'numericColumn',
	},
	{
		headerName: '1 Year % Change: Low Risk',
		field: 'year1Change1Value',
		...defaultColProps,
		editable: true,
		type: 'numericColumn',
	},
	{
		headerName: '1 Year % Change: High Risk',
		field: 'year1Change2Value',
		...defaultColProps,
		editable: true,
		type: 'numericColumn',
	},
	{
		headerName: '2 Year % Change: Low Risk',
		field: 'year2Change1Value',
		...defaultColProps,
		editable: true,
		type: 'numericColumn',
	},
	{
		headerName: '2 Year % Change: High Risk',
		field: 'year2Change2Value',
		...defaultColProps,
		editable: true,
		type: 'numericColumn',
	},
	{
		headerName: 'Hurdle Rate',
		field: 'hurdleRate',
		type: 'numericColumn',
		...defaultColProps,
		// editable: true,
		editable: (params: any) =>
			['Profitability', 'Solvency'].includes(params.data.categoryName),
		cellStyle: (params: any) =>
			['Profitability', 'Solvency'].includes(params.data.categoryName)
				? null
				: { backgroundColor: '#c4c4c4' },
	},
];

const buildRowData = (data: ICategory[] = []) => {
	const rowData: IGridCategory[] = [];

	data.forEach((obj) => {
		const categoryInfo = {
			categoryName: obj.categoryName,
			categoryWeighting: obj.categoryWeighting,
		};
		if (obj.kpis) {
			obj.kpis.forEach((kpi: IKpi) => {
				rowData.push({
					...categoryInfo,
					dataItemId: kpi.dataItemId,
					description: kpi.description,
					hurdleRate: kpi.hurdleRate,
					kpiWeighting: kpi.kpiWeighting,
					year1Change1Value: kpi.year1Change1Value,
					year1Change2Value: kpi.year1Change2Value,
					year2Change1Value: kpi.year2Change1Value,
					year2Change2Value: kpi.year2Change2Value,
				});
			});
		}
	});

	return rowData;
};

export class KPIPanel extends React.Component<IKPIPanelProps, unknown> {
	invalidCategories: string[] = [];
	columnNames: any[] = kpiColumnNames;
	gridApi: any;
	gridColumnApi: any;

	addToInvalidCategories = (categoryName: string) => {
		if (!this.invalidCategories.includes(categoryName)) {
			this.invalidCategories.push(categoryName);
		}
	};

	removeFromInvalidCategories = (categoryName: string) => {
		if (this.invalidCategories.includes(categoryName)) {
			this.invalidCategories.splice(
				this.invalidCategories.indexOf(categoryName),
				1,
			);
		}
	};

	componentDidMount() {
		const savedColumnsWidth = localStorage.getItem('KPIcolumnsWidth');

		let newColumnNames;
		if (savedColumnsWidth) {
			const parsedWidths = JSON.parse(savedColumnsWidth);
			newColumnNames = this.columnNames.map((column: any) => {
				const savedColumn = parsedWidths.find(
					(saved: any) => saved.field === column.field,
				);
				return {
					...column,
					width: savedColumn?.width || column,
				};
			});
			this.columnNames = newColumnNames;
		}
	}

	onGridReady(params: any) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	onColumnResized(params: any) {
		const { columnApi } = params;
		const columns = columnApi.getAllColumns();
		const newColumnsWidth = columns.map((column: any) => ({
			field: column.colId,
			width: column.getActualWidth(),
		}));
		localStorage.setItem('KPIcolumnsWidth', JSON.stringify(newColumnsWidth));
	}

	render() {
		const { data, title, intro, onChange, onValidate } = this.props;
		const rowData = buildRowData(data);

		return (
			<section className="kpi-panel">
				<p className="kpi-panel__title">{title}</p>
				<span className="kpi-panel__hr"></span>
				{intro && <div className="category-margins__info">{intro()}</div>}
				<div
					className="kpi-panel__grid ag-theme-balham"
					style={{ height: `${(rowData.length + 1) * 36}px`, width: '100%' }}
				>
					<AgGridReact
						onGridReady={this.onGridReady}
						onColumnResized={this.onColumnResized}
						columnDefs={this.columnNames}
						rowData={rowData}
						modules={AllCommunityModules}
						rowHeight={36}
						stopEditingWhenGridLosesFocus={true}
						onCellEditingStopped={(params: CellEditingStoppedEvent) => {
							const isWeightingColumn =
								params.colDef.headerName === 'KPI Weighting';

							const updatedCategories: IGridCategory[] = [];
							const rowsToUpdateStyling: RowNode[] = [];

							params.api.forEachNode((node) => {
								if (
									isWeightingColumn &&
									node.data.categoryName === params.data.categoryName
								) {
									// If this is a node from KPI Weighting column we need to group all
									// related nodes with same Category name
									rowsToUpdateStyling.push(node);
								}
								updatedCategories.push(node.data);
							});

							if (isWeightingColumn) {
								const kpisSum = rowsToUpdateStyling.reduce((acc, item) => {
									acc += item.data.kpiWeighting;
									return acc;
								}, 0);

								// Validate KPI Weighting by category
								if (kpisSum !== 100) {
									this.addToInvalidCategories(params.data.categoryName);
									params.colDef.cellStyle = { backgroundColor: '#ed6661' };
								} else {
									params.colDef.cellStyle = { backgroundColor: 'none' };
									this.removeFromInvalidCategories(params.data.categoryName);
								}
								params.api.redrawRows({ rowNodes: rowsToUpdateStyling });
								onValidate(this.invalidCategories.length === 0);
							}

							onChange(updatedCategories);
						}}
					/>
				</div>
			</section>
		);
	}
}
