import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
} from './common';
import { numberWithCommas, roundOneDecimal } from '../utils';
import { ICashflowOverview } from '../types';

interface IMapChild {
	name: string;
	apiField: string;
	data: any[];
}

interface IFieldMapItem {
	hasChildren: boolean;
	apiField: string;
	children?: IMapChild[];
	data: any[];
}

interface IFieldsMap {
	'- Profit For The Year': IFieldMapItem;
	'Cash Generated From/(Used In) Operations': IFieldMapItem;
	'Cash Generated From/(Used In) Investing': IFieldMapItem;
	'Cash Generated From/(Used In) Financing': IFieldMapItem;
	'Net Increase/(Decrease) In Cash And Equivalents': IFieldMapItem;
}

const displayOrderMap: string[] = [
	'- Profit For The Year',
	'Cash Generated From/(Used In) Operations',
	'Cash Generated From/(Used In) Investing',
	'Cash Generated From/(Used In) Financing',
	'Net Increase/(Decrease) In Cash And Equivalents',
];

const highLightRowsMap: string[] = [
	// 'Profit For The Year',
	'Cash Generated From/(Used In) Operations',
	'Cash Generated From/(Used In) Investing',
	'Cash Generated From/(Used In) Financing',
	'Net Increase/(Decrease) In Cash And Equivalents',
];

const fieldsMap: IFieldsMap = {
	'- Profit For The Year': {
		hasChildren: true,
		apiField: 'profitOfTheYear',
		children: [
			{ name: 'Depreciation', apiField: 'depreciationAmortization', data: [] },
			{
				name: 'Other changes',
				apiField: 'cashOperationsOtherChanges',
				data: [],
			},
		],
		data: [],
	},
	'Cash Generated From/(Used In) Operations': {
		hasChildren: true,
		apiField: 'cashGeneratedFromOperations',
		children: [
			{ name: 'Capital Expenditure', apiField: 'capitalExpenditure', data: [] },
			{
				name: 'Invest. in Marketable & Equity Securt.',
				apiField: 'investmentsInMarketableAndEquitySecurities',
				data: [],
			},
			{ name: 'Other changes', apiField: 'cashInvestOtherChanges', data: [] },
		],
		data: [],
	},
	'Cash Generated From/(Used In) Investing': {
		hasChildren: true,
		apiField: 'cashGeneratedFromInvesting',
		children: [
			{ name: 'Total debt issued', apiField: 'totalDebtIssued', data: [] },
			{ name: 'Total debt repaid', apiField: 'totalDebtRepaid', data: [] },
			{
				name: 'Other Financing activities',
				apiField: 'otherFinancingActivities',
				data: [],
			},
		],
		data: [],
	},
	'Cash Generated From/(Used In) Financing': {
		hasChildren: true,
		children: [{ name: 'FX effect', apiField: 'fxEffect', data: [] }],
		apiField: 'cashGeneratedFromFinancing',
		data: [],
	},
	'Net Increase/(Decrease) In Cash And Equivalents': {
		hasChildren: false,
		apiField: 'netInCashAndEquivalents',
		data: [],
	},
};

const getYearsList = (data: any[]) => {
	const yearsList: number[] = [];
	data.map((row) => {
		return yearsList.push(row.fiscalYear);
	});
	return yearsList.sort((a, b) => a - b);
};

const processRespData = (respData: ICashflowOverview[], fieldsMap: any) => {
	for (const prop in fieldsMap) {
		if (Object.prototype.hasOwnProperty.call(fieldsMap, prop)) {
			const rowProps: any = fieldsMap[prop];
			if (!rowProps.hasChildren) {
				rowProps.data = respData.map((row: any) => row[rowProps.apiField]);
			} else {
				rowProps.children.map((child: IMapChild) => {
					return (child.data = respData.map((row: any) => row[child.apiField]));
				});
				const childsData = rowProps.children.map(
					(child: IMapChild) => child.data,
				);
				if (rowProps.apiField) {
					rowProps.data = respData.map((row: any) => row[rowProps.apiField]);
				} else {
					rowProps.data = childsData.reduce(function (
						r: number[],
						a: number[],
					) {
						a.forEach(function (b: number, i: number) {
							r[i] = (r[i] || 0) + (b || 0);
						});
						return r;
					},
					[]);
				}
			}
		}
	}
	// const profitField = fieldsMap['Profit For The Year'];
	// const cashGenerated = fieldsMap['Cash Generated From/(Used In) Operations'];
	// let parentData = profitField.data;
	// profitField.children[1].data = [];

	// parentData.forEach((value: number, index: number) => {
	// 	profitField.children[1].data.push(
	// 		parentData[index] - profitField.children[0].data[index],
	// 	);
	// });

	// parentData = cashGenerated.data;
	// cashGenerated.children[2].data = [];

	// parentData.forEach((value: number, index: number) => {
	// 	console.log([
	// 		parentData[index],
	// 		cashGenerated.children[0].data[index],
	// 		cashGenerated.children[1].data[index],
	// 	]);

	// 	console.log(
	// 		parentData[index] -
	// 			cashGenerated.children[0].data[index] -
	// 			cashGenerated.children[1].data[index],
	// 	);

	// 	cashGenerated.children[2].data.push(
	// 		parentData[index] -
	// 			cashGenerated.children[0].data[index] -
	// 			cashGenerated.children[1].data[index],
	// 	);
	// });
};

const prepareTableData = (fieldsMap: any) => {
	const tableData: any[] = [];
	displayOrderMap.forEach((keyName) => {
		const mainRowOptions: any = { margin: [0, 7, 0, 7] };
		if (highLightRowsMap.includes(keyName)) {
			mainRowOptions['fill'] = 'ADAFB2';
			mainRowOptions['color'] = 'FFFFFF';
			mainRowOptions['bold'] = true;
		}
		const fieldArray = fieldsMap[keyName].data.map((value: any) => {
			return value > 0
				? {
						text: value ? numberWithCommas(roundOneDecimal(value)) : '0',
						options: {
							...mainRowOptions,
							align: 'right',
							margin: [0, 7, 0, 7],
						},
				  }
				: {
						text: value
							? `(${numberWithCommas(roundOneDecimal(Math.abs(value)))})`
							: '0',
						options: {
							...mainRowOptions,
							align: 'right',
							margin: [0, 7, 0, 7],
						},
				  };
		});
		fieldArray.unshift({ text: keyName, options: mainRowOptions });

		tableData.push(fieldArray);
		if (fieldsMap[keyName].hasChildren) {
			fieldsMap[keyName].children.forEach((child: IMapChild) => {
				const childData = child.data.map((value: any) => {
					return value > 0
						? {
								text: value ? numberWithCommas(roundOneDecimal(value)) : '0',
								options: { align: 'right', margin: [0, 7, 0, 7] },
						  }
						: {
								text: value
									? `(${numberWithCommas(roundOneDecimal(Math.abs(value)))})`
									: '0',
								options: { align: 'right', margin: [0, 7, 0, 7] },
						  };
				});
				// @ts-ignore
				childData.unshift({ text: `- ${child.name}` });
				tableData.push(childData);
			});
		}
	});

	return tableData;
};

export const appendCashflowOverviewSlide = async (
	deckInstance: pptxgen,
	companyId: number,
	currencyCode: string,
	respData: ICashflowOverview[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`Appendix: Cashflow Overview`, SLIDE_TITLE_CONFIG_WIDE);
	slide.addText(`Appendix`, SLIDE_TOP_LABEL_WIDE);

	const sortedData = respData.sort((a, b) => a.fiscalYear - b.fiscalYear);
	processRespData(sortedData, fieldsMap);

	const headerConfig = {
		fill: '006176',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
		align: 'right',
	};

	const yearsList = getYearsList(respData);
	const headersRow = yearsList.map((item) => {
		return { text: item.toString(), options: headerConfig };
	});
	headersRow.unshift({
		text: `${getSymbolFromCurrency(currencyCode)}M`,
		options: { ...headerConfig, align: 'left' },
	});
	const tableRows = prepareTableData(fieldsMap);

	// calculate dynamically size of columns
	const colW = [4.17];
	const colsCount = headersRow.length - 1;
	for (let i = 0; i < colsCount; i++) {
		colW.push(Math.round((8.3 / colsCount) * 100) / 100);
	}

	slide.addTable([headersRow, ...tableRows], {
		x: 0.4,
		y: 1.46,
		w: 12.53,
		border: [
			{ type: 'solid', pt: 0, color: 'FFFFFF' },
			{ type: 'solid', pt: 1.5, color: 'FFFFFF' },
			{ type: 'dash', pt: 0.5, color: 'B3B3B5' },
			{ type: 'solid', pt: 1.5, color: 'FFFFFF' },
		],
		colW: colW,
		// @ts-ignore
		rowH: [0.27],
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		color: '383838',
		margin: [3, 0, 3, 7], // Adjusted to fit long roles
		valign: 'middle',
		align: 'left',
	});
};
