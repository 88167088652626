import pptxgen from 'pptxgenjs';
import moment from 'moment';

import {
	IPeople,
	ICompetitor,
	IChart,
	IBalanceOverview,
	ICashflowOverview,
	IPeersFinancialWidget,
} from '../types';
import {
	MASTER_SLIDE_DEFAULT,
	MASTER_SLIDE_DEFAULT_WIDE,
	MASTER_SLIDE_COVER,
	MASTER_SLIDE_COVER_WIDE,
	MASTER_SLIDE_FINAL,
	MASTER_SLIDE_FINAL_WIDE,
} from '../deck/common';

import { appendCoverSlide } from '../deck/slide1Cover';
import { appendCoverSlide as appendCoverSlideWide } from '../deck/slide1CoverWide';
import { appendCompanySummarySlide } from '../deck/slide2CompanySummary';
import { appendCompanySummarySlide as appendCompanySummarySlideWide } from '../deck/slide2CompanySummaryWide';
import { appendBusinessDescriptionSlide } from '../deck/slide3BusinessDescription';
import { appendBusinessDescriptionSlide as appendBusinessDescriptionSlideWide } from '../deck/slide3BusinessDescriptionWide';
import { appendKeyPeopleSlide } from '../deck/slide6KeyPeople';
import { appendKeyPeopleSlide as appendKeyPeopleSlideWide } from '../deck/slide6KeyPeopleWide';
import { appendPeopleConnectionsSlide } from '../deck/slide6PeopleConnections';
import { appendPeopleConnectionsSlide as appendPeopleConnectionsSlideWide } from '../deck/slide6PeopleConnectionsWide';
import { appendRevenueSlide } from '../deck/slide7Revenue';
import { appendRevenueSlide as appendRevenueSlideWide } from '../deck/slide7RevenueWide';
import { appendRevenueMarginSlide } from '../deck/slide8RevenueMargin';
import { appendRevenueMarginSlide as appendRevenueMarginSlideWide } from '../deck/slide8RevenueMarginWide';
import { appendGrowthProfitabilitySlide } from '../deck/slide9GrowthProfitability';
import { appendGrowthProfitabilitySlide as appendGrowthProfitabilitySlideWide } from '../deck/slide9GrowthProfitabilityWide';
import { appendPeersComparisonSlide } from '../deck/slide11PeersComparison';
import { appendPeersComparisonSlide as appendPeersComparisonSlideWide } from '../deck/slide11PeersComparisonWide';
import { appendSharePriceSlide } from '../deck/slide12SharePrice';
import { appendSharePriceSlide as appendSharePriceSlideWide } from '../deck/slide12SharePriceWide';
import { appendPLOverviewSlide } from '../deck/slide13PLOverview';
import { appendBalanceSheetOverviewSlide } from '../deck/slide14BalanceOverview';
import { appendCashflowOverviewSlide } from '../deck/slide15Cashflow';
import { appendPLOverviewSlide as appendPLOverviewSlideWide } from '../deck/slide13PLOverviewWide';
import { appendBalanceSheetOverviewSlide as appendBalanceSheetOverviewSlideWide } from '../deck/slide14BalanceOverviewWide';
import { appendCashflowOverviewSlide as appendCashflowOverviewSlideWide } from '../deck/slide15CashflowWide';
import { appendGrowthProfitabilityLastYearSlide } from '../deck/slide10GrowthProfitabilityLastYear';
import { appendGrowthProfitabilityLastYearSlide as appendGrowthProfitabilityLastYearSlideWide } from '../deck/slide10GrowthProfitabilityLastYearWide';

import { appendFinalSlide } from '../deck/slide16Final';
import { appendFinalSlide as appendFinalSlideWide } from '../deck/slide16FinalWide';

export interface IDeckChartImagesMap {
	growsProfitability: string;
	growsProfitabilityLastYear: string;
	sharePrice: string;
}

export interface IDeckConfig {
	companyId: number;
	name: string;
	description: string;
	currencyCode: string;
	revenue: number;
	operatingIncome: number;
	totalEbitda: number;
	prevEbitda: number;
	prevRevenue: number;
	prevGrossProfit: number;
	netIncome: number;
	sharePrice: number;
	sharePrice52WeekHigh: number;
	keyOwnership: string;
	leadership: string;
	latestYear: number;
	employees: number;
	competitors: ICompetitor[];
	yearFounded: number;
	country: string;
	city: string;
	tickerSymbol: string;
	marketCap: number;
	countries: string[];
	grossProfit: number;
	keyPeople: IPeople[];
	connectionsData: IPeople[];
	exchangeName: string;
	logo?: string;
	logoWidth?: number;
	logoHeight?: number;
	deckChartImages: IDeckChartImagesMap;
	peersFinancialData: IPeersFinancialWidget[];

	// TODO: refactor interfaces
	revenueChartsPercentage: any;
	revenueChartsDataLast5: any;
	chartData: IChart;
	balanceData: IBalanceOverview[];
	cashFlowData: ICashflowOverview[];
}

export const exportDeck = (deckConfig: IDeckConfig, isWidescreen: boolean) => {
	const {
		companyId,
		name,
		description,
		currencyCode,
		revenue,
		operatingIncome,
		totalEbitda,
		prevEbitda,
		prevRevenue,
		prevGrossProfit,
		netIncome,
		sharePrice,
		sharePrice52WeekHigh,
		keyOwnership,
		leadership,
		latestYear,
		employees,
		competitors,
		yearFounded,
		country,
		city,
		tickerSymbol,
		marketCap,
		countries,
		grossProfit,
		keyPeople,
		connectionsData,
		exchangeName,
		revenueChartsPercentage,
		revenueChartsDataLast5,
		deckChartImages,
		logo,
		logoWidth,
		logoHeight,
		chartData,
		balanceData,
		cashFlowData,
		peersFinancialData,
	} = deckConfig;

	const pres = new pptxgen();
	pres.layout = isWidescreen ? 'LAYOUT_WIDE' : 'LAYOUT_4x3';

	const companyFinancialData = competitors.find(
		(c) => c.companyId === companyId,
	)!.financialData;

	// @ts-ignore
	pres.defineSlideMaster({
		...MASTER_SLIDE_DEFAULT_WIDE,
		objects: [
			...MASTER_SLIDE_DEFAULT_WIDE.objects,
			{
				text: {
					text: name,
					options: {
						x: 0.4,
						y: 7.05,
						h: 0.4,
						w: 9.32,
						fontSize: 8,
						fontFace: 'Verdana (Body)',
						color: '595959',
						autoFit: true,
						valign: 'middle',
						// shrinkText: true,
						margin: [0, 0, 0, 0],
					},
				},
			},
		],
	});
	pres.defineSlideMaster(MASTER_SLIDE_COVER_WIDE);
	pres.defineSlideMaster(MASTER_SLIDE_FINAL_WIDE);
	// @ts-ignore
	pres.defineSlideMaster(MASTER_SLIDE_DEFAULT);
	pres.defineSlideMaster(MASTER_SLIDE_COVER);
	pres.defineSlideMaster(MASTER_SLIDE_FINAL);

	if (isWidescreen) {
		appendCoverSlideWide(pres, name, logo, logoWidth, logoHeight);
		appendCompanySummarySlideWide(
			pres,
			name,
			yearFounded,
			city,
			country,
			tickerSymbol,
			exchangeName,
			currencyCode,
			marketCap,
			sharePrice,
			sharePrice52WeekHigh,
			countries,
			latestYear,
			revenue,
			prevRevenue,
			grossProfit,
			prevGrossProfit,
			totalEbitda,
			prevEbitda,
		);
		appendBusinessDescriptionSlideWide(
			pres,
			name,
			description,
			latestYear,
			currencyCode,
			revenue,
			operatingIncome,
			totalEbitda,
			netIncome,
			keyOwnership,
			leadership,
			employees,
			competitors
				.filter((c) => c.companyId !== companyId)
				.map((c) => c.name)
				.sort((a: string, b: string) => {
					return a.localeCompare(b);
				}),
		);
		appendKeyPeopleSlideWide(pres, name, keyPeople);
		if (connectionsData.length) {
			appendPeopleConnectionsSlideWide(pres, name, connectionsData);
		}
		appendRevenueSlideWide(
			pres,
			name,
			currencyCode,
			revenueChartsDataLast5,
			companyFinancialData,
		);
		appendRevenueMarginSlideWide(
			pres,
			name,
			currencyCode,
			revenueChartsDataLast5,
		);
		appendGrowthProfitabilitySlideWide(
			pres,
			name,
			latestYear,
			competitors,
			deckChartImages.growsProfitability,
		);
		appendGrowthProfitabilityLastYearSlideWide(
			pres,
			name,
			deckChartImages.growsProfitabilityLastYear,
		);
		appendPeersComparisonSlideWide(
			pres,
			// name,
			// latestYear,
			companyId,
			// competitors,
			peersFinancialData,
		);
		appendSharePriceSlideWide(
			pres,
			name,
			competitors,
			deckChartImages.sharePrice,
		);
		appendPLOverviewSlideWide(pres, chartData, currencyCode, latestYear);
		balanceData.length &&
			appendBalanceSheetOverviewSlideWide(
				pres,
				companyId,
				currencyCode,
				balanceData,
			);
		cashFlowData.length &&
			appendCashflowOverviewSlideWide(
				pres,
				companyId,
				currencyCode,
				cashFlowData,
			);
		appendFinalSlideWide(pres);
	} else {
		// Slide 1 - Cover slide
		appendCoverSlide(pres, name, logo, logoWidth, logoHeight);

		// Slide 2 - Company Summary
		appendCompanySummarySlide(
			pres,
			name,
			yearFounded,
			city,
			country,
			tickerSymbol,
			exchangeName,
			currencyCode,
			marketCap,
			sharePrice,
			sharePrice52WeekHigh,
			countries,
			latestYear,
			revenue,
			prevRevenue,
			grossProfit,
			prevGrossProfit,
			totalEbitda,
			prevEbitda,
		);

		// Slide 3 - Business Description
		appendBusinessDescriptionSlide(
			pres,
			name,
			description,
			latestYear,
			currencyCode,
			revenue,
			operatingIncome,
			totalEbitda,
			netIncome,
			keyOwnership,
			leadership,
			employees,
			competitors
				.filter((c) => c.companyId !== companyId)
				.map((c) => c.name)
				.sort((a: string, b: string) => {
					return a.localeCompare(b);
				}),
		);

		// Slide 6 - Key People
		appendKeyPeopleSlide(pres, name, keyPeople);
		if (connectionsData.length) {
			appendPeopleConnectionsSlide(pres, name, connectionsData);
		}

		// Slide 7 - Revenue charts
		appendRevenueSlide(
			pres,
			name,
			currencyCode,
			// deckChartImages.revenue,
			// deckChartImages.grossProfit,
			// deckChartImages.sgAndA,
			// deckChartImages.ebitda,
			revenueChartsPercentage,
			revenueChartsDataLast5,
		);

		// Slide 8 - Revenue Margin charts
		appendRevenueMarginSlide(
			pres,
			name,
			currencyCode,
			// deckChartImages.revenueMargin,
			// deckChartImages.revenueCogs,
			revenueChartsDataLast5,
		);

		// Slide 9 - Growth vs Profitability (5 years - LTM)
		appendGrowthProfitabilitySlide(
			pres,
			name,
			competitors,
			deckChartImages.growsProfitability,
		);

		// Slide 10 - Growth vs Profitability (LTM)
		appendGrowthProfitabilityLastYearSlide(
			pres,
			name,
			deckChartImages.growsProfitabilityLastYear,
		);

		// Slide 11 - Peers compariosn
		appendPeersComparisonSlide(
			pres,
			// name,
			// latestYear,
			companyId,
			competitors,
			peersFinancialData,
		);

		// Slide 12 - Share Price
		appendSharePriceSlide(pres, name, competitors, deckChartImages.sharePrice);

		// Slide 13 - P&L Overview
		appendPLOverviewSlide(pres, chartData, currencyCode, latestYear);

		// Slide 14 - Balance sheet Overview
		balanceData.length &&
			appendBalanceSheetOverviewSlide(
				pres,
				companyId,
				currencyCode,
				balanceData,
			);

		// Slide 15 - Balance sheet Overview
		cashFlowData.length &&
			appendCashflowOverviewSlide(pres, companyId, currencyCode, cashFlowData);

		appendFinalSlide(pres);
	}

	pres.writeFile(`${name} ${moment().format('DD MMM YYYY')}`);
};
