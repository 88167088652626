import pptxgen from 'pptxgenjs';

import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
} from './common';

export const appendGrowthProfitabilityLastYearSlide = (
	deckInstance: pptxgen,
	companyName: string,
	growsProfitabilityChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`BENCHMARKS`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(
		'YoY Revenue Growth vs Profitability Chart',
		SLIDE_TITLE_CONFIG_WIDE,
	);

	slide.addText(
		[
			{
				text: 'Revenue Growth ',
				options: { bold: true, breakLine: false },
			},
			{
				text: '(YoY, PY vs CY) ',
				options: { italic: true, breakLine: false },
			},
			{ text: 'vs. EBIT margin ', options: { bold: true, breakLine: false } },
			{
				text: '(PY vs CY)',
				options: { italic: true },
			},
		],
		{
			x: 0.4,
			y: 1.13,
			w: 9.4,
			h: 0.4,
			fontSize: 14,
			fontFace: 'Verdana (Body)',
			align: 'left',
			valign: 'top',
			bold: true,
			color: '383838',
			margin: [0, 0, 0, 0],
		},
	);

	slide.addImage({
		data: growsProfitabilityChart,
		x: 0.4,
		y: 1.5,
		h: 5.27,
		w: 12.46,
	});

	slide.addText(
		'Solid colours represent current year revenue, opaque colours represent revenue for the previous year',
		{
			x: 0.4,
			y: 6.61,
			h: 0.38,
			w: 9.4,
			fontSize: 8,
			fontFace: 'Verdana (Body)',
			color: '808083',
			valign: 'bottom',
		},
	);
};
