import React from 'react';
import { ApButtonSecondary } from '@alixpartners/ui-components';

import './WidgetError.scss';

interface IWidgetErrorProps {
	style?: any;
	onRetry?(): Promise<any>;
}
interface IWidgetErrorState {}

export class WidgetError extends React.Component<
	IWidgetErrorProps,
	IWidgetErrorState
> {
	state = {};

	render() {
		const { style, onRetry } = this.props;

		return (
			<div className="widget-error" style={style}>
				<div className="widget-error__title">Error</div>
				<div className="widget-error__message">
					There was an error loading data. Please try again.
				</div>
				{onRetry && (
					<div className="widget-error__wrapper">
						<ApButtonSecondary onClick={onRetry}>Retry</ApButtonSecondary>
					</div>
				)}
			</div>
		);
	}
}
