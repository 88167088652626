import React from 'react';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

interface IDateRangeFilterProps {
	from: string | undefined;
	to: string | undefined;
	onFromChange(from: string): void;
	onToChange(to: string, toRef: any): void;
}

// @ts-ignore
const CustomOverlayFrom = ({ classNames, selectedDay, children, ...props }) => {
	return (
		<div
			className={classNames.overlayWrapper}
			style={{ marginLeft: -200 }}
			{...props}
		>
			<div className={classNames.overlay}>{children}</div>
		</div>
	);
};

// @ts-ignore
const CustomOverlayTo = ({ classNames, selectedDay, children, ...props }) => {
	return (
		<div
			className={classNames.overlayWrapper}
			style={{ marginLeft: -400 }}
			{...props}
		>
			<div className={classNames.overlay}>{children}</div>
		</div>
	);
};

export const DateRangeFilter: React.FC<IDateRangeFilterProps> = ({
	from,
	to,
	onFromChange,
	onToChange,
}) => {
	const toRef = React.useRef(null);

	return (
		<div className="watchlist-report__date">
			<div className="watchlist-report__from">
				<DayPickerInput
					overlayComponent={CustomOverlayFrom}
					value={from}
					format="D/M/YYYY"
					placeholder="DD/MM/YYYY"
					formatDate={formatDate}
					parseDate={parseDate}
					dayPickerProps={{
						selectedDays: [from, { from, to }],
						disabledDays: { after: to },
						toMonth: to,
						modifiers: { start: from, end: to },
						numberOfMonths: 2,
						// @ts-ignore
						onDayClick: () => toRef.current.getInput().focus(),
					}}
					onDayChange={onFromChange}
				/>
			</div>
			<div className="watchlist-report__filter-label">TO</div>
			<div className="watchlist-report__to">
				<DayPickerInput
					ref={toRef}
					overlayComponent={CustomOverlayTo}
					value={to}
					format="D/M/YYYY"
					placeholder="DD/MM/YYYY"
					formatDate={formatDate}
					parseDate={parseDate}
					dayPickerProps={{
						selectedDays: [from, { from, to }],
						disabledDays: { before: from },
						modifiers: { start: from, end: to },
						month: from,
						fromMonth: from,
						numberOfMonths: 2,
					}}
					onDayChange={(toValue: string) => onToChange(toValue, toRef.current)}
				/>
			</div>
		</div>
	);
};
