import pptxgen from 'pptxgenjs';
import moment from 'moment';

import { MASTER_SLIDE_FINAL_WIDE } from './common';

const FINAL_DATE_CONFIG: pptxgen.TextPropsOptions = {
	h: 0.13,
	w: 3.63,
	x: 4.85,
	y: 7.11,
	fontSize: 8,
	fontFace: 'Verdana (Body)',
	align: 'center',
	color: '000000',
};

export const appendFinalSlide = (deckInstance: pptxgen) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_FINAL_WIDE.title);

	slide.addText(
		`©${moment().format('YYYY')} AlixPartners, LLP.`,
		FINAL_DATE_CONFIG,
	);
};
