import pptxgen from 'pptxgenjs';

import { ICompetitor } from '../types';

import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	NO_DATA_CONFIG,
} from './common';

export const appendSharePriceSlide = (
	deckInstance: pptxgen,
	companyName: string,
	competitors: ICompetitor[],
	sharePriceChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`SHARE PRICE`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(`Stock price performance`, SLIDE_TITLE_CONFIG_WIDE);
	const isNoSharePriceData = competitors
		.map((c) => c.sharePriceData.length)
		.every((item) => item === 0);

	slide.addText(
		[
			{
				text: 'Stock performance vs. major competitors ',
				options: { bold: true, breakLine: false },
			},
			{
				text: '(last 3 years)',
				options: { italic: true, breakLine: false },
			},
		],
		{
			x: 0.4,
			y: 1.13,
			w: 9.4,
			h: 0.4,
			fontSize: 14,
			fontFace: 'Verdana (Body)',
			align: 'left',
			valign: 'top',
			bold: true,
			color: '383838',
			margin: [0, 0, 0, 0],
		},
	);

	const colors = [
		'd12724',
		'ff930f',
		'15723c',
		'0e053d',
		'004299',
		'9d9c9c',
		'f2d815',
	];

	const rowsData = competitors
		.map((c, currentIndex) => {
			const { sharePriceGrowth } = c;

			return [
				{
					text: ' ',
					options: {
						fill: { color: colors[currentIndex], margin: [3, 0, 3, 7] },
					},
				},
				{ text: c.name, options: { margin: [3, 0, 3, 7] } },
				{
					text: sharePriceGrowth ? `${sharePriceGrowth}%` : 'N/A',
					sortValue: sharePriceGrowth,
					options: { align: 'right', margin: [3, 0, 3, 7] },
				},
			];
		})
		.sort((a: any, b: any) => {
			if (a[1].sortValue < b[1].sortValue) {
				return 1;
			}
			if (a[1].sortValue > b[1].sortValue) {
				return -1;
			}
			return 0;
		});

	const headerConfig = {
		color: '383838',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRow = [
		{ text: '', options: headerConfig },
		{ text: 'COMPANY', options: headerConfig },
		{ text: 'RETURN', options: { ...headerConfig, align: 'right' } },
	];

	if (!isNoSharePriceData) {
		slide.addTable(
			[
				// @ts-ignore
				headersRow,
				// @ts-ignore
				...rowsData,
			],
			{
				x: 9.26,
				y: 2.11,
				w: 3.66,
				border: { type: 'solid', pt: 1, color: 'FFFFFF' },
				colW: [0.26, 2.21, 1.19],
				// @ts-ignore
				rowH: [0.3],
				fontFace: 'Verdana (Body)',
				fontSize: 10,
				color: '383838',
				margin: [3, 0, 3, 7],
				valign: 'middle',
				align: 'left',
			},
		);
	}

	if (!isNoSharePriceData) {
		slide.addImage({
			data: sharePriceChart,
			x: 0.4,
			y: 1.69,
			h: 5.15,
			w: 8.83,
		});
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 0.4, y: 1.69 });
	}
};
