import pptxgen from 'pptxgenjs';

import { IPeople } from '../types';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
	NO_DATA_CONFIG,
} from './common';

export const appendKeyPeopleSlide = (
	deckInstance: pptxgen,
	companyName: string,
	keyPeople: IPeople[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`${companyName} Key People`, SLIDE_TITLE_CONFIG);
	slide.addText(`MANAGEMENT & BOD`, SLIDE_TOP_LABEL);

	slide.addText('Board of Directors', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		x: 0.3,
		y: 1.35 + 0.2,
		w: 4.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText('Key Executives', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		x: 5.07,
		y: 1.35 + 0.2,
		w: 4.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	const headerConfig = {
		fill: '006176',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRowBoard = [
		{ text: 'Board Member', options: headerConfig },
		{ text: 'Designation', options: headerConfig },
	];

	const headersRowBExec = [
		{ text: 'Key Executive', options: headerConfig },
		{ text: 'Designation', options: headerConfig },
	];

	slide.addTable(
		[
			headersRowBoard,
			...keyPeople
				.filter((p) => p.isBoardMember)
				.slice(0, 10)
				.sort((a: IPeople, b: IPeople) => {
					const textA = a.role ? a.role.toUpperCase() : '';
					const textB = b.role ? b.role.toUpperCase() : '';
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				})
				.map((data) => {
					return [{ text: data.name }, { text: data.role }];
				}),
		],
		{
			x: 0.3,
			y: 1.65,
			w: 4.6,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [1.8, 2.8],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);

	slide.addTable(
		[
			headersRowBExec,
			...keyPeople
				.filter((p) => !p.isBoardMember)
				.slice(0, 10)
				.map((data) => {
					return [{ text: data.name }, { text: data.role }];
				}),
		],
		{
			x: 5.07,
			y: 1.65,
			w: 4.6,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [1.8, 2.8],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);

	if (!keyPeople.filter((p) => p.isBoardMember).length) {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 0.3, y: 2.05 });
	}

	if (!keyPeople.filter((p) => !p.isBoardMember).length) {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 5.07, y: 2.05 });
	}
};
