import React from 'react';

import { getConnectionAnalyticsData } from '../../api';

export const { Provider, Consumer } = React.createContext<
	IConnectionAnalyticsContext
>({
	webAppUrl: '',
	isLoading: true,
});

interface IConnectionAnalyticsContextProps {
	children: any;
}

interface IConnectionAnalyticsContext {
	webAppUrl: string;
	isLoading: boolean;
}

interface IConnectionAnalyticsContextState {
	webAppUrl: string;
	isLoading: boolean;
}

class ConnectionAnalyticsContext extends React.Component<
	IConnectionAnalyticsContextProps,
	IConnectionAnalyticsContextState
> {
	state = {
		webAppUrl: '',
		isLoading: true,
	};

	async componentDidMount() {
		try {
			const {
				data: { webAppUrl },
			} = await getConnectionAnalyticsData();
			this.setState({
				webAppUrl,
				isLoading: false,
			});
		} catch (error) {
			console.log('Error', error);
		}
	}

	render() {
		return <Provider value={{ ...this.state }}>{this.props.children}</Provider>;
	}
}

export default ConnectionAnalyticsContext;
