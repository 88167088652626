import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import 'react-day-picker/lib/style.css';
import ReadMoreAndLess from 'react-read-more-less';
import { ScreenClassProvider } from 'react-grid-system';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ApButtonGroup,
	ApButtonMain,
	ApButtonSecondary,
	ApCheckbox,
	ApChip,
	ApIcon,
	ApInput,
	ApLoaderDefault,
	ApModal,
	ApSimpleDropdown,
	IOption,
	snack,
} from '@alixpartners/ui-components';

import { WatchlistTable } from '../WatchlistTable';

import { CompanySelect } from '../CompanySelect';
import { CompanyCountWidget } from './CompanyCountWidget';
import { MultiSelect } from '../MultiSelect';
import { WatchlistReport } from '../WatchlistReport';
import { DateRangeFilter } from './DateRangeFilter';

import {
	addWatchlistCompany,
	addWatchlistOwner,
	deleteWatchlistOwner,
	editWatchlist,
	generateWatchlistReport,
	getWatchlistById,
	getWatchlistIndustries,
	getWatchlistReport,
	removeWatchlistCompany,
	updateWatchlist,
} from '../../api';
import {
	IPerson,
	IUser,
	IWatchlist,
	IWatchlistComment,
	IWatchlistProspectMeta,
	IWatchlistReport,
	IWatchlistReportCompany,
} from '../../types';

import { insert, numberWithCommas, splitToGroups } from '../../utils';
import avatar from './avatar.svg';

import './Watchlist.scss';

interface IRouteMatchParams {
	id: string;
}

interface IComment extends IWatchlistComment {
	isSelected: boolean;
}

interface ICompany extends IWatchlistReportCompany {
	isSelected: boolean;
	comments: IComment[];
}

interface IWatchlistProps extends RouteComponentProps<IRouteMatchParams> {
	currentUserId: string;
	currentUserFullName: string;
	users: IUser[];
	isUserListLoading: boolean;
	isSuperAdmin: boolean;
}

interface IWatchlistState {
	isLoading: boolean;
	isGeneratingReport: boolean;
	isLoadingReportData: boolean;
	isEditDescription: boolean;
	isEditWatchlistModalOpen: boolean;
	isAddOwnerModalOpen: boolean;
	isAddCompanyModalOpen: boolean;
	isDeleteModalOpen: boolean;
	isReportModalOpen: boolean;
	currentCompany: IWatchlistProspectMeta;
	watchlist: IWatchlist;
	watchlistCopy: IWatchlist;
	industriesOptions: IOption[];
	newCompanyId: number;
	newCompanyOwnerId: string;
	newCompanyOwnerName: string;
	newCompanyName: string;
	isAddingCompany: boolean;
	isUpdatingWatchlist: boolean;
	currentNumber: number;
	added: number;
	removed: number;

	pursuitCount: number;
	monitorCount: number;

	// Watchlist report
	watchlistReport: IWatchlistReport;
	reportCompanies: ICompany[];
	reportCompaniesCopy: ICompany[];
	selectedReportCompanyId: number;
	from: string | undefined;
	to: string | undefined;
	statusFilter: string[];
	isAllSelected: boolean;

	// Prospects sorting
	filteredProspects: IWatchlistProspectMeta[];

	selectedIndustryFilters: IOption[];
	selectedStatusFilters: IOption[];
	selectedRevenueFilters: IOption[];
	selectedEbitdaFilters: IOption[];
	selectedTotalDebtFilters: IOption[];
	selectedOwnerFilters: IOption[];

	revenueFilters: IOption[];
	ebitdaFilters: IOption[];
	totalDebtFilters: IOption[];

	// Date filters
	lastCommentFrom: string | undefined;
	lastCommentTo: string | undefined;
	lastReviewedFrom: string | undefined;
	lastReviewedTo: string | undefined;

	isFilterTabOpen: boolean;

	// Sorting
	sortProp: string | null;
	sortOrder: string;
}

const COMMUNITIES_OPTIONS = [
	{
		label: 'Digital',
		value: '1',
	},
	{
		label: 'EI',
		value: '2',
	},
	{
		label: 'FAS',
		value: '3',
	},
	{
		label: 'TRS',
		value: '4',
	},
];

export class WatchlistComponent extends React.Component<
	IWatchlistProps,
	IWatchlistState
> {
	state = {
		watchlist: {} as IWatchlist,
		watchlistCopy: {} as IWatchlist,
		isLoading: true,
		isGeneratingReport: false,
		isLoadingReportData: false,
		isEditDescription: false,
		isEditWatchlistModalOpen: false,
		isAddOwnerModalOpen: false,
		isAddCompanyModalOpen: false,
		isDeleteModalOpen: false,
		isReportModalOpen: false,
		currentCompany: {} as IWatchlistProspectMeta,
		industriesOptions: [],
		newCompanyOwnerId: this.props.currentUserId,
		newCompanyOwnerName: '',
		newCompanyId: 0,
		newCompanyName: '',
		isAddingCompany: false,
		isUpdatingWatchlist: false,
		currentNumber: 0,
		added: 0,
		removed: 0,

		pursuitCount: 0,
		monitorCount: 0,

		// Watchlist report
		watchlistReport: {} as IWatchlistReport, // TODO: remove?
		reportCompanies: [] as ICompany[],
		reportCompaniesCopy: [] as ICompany[],
		selectedReportCompanyId: -1,
		from: undefined,
		to: undefined,
		statusFilter: ['monitor', 'pursuit'],
		isAllSelected: false,

		// Prospects filtering
		filteredProspects: [] as IWatchlistProspectMeta[],
		selectedIndustryFilters: [] as IOption[],
		selectedStatusFilters: [] as IOption[],
		selectedRevenueFilters: [] as IOption[],
		selectedEbitdaFilters: [] as IOption[],
		selectedTotalDebtFilters: [] as IOption[],
		selectedOwnerFilters: [] as IOption[],
		revenueFilters: [] as IOption[],
		ebitdaFilters: [] as IOption[],
		totalDebtFilters: [] as IOption[],

		// Date filters
		lastCommentFrom: undefined,
		lastCommentTo: undefined,
		lastReviewedFrom: undefined,
		lastReviewedTo: undefined,

		isFilterTabOpen: false,

		sortProp: null,
		sortOrder: 'asc',
	};

	async componentDidMount() {
		this.loadWatchlistsData();
	}

	loadWatchlistsData = async () => {
		window.scrollTo(0, 0);
		const {
			history,
			match: {
				params: { id },
			},
		} = this.props;

		try {
			const [
				{ data: watchlistData },
				{ data: industriesData },
			] = await Promise.all([getWatchlistById(id), getWatchlistIndustries()]);

			if (watchlistData && industriesData) {
				this.setState({
					watchlist: watchlistData,
					watchlistCopy: watchlistData,
					filteredProspects: watchlistData.prospects,
					industriesOptions: industriesData.map((d) => ({
						label: d.name,
						value: d.id + '',
					})),
					revenueFilters:
						watchlistData.prospects && watchlistData.prospects.length > 0
							? this.generateNumericFilters(
									watchlistData.prospects.map((p) => p.revenue),
							  )
							: [],
					ebitdaFilters:
						watchlistData.prospects && watchlistData.prospects.length > 0
							? this.generateNumericFilters(
									watchlistData.prospects.map((p) => p.ebitda),
							  )
							: [],
					totalDebtFilters:
						watchlistData.prospects && watchlistData.prospects.length > 0
							? this.generateNumericFilters(
									watchlistData.prospects.map((p) => p.totalDebt),
							  )
							: [],
					currentNumber: watchlistData.companyTimeline.numberOfCompanies,
					added: watchlistData.companyTimeline.companiesAdded,
					removed: watchlistData.companyTimeline.companiesRemoved,
					pursuitCount:
						watchlistData.companyTimeline.numberOfCompaniesInPursuit,
					monitorCount:
						watchlistData.companyTimeline.numberOfCompaniesInMonitor,
					isLoading: false,
				});
			} else {
				throw new Error('Incorrect watchlist ID');
			}
		} catch (error) {
			history.push('/watchlists');
		}
	};

	handleClearFilters = () => {
		this.setState(
			{
				from: undefined,
				to: undefined,
				statusFilter: ['monitor', 'pursuit'],
			},
			() => {
				this.handleFilterReportCompanies();
			},
		);
	};

	componentDidUpdate(prevProps: IWatchlistProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.setState({ isLoading: true });
			this.loadWatchlistsData();
		}
	}

	handleSelectAllReportCompanies = () => {
		this.setState((prevState) => {
			return {
				isAllSelected: !prevState.isAllSelected,
				reportCompanies: !prevState.isAllSelected
					? prevState.reportCompanies.map((c) => ({
							...c,
							isSelected: true,
							comments: c.comments.map((c) => ({ ...c, isSelected: true })),
					  }))
					: prevState.reportCompanies.map((c) => ({
							...c,
							isSelected: false,
							comments: c.comments.map((c) => ({ ...c, isSelected: false })),
					  })),
			};
		});
	};

	handleReportCompanySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = e.currentTarget;
		this.setState((prevState) => {
			return {
				isAllSelected: false,
				reportCompanies: prevState.reportCompanies.map((c) => {
					return c.companyId === parseInt(name)
						? {
								...c,
								comments: c.isSelected
									? c.comments.map((c) => {
											return { ...c, isSelected: false };
									  })
									: c.comments.map((c) => {
											return { ...c, isSelected: true };
									  }),
								isSelected: !c.isSelected,
						  }
						: c;
				}),
			};
		});
	};

	handleReportCompanyCommentSelect = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { name } = e.currentTarget;
		const [commentId, companyId] = name.split('-');

		this.setState(
			(prevState) => {
				return {
					isAllSelected: false,
					reportCompanies: prevState.reportCompanies.map((c) => {
						return c.companyId === parseInt(companyId)
							? {
									...c,
									comments: c.comments.map((comment) => {
										return comment.id === parseInt(commentId)
											? {
													...comment,
													isSelected: !comment.isSelected,
											  }
											: comment;
									}),
							  }
							: c;
					}),
				};
			},
			() => {
				this.setState((prevState) => {
					return {
						reportCompanies: prevState.reportCompanies.map((c) => {
							return c.companyId === parseInt(companyId)
								? {
										...c,
										isSelected: c.isSelected
											? !c.comments.every((c) => !c.isSelected)
											: c.comments.some((c) => c.isSelected),
								  }
								: c;
						}),
					};
				});
			},
		);
	};

	handleFromChange = (from: string) => {
		this.setState({ from }, () => {
			this.handleFilterReportCompanies();
		});
	};

	handleToChange = (to: string, toRef: any) => {
		this.setState({ to }, () => {
			this.showFromMonth(toRef);
			this.handleFilterReportCompanies();
		});
	};

	showFromMonth = (toRef: any) => {
		const { from, to } = this.state;
		if (!from) {
			return;
		}
		if (moment(to).diff(moment(from), 'months') < 2) {
			toRef.getDayPicker().showMonth(from);
		}
	};

	handleLastCommentFromChange = (lastCommentFrom: string) => {
		this.setState({ lastCommentFrom }, () => {
			this.handleApplyFilters();
		});
	};

	handleLastCommentToChange = (lastCommentTo: string, toRef: any) => {
		this.setState({ lastCommentTo }, () => {
			this.showLastCommentFromMonth(toRef);
			this.handleApplyFilters();
		});
	};

	showLastCommentFromMonth = (toRef: any) => {
		const { lastCommentFrom, lastCommentTo } = this.state;
		if (!lastCommentFrom) {
			return;
		}
		if (moment(lastCommentTo).diff(moment(lastCommentFrom), 'months') < 2) {
			toRef.getDayPicker().showMonth(lastCommentFrom);
		}
	};

	handleLastReviewedFromChange = (lastReviewedFrom: string) => {
		this.setState({ lastReviewedFrom }, () => {
			this.handleApplyFilters();
		});
	};

	handleLastReviewedToChange = (lastReviewedTo: string, toRef: any) => {
		this.setState({ lastReviewedTo }, () => {
			this.showLastReviewedFromMonth(toRef);
			this.handleApplyFilters();
		});
	};

	showLastReviewedFromMonth = (toRef: any) => {
		const { lastReviewedFrom, lastReviewedTo } = this.state;
		if (!lastReviewedFrom) {
			return;
		}
		if (moment(lastReviewedTo).diff(moment(lastReviewedFrom), 'months') < 2) {
			toRef.getDayPicker().showMonth(lastReviewedFrom);
		}
	};

	handleFilterReportCompanies = () => {
		const { from, to, statusFilter } = this.state;

		this.setState((prevState) => {
			const newReportCompanies = prevState.reportCompaniesCopy
				.filter((company) =>
					statusFilter.includes(company.status.toLowerCase()),
				)
				.map((c) => {
					return {
						...c,
						comments: c.comments
							.filter((c) =>
								from
									? moment(c.createdOn).isSameOrAfter(moment(from), 'day')
									: true,
							)
							.filter((c) =>
								to
									? moment(c.createdOn).isSameOrBefore(moment(to), 'day')
									: true,
							),
					};
				});

			if (newReportCompanies.length === 0) {
				return null;
			}

			const isCurrentIdIn = newReportCompanies.find(
				(c) => c.companyId === prevState.selectedReportCompanyId,
			);

			return {
				isAllSelected: false,
				reportCompanies: newReportCompanies,
				selectedReportCompanyId: isCurrentIdIn
					? prevState.selectedReportCompanyId
					: newReportCompanies[0].companyId,
			};
		});
	};

	handleStatusFilterChange = (option: IOption | IOption[]) => {
		const { statusFilter } = this.state;
		const status = (option as IOption).value;

		if (statusFilter.includes(status)) {
			this.setState(
				(prevState) => {
					return {
						statusFilter: prevState.statusFilter.filter((s) => s !== status),
					};
				},
				() => {
					this.handleFilterReportCompanies();
				},
			);
		} else {
			this.setState(
				(prevState) => {
					return {
						statusFilter: [...prevState.statusFilter, status],
					};
				},
				() => {
					this.handleFilterReportCompanies();
				},
			);
		}
	};

	handleReportCompanyClick = (companyId: number) => {
		this.setState({ selectedReportCompanyId: companyId });
	};

	handleGenerateReportFile = async () => {
		this.setState({ isGeneratingReport: true });
		const {
			reportCompanies,
			watchlist: { watchlistId },
		} = this.state;

		try {
			const { data } = await generateWatchlistReport({
				watchlistId,
				companies: reportCompanies
					.filter((c) => c.isSelected)
					.map((c) => {
						return {
							companyId: c.companyId,
							comments: c.comments
								.filter((c) => c.isSelected)
								.map((c) => {
									return c.id;
								}),
						};
					}),
			});

			saveAs(
				new Blob([data], {
					type:
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				}),
				`WatchlistReport-${moment().format('DD-MM-YYYY')}.docx`,
			);
			this.setState({ isGeneratingReport: false });
		} catch (error) {
			console.log(error);
			this.setState({ isGeneratingReport: false });
		}
	};

	handleOpenReportModal = () => {
		this.setState({ isLoadingReportData: true });
		this.fetchWatchlistReport();
	};

	handleCloseReportModal = () => {
		this.setState({
			isReportModalOpen: false,
			watchlistReport: {} as IWatchlistReport,
			from: undefined,
			to: undefined,
			statusFilter: ['monitor', 'pursuit'],
		});
	};

	fetchWatchlistReport = async () => {
		const {
			watchlist: { watchlistId },
		} = this.state;

		try {
			const { data } = await getWatchlistReport(watchlistId);
			if (data) {
				const reportCompanies = data.companies.map((c) => ({
					...c,
					isSelected: false,
					comments: c.comments.map((comment) => {
						return { ...comment, isSelected: false };
					}),
				}));

				this.setState({
					watchlistReport: data,
					isLoadingReportData: false,
					isReportModalOpen: true,
					reportCompanies: reportCompanies,
					reportCompaniesCopy: reportCompanies,
					selectedReportCompanyId: reportCompanies[0].companyId,
				});
			}
		} catch (error) {
			console.log(error);
			this.setState({ isLoadingReportData: false, isReportModalOpen: false });
		}
	};

	handleStatusChange = async (statusIndex: number) => {
		const statusList = ['', 'Active', 'ToDo', 'Complete'];
		const { watchlist } = this.state;
		const { watchlistId, description, status } = watchlist;
		const currentStatus = status;

		if (watchlist.status !== statusList[statusIndex]) {
			try {
				this.setState((prevState) => {
					return {
						watchlist: {
							...prevState.watchlist,
							status: statusList[statusIndex],
						},
					};
				});

				await updateWatchlist(
					watchlistId,
					description,
					statusList[statusIndex],
				);
				snack.success({
					message: `Status is updated from '${currentStatus}' to '${statusList[statusIndex]}' for '${watchlist.watchlistName}' watchlist`,
				});
			} catch (error) {
				console.log(error);
				snack.error({
					message: `Error while updating status for '${watchlist.watchlistName}' watchlist`,
				});
				this.setState((prevState) => {
					return {
						watchlist: {
							...prevState.watchlist,
							status: currentStatus,
						},
					};
				});
			}
		}
	};

	handleCloseAddOwnerModal = () => {
		this.setState({ isAddOwnerModalOpen: false });
	};

	handleCloseEditWatchlistModal = () => {
		this.setState((prevState) => {
			return {
				isEditWatchlistModalOpen: false,
				watchlistCopy: prevState.watchlist,
			};
		});
	};

	handleOpenEditWatchlistModal = () => {
		this.setState((prevState) => {
			return {
				isEditWatchlistModalOpen: true,
			};
		});
	};

	handleNameChange = (value: string) => {
		this.setState((prevState) => {
			return {
				watchlistCopy: { ...prevState.watchlistCopy, watchlistName: value },
			};
		});
	};

	handleCommunitiesChange = (options: IOption[]) => {
		this.setState((prevState) => {
			return {
				watchlistCopy: {
					...prevState.watchlistCopy,
					communities: options.map((o) => ({ id: +o.value, name: o.label })),
				},
			};
		});
	};

	handleIndustriesChange = (options: IOption[]) => {
		this.setState((prevState) => {
			return {
				watchlistCopy: {
					...prevState.watchlistCopy,
					industries: options.map((o) => ({ id: +o.value, name: o.label })),
				},
			};
		});
	};

	handleDescriptionChange = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { value } = event.target as HTMLInputElement;
		this.setState((prevState) => {
			return {
				watchlistCopy: { ...prevState.watchlistCopy, description: value },
			};
		});
	};

	handleEditWatchlist = async () => {
		const {
			watchlist,
			watchlistCopy: {
				watchlistId,
				watchlistName,
				description,
				communities,
				industries,
			},
		} = this.state;

		const currentIndustries = watchlist.industries;
		const currentCommunities = watchlist.communities;
		const currentName = watchlist.watchlistName;
		const currentDescription = watchlist.description;

		this.setState({ isUpdatingWatchlist: true });

		try {
			this.setState((prevState) => ({
				watchlist: {
					...prevState.watchlist,
					watchlistName,
					description,
					communities,
					industries,
				},
			}));

			this.handleCloseEditWatchlistModal();

			await editWatchlist(
				watchlistId,
				watchlistName,
				description,
				communities.map((c) => c.id),
				industries.map((i) => i.id),
			);

			snack.success({
				message: `Watchlist '${currentName}' was successfully updated`,
			});

			this.setState({ isUpdatingWatchlist: false });
		} catch (error) {
			snack.error({
				message: `Error updating '${currentName}'`,
			});
			this.setState((prevState) => ({
				isUpdatingWatchlist: false,
				watchlist: {
					...prevState.watchlist,
					watchlistName: currentName,
					description: currentDescription,
					communities: currentCommunities,
					industries: currentIndustries,
				},
			}));

			this.handleCloseEditWatchlistModal();
		}
	};

	handleCloseAddCompanyModal = () => {
		this.setState({
			isAddCompanyModalOpen: false,
			newCompanyId: 0,
			newCompanyOwnerName: '',
			newCompanyName: '',
		});
	};

	handleAddCompany = async () => {
		const {
			newCompanyOwnerId,
			newCompanyId,
			newCompanyName,
			watchlist: { watchlistId, watchlistName, industries },
		} = this.state;
		this.setState({ isAddingCompany: true, isAddCompanyModalOpen: false });
		try {
			const { data } = await addWatchlistCompany({
				watchlistId: watchlistId,
				companyId: newCompanyId,
				owners: [newCompanyOwnerId],
				status: 'Pursuit',
			});

			const maxIndustryId = Math.max(...industries.map((i) => i.id)) * 10 + 1;

			if (data) {
				this.setState(
					(prevState) => {
						return {
							watchlist: {
								...prevState.watchlist,
								prospects: [...prevState.watchlist.prospects, data],
								industries: prevState.watchlist.industries
									.map((i) => {
										return i.name;
									})
									.includes(data.industry)
									? prevState.watchlist.industries
									: [
											...prevState.watchlist.industries,
											{ name: data.industry, id: maxIndustryId },
									  ],
							},
							filteredProspects: [...prevState.filteredProspects, data],
							currentNumber: prevState.currentNumber + 1,
							added: prevState.added + 1,
							pursuitCount: prevState.pursuitCount + 1,
							newCompanyId: 0,
							newCompanyOwnerName: '',
							newCompanyName: '',
							revenueFilters: this.generateNumericFilters([
								...prevState.watchlist.prospects.map((p) => p.revenue),
								data.revenue,
							]),
							ebitdaFilters: this.generateNumericFilters([
								...prevState.watchlist.prospects.map((p) => p.ebitda),
								data.ebitda,
							]),
							totalDebtFilters: this.generateNumericFilters([
								...prevState.watchlist.prospects.map((p) => p.totalDebt),
								data.totalDebt,
							]),
							isAddingCompany: false,
						};
					},
					() => {
						this.handleApplyFilters();
					},
				);
			}

			snack.success({
				message: `Company '${data.companyName}' is successfully added to '${watchlistName}' watchlist`,
			});
		} catch (error) {
			// TODO: add separate wrapper to parse error message
			snack.error({
				hideAfter: 10,
				message: error.message
					? error.message.replace('Error: ', '').replace(/[[\]'"]+/g, '')
					: `Error while adding company '${newCompanyName}' to '${watchlistName}' watchlist`,
			});
			this.setState((prevState) => {
				return {
					watchlist: {
						...prevState.watchlist,
						prospects: prevState.watchlist.prospects.filter(
							(p) => p.companyId !== newCompanyId,
						),
					},
					newCompanyId: 0,
					newCompanyOwnerName: '',
					newCompanyName: '',
					isAddingCompany: false,
				};
			});
		}
	};

	handleCloseDeleteModal = () => {
		this.setState({
			isDeleteModalOpen: false,
			currentCompany: {} as IWatchlistProspectMeta,
		});
	};

	handleOpenDeleteModal = (data: any) => {
		// outside the scope
		const currentCompany = data.data;
		this.setState({
			isDeleteModalOpen: true,
			currentCompany,
		});
	};

	handleDeleteCompany = async () => {
		const {
			watchlist: { watchlistId, watchlistName, prospects },
			currentCompany,
		} = this.state;

		const currentCompanyIndex = prospects.findIndex(
			(p) => p.companyId === currentCompany.companyId,
		);

		this.setState(
			(prevState) => {
				return {
					currentNumber: prevState.currentNumber - 1,
					monitorCount:
						currentCompany.status === 'Monitor'
							? prevState.monitorCount - 1
							: prevState.monitorCount,
					removed: prevState.removed + 1,
					isDeleteModalOpen: false,
					watchlist: {
						...prevState.watchlist,
						prospects: prevState.watchlist.prospects.filter(
							(p) => p.companyId !== currentCompany.companyId,
						),
					},
				};
			},
			() => {
				this.handleApplyFilters();
			},
		);

		try {
			await removeWatchlistCompany(watchlistId, currentCompany.companyId);

			snack.success({
				message: `Company '${currentCompany.companyName}' was removed from '${watchlistName}' watchlist`,
			});
		} catch (error) {
			if (error.message) {
				snack.error({
					message: error.message.replace('"]', '').replace('Error: ["', ''),
				});
			} else {
				snack.error({
					message: `Error while removing company '${currentCompany.companyName}' from '${watchlistName}' watchlist`,
				});
			}

			this.setState(
				(prevState) => {
					return {
						currentNumber: prevState.currentNumber + 1,
						removed: prevState.removed - 1,
						isDeleteModalOpen: false,
						watchlist: {
							...prevState.watchlist,
							prospects: insert(
								prevState.watchlist.prospects,
								currentCompanyIndex,
								currentCompany,
							),
						},
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
		}
	};

	handleAddOwner = async (option: any) => {
		const {
			watchlist: { watchlistId, watchlistName },
		} = this.state;
		const { users } = this.props;

		const user = users.find((u) => u.id === option.value);

		if (user) {
			const { firstName, lastName, email, id } = user;

			this.setState((prevState) => {
				return {
					isAddOwnerModalOpen: false,
					watchlist: {
						...prevState.watchlist,
						subscribers: [
							...prevState.watchlist.subscribers,
							{ userId: id, email, firstName, lastName },
						],
					},
				};
			});

			try {
				await addWatchlistOwner(watchlistId, user.id);
				snack.success({
					message: `User ${firstName} ${lastName} is added to '${watchlistName}' watchlist`,
				});
			} catch (error) {
				snack.error({
					message: `Error while adding user ${firstName} ${lastName} to '${watchlistName}' watchlist`,
				});
				this.setState((prevState) => {
					return {
						watchlist: {
							...prevState.watchlist,
							subscribers: [
								...prevState.watchlist.subscribers.filter(
									(s) => s.userId !== id,
								),
							],
						},
					};
				});
				console.log(error);
			}
		}
	};

	handleRemoveOwner = async (user: IPerson) => {
		const {
			watchlist: { watchlistId, watchlistName },
		} = this.state;
		const { userId, firstName, lastName } = user;

		this.setState((prevState) => {
			return {
				watchlist: {
					...prevState.watchlist,
					subscribers: [
						...prevState.watchlist.subscribers.filter(
							(s) => s.userId !== userId,
						),
					],
				},
			};
		});

		try {
			await deleteWatchlistOwner(watchlistId, userId);

			snack.success({
				message: `User ${firstName} ${lastName} is successfully removed from '${watchlistName}' watchlist`,
			});
		} catch (error) {
			snack.error({
				message: `Error while removiong user ${firstName} ${lastName} to '${watchlistName}' watchlist`,
			});
			this.setState((prevState) => {
				return {
					watchlist: {
						...prevState.watchlist,
						subscribers: [...prevState.watchlist.subscribers, { ...user }],
					},
				};
			});
		}
	};

	handleCompanySelect = (option: any) => {
		this.setState((prevState) => {
			return {
				newCompanyId: option.value,
				newCompanyName: option.label,
			};
		});
	};

	handleCompanyOwnerSelect = (option: any) => {
		if (!option) {
			this.setState((prevState) => {
				return {
					newCompanyOwnerId: '',
					newCompanyOwnerName: '',
				};
			});

			return;
		}

		this.setState((prevState) => {
			return {
				newCompanyOwnerId: option.value,
				newCompanyOwnerName: option.label,
			};
		});
	};

	generateNumericFilters = (values: number[]): IOption[] => {
		const GROUPS = 5;
		const filteredValues = values.filter((v) => v !== 0);
		const groupsCount =
			filteredValues.length < GROUPS ? filteredValues.length : GROUPS;

		const rounded = (v: number) => Math.ceil(v / 10) * 10;
		// const rounded = (v: number) => Math.round(v / 1000) * 1000;
		const [group1, group2, group3, group4, group5] = splitToGroups(
			filteredValues.filter((v) => v !== 0),
			groupsCount,
		);

		const filters = [];

		if (group1) {
			filters.push({
				label: `Under ${numberWithCommas(rounded(group1[group1.length - 1]))}M`,
				value: `${rounded(group1[group1.length - 1])}`,
			});
		}

		if (group2) {
			const group2Start = rounded(group1[group1.length - 1]);
			const group2End = rounded(group2[group2.length - 1]);

			filters.push({
				label: `${numberWithCommas(group2Start)}M - ${numberWithCommas(
					group2End,
				)}M`,
				value: `${group2Start}-${group2End}`,
			});
		}

		if (group3) {
			const group3Start = rounded(group2[group2.length - 1]);
			const group3End = rounded(group3[group3.length - 1]);

			filters.push({
				label: `${numberWithCommas(group3Start)}M - ${numberWithCommas(
					group3End,
				)}M`,
				value: `${group3Start}-${group3End}`,
			});
		}

		if (group4) {
			const group4Start = rounded(group3[group3.length - 1]);
			const group4End = rounded(group4[group4.length - 1]);

			filters.push({
				label: `${numberWithCommas(group4Start)}M - ${numberWithCommas(
					group4End,
				)}M`,
				value: `${group4Start}-${group4End}`,
			});
		}

		if (group5) {
			const group5Start = rounded(group4[group4.length - 1]);
			const group5End = rounded(group5[group5.length - 1]);

			filters.push({
				label: `${numberWithCommas(group5Start)}M - ${numberWithCommas(
					group5End,
				)}M`,
				value: `${group5Start}-${group5End}`,
			});
		}

		return filters;
	};

	generateCurrentFilters = () => {
		const {
			watchlist: { prospects },
		} = this.state;

		const prospectsData = prospects.reduce(
			(
				acc: {
					currentIndustryFilters: IOption[];
					currentStatusFilters: IOption[];
					currentOwnerFilters: IOption[];
				},
				next,
			) => {
				if (
					!acc.currentIndustryFilters
						.map((o) => o.value)
						.includes(next.industry)
				) {
					acc.currentIndustryFilters.push({
						label: next.industry,
						value: next.industry,
					});
				}
				if (
					!acc.currentStatusFilters.map((o) => o.value).includes(next.status)
				) {
					acc.currentStatusFilters.push({
						label: next.status,
						value: next.status,
					});
				}

				const currentValues = acc.currentOwnerFilters.map((o) => o.value);
				next.owners.split(', ').forEach((owner) => {
					if (!currentValues.includes(owner)) {
						acc.currentOwnerFilters.push({
							label: owner,
							value: owner,
						});
					}
				});

				return acc;
			},
			{
				currentIndustryFilters: [],
				currentStatusFilters: [],
				currentOwnerFilters: [],
			},
		);

		return {
			currentIndustryFilters: prospectsData.currentIndustryFilters,
			currentStatusFilters: prospectsData.currentStatusFilters,
			currentOwnerFilters: prospectsData.currentOwnerFilters,
		};
	};

	handleApplyFilters = () => {
		const {
			watchlist: { prospects },
			selectedIndustryFilters,
			selectedStatusFilters,
			selectedRevenueFilters,
			selectedEbitdaFilters,
			selectedTotalDebtFilters,
			selectedOwnerFilters,
			lastCommentFrom,
			lastCommentTo,
			lastReviewedFrom,
			lastReviewedTo,
		} = this.state;

		let filteredProspects = [...prospects];

		if (selectedIndustryFilters.length > 0) {
			filteredProspects = filteredProspects.filter((p) =>
				selectedIndustryFilters.map((o) => o.value).includes(p.industry),
			);
		}

		if (selectedStatusFilters.length > 0) {
			filteredProspects = filteredProspects.filter((p) =>
				selectedStatusFilters.map((o) => o.value).includes(p.status),
			);
		}

		if (selectedOwnerFilters.length > 0) {
			const prospectsResult: IWatchlistProspectMeta[] = [];

			selectedOwnerFilters.forEach(({ value }) => {
				prospectsResult.push(
					...filteredProspects.filter((p) =>
						p.owners.split(', ').includes(value),
					),
				);
			});

			filteredProspects = prospectsResult;
		}

		if (selectedRevenueFilters.length > 0) {
			const prospectsResult: IWatchlistProspectMeta[] = [];

			selectedRevenueFilters.forEach(({ value }) => {
				const [min, max] = value.split('-').map((v) => parseInt(v));

				prospectsResult.push(
					...filteredProspects.filter((p) =>
						max ? p.revenue >= min && p.revenue <= max : p.revenue <= min,
					),
				);
			});

			filteredProspects = prospectsResult;
		}

		if (selectedEbitdaFilters.length > 0) {
			const prospectsResult: IWatchlistProspectMeta[] = [];

			selectedEbitdaFilters.forEach(({ value }) => {
				const [min, max] = value.split('-').map((v) => parseInt(v));

				prospectsResult.push(
					...filteredProspects.filter((p) =>
						max ? p.ebitda >= min && p.ebitda <= max : p.ebitda <= min,
					),
				);
			});

			filteredProspects = prospectsResult;
		}

		if (selectedTotalDebtFilters.length > 0) {
			const prospectsResult: IWatchlistProspectMeta[] = [];

			selectedTotalDebtFilters.forEach(({ value }) => {
				const [min, max] = value.split('-').map((v) => parseInt(v));

				prospectsResult.push(
					...filteredProspects.filter((p) =>
						max ? p.totalDebt >= min && p.totalDebt <= max : p.totalDebt <= min,
					),
				);
			});

			filteredProspects = prospectsResult;
		}

		if (lastCommentFrom) {
			filteredProspects = filteredProspects.filter((p) => {
				return moment(p.latestCommentCreatedOn).isSameOrAfter(
					moment(lastCommentFrom),
					'day',
				);
			});
		}

		if (lastCommentTo) {
			filteredProspects = filteredProspects.filter((p) => {
				return moment(p.latestCommentCreatedOn).isSameOrBefore(
					moment(lastCommentTo),
					'day',
				);
			});
		}

		if (lastReviewedFrom) {
			filteredProspects = filteredProspects.filter((p) => {
				return moment(p.lastCommentReviewedOn).isSameOrAfter(
					moment(lastReviewedFrom),
					'day',
				);
			});
		}

		if (lastReviewedTo) {
			filteredProspects = filteredProspects.filter((p) => {
				return moment(p.lastCommentReviewedOn).isSameOrBefore(
					moment(lastReviewedTo),
					'day',
				);
			});
		}

		this.setState({
			filteredProspects,
		});
	};

	handleSelectIndustryFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedIndustryFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectOwnerFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedOwnerFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectStatusFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedStatusFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectRevenueFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedRevenueFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectEbitdaFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedEbitdaFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	handleSelectTotalDebtFilters = (options: IOption | IOption[]) => {
		this.setState(
			() => ({
				selectedTotalDebtFilters: options as IOption[],
			}),
			this.handleApplyFilters,
		);
	};

	render() {
		const {
			currentUserId,
			users,
			isUserListLoading,
			isSuperAdmin,
		} = this.props;
		const {
			watchlist: {
				watchlistName,
				subscribers,
				description,
				prospects,
				createdOn,
				status,
				communities,
				industries,
			},
			industriesOptions,
			watchlistCopy,
			isLoading,
			isLoadingReportData,
			isEditWatchlistModalOpen,
			isAddOwnerModalOpen,
			isAddCompanyModalOpen,
			isDeleteModalOpen,
			isReportModalOpen,
			currentNumber,
			added,
			removed,
			pursuitCount,
			monitorCount,
			newCompanyId,
			from,
			to,
			statusFilter,

			filteredProspects,
			selectedIndustryFilters,
			selectedStatusFilters,
			selectedRevenueFilters,
			selectedEbitdaFilters,
			selectedTotalDebtFilters,
			selectedOwnerFilters,

			revenueFilters,
			ebitdaFilters,
			totalDebtFilters,
			lastCommentFrom,
			lastCommentTo,
			lastReviewedFrom,
			lastReviewedTo,
			isFilterTabOpen,
		} = this.state;

		const isOwner = subscribers
			? subscribers.map((s) => s.userId).includes(currentUserId)
			: false;

		const isEditable = isOwner || isSuperAdmin;
		const isNewCompanyConfigured = newCompanyId !== 0;
		const isWatchlistConfigured = watchlistCopy.watchlistName;

		let industryFilters = [] as IOption[];
		let statusFilters = [] as IOption[];
		let ownerFilter = [] as IOption[];

		if (!isLoading) {
			const {
				currentIndustryFilters,
				currentStatusFilters,
				currentOwnerFilters,
			} = this.generateCurrentFilters();

			industryFilters = currentIndustryFilters;
			statusFilters = currentStatusFilters;
			ownerFilter = currentOwnerFilters;
		}

		return (
			<ScreenClassProvider>
				<div className="secondary-nav">
					<div className="wrapper">
						<div className="max-width">
							<div className="watchlist-nav">Watchlists</div>
						</div>
					</div>
				</div>
				{isLoading || isUserListLoading ? (
					<>
						<br />
						<br />
						<br />
						<br />
						<ApLoaderDefault />
					</>
				) : (
					<div className="container">
						<div className="wrapper">
							<div className="max-width">
								<div className="watchlist">
									<div className="watchlist__title">
										<div>
											<span
												className="watchlist__title__link"
												onClick={() => {
													this.props.history.push('/watchlists');
												}}
											>
												Dashboard
											</span>
											<span> / </span>
											{/* {isEditable ? (
												<>
													<span
														className="watchlist__title__link"
														onClick={() => {
															this.props.history.push('/watchlists');
														}}
													>
														Assigned to me
													</span>
													<span> / </span>
												</>
											) : (
												<>
													<span
														className="watchlist__title__link"
														onClick={() => {
															this.props.history.push('/watchlists');
														}}
													>
														Other watchlists
													</span>
													<span> / </span>
												</>
											)} */}
											<span className="watchlist__name">{watchlistName}</span>
										</div>
										<div>
											<ApButtonGroup>
												<ApButtonSecondary
													iconName="outline_edit"
													iconProps={{
														iconColor: '#333333',
													}}
													left={() => status.toUpperCase()}
													onClick={this.handleOpenEditWatchlistModal}
													disabled={!isEditable}
												>
													Edit Watchlist
												</ApButtonSecondary>
												<ApButtonMain
													style={{ width: '168px' }}
													iconName="outline_save_alt"
													iconProps={{
														iconColor: '#333333',
													}}
													disabled={
														isLoadingReportData || prospects.length === 0
													}
													onClick={this.handleOpenReportModal}
												>
													{isLoadingReportData
														? 'Loading...'
														: 'Generate Report'}
												</ApButtonMain>
											</ApButtonGroup>
										</div>
									</div>
									<div className="watchlist__wrapper">
										<div className="watchlist__data">
											<div className="watchlist__data__title">
												{watchlistName}
											</div>
											<div className="watchlist__data__description">
												<ReadMoreAndLess
													charLimit={430}
													readMoreText=" Read more"
													readLessText=" Read less"
												>
													{description}
												</ReadMoreAndLess>
												<div className="watchlist__data__wrapper">
													<div className="watchlist__metric">
														<div className="watchlist__h2">Creation date</div>
														<div className="watchlist__metric__value">
															{moment(createdOn).format('Do MMMM YYYY')}
														</div>
													</div>
													<div className="watchlist__metric">
														<div className="watchlist__h2">Communities</div>
														<div className="watchlist__metric__value">
															{communities.map((c) => c.name).join(', ')}
														</div>
													</div>
													<div className="watchlist__metric">
														<div className="watchlist__h2">Industries</div>
														<div className="watchlist__metric__value">
															{industries.map((i) => i.name).join(', ')}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="watchlist__chart">
											<CompanyCountWidget
												numberOfCompanies={currentNumber}
												companiesRemoved={removed}
												companiesAdded={added}
												totalPursuitCount={pursuitCount}
												totalMonitorCount={monitorCount}
											/>
										</div>
									</div>
									<div className="watchlist__line" />
									<div className="watchlist__heading-wrapper">
										<div className="watchlist__h1">Watchlist owners</div>
										<ApButtonSecondary
											onClick={() => {
												this.setState({ isAddOwnerModalOpen: true });
											}}
											disabled={!isEditable}
										>
											<div className="button-wrapper">
												<ApIcon
													iconName="baseline_add"
													iconColor="#333333"
												></ApIcon>
												<div>Add Person</div>
											</div>
										</ApButtonSecondary>
									</div>
									<div>
										{subscribers.map((s) => (
											<ApChip
												onClick={() =>
													subscribers.length > 1 && isEditable
														? this.handleRemoveOwner(s)
														: null
												}
												className={`watchlist__owner${
													subscribers.length === 1 ? ' disabled' : ''
												}`}
												key={s.userId}
												iconName={
													subscribers.length > 1 && isEditable
														? 'outline_cancel'
														: undefined
												}
											>
												<div className="watchlist__owner__avatar">
													<img
														src={avatar}
														alt={`${s.firstName} ${s.lastName}`}
													/>
												</div>
												{`${s.firstName} ${s.lastName}`}
											</ApChip>
										))}
									</div>
								</div>
								<div className="watchlist">
									<div className="watchlist__heading-wrapper">
										<div className="watchlist__h1">Prospecting list</div>
										<div>
											{/* <ApButtonSecondary
												disabled={prospects.length < 2}
												className="button-filter"
												onClick={() => {
													this.setState((prevState) => {
														return {
															isFilterTabOpen: !prevState.isFilterTabOpen,
														};
													});
												}}
											>
												<div className="button-wrapper">
													<ApIcon
														iconName="outline_filter_list"
														// iconColor={!isEditable ? 'red' : '#737373'}
													></ApIcon>
													<div> Filters</div>
												</div>
											</ApButtonSecondary> */}
											<ApButtonSecondary
												disabled={!isEditable}
												onClick={() => {
													this.setState({ isAddCompanyModalOpen: true });
												}}
											>
												<div className="button-wrapper">
													<ApIcon
														iconName="baseline_add"
														iconColor="#333333"
													></ApIcon>
													<div>Add Company</div>
												</div>
											</ApButtonSecondary>
										</div>
									</div>
									<div
										className={`watchlist__filters${
											isFilterTabOpen ? ' watchlist__filters--open' : ''
										}`}
									>
										<div className="watchlist__filters__date">
											<div
												className="watchlist__h2"
												style={{ marginRight: '16px' }}
											>
												Last time comment reviewed date:
											</div>
											<div>
												<DateRangeFilter
													to={lastReviewedTo}
													from={lastReviewedFrom}
													onFromChange={this.handleLastReviewedFromChange}
													onToChange={this.handleLastReviewedToChange}
												/>
											</div>
										</div>
										<div className="watchlist__filters__date">
											<div
												className="watchlist__h2"
												style={{ marginRight: '16px' }}
											>
												Last comment created date:
											</div>
											<div>
												<DateRangeFilter
													to={lastCommentTo}
													from={lastCommentFrom}
													onFromChange={this.handleLastCommentFromChange}
													onToChange={this.handleLastCommentToChange}
												/>
											</div>
										</div>
										<div className="watchlist__filters__generic">
											<ApSimpleDropdown
												multiple
												disabled={prospects.length <= 1}
												label={
													selectedOwnerFilters.length
														? `${selectedOwnerFilters.length} owner(s)`
														: 'All owners'
												}
												onChange={this.handleSelectOwnerFilters}
												options={ownerFilter}
												selected={selectedOwnerFilters}
											/>
											<ApSimpleDropdown
												multiple
												disabled={prospects.length <= 1}
												label={
													selectedIndustryFilters.length
														? `${selectedIndustryFilters.length} industry(ies)`
														: 'All industries'
												}
												onChange={this.handleSelectIndustryFilters}
												options={industryFilters}
												selected={selectedIndustryFilters}
											/>
											<ApSimpleDropdown
												multiple
												disabled={
													prospects.length <= 1 || revenueFilters.length === 0
												}
												label={
													selectedRevenueFilters.length
														? `${selectedRevenueFilters.length} revenue(s)`
														: 'All revenues'
												}
												onChange={this.handleSelectRevenueFilters}
												options={revenueFilters}
												selected={selectedRevenueFilters}
											/>
											<ApSimpleDropdown
												multiple
												disabled={
													prospects.length <= 1 || ebitdaFilters.length === 0
												}
												label={
													selectedEbitdaFilters.length
														? `${selectedEbitdaFilters.length} ebitda`
														: 'All ebitda'
												}
												onChange={this.handleSelectEbitdaFilters}
												options={ebitdaFilters}
												selected={selectedEbitdaFilters}
											/>
											<ApSimpleDropdown
												multiple
												disabled={
													prospects.length <= 1 || totalDebtFilters.length === 0
												}
												label={
													selectedTotalDebtFilters.length
														? `${selectedTotalDebtFilters.length} total debt(s)`
														: 'All total debt'
												}
												onChange={this.handleSelectTotalDebtFilters}
												options={totalDebtFilters}
												selected={selectedTotalDebtFilters}
											/>
											<ApSimpleDropdown
												multiple
												disabled={prospects.length <= 1}
												label={
													selectedStatusFilters.length
														? `${selectedStatusFilters.length} status(es)`
														: 'All statuses'
												}
												onChange={this.handleSelectStatusFilters}
												options={statusFilters}
												selected={selectedStatusFilters}
											/>
											<ApButtonMain
												onClick={() => {
													this.setState({
														filteredProspects: prospects,
														selectedIndustryFilters: [],
														selectedStatusFilters: [],
														selectedRevenueFilters: [],
														selectedEbitdaFilters: [],
														selectedTotalDebtFilters: [],
														selectedOwnerFilters: [],
														lastCommentFrom: undefined,
														lastCommentTo: undefined,
														lastReviewedFrom: undefined,
														lastReviewedTo: undefined,
													});
												}}
											>
												Clear
											</ApButtonMain>
										</div>
										<div className="watchlist__line" />
									</div>
									<WatchlistTable
										data={filteredProspects}
										openDialog={this.handleOpenDeleteModal}
									/>
								</div>
							</div>
						</div>
						<ApModal
							isDismissible
							className="watchlist__owners-modal"
							isOpen={isAddOwnerModalOpen}
							hasClosed={this.handleCloseAddOwnerModal}
							header="Assign User to a Watchlist"
						>
							<div className="modal__content">
								<MultiSelect
									isSingle
									isClearable
									options={users
										.filter(
											(u) => !subscribers.map((s) => s.userId).includes(u.id),
										)
										.map((u) => ({
											label: `${u.firstName} ${u.lastName}`,
											value: u.id,
										}))}
									onChange={this.handleAddOwner}
								/>
							</div>
						</ApModal>
						<ApModal
							isDismissible
							className="add-company"
							isOpen={isAddCompanyModalOpen}
							hasClosed={this.handleCloseAddCompanyModal}
							header="Add Company to a Watchlist"
							footer={() => (
								<div className="modal__footer">
									<ApButtonSecondary onClick={this.handleCloseAddCompanyModal}>
										Cancel
									</ApButtonSecondary>
									<ApButtonMain
										disabled={!isNewCompanyConfigured}
										onClick={this.handleAddCompany}
									>
										Add Company
									</ApButtonMain>
								</div>
							)}
						>
							<div className="modal__content">
								<div className="modal__label">Company name</div>
								<CompanySelect
									onChange={this.handleCompanySelect}
									exclude={prospects.map((p) => p.companyId)}
								/>
								<br />
								<div className="modal__label">Owner</div>
								<MultiSelect
									disabled
									value={[
										{
											label: this.props.currentUserFullName,
											value: currentUserId,
										},
									]}
									isSingle
									isClearable
									options={users.map((u) => ({
										label: `${u.firstName} ${u.lastName}`,
										value: u.id,
									}))}
									onChange={this.handleCompanyOwnerSelect}
								/>
							</div>
						</ApModal>
						<ApModal
							isDismissible
							className="edit-watchlist"
							isOpen={isEditWatchlistModalOpen}
							hasClosed={this.handleCloseEditWatchlistModal}
							header="Edit watchlist"
							footer={() => (
								<div className="modal__footer">
									<ApButtonSecondary
										onClick={this.handleCloseEditWatchlistModal}
									>
										Cancel
									</ApButtonSecondary>
									<ApButtonMain
										disabled={!isWatchlistConfigured}
										onClick={this.handleEditWatchlist}
									>
										Save
									</ApButtonMain>
								</div>
							)}
						>
							<div className="modal__content">
								<div className="modal__label">Watchlist name</div>
								<ApInput
									name="watchlist-name"
									onChange={this.handleNameChange}
									value={watchlistCopy.watchlistName}
								/>
								<br />
								<div className="modal__label">Communities</div>
								<MultiSelect
									options={COMMUNITIES_OPTIONS}
									value={watchlistCopy.communities.map((c) => ({
										value: c.id + '',
										label: c.name,
									}))}
									onChange={this.handleCommunitiesChange}
								/>
								<br />
								<div className="modal__label">Industries</div>
								<MultiSelect
									options={industriesOptions}
									value={watchlistCopy.industries.map((i) => ({
										value: i.id + '',
										label: i.name,
									}))}
									onChange={this.handleIndustriesChange}
								/>
								<br />
								<div className="modal__label">
									<div>Watchlist description</div>
									<div>{`${watchlistCopy.description.length}/150`}</div>
								</div>
								<textarea
									maxLength={150}
									name="notes"
									rows={5}
									className="modal__description"
									value={watchlistCopy.description}
									onChange={this.handleDescriptionChange}
								/>
							</div>
						</ApModal>
						<ApModal
							className="delete-company"
							isOpen={isDeleteModalOpen}
							hasClosed={this.handleCloseDeleteModal}
							footer={() => (
								<div className="modal__footer">
									<ApButtonSecondary onClick={this.handleCloseDeleteModal}>
										Cancel
									</ApButtonSecondary>
									<ApButtonMain
										onClick={() => {
											this.handleDeleteCompany();
										}}
									>
										Delete
									</ApButtonMain>
								</div>
							)}
						>
							<div className="modal__content">{`Are you sure you want to remove ${this.state.currentCompany.companyName} company from watchlist?`}</div>
						</ApModal>
						<ApModal
							isDismissible
							className="watchlist-report"
							isOpen={isReportModalOpen}
							header={
								<>
									<div>Report generator</div>
									<div className="watchlist-report__heading">
										Select companies and comments to include in the report
									</div>
									<div className="watchlist-report__note">
										Companies with 'Drop' status will be omitted from the report
									</div>
									<div className="watchlist-report__filters">
										<div className="watchlist-report__select-all">
											<ApCheckbox
												id="storybook-checkbox"
												theme="light"
												type="checkbox"
												checked={this.state.isAllSelected}
												onChange={this.handleSelectAllReportCompanies}
											>
												Select All
											</ApCheckbox>
										</div>
										<DateRangeFilter
											to={to}
											from={from}
											onFromChange={this.handleFromChange}
											onToChange={this.handleToChange}
										/>
										<div className="watchlist-report__select-type">
											<ApSimpleDropdown
												label="Status"
												onChange={this.handleStatusFilterChange}
												options={[
													{
														label: 'Monitor',
														value: 'monitor',
														disabled:
															(statusFilter[0] === 'monitor' &&
																statusFilter.length === 1) ||
															this.state.reportCompanies.filter(
																(c) => c.status.toLowerCase() === 'pursuit',
															).length === 0,
													},
													{
														label: 'Pursuit',
														value: 'pursuit',
														disabled:
															(statusFilter[0] === 'pursuit' &&
																statusFilter.length === 1) ||
															this.state.reportCompanies.filter(
																(c) => c.status.toLowerCase() === 'monitor',
															).length === 0,
													},
												]}
												selected={statusFilter.map((s) => ({
													label: s,
													value: s,
												}))}
											/>
										</div>
										<div>
											<ApButtonSecondary
												onClick={this.handleClearFilters}
												disabled={
													from === undefined &&
													to === undefined &&
													statusFilter.length === 2
												}
											>
												Clear Filters
											</ApButtonSecondary>
										</div>
									</div>
								</>
							}
							hasClosed={this.handleCloseReportModal}
							footer={() => (
								<div className="modal__footer">
									<ApButtonSecondary onClick={this.handleCloseReportModal}>
										Cancel
									</ApButtonSecondary>
									<ApButtonMain
										disabled={
											this.state.isGeneratingReport ||
											this.state.reportCompanies.filter((c) => c.isSelected)
												.length === 0
										}
										onClick={this.handleGenerateReportFile}
									>
										Generate file
									</ApButtonMain>
								</div>
							)}
						>
							<WatchlistReport
								companies={this.state.reportCompanies}
								selectedCompanyId={this.state.selectedReportCompanyId}
								onCompanyClick={this.handleReportCompanyClick}
								onCommentSelect={this.handleReportCompanyCommentSelect}
								onCompanySelect={this.handleReportCompanySelect}
							/>
						</ApModal>
					</div>
				)}
			</ScreenClassProvider>
		);
	}
}

export const Watchlist = withRouter(WatchlistComponent);
