import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas, roundOneDecimal } from '../../../utils';

import './DebtWidget.scss';

interface IDebtWidgetProps {
	data: any[];
	currency: string;
}

export const DebtWidget: React.FC<IDebtWidgetProps> = ({ data, currency }) => {
	const chartOptions: Highcharts.Options = {
		chart: {
			height: 240,
			marginLeft: 0,
			marginRight: 0,
			marginTop: 0,
			// margin: 0,
			// width: 512,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: [
			{
				visible: false,
			},

			{
				// Secondary yAxis
				title: {
					text: 'Rainfall',
					style: {
						color: 'red',
					},
				},
				labels: {
					format: '{value} mm',
					style: {
						color: 'red',
					},
				},
				opposite: true,
			},
		],

		legend: {
			enabled: true,
			itemStyle: {
				color: '#1A1A1A',
				fontFamily: 'Roboto',
				fontSize: '13px',
				// lineHeight: '24px',
				fontWeight: 'normal',
			},
		},
		xAxis: {
			labels: {
				useHTML: true,
				style: {
					color: '#737373',
					fontFamily: 'Roboto',
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			lineColor: '#CCCCCC',
			crosshair: {
				color: '#CCCCCC',
				width: 1,
			},
			// tickLength: 0,
			// gridLineWidth: 0,
			// tickInterval: 1,
		},
		tooltip: {
			outside: true,
			shared: true,
			backgroundColor: undefined,
			borderWidth: 0,
			useHTML: true,
			formatter: function () {
				const addTooltipSeries = (
					label: string,
					value: number,
					color:
						| string
						| Highcharts.GradientColorObject
						| Highcharts.PatternObject,
					currentRevenue: number | null = null,
				): string => {
					return `<span class="custom-tooltip__point" style="color:${color}">\u25CF</span> ${label}: <span class="custom-tooltip__yvalue">${
						label === 'Net Debt/EBITDA' ? '' : getSymbolFromCurrency(currency)
					}${numberWithCommas(roundOneDecimal(value))}${
						label === 'Net Debt/EBITDA' ? 'X' : 'M'
					}</span><br/>`;
				};

				let tooltipMarkup = '<div class="custom-tooltip">';

				const totalDebt = this.points?.find(
					(p) => p.series.name === 'Total Debt',
				);
				const netDebt = this.points?.find((p) => p.series.name === 'Net Debt');
				const netDebtOverEbitda = this.points?.find(
					(p) => p.series.name === 'Net Debt/EBITDA',
				);

				if (totalDebt) {
					tooltipMarkup += addTooltipSeries(
						'Total Debt',
						totalDebt.y,
						'#022E45',
					);
				}
				if (netDebt) {
					tooltipMarkup += addTooltipSeries('Net Debt', netDebt.y, '#0896A6');
				}
				if (netDebtOverEbitda) {
					tooltipMarkup += addTooltipSeries(
						'Net Debt/EBITDA',
						netDebtOverEbitda.y,
						'#5CB335',
					);
				}

				return tooltipMarkup + '</div>';
			},
		},
		plotOptions: {
			column: {
				// pointWidth: 62,
				// groupPadding: 0,
				pointPadding: 0,
				borderWidth: 0,
			},
			series: {
				events: {
					legendItemClick: function (e) {
						e.preventDefault();
					},
				},
				marker: {
					symbol: 'square',
				},
				// color: '#0696A6',
				dataLabels: {
					// useHTML: true,
					allowOverlap: true,
					// inside: true,
					// verticalAlign: 'top',
					// crop: false,
					// overflow: 'none',
					// y: -5,
					enabled: false,
					formatter: function () {
						if (this.y) {
							return `${getSymbolFromCurrency(currency)}${numberWithCommas(
								this.y,
							)}`;
						}
					},
					style: {
						color: '#1A1A1A',
						fontFamily: 'Roboto',
						fontSize: '16px',
						lineHeight: '24px',
						fontWeight: 'normal',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};

	const options: Highcharts.Options = {
		...chartOptions,
		xAxis: {
			...chartOptions.xAxis,
			categories: data.map((d) => d.fiscalYear.toString()),
		},
		series: [
			{
				data: data.map((d) => d.totalDebt),
				type: 'column',
				color: '#022E45',
				name: 'Total Debt',
			},
			{
				data: data.map((d) => d.netDebt),
				type: 'column',
				color: '#0896A6',
				name: 'Net Debt',
			},
			{
				data: data.map((d) => d.netDebtOverEBITDA),
				type: 'line',
				yAxis: 1,
				color: '#5CB335',
				name: 'Net Debt/EBITDA',
			},
		],
	};

	return (
		<div className="debt-widget">
			<HighchartsReact highcharts={Highcharts} options={options} />
			<p className="debt-widget__note">Please hover over graph for values</p>
		</div>
	);
};
