import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
	ApSimpleTable,
	ApSimpleTableBody,
	ApSimpleTableBodyCell,
	ApSimpleTableHead,
	ApSimpleTableHeadCell,
	ApSimpleTableRow,
} from '@alixpartners/ui-components';

import { IBalanceSheetData } from '../../../types';
import { isEmpty, numberWithCommas, roundOneDecimal } from '../../../utils';

import './BalanceSheetSection.scss';

interface IBalanceSheetSectionProps {
	data: IBalanceSheetData[];
	companyName: string;
	currency: string;
}

export const BalanceSheetSection: React.FC<IBalanceSheetSectionProps> = ({
	data,
	companyName,
	currency,
}) => {
	if (!data || isEmpty(data)) {
		return (
			<div className="balance-sheet-section">
				<div className="balance-sheet-section__empty">No data available</div>
			</div>
		);
	}

	const renderBoldHeaderRow = (
		header: string,
		valueName: string,
		isBold = false,
	) => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					className={`row-${valueName}`}
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
				>
					{isBold ? <strong>{header}</strong> : ` - ${header}`}
				</ApSimpleTableBodyCell>
				{data.map((d) => {
					return (
						<ApSimpleTableBodyCell
							className={`row-${valueName} text-align-right`}
							key={d.fiscalYear}
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							{isBold ? (
								<strong>
									<span>{numberWithCommas(roundOneDecimal(d[valueName]))}</span>
								</strong>
							) : (
								<span>{numberWithCommas(roundOneDecimal(d[valueName]))}</span>
							)}
						</ApSimpleTableBodyCell>
					);
				})}
			</ApSimpleTableRow>
		);
	};

	const renderCurrentLiabilitiesRow = () => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
				>
					<strong>Current Liabilities</strong>
				</ApSimpleTableBodyCell>
				{data.map((d) => {
					return (
						<ApSimpleTableBodyCell
							key={d.fiscalYear}
							className="text-align-right"
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							<strong>
								<span>
									{numberWithCommas(
										roundOneDecimal(
											d.accountPayable +
												d.currentPortionOfLongTermDebt +
												d.otherCurrentLiabilitis,
										),
									)}
								</span>
							</strong>
						</ApSimpleTableBodyCell>
					);
				})}
			</ApSimpleTableRow>
		);
	};

	const renderNonCurrentLiabilitiesRow = () => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
				>
					<strong>Non-current Liabilities</strong>
				</ApSimpleTableBodyCell>
				{data.map((d) => {
					return (
						<ApSimpleTableBodyCell
							key={d.fiscalYear}
							className="text-align-right"
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							<strong>
								<span>
									{numberWithCommas(
										roundOneDecimal(
											d.longTermDebt + d.otherNonCurrentLiabilities,
										),
									)}
								</span>
							</strong>
						</ApSimpleTableBodyCell>
					);
				})}
			</ApSimpleTableRow>
		);
	};

	return (
		<div className="balance-sheet-section">
			<div className="balance-sheet-section__container">
				<ApSimpleTable
					tableBorders={{
						bottom: true,
						left: true,
						right: true,
						top: true,
					}}
					tableHeight="420px"
					tableWidth="100%"
					className="ap-table-with-numeric-column"
				>
					<ApSimpleTableHead>
						<ApSimpleTableRow>
							<ApSimpleTableHeadCell
								cellTextColor="#737373"
								className=" numericColumn text-align-left"
							>
								{`${getSymbolFromCurrency(currency)}M`}
							</ApSimpleTableHeadCell>
							{data.map((d) => {
								return (
									<ApSimpleTableHeadCell
										cellTextColor="#737373"
										key={d.fiscalYear}
										className=" numericColumn"
									>
										{d.fiscalYear}
									</ApSimpleTableHeadCell>
								);
							})}
						</ApSimpleTableRow>
					</ApSimpleTableHead>
					<ApSimpleTableBody>
						{renderBoldHeaderRow(
							'Non-current Assets',
							'nonCurrentAssets',
							true,
						)}
						{renderBoldHeaderRow('Goodwill', 'goodwill')}

						{renderBoldHeaderRow(
							'Net property, plant and equipment',
							'netPropertyPlanAndEquipment',
						)}
						{renderBoldHeaderRow(
							'Other non-current assets',
							'otherNonCurrentAssets',
						)}
						{renderBoldHeaderRow('Current Assets', 'currentAssets', true)}

						{renderBoldHeaderRow(
							'Cash and cash equivalent',
							'cashAndEquivalents',
						)}
						{renderBoldHeaderRow('Inventories', 'inventory')}
						{renderBoldHeaderRow('Accounts receivables', 'accountReceivable')}
						{renderBoldHeaderRow('Other current assets', 'otherCurrentAssets')}
						{renderBoldHeaderRow('Total Assets', 'totalAssets', true)}
						{renderBoldHeaderRow('Total Equity', 'totalEquity', true)}
						{renderNonCurrentLiabilitiesRow()}
						{renderBoldHeaderRow('Long-Tem Debt', 'longTermDebt')}
						{renderBoldHeaderRow(
							'Other non-current liabilities',
							'otherNonCurrentLiabilities',
						)}
						{renderCurrentLiabilitiesRow()}
						{renderBoldHeaderRow('Accounts Payable', 'accountPayable')}
						{renderBoldHeaderRow(
							'Current Portion Of LT Debt',
							'currentPortionOfLongTermDebt',
						)}
						{renderBoldHeaderRow(
							'Other current liabilities',
							'otherCurrentLiabilitis',
						)}
						{renderBoldHeaderRow(
							'Total Liabilities & Equity',
							'totalLiabilitiesAndEquity',
							true,
						)}
					</ApSimpleTableBody>
				</ApSimpleTable>
			</div>
		</div>
	);
};
