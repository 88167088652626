import React, { useState } from 'react';
import { getLogoUrl } from '../../utils';

import './Logo.scss';

interface ILogoProps {
	url: string | undefined;
	sml?: boolean | undefined;
}

export const Logo: React.FC<ILogoProps> = (props) => {
	const { url, sml } = props;
	const [logoError, setLogoError] = useState(false);

	return (
		<>
			{url && !logoError && (
				<div className={`company-logo ${sml ? 'sml' : ''}`}>
					<img
						src={getLogoUrl(url)}
						alt="company logo"
						onError={() => setLogoError(true)}
					></img>
				</div>
			)}
		</>
	);
};
