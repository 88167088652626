import pptxgen from 'pptxgenjs';

import { IPeople } from '../types';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	NO_DATA_CONFIG,
} from './common';

export const appendKeyPeopleSlide = (
	deckInstance: pptxgen,
	companyName: string,
	keyPeople: IPeople[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`Key People`, SLIDE_TITLE_CONFIG_WIDE);
	slide.addText(`MANAGEMENT AND BOD`, SLIDE_TOP_LABEL_WIDE);

	slide.addText('BOARD OF DIRECTORS', {
		h: 0.43,
		w: 6.13,
		x: 0.4,
		y: 1.26,
		fontSize: 14,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText('KEY EXECUTIVES', {
		h: 0.43,
		w: 6.13,
		x: 6.81,
		y: 1.25,
		fontSize: 14,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	const headerConfig = {
		fill: '383838',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRowBoard = [
		{ text: 'BOARD MEMBER', options: headerConfig },
		{ text: 'DESIGNATION', options: headerConfig },
	];

	const headersRowBExec = [
		{ text: 'KEY EXECUTIVE', options: headerConfig },
		{ text: 'DESIGNATION', options: headerConfig },
	];

	slide.addTable(
		[
			headersRowBoard,
			...keyPeople
				.filter((p) => p.isBoardMember)
				.slice(0, 10)
				.sort((a: IPeople, b: IPeople) => {
					const textA = a.role ? a.role.toUpperCase() : '';
					const textB = b.role ? b.role.toUpperCase() : '';
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				})
				.map((data) => {
					return [{ text: data.name }, { text: data.role }];
				}),
		],
		{
			x: 0.4,
			y: 1.65,
			w: 6.13,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [3.1, 3],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);

	slide.addTable(
		[
			headersRowBExec,
			...keyPeople
				.filter((p) => !p.isBoardMember)
				.slice(0, 10)
				.map((data) => {
					return [{ text: data.name }, { text: data.role }];
				}),
		],
		{
			x: 6.81,
			y: 1.65,
			w: 6.13,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [2, 4.1],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);

	if (!keyPeople.filter((p) => p.isBoardMember).length) {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 0.4, y: 2.05 });
	}

	if (!keyPeople.filter((p) => !p.isBoardMember).length) {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 6.81, y: 2.05 });
	}
};
