import pptxgen from 'pptxgenjs';

import { IPeersFinancialWidget } from '../types';

import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	AP_LOGO,
} from './common';

export const appendPeersComparisonSlide = (
	deckInstance: pptxgen,
	// companyName: string,
	// ltm: number,
	companyId: number,
	// competitors: ICompetitor[],
	peersFinancialData: IPeersFinancialWidget[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addShape(deckInstance.ShapeType.rect, {
		x: 9.98,
		y: 0,
		w: 3.35,
		h: 7.5,
		fill: { color: 'ECECEE' },
		line: { type: 'none' },
	});
	slide.addImage({
		data: AP_LOGO,
		x: 10.91,
		y: 7.15,
		w: 1.4,
		h: 0.21,
	});
	slide.addText(`BENCHMARKS`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(`Peer comparison`, SLIDE_TITLE_CONFIG_WIDE);

	const currentCompanyFinancialData = peersFinancialData.find(
		(d) => d.companyId === companyId,
	);

	const {
		companyName,
		year,
	} = currentCompanyFinancialData as IPeersFinancialWidget;

	const peersData: IPeersFinancialWidget[] = [
		currentCompanyFinancialData as IPeersFinancialWidget,
		...peersFinancialData
			.filter((d) => d.companyId !== companyId)
			.sort((a, b) => {
				const textA = a.companyName.toUpperCase();
				const textB = b.companyName.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			}),
	];

	const {
		labels,
		valuesCagr,
		valuesEbitda,
		valuesSga,
		valuesCapex,
		valuesLeverage,
		valuesPerEmployee,
	} = peersData.reduce(
		(acc: any, current: IPeersFinancialWidget) => {
			acc.labels.push(
				current.companyName
					.replace(/[aeiou]/g, '')
					.toUpperCase()
					.substr(0, 3),
			);
			acc.valuesCagr.push(Math.round(current.revenueCAGR * 10) / 10);
			acc.valuesEbitda.push(Math.round(current.ebitdaMargin * 10) / 10);
			acc.valuesSga.push(Math.round(current.sgaMargin * 10) / 10);
			acc.valuesCapex.push(
				Math.round(current.capexPercentageOfRevenue * 10) / 10,
			);
			acc.valuesLeverage.push(Math.round(current.leverage * 10) / 10);
			acc.valuesPerEmployee.push(
				Math.round(current.revenuePerEmployee * 10) / 10,
			);
			return acc;
		},
		{
			labels: [],
			valuesCagr: [],
			valuesEbitda: [],
			valuesSga: [],
			valuesCapex: [],
			valuesLeverage: [],
			valuesPerEmployee: [],
		},
	);

	const addChart = (
		x: number,
		y: number,
		// h: number,
		// w: number,
		values: number[],
		dataLabelFormatCode = '#0.0##\\%;-#0.0##\\%;;',
	) => {
		slide.addChart(
			'bar',
			[
				{
					name: 'Revenue',
					labels,
					values,
				},
			],
			{
				showDataTableHorzBorder: false,
				showDataTableVertBorder: false,
				x,
				y,
				h: 2.4,
				w: 3.32,
				border: { pt: 0, color: 'ffffff' },
				chartColors: [
					'0696A6',
					'032E45',
					'032E45',
					'032E45',
					'032E45',
					'032E45',
				],
				valGridLine: { style: 'none' },
				serAxisHidden: true,
				valAxisHidden: true,
				valAxisLineShow: false,
				serAxisLineShow: false,
				showLeaderLines: false,
				showValue: true,
				catAxisMajorTickMark: 'none',
				catAxisMinorTickMark: 'none',
				// @ts-ignore
				axisLineColor: 'ffffff',
				dataLabelColor: '383838',
				dataLabelFontFace: 'Verdana (Body)',
				dataLabelFontSize: 10,
				dataLabelPosition: 'outEnd',
				dataLabelFormatCode,
				catAxisLabelColor: '383838',
				catAxisLabelFontFace: 'Verdana (Body)',
				catAxisLabelFontSize: 10,
			},
		);
	};

	// Actual charts
	addChart(0.17, 1.67, valuesCagr);
	addChart(3.45, 1.67, valuesEbitda);
	addChart(6.63, 1.67, valuesSga);
	addChart(0.17, 4.56, valuesCapex);
	// addChart(3.45, 4.56, valuesLeverage);
	// addChart(6.63, 4.56, valuesPerEmployee);
	addChart(3.45, 4.56, valuesLeverage, '#0.0##\\x;-#0.0##\\x;;');
	addChart(6.63, 4.56, valuesPerEmployee, '#0.0##;-#0.0##;;');

	slide.addText(
		[
			{
				text: `${companyName
					.replace(/[aeiou]/g, '')
					.toUpperCase()
					.substr(0, 3)}`,
				options: { bold: true },
			},
			{
				text: ` = ${companyName} (FY${year})`,
				options: { breakLine: true },
			},
			...peersData
				.filter((c) => c.companyId !== companyId)
				.map((c) => [
					{
						text: `${c.companyName
							.replace(/[aeiou]/g, '')
							.toUpperCase()
							.substr(0, 3)}`,
						options: { bold: true },
					},
					{
						text: ` = ${c.companyName} (FY${c.year})`,
						options: { breakLine: true },
					},
				])
				.flat(),
		],
		{
			w: 2.7,
			x: 10.22,
			y: 3.14,
			fontSize: 10,
			fontFace: 'Verdana (Body)',
			margin: 0,
			align: 'left',
			valign: 'top',
			paraSpaceAfter: 12,
			color: '383838',
		},
	);

	// Lines

	slide.addShape(deckInstance.ShapeType.line, {
		x: 0.4,
		y: 4.12,
		w: 9.48,
		h: 0,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 3.52,
		y: 1.37,
		w: 0,
		h: 2.65,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 6.7,
		y: 1.37,
		w: 0,
		h: 2.65,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 3.52,
		y: 4.25,
		w: 0,
		h: 2.65,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	slide.addShape(deckInstance.ShapeType.line, {
		x: 6.7,
		y: 4.24,
		w: 0,
		h: 2.65,
		line: { color: 'DEDFE0', width: 0.75 },
	});

	// Labels
	slide.addText(`TOTAL REVENUE 3Y CAGR (%, FY${year})`, {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 0.4,
		y: 1.37,
		h: 0.17,
		w: 3.2,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText(`EBITDA MARGIN (%, FY${year})`, {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 3.6,
		y: 1.37,
		h: 0.17,
		w: 3.2,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText(`SG&A MARGIN (%, FY${year})`, {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 6.8,
		y: 1.37,
		h: 0.17,
		w: 3.2,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText('CAPEX % OF REVENUE', {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 0.4,
		y: 4.31,
		h: 0.17,
		w: 3.2,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText(`LEVERAGE (NET DEBT/EBITDA, FY${year})`, {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 3.6,
		y: 4.31,
		h: 0.17,
		w: 2.5,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});

	slide.addText(`REVENUE PER EMPLOYEE, USD (FY${year})`, {
		fontFace: 'Verdana (Body)',
		fontSize: 10,
		x: 6.8,
		y: 4.31,
		h: 0.17,
		w: 3.2,
		align: 'left',
		valign: 'top',
		bold: true,
		color: '383838',
		margin: [0, 0, 0, 0],
	});
};
