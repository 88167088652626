import React from 'react';

import { IScoreData } from '../../../types';
import { roundOneDecimal } from '../../../utils';

import './AlixScreenScore.scss';

interface IAlixScreenScoreProps {
	scoreData: IScoreData;
}

const getClass = (v: number): string => {
	let className = 'score__green';

	if (v <= 70 && v > 30) {
		className = 'score__amber';
	} else if (v > 70) {
		className = 'score__red';
	}

	return className;
};

export const AlixScreenScore: React.FC<IAlixScreenScoreProps> = ({
	scoreData,
}) => {
	return (
		<div className="score">
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.overallScore)}>
					{roundOneDecimal(scoreData.overallScore)}
				</div>
				<div className="score__label">Overall Score</div>
			</div>
			<div className="score__separator" />
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.workingCapital)}>
					{roundOneDecimal(scoreData.workingCapital)}
				</div>
				<div className="score__label">Working Capital</div>
			</div>
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.profitability)}>
					{roundOneDecimal(scoreData.profitability)}
				</div>
				<div className="score__label">Profitability</div>
			</div>
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.sales)}>
					{roundOneDecimal(scoreData.sales)}
				</div>
				<div className="score__label">Sales</div>
			</div>
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.solvency)}>
					{roundOneDecimal(scoreData.solvency)}
				</div>
				<div className="score__label">Solvency</div>
			</div>
			<div className="scrore__wrapper">
				<div className={getClass(scoreData.marketData)}>
					{roundOneDecimal(scoreData.marketData)}
				</div>
				<div className="score__label">Market Data</div>
			</div>
		</div>
	);
};
