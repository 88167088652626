import React from 'react';
import moment from 'moment';
import { ApSimpleDropdown, IOption, ApIcon } from '@alixpartners/ui-components';

import { ITask, TaskStatus } from '../../../types';
import './Task.scss';

interface ITaskProps {
	task: ITask;
	isEditEnabled: boolean;
	isDeleteEnabled: boolean;
	isStatusChangeEnabled: boolean;
	onStatusChange(newStatus: IOption | IOption[]): void;
	onTaskEdit(task: ITask): void;
	onTaskDelete(id: number): void;
}

export const Task: React.FC<ITaskProps> = ({
	task,
	isEditEnabled,
	isDeleteEnabled,
	isStatusChangeEnabled,
	onStatusChange,
	onTaskEdit,
	onTaskDelete,
}) => {
	const statusColorMap = {
		[TaskStatus.TODO]: '#AFAFAF',
		[TaskStatus.IN_PROGRESS]: '#F0B358',
		[TaskStatus.DONE]: '#85BDDD',
	};
	const statusLabelMap = {
		[TaskStatus.TODO]: 'To Do',
		[TaskStatus.IN_PROGRESS]: 'In Progress',
		[TaskStatus.DONE]: 'Done',
	};

	return (
		<div className="task-row">
			<div
				className="task-row__status-block"
				style={{ backgroundColor: statusColorMap[task.status] }}
			/>
			<div className="task-row__wrapper">
				<div className="task-row__desciption">
					<h5>{task.name}</h5>
					<p>{task.description}</p>
				</div>
				<div className="task-row__assignee">
					<div className="task-row__label">Assignee</div>
					<div>{task.assignedToFullName}</div>
				</div>
				<div className="task-row__date">
					<div className="task-row__label">Due date</div>
					<div>{moment(task.dueDate).format('D MMM, YYYY')}</div>
				</div>
				<div className="task-row__status">
					<div className="task-row__label">Status</div>
					<div className="task-row__status__dropdown">
						<ApSimpleDropdown
							onChange={onStatusChange}
							options={[
								{
									label: statusLabelMap[TaskStatus.TODO],
									value: TaskStatus.TODO,
								},
								{
									label: statusLabelMap[TaskStatus.IN_PROGRESS],
									value: TaskStatus.IN_PROGRESS,
								},
								{
									label: statusLabelMap[TaskStatus.DONE],
									value: TaskStatus.DONE,
								},
							]}
							selected={{
								label: statusLabelMap[task.status],
								value: task.status,
							}}
							disabled={!isStatusChangeEnabled}
						/>
					</div>
				</div>
				<div className="task-row__owner">
					<div className="task-row__label">Owner</div>
					<div>{task.createdByFullName}</div>
				</div>
				<div className="task-row__actions">
					{isEditEnabled && (
						<div
							onClick={() => {
								onTaskEdit(task);
							}}
						>
							<ApIcon iconName="outline_edit" iconColor="#999999" />
						</div>
					)}
					{isDeleteEnabled && (
						<div
							onClick={() => {
								onTaskDelete(task.id);
							}}
						>
							<ApIcon iconName="delete_outline" iconColor="#999999" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
