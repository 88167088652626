import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { ISummaryData } from '../../../types';
import { roundOneDecimal, numberWithCommas, isEmpty } from '../../../utils';
import { SummaryWidget } from '../SummaryWidget';
import { QuarterlyDataWidget } from '../QuarterlyDataWidget';

import './SummarySection.scss';

interface ISummarySectionProps {
	summaryData: ISummaryData;
	companyName: string;
}

export const SummarySection: React.FC<ISummarySectionProps> = ({
	summaryData,
	companyName,
}) => {
	if (!summaryData || isEmpty(summaryData)) {
		return (
			<div className="summary-section">
				<div className="summary-section__empty">No data available</div>
			</div>
		);
	}

	const description = `${companyName} is led by ${
		summaryData.ceo
	}. ${companyName}${
		summaryData.exchange
			? ' is listed in ' +
			  summaryData.exchange +
			  ' as ' +
			  summaryData.ticker +
			  ' and '
			: ''
	} is headquartered in ${summaryData.location}. The company’s net sales for ${
		summaryData.latestYear
	} were ${getSymbolFromCurrency(summaryData.currencyCode)}${numberWithCommas(
		roundOneDecimal(summaryData.netSales.currentValue),
	)} million, an ${
		summaryData.netSales.growth > 0 ? 'increase' : 'decrease'
	} of ${Math.abs(
		roundOneDecimal(summaryData.netSales.growth),
	)}% yoy. SG&A was ${summaryData.sga.growth > 0 ? 'up' : 'down'} ${Math.abs(
		roundOneDecimal(summaryData.sga.growth),
	)}% to ${getSymbolFromCurrency(summaryData.currencyCode)}${numberWithCommas(
		roundOneDecimal(summaryData.sga.currentValue),
	)} million and EBITDA came out at ${getSymbolFromCurrency(
		summaryData.currencyCode,
	)}${numberWithCommas(
		roundOneDecimal(summaryData.ebitda.currentValue),
	)} million an ${
		summaryData.ebitda.growth > 0 ? 'increase' : 'decrease'
	} of ${Math.abs(
		roundOneDecimal(summaryData.ebitda.growth),
	)}% yoy. Operating income ${
		summaryData.operatingIncome.growth > 0 ? 'increased' : 'decreased'
	} by ${Math.abs(
		roundOneDecimal(summaryData.operatingIncome.growth),
	)}% to ${getSymbolFromCurrency(summaryData.currencyCode)}${numberWithCommas(
		roundOneDecimal(summaryData.operatingIncome.currentValue),
	)} million.`;

	return (
		<div className="summary-section">
			{description && (
				<div className="summary-section__description">{description}</div>
			)}
			<div className="summary-section__container">
				<div className="summary-section__chart">
					<div className="summary-section__row">
						<div>
							<SummaryWidget
								name="Net Sales"
								currentValue={roundOneDecimal(
									summaryData.netSales.currentValue,
								)}
								growth={summaryData.netSales.growth}
								currencyCode={summaryData.currencyCode}
								latestYear={summaryData.latestYear}
							/>
						</div>
						<div>
							<SummaryWidget
								name="SG&A"
								currentValue={roundOneDecimal(summaryData.sga.currentValue)}
								growth={summaryData.sga.growth}
								currencyCode={summaryData.currencyCode}
								latestYear={summaryData.latestYear}
							/>
						</div>
					</div>
					<div className="summary-section__row">
						<div>
							<SummaryWidget
								name="Ebitda"
								currentValue={roundOneDecimal(summaryData.ebitda.currentValue)}
								growth={summaryData.ebitda.growth}
								currencyCode={summaryData.currencyCode}
								latestYear={summaryData.latestYear}
							/>
						</div>
						<div>
							<SummaryWidget
								name="Operating Income"
								currentValue={roundOneDecimal(
									summaryData.operatingIncome.currentValue,
								)}
								growth={summaryData.operatingIncome.growth}
								currencyCode={summaryData.currencyCode}
								latestYear={summaryData.latestYear}
							/>
						</div>
					</div>
				</div>
				<div className="summary-section__quarterly">
					{/* <QuarterlyDataWidget
						data={summaryData.quarterlyFinancialData.map((d, i) => {
							if (i === 1) {
								return { ...d, revenue: 0 };
							} else {
								return d;
							}
						})}
					/> */}
					<QuarterlyDataWidget
						data={summaryData.quarterlyFinancialData}
						latestYear={summaryData.latestYear}
						netSalesValue={summaryData.netSales.currentValue}
						currency={summaryData.currencyCode}
					/>
				</div>
			</div>
		</div>
	);
};
