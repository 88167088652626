import React from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getChartOptions } from './chartOptions';

import { numberWithCommas } from '../../utils';
import { IChart } from '../../types';
import './SharePriceChart.scss';

const LINE_COLOR = '#6BABC7';

interface ISharePriceChartProps {
	chartData: IChart;
	tickerSymbol: string;
	currencySymbol: string;
}

interface ISharePriceChartState {}

export class SharePriceChart extends React.PureComponent<
	ISharePriceChartProps,
	ISharePriceChartState
> {
	render() {
		const { chartData, currencySymbol, tickerSymbol } = this.props;

		const valuesData = chartData.series.data[0].data;
		const data = chartData.series.data[0].data.map((v, i) => {
			return [+chartData.xAxis.categories[i] * 1000, v];
		});

		const sharePrice = valuesData[valuesData.length - 1];
		const sharePriceChange = sharePrice - valuesData[valuesData.length - 2];
		const chartOptions = getChartOptions(currencySymbol);
		const shareOptions: Highcharts.Options = {
			...chartOptions,
			xAxis: { ...chartOptions.xAxis, ...chartData.xAxis },
			series: [
				{
					type: 'line',
					color: LINE_COLOR,
					data,
				},
			],
		};

		const sharePriceChangeLable = `${
			sharePriceChange > 0 ? '+' : '-'
		}${currencySymbol}${Math.abs(
			Math.round((sharePriceChange + Number.EPSILON) * 100) / 100,
		)} (${((100 * Math.abs(sharePriceChange)) / sharePrice).toFixed(3)}%)`;

		return (
			<div className="share-price">
				<div className="share-price__title">
					Share price (24h period),{' '}
					<span className="share-price__ticker">{tickerSymbol}</span>:
					<span className="share-price__value">
						{currencySymbol}
						{numberWithCommas(sharePrice)}
					</span>
					<span
						className={classNames('share-price__value', {
							'share-price__value--up': sharePriceChange > 0,
							'share-price__value--down': sharePriceChange < 0,
						})}
					>
						{sharePriceChangeLable}
					</span>
				</div>
				<div>
					<HighchartsReact
						constructorType="stockChart"
						highcharts={Highcharts}
						options={shareOptions}
						immutable
						updateArgs={[false, false, false]}
					/>
				</div>
				<div className="share-price__note">Data sourced from CAPIQ</div>
			</div>
		);
	}
}
