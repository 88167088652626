export const defaultProps = {
	companyData: {
		companyId: '123e4567-e89b-12d3-a456-426655440000',
		name: 'Test Company LTD',
		additionalNames: ['Test Company LTD UK', 'Test Company USA'],
		engagements: [
			{
				name: 'Procurement1',
				minSpend: '$12M',
				maxSpend: '$15M',
				contracts: 5,
				transactions: 12,
			},
			{
				name: 'Procurement2',
				minSpend: '$5M',
				maxSpend: '$11M',
				contracts: 4,
				transactions: 6,
			},
		],
		classification: {
			industry: 'Software',
			industryGroup: 'Software and Services',
			sector: 'Information Technology',
			subIndustry: 'Systems Software',
		},
	},
};
