import React from 'react';
import { ApLoaderDefault, ApButtonIcon } from '@alixpartners/ui-components';
import html2canvas from 'html2canvas';

import { WidgetError } from '../WidgetError';
import { saveAs } from '../../utils';
import './WidgetWrapper.scss';

interface IWidgetWrapperProps {
	name: string;
	component: any;
	props?: any;
	disableExport?: boolean;
	isHidden?: boolean;
	cachedData?: any;
	minHeight?: number;
	api?(): Promise<any>;
	processData?(data: any): any;
	onDataLoaded?(data: any): any;
}
interface IWidgetWrapperState {
	isLoading: boolean;
	widgetData: any;
	error: any;
}

export class WidgetWrapper extends React.Component<
	IWidgetWrapperProps,
	IWidgetWrapperState
> {
	private isFullyMounted = false;

	public set isMounted(status: boolean) {
		this.isFullyMounted = status;
	}

	public get isMounted() {
		return this.isFullyMounted;
	}

	getInitialState = (): IWidgetWrapperState => {
		const { cachedData, processData } = this.props;
		let initialWidgetData = null;

		if (cachedData) {
			initialWidgetData = processData ? processData(cachedData) : cachedData;
		}

		return {
			widgetData: initialWidgetData,
			isLoading: cachedData ? false : !!this.props.api,
			error: null,
		};
	};

	state = this.getInitialState();

	async componentDidMount() {
		const { api, processData, onDataLoaded, cachedData, isHidden } = this.props;
		this.isMounted = true;

		if (isHidden) {
			return null;
		}

		if (!api && !cachedData) {
			return null;
		}

		if (!cachedData && api) {
			try {
				const { data } = await api();

				if (data && this.isMounted) {
					this.setState({
						isLoading: false,
						widgetData: processData ? processData(data) : data,
					});

					if (onDataLoaded) {
						onDataLoaded(data);
					}
				}
			} catch (error) {
				this.setState({ isLoading: false, error });
			}
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	handleRetry = async () => {
		const { api, processData } = this.props;
		this.setState({ isLoading: true, error: null });

		if (!api) {
			return;
		}

		try {
			const { data } = await api();
			this.setState({
				isLoading: false,
				widgetData: processData ? processData(data) : data,
			});
		} catch (error) {
			this.setState({ isLoading: false, error });
		}
	};

	handleExportToPNG = () => {
		const { name } = this.props;
		const element = document.querySelector(
			`.${name.toLowerCase().replace(' ', '-')}`,
		);

		if (element) {
			html2canvas(element as HTMLElement, {
				scrollX: 0,
				scrollY: -window.scrollY,
				logging: false,
			}).then(function (canvas: HTMLCanvasElement) {
				saveAs(canvas.toDataURL(), `${name}.png`);
			});
		}
	};

	handleSetIsLoading = (isLoading: boolean) => {
		this.setState({ isLoading });
	};

	render() {
		const { isLoading, widgetData, error } = this.state;
		const { name, props, disableExport, isHidden, minHeight, api } = this.props;
		const WidgetComponent = this.props.component;

		const getExportBlock = () => (
			<div className="widget-wrapper__export">
				<ApButtonIcon
					iconName="outline_save_alt"
					iconProps={{ iconSize: '16px', iconColor: '#737373' }}
					onClick={this.handleExportToPNG}
				/>
			</div>
		);

		if (isHidden) {
			return null;
		}

		if (error) {
			return (
				<WidgetError
					style={minHeight ? { minHeight } : undefined}
					onRetry={this.handleRetry}
				/>
			);
		}

		if (isLoading) {
			return (
				<div
					className="loading-wrapper"
					style={minHeight ? { minHeight } : undefined}
				>
					<div className="loading-wrapper__title">{name}:</div>
					<ApLoaderDefault />
				</div>
			);
		}

		if (!widgetData && !props) {
			return null;
		}

		if (api && !widgetData) {
			return null;
		}

		return (
			<div className="widget-wrapper">
				<WidgetComponent
					{...widgetData}
					{...props}
					setIsLoading={this.handleSetIsLoading}
				/>
				{!disableExport && getExportBlock()}
			</div>
		);

		// if (api && !isLoading && !widgetData) {
		// 	return null;
		// } else if (api && widgetData) {
		// 	return (
		// 		<div className="widget-wrapper">
		// 			<WidgetComponent
		// 				{...widgetData}
		// 				{...props}
		// 				setIsLoading={this.handleSetIsLoading}
		// 			/>
		// 			{!disableExport && getExportBlock()}
		// 		</div>
		// 	);
		// } else if (!api && props) {
		// 	return (
		// 		<div className="widget-wrapper">
		// 			<WidgetComponent {...props} setIsLoading={this.handleSetIsLoading} />
		// 			{!disableExport && getExportBlock()}
		// 		</div>
		// 	);
		// } else if (!api && !props) {
		// 	return null;
		// }
	}
}
