import React from 'react';
import {
	ApSimpleTable,
	ApSimpleTableHead,
	ApSimpleTableRow,
	ApSimpleTableHeadCell,
	ApSimpleTableBodyCell,
	ApSimpleTableBody,
	ApIcon,
} from '@alixpartners/ui-components';

import { IPeople } from '../../types';

import './People.scss';

interface IPeopleProps {
	people: IPeople[];
}

interface IPeopleState {}

export const People: React.FC<IPeopleProps> = ({ people }) => {
	return (
		<div className="people">
			<div className="people__title">People</div>
			<div>
				<ApSimpleTable
					tableBorders={{
						bottom: true,
						left: true,
						right: true,
						top: true,
					}}
					tableHeight="420px"
					tableWidth="100%"
				>
					<ApSimpleTableHead>
						<ApSimpleTableRow>
							<ApSimpleTableHeadCell cellTextColor="#737373">
								EMPLOYEE NAME
							</ApSimpleTableHeadCell>
							<ApSimpleTableHeadCell cellTextColor="#737373">
								ROLE
							</ApSimpleTableHeadCell>
							<ApSimpleTableHeadCell cellTextColor="#737373">
								IS BOARD MEMBER
							</ApSimpleTableHeadCell>
						</ApSimpleTableRow>
					</ApSimpleTableHead>
					<ApSimpleTableBody>
						{people.map((p) => {
							return (
								<ApSimpleTableRow key={p.name}>
									<ApSimpleTableBodyCell
										cellBorders={{
											bottom: true,
											left: false,
											right: false,
											top: false,
										}}
										cellTextColor="#1A1A1A"
									>
										<strong>{p.name}</strong>
									</ApSimpleTableBodyCell>
									<ApSimpleTableBodyCell
										cellBorders={{
											bottom: true,
											left: false,
											right: false,
											top: false,
										}}
										cellTextColor="#1A1A1A"
									>
										{p.role}
									</ApSimpleTableBodyCell>
									<ApSimpleTableBodyCell
										className="board-member"
										cellBorders={{
											bottom: true,
											left: false,
											right: false,
											top: false,
										}}
										cellTextColor="#1A1A1A"
									>
										{p.isBoardMember ? (
											<ApIcon iconName="outline_done" />
										) : null}
									</ApSimpleTableBodyCell>
								</ApSimpleTableRow>
							);
						})}
					</ApSimpleTableBody>
				</ApSimpleTable>
			</div>
		</div>
	);
};
