import pptxgen from 'pptxgenjs';

import { IPeople } from '../types';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
} from './common';

export const appendPeopleConnectionsSlide = (
	deckInstance: pptxgen,
	companyName: string,
	connectionsData: IPeople[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`${companyName} Connections`, SLIDE_TITLE_CONFIG);
	slide.addText(`MANAGEMENT & BOD`, SLIDE_TOP_LABEL);

	slide.addText('Connections', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		x: 0.3,
		y: 1.35 + 0.2,
		w: 9.2,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	const headerConfig = {
		fill: '006176',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRowBoard = [
		{ text: companyName, options: headerConfig },
		{ text: 'AlixPartners', options: headerConfig },
	];

	slide.addTable(
		[
			headersRowBoard,
			...connectionsData
				.sort((a: IPeople, b: IPeople) => {
					if (a.relationships.length < b.relationships.length) {
						return 1;
					}
					if (a.relationships.length > b.relationships.length) {
						return -1;
					}
					return 0;
				})
				.map((data) => {
					return [
						{ text: `${data.name} (${data.role})` },
						{
							text: data.relationships
								.map((r) => `${r.name} (${r.role})`)
								.join('\n\n'),
						},
					];
				}),
		],
		{
			autoPage: true,
			autoPageRepeatHeader: true,
			newSlideStartY: 1,
			x: 0.3,
			y: 1.65,
			w: 9.2,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [4.6, 4.6],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);
};
