import React from 'react';
import classNames from 'classnames';
import { ApIcon, ApCheckbox } from '@alixpartners/ui-components';
import { IFilter } from '../../types';

import './Filter.scss';

interface IFilterItem {
	id: string;
	value: string;
	subFilters?: string[];
	selectedSubFilters: string[];
	isSubListExpanded: boolean;
	isSelected?: boolean;
}

interface IFilterGroup {
	value: string;
	items: IFilterItem[];
	isExpanded?: boolean;
}

interface IFilterProps {
	filters: IFilter[];
	activeFilters: IFilter[];
	isSearchLoading: boolean;
	expandedByDefault: boolean;
	onFilterAdd(filter: IFilter): void;
	onFilterRemove(filter: IFilter): void;
}

interface IFilterState {
	filterGroups: IFilterGroup[];
}

interface IFilterGroupProps {
	filterGroup: IFilterGroup;
	children?: JSX.Element[] | JSX.Element;
	onGroupExpand(value: string): void;
}

interface IFilterItemProps {
	filterItem: IFilterItem;
	isLoading: boolean;
	onItemSelect(item: IFilterItem): void;
	onSubListExpand(item: IFilterItem): void;
}

export const FilterGroup: React.FC<IFilterGroupProps> = (props) => {
	const { children, filterGroup, onGroupExpand } = props;
	return (
		<div
			className={classNames('filter-group', {
				'filter-group--expanded': filterGroup.isExpanded,
			})}
		>
			<div
				className="filter-group-title"
				onClick={() => {
					onGroupExpand(filterGroup.value);
				}}
			>
				<div className="filter-group-title__name">{filterGroup.value}</div>
				<div className="filter-group-title__icon">
					<ApIcon
						iconName={
							filterGroup.isExpanded
								? 'baseline_keyboard_arrow_down'
								: 'baseline_keyboard_arrow_up'
						}
						iconSize="20px"
					/>
				</div>
			</div>
			<div>{filterGroup.isExpanded && children}</div>
		</div>
	);
};

export const FilterItem: React.FC<IFilterItemProps> = (props) => {
	const { filterItem, onItemSelect, isLoading, onSubListExpand } = props;

	return (
		<div className="filter-item">
			{filterItem.subFilters && (
				<div
					onClick={() => {
						onSubListExpand(filterItem);
					}}
				>
					<ApIcon
						iconName={
							filterItem.isSubListExpanded
								? 'baseline_keyboard_arrow_down'
								: 'baseline_keyboard_arrow_up'
						}
						iconSize="20px"
					/>
				</div>
			)}
			<div>
				<ApCheckbox
					disabled={isLoading}
					id={filterItem.id}
					checked={!!filterItem.isSelected}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onItemSelect(filterItem);
					}}
				>
					<div>{filterItem.value}</div>
				</ApCheckbox>
			</div>
		</div>
	);
};

export class Filter extends React.Component<IFilterProps, IFilterState> {
	processFilters = (filters: IFilter[]): IFilterGroup[] =>
		filters.reduce((previousValue: IFilterGroup[], currentValue: IFilter) => {
			const { id, value, subFilters } = currentValue;
			const isSelected = !!this.props.activeFilters.find(
				(filter) => filter.id === id,
			);
			const exisitingGroup = previousValue.find(
				(filterGroup) => filterGroup.value === currentValue.type,
			);

			return exisitingGroup
				? previousValue.map((filterGroup) => ({
						...filterGroup,
						items: [
							...filterGroup.items,
							{
								id,
								value,
								subFilters: subFilters?.filter((item) => item),
								selectedSubFilters: subFilters
									? [...subFilters?.filter((item) => item)]
									: [],
								isSubListExpanded: false,
								isSelected,
							},
						],
				  }))
				: [
						...previousValue,
						{
							value: currentValue.type,
							isExpanded: this.props.expandedByDefault,
							items: [
								{
									id,
									value,
									subFilters: subFilters?.filter((item) => item),
									selectedSubFilters: subFilters
										? [...subFilters?.filter((item) => item)]
										: [],
									isSubListExpanded: false,
									isSelected,
								},
							],
						},
				  ];
		}, []);

	state = {
		filterGroups: this.processFilters(this.props.filters),
	};

	handleGroupExpand = (value: string) => {
		const { filterGroups } = this.state;

		this.setState({
			filterGroups: filterGroups.map((filterGroup) => {
				return filterGroup.value === value
					? { ...filterGroup, isExpanded: !filterGroup.isExpanded }
					: filterGroup;
			}),
		});
	};

	handleItemSelect = (groupType: string, filterItem: IFilterItem) => {
		const { onFilterAdd, onFilterRemove } = this.props;
		const { filterGroups } = this.state;

		this.setState(
			{
				filterGroups: filterGroups.map((filterGroup: IFilterGroup) => {
					return filterGroup.value === groupType
						? {
								...filterGroup,
								items: filterGroup.items.map((item: IFilterItem) => {
									return item.id === filterItem.id
										? {
												...item,
												isSelected: !item.isSelected,
												isSubListExpanded: !item.isSelected,
												selectedSubFilters: item.subFilters || [],
										  }
										: item;
								}),
						  }
						: filterGroup;
				}),
			},
			() => {
				const currentFilter: IFilter = {
					id: filterItem.id,
					subFilters: filterItem.subFilters,
					type: groupType,
					value: filterItem.value,
				};

				filterItem.isSelected
					? onFilterRemove(currentFilter)
					: onFilterAdd(currentFilter);
			},
		);
	};

	handleItemSubListExpand = (groupType: string, filterItem: IFilterItem) => {
		const { filterGroups } = this.state;

		this.setState({
			filterGroups: filterGroups.map((filterGroup: IFilterGroup) => {
				return filterGroup.value === groupType
					? {
							...filterGroup,
							items: filterGroup.items.map((item: IFilterItem) => {
								return item.id === filterItem.id
									? {
											...item,
											isSubListExpanded: !item.isSubListExpanded,
									  }
									: item;
							}),
					  }
					: filterGroup;
			}),
		});
	};

	handleSubItemSelect = (
		groupType: string,
		filterItem: IFilterItem,
		subItem: string,
	) => {
		const { onFilterAdd, onFilterRemove } = this.props;
		const { filterGroups } = this.state;

		const selectedFilterSubItems = filterItem.selectedSubFilters?.includes(
			subItem,
		)
			? filterItem.selectedSubFilters.filter((s) => s !== subItem)
			: [...filterItem.selectedSubFilters, subItem];

		const newSelectedSubFilters = filterItem.isSelected
			? selectedFilterSubItems
			: [subItem];

		this.setState(
			{
				filterGroups: filterGroups.map((filterGroup: IFilterGroup) => {
					return filterGroup.value === groupType
						? {
								...filterGroup,
								items: filterGroup.items.map((item: IFilterItem) => {
									// const newSelectedSubFilters = item.selectedSubFilters?.includes(
									// 	subItem,
									// )
									// 	? item.selectedSubFilters.filter((s) => s !== subItem)
									// 	: [...item.selectedSubFilters, subItem];

									return item.id === filterItem.id
										? {
												...item,
												isSelected:
													newSelectedSubFilters.length === 0 ? false : true,
												selectedSubFilters: newSelectedSubFilters,
										  }
										: item;
								}),
						  }
						: filterGroup;
				}),
			},
			() => {
				const currentFilter: IFilter = {
					id: filterItem.id,
					subFilters: newSelectedSubFilters,
					type: groupType,
					value: filterItem.value,
				};

				newSelectedSubFilters.length === 0
					? onFilterRemove(currentFilter)
					: onFilterAdd(currentFilter);
			},
		);
	};

	componentDidUpdate(prevProps: IFilterProps, prevState: IFilterState) {
		const { filters, activeFilters } = this.props;

		if (JSON.stringify(prevProps.filters) !== JSON.stringify(filters)) {
			this.setState({ filterGroups: this.processFilters(this.props.filters) });
		}

		if (prevProps.activeFilters.length !== activeFilters.length) {
			this.setState((prevState, props) => ({
				filterGroups: prevState.filterGroups.map((filterGroup) => ({
					...filterGroup,
					items: filterGroup.items.map((item) => {
						const isSelected = !!activeFilters.find(
							(filter) => filter.id === item.id,
						);

						return {
							...item,
							isSelected,
							isSubListExpanded: isSelected,
						};
					}),
				})),
			}));
		}
	}

	render() {
		const { filterGroups } = this.state;
		const { isSearchLoading } = this.props;

		return (
			<div className="filter">
				{filterGroups.map((filterGroup) => {
					return (
						<FilterGroup
							key={filterGroup.value}
							filterGroup={filterGroup}
							onGroupExpand={this.handleGroupExpand}
						>
							{filterGroup.items.map((filterItem: IFilterItem) => {
								const { subFilters } = filterItem;

								return (
									<div key={filterItem.id}>
										<FilterItem
											filterItem={filterItem}
											isLoading={isSearchLoading}
											onItemSelect={(item: IFilterItem) =>
												this.handleItemSelect(filterGroup.value, item)
											}
											onSubListExpand={(item: IFilterItem) =>
												this.handleItemSubListExpand(filterGroup.value, item)
											}
										></FilterItem>
										{filterItem.subFilters && filterItem.isSubListExpanded && (
											<div className="sub-filters">
												{subFilters?.map((f, i) => {
													const key = f.toLowerCase().replace(/ /g, '-') + i;
													return (
														<div key={key} className="sub-filter">
															<ApCheckbox
																id={key}
																disabled={isSearchLoading}
																checked={
																	filterItem.isSelected &&
																	filterItem.selectedSubFilters?.includes(f)
																}
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>,
																) => {
																	this.handleSubItemSelect(
																		filterGroup.value,
																		filterItem,
																		f,
																	);
																}}
															>
																{f}
															</ApCheckbox>
														</div>
													);
												})}
											</div>
										)}
									</div>
								);
							})}
						</FilterGroup>
					);
				})}
			</div>
		);
	}
}
