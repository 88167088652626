import React from 'react';
import { ApButtonLink, ApButtonGroup } from '@alixpartners/ui-components';

import { ConnectionAnalyticsConsumer } from '../../components/ConnectionAnalyticsContext';
import { ICompanyEngagement } from '../../types';

import './Engagements.scss';
import { PitchCaptains } from '../PitchCaptains';

interface IEngagementsProps {
	engagements: ICompanyEngagement[];
}

export const Engagements: React.FC<IEngagementsProps> = ({ engagements }) => {
	return (
		<ConnectionAnalyticsConsumer>
			{({ webAppUrl }) => {
				return (
					<div className="engagements">
						<div className="engagements__title">Engagements:</div>
						<PitchCaptains engagements={engagements} />
						<ApButtonGroup justifyContent="center">
							<ApButtonLink
								className="engagements__ca-link"
								href={`${webAppUrl}/Graph/pna?entityType=2&entityId=${engagements[0].caId}&userOrigin=BICCompanyProfilePlatform`}
								target="_blank"
							>
								View in connection analytics
							</ApButtonLink>
						</ApButtonGroup>
					</div>
				);
			}}
		</ConnectionAnalyticsConsumer>
	);
};
