import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
	ApSimpleTable,
	ApSimpleTableBody,
	ApSimpleTableBodyCell,
	ApSimpleTableHead,
	ApSimpleTableHeadCell,
	ApSimpleTableRow,
} from '@alixpartners/ui-components';

import { ICashFlowData } from '../../../types';
import { isEmpty, numberWithCommas } from '../../../utils';

import './CashflowSection.scss';

interface ICashflowSectionProps {
	data: ICashFlowData[];
	companyName: string;
	currency: string;
}

export const CashflowSection: React.FC<ICashflowSectionProps> = ({
	data,
	companyName,
	currency,
}) => {
	if (!data || isEmpty(data)) {
		return (
			<div className="cashflow-section">
				<div className="cashflow-section__empty">No data available</div>
			</div>
		);
	}

	const renderRow = (header: string, valueName: string, isBold = false) => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					className={`row-${valueName}`}
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
				>
					{isBold ? <strong>{header}</strong> : ` - ${header}`}
				</ApSimpleTableBodyCell>
				{data.map((d) => {
					const value: string =
						d[valueName] > 0
							? `${numberWithCommas(d[valueName])}`
							: `(${numberWithCommas(Math.abs(d[valueName]))})`;

					return (
						<ApSimpleTableBodyCell
							className={`row-${valueName} text-align-right`}
							key={d.fiscalYear}
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							{isBold ? (
								<strong>
									<span>{value}</span>
								</strong>
							) : (
								<span>{value}</span>
							)}
						</ApSimpleTableBodyCell>
					);
				})}
			</ApSimpleTableRow>
		);
	};

	return (
		<div className="cashflow-section">
			<div className="cashflow-section__container">
				<ApSimpleTable
					tableBorders={{
						bottom: true,
						left: true,
						right: true,
						top: true,
					}}
					tableHeight="420px"
					tableWidth="100%"
					className="ap-table-with-numeric-column"
				>
					<ApSimpleTableHead>
						<ApSimpleTableRow>
							<ApSimpleTableHeadCell
								cellTextColor="#737373"
								className=" numericColumn text-align-left"
							>
								{`${getSymbolFromCurrency(currency)}M`}
							</ApSimpleTableHeadCell>
							{data.map((d) => {
								return (
									<ApSimpleTableHeadCell
										cellTextColor="#737373"
										key={d.fiscalYear}
										className=" numericColumn"
									>
										{d.fiscalYear}
									</ApSimpleTableHeadCell>
								);
							})}
						</ApSimpleTableRow>
					</ApSimpleTableHead>
					<ApSimpleTableBody>
						{renderRow('Profit For The Year', 'profitOfTheYear')}
						{renderRow('Depreciation', 'depreciationAmortization')}
						{renderRow('Other changes', 'cashOperationsOtherChanges')}
						{renderRow(
							'Cash Generated From/(Used In) Operations',
							'cashGeneratedFromOperations',
							true,
						)}
						{renderRow('Capital Expenditure', 'capitalExpenditure')}
						{renderRow(
							'Invest. in Marketable & Equity Securt.',
							'cashOperationsOtherChanges',
						)}
						{renderRow('Other changes', 'cashInvestOtherChanges')}
						{renderRow(
							'Cash Generated From/(Used In) Investing',
							'cashGeneratedFromInvesting',
							true,
						)}
						{renderRow('Total debt issued', 'totalDebtIssued')}
						{renderRow('Total debt repaid', 'totalDebtRepaid')}
						{renderRow(
							'Other Financing activities',
							'otherFinancingActivities',
						)}
						{renderRow(
							'Cash Generated From/(Used In) Financing',
							'cashGeneratedFromFinancing',
							true,
						)}
						{renderRow('FX effect', 'fxEffect')}
						{renderRow(
							'Net Increase/(Decrease) In Cash And Equivalents',
							'netInCashAndEquivalents',
							true,
						)}
					</ApSimpleTableBody>
				</ApSimpleTable>
			</div>
		</div>
	);
};
