import moment from 'moment';
import { numberWithCommas } from '../../utils';

export const getChartOptions = (
	currencySymbol: string,
): Highcharts.Options => ({
	chart: {
		height: 124,
	},
	title: {
		text: '',
	},
	credits: {
		enabled: false,
	},
	yAxis: {
		tickAmount: 3,
		title: {
			text: '',
		},
		gridLineColor: '#E6E6E6',
		labels: {
			formatter: function () {
				return `${currencySymbol}${numberWithCommas(this.value)}`;
			},
			style: {
				color: '#737373',
				fontFamily: 'Roboto, Verdana',
				fontSize: '11px',
			},
		},
	},
	legend: {
		enabled: false,
	},
	rangeSelector: {
		enabled: false,
	},
	scrollbar: {
		enabled: false,
	},
	navigator: {
		enabled: false,
	},
	stockTools: {
		gui: {
			enabled: false,
		},
	},
	xAxis: {
		labels: {
			enabled: true,
			formatter: function () {
				return moment(this.value).format('MMM YYYY');
			},
		},
		lineColor: 'transparent',
		tickLength: 0,
	},
	plotOptions: {
		line: {
			marker: {
				enabled: false,
			},
		},
		series: {
			animation: false,
			states: {
				hover: {
					enabled: false,
				},
			},
		},
	},
	tooltip: {
		enabled: true,
		outside: true,
		shared: true,
		backgroundColor: undefined,
		borderWidth: 0,
		useHTML: true,
		split: false,
		formatter: function () {
			return this.x && this.y
				? `<div class="custom-tooltip custom-tooltip--share-price"><span class="custom-tooltip__yvalue">${currencySymbol}${
						this.y
				  }</span> (${moment(this.x).format('MMMM Do YYYY')})</div>`
				: null;
		},
	},
});
