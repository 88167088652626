import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ApButtonSecondary,
	ApButtonText,
	ApSimpleTable,
	ApSimpleTableHead,
	ApSimpleTableRow,
	ApSimpleTableHeadCell,
	ApSimpleTableBody,
	ApSimpleTableBodyCell,
	ApModal,
	ApButtonGroup,
	snack,
} from '@alixpartners/ui-components';
import { ApAuthenticationContextConsumer } from '@alixpartners/ui-utils';
import { MultiSelect } from '../MultiSelect';
import { addWatchlistCompany } from '../../api';

import { IWatchlistMeta, IWatchlistWidgetData } from '../../types';

import './CompanyWatchlists.scss';

interface IWatchlistsProps extends RouteComponentProps {
	companyId: string;
	watchlists: IWatchlistMeta[];
	companyWatchlists: IWatchlistWidgetData[];
	isModalOpen: boolean;
	setIsLoading(isLoading: boolean): void;
	onModalClose(): void;
	onWatchlistSelect(watchlistItem: IWatchlistWidgetData | null): void;
	onAddToWatchlist(): void;
}

const Watchlists: React.FC<IWatchlistsProps> = ({
	companyId,
	watchlists,
	companyWatchlists,
	onAddToWatchlist,
	history,
	isModalOpen,
	onModalClose,
	onWatchlistSelect,
	setIsLoading,
}) => {
	return (
		<ApAuthenticationContextConsumer>
			{({ user }) => {
				const currentUsertId = user!.profile.oid;
				return (
					<div className="watchlists">
						<div className="watchlists__title">Watchlists:</div>
						<div className="watchlists__list">
							{companyWatchlists.length > 0 && (
								<ApSimpleTable
									bodyOffset="32px"
									tableHeight="100px"
									tableMinWidth="280px"
									tableWidth="100%"
								>
									<ApSimpleTableHead>
										<ApSimpleTableRow>
											<ApSimpleTableHeadCell>
												Watchlist name
											</ApSimpleTableHeadCell>
											<ApSimpleTableHeadCell>Status</ApSimpleTableHeadCell>
										</ApSimpleTableRow>
									</ApSimpleTableHead>
									<ApSimpleTableBody>
										{companyWatchlists.map((w) => {
											return (
												<ApSimpleTableRow key={w.id}>
													<ApSimpleTableBodyCell>
														<ApButtonText
															onClick={() => {
																history.push(`/watchlists/${w.id}`);
															}}
														>
															{w.name}
														</ApButtonText>
													</ApSimpleTableBodyCell>
													<ApSimpleTableBodyCell>
														{w.status}
													</ApSimpleTableBodyCell>
												</ApSimpleTableRow>
											);
										})}
									</ApSimpleTableBody>
								</ApSimpleTable>
							)}
						</div>
						<ApButtonGroup justifyContent="center">
							<ApButtonSecondary onClick={onAddToWatchlist}>
								Add Company To Watchlist
							</ApButtonSecondary>
						</ApButtonGroup>
						<ApModal
							isDismissible
							className="company-watchlist-modal"
							isOpen={isModalOpen}
							hasClosed={onModalClose}
							header="Add Company to a Watchlist"
						>
							<div className="modal__content">
								<MultiSelect
									isSingle
									isClearable
									options={watchlists
										.filter(
											(w) =>
												!companyWatchlists
													.map((cw) => cw.id)
													.includes(w.watchlistId),
										)
										.map((w) => ({
											label: w.watchlistName,
											value: w.watchlistId + '',
										}))}
									onChange={async ({ label, value }) => {
										const watchlistName = label;
										const watchlistId = parseInt(value);
										setIsLoading(true);
										try {
											await addWatchlistCompany({
												watchlistId,
												companyId: +companyId,
												owners: [currentUsertId],
												status: 'Pursuit',
											});
											snack.success({
												message: `Company is successfully added to '${watchlistName}' watchlist.`,
											});
											onWatchlistSelect({
												id: watchlistId,
												name: watchlistName,
												status: 'Pursuit',
											});
											setIsLoading(false);
										} catch (error) {
											onWatchlistSelect(null);
											snack.error({
												message: `Error while adding to watchlist`,
											});
											setIsLoading(false);
										}
									}}
								/>
							</div>
						</ApModal>
					</div>
				);
			}}
		</ApAuthenticationContextConsumer>
	);
};

export const CompanyWatchlists = withRouter(Watchlists);
