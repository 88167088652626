import { ApButtonSecondary } from '@alixpartners/ui-components';
import React, { useState } from 'react';
import { ICompanyEngagement } from '../../types';
import { formatAndGroupDates } from './groupDates';
import './PitchCaptains.scss';

interface IPitchCaptains {
	engagements: ICompanyEngagement[] | null;
}

const renderExpandBtn = (
	expandedState: boolean,
	setExpandedState: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	return (
		<div className="engagement-types">
			<div></div>

			<div>
				<ApButtonSecondary
					className="expand-btn"
					onClick={() => setExpandedState((prev) => !prev)}
				>
					{expandedState ? 'Unexpand' : 'Expand...'}
				</ApButtonSecondary>
			</div>

			<div></div>
		</div>
	);
};

export const PitchCaptains: React.FC<IPitchCaptains> = ({ engagements }) => {
	const [expanded, setExpand] = useState(false);

	if (!engagements?.some((eng) => eng.pitchCaptains?.length)) return null;

	// Expansion
	const PRE_EXPAND_DISPLAY_COUNT = 5;

	const [
		{ pitchCaptains: wonCaps },
		{ pitchCaptains: openCaps },
		{ pitchCaptains: otherCaps },
	] = engagements;

	const wonPreExpand = wonCaps?.slice(0, PRE_EXPAND_DISPLAY_COUNT);
	const wonPostExpand = wonCaps?.slice(wonPreExpand?.length);

	const openPreExpand = openCaps?.slice(0, PRE_EXPAND_DISPLAY_COUNT);
	const openPostExpand = openCaps?.slice(openPreExpand?.length);

	const otherPreExpand = otherCaps?.slice(0, PRE_EXPAND_DISPLAY_COUNT);
	const otherPostExpand = otherCaps?.slice(otherPreExpand?.length);

	const EXPANSION_REQUIRED =
		wonPostExpand?.length || openPostExpand?.length || otherPostExpand?.length;

	// Render
	return (
		<div className="pitch-captains">
			<div className="engagement-type-titles">
				<div className="type">WON</div>
				<div className="type">OPEN</div>
				<div className="type">OTHER</div>

				<div className="count">{engagements[0].count}</div>
				<div className="count">{engagements[1].count}</div>
				<div className="count">{engagements[2].count}</div>
			</div>

			<div className="years-grid">
				<div className="years1">
					{formatAndGroupDates(engagements[0].years)}
				</div>
				<div className="years2">
					{formatAndGroupDates(engagements[1].years)}
				</div>
				<div className="years3">
					{formatAndGroupDates(engagements[2].years)}
				</div>
			</div>

			<div className="title">Pitch Captains</div>

			<div className="engagement-types">
				<div>
					{wonPreExpand?.map((pc) => (
						<div key={pc}>{pc}</div>
					))}
					{wonPostExpand?.map((pc) => (
						<div key={pc} className={expanded ? 'expand' : 'no-expand'}>
							{pc}
						</div>
					))}
				</div>

				<div>
					{openPreExpand?.map((pc) => (
						<div key={pc}>{pc}</div>
					))}
					{openPostExpand?.map((pc) => (
						<div key={pc} className={expanded ? 'expand' : 'no-expand'}>
							{pc}
						</div>
					))}
				</div>

				<div>
					{otherPreExpand?.map((pc) => (
						<div key={pc}>{pc}</div>
					))}
					{otherPostExpand?.map((pc) => (
						<div key={pc} className={expanded ? 'expand' : 'no-expand'}>
							{pc}
						</div>
					))}
				</div>
			</div>

			{EXPANSION_REQUIRED ? renderExpandBtn(expanded, setExpand) : null}
		</div>
	);
};
