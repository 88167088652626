import pptxgen from 'pptxgenjs';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas } from '../utils';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TITLE_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
	STICKER_CONFIG,
	truncateText,
} from './common';

const FOOTER_CONFIG: pptxgen.TextPropsOptions = {
	w: 7.86,
	h: 0.25,
	x: 0.3,
	y: 7.05,
	fontSize: 8,
	fontFace: 'Verdana (Body)',
	valign: 'middle',
	color: '808083',
	margin: 0,
	paraSpaceAfter: 12,
};

const DESCRIPTION_CONFIG: pptxgen.TextPropsOptions = {
	w: 9.39,
	h: 2.83,
	x: 0.3,
	y: 1.58,
	fontSize: 10,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '383838',
	margin: [6, 6, 6, 6],
	// Enable if we need to have bulleted text here
	// bullet: { marginPt: 14.5 },
	paraSpaceAfter: 4,
};

const KEY_FACTS_CONFIG: pptxgen.TextPropsOptions = {
	w: 5.33,
	h: 2.09,
	x: 0.3,
	y: 4.86,
	fontSize: 10,
	fontFace: 'Verdana (Body)',
	margin: 0,
	// align: 'left',
	valign: 'top',
	paraSpaceAfter: 4,
};

export const generatePeersTable = (items: string[], slide: pptxgen.Slide) => {
	const tableX = 5.83;
	const tableY = 4.86;
	const tableWidth = 3.86;
	const rowH = 0.3;

	items.forEach((element, index) => {
		const lineY = tableY + rowH * (index + 1);
		slide.addShape('line', {
			x: tableX,
			y: lineY,
			w: tableWidth,
			h: 0.0,
			line: { color: 'ADAFB2' },
			lineSize: 0.75,
			lineDash: 'sysDot',
		});
	});

	slide.addTable(
		items.map((item) => [{ text: truncateText(item, 47) }]),
		{
			x: tableX,
			y: tableY,
			// h: 2.09,
			w: tableWidth,
			border: { type: 'none' },
			colW: [3.86],
			rowH: [rowH],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [0, 0, 0, 7],
			valign: 'middle',
			align: 'left',
		},
	);
};

export const appendBusinessDescriptionSlide = (
	deckInstance: pptxgen,
	companyName: string,
	description: string,
	latestYear: number,
	currencyCode: string,
	revenue: number,
	operatingIncome: number,
	totalEbitda: number,
	netIncome: number,
	keyOwnership: string,
	leadership: string,
	employees: number,
	competitorNames: string[],
) => {
	const DESCRIPTION_MAX_LENGTH = 2000;
	const MAX_PEERS_AMOUNT = 7;

	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`${companyName} Summary`, SLIDE_TITLE_CONFIG);
	slide.addText(
		[
			{ text: 'Source: Capital IQ ' },
			{ text: moment().format('YYYY'), options: { bold: true } },
		],
		FOOTER_CONFIG,
	);

	// Horizontal line only works with h: 0
	slide.addText('Business description', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		bold: true,
		x: 0.3,
		y: 1.2 + 0.2,
		w: 9.4,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText('Key facts and figures', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		bold: true,
		x: 0.32,
		y: 4.57 + 0.2,
		w: 5.34,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText('Relevant peers', {
		...SLIDE_SECONDARY_TITLE_CONFIG,
		bold: true,
		x: 5.83,
		y: 4.57 + 0.2,
		w: 3.86,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(
		truncateText(description ? description : 'No Data', DESCRIPTION_MAX_LENGTH),
		DESCRIPTION_CONFIG,
	);

	const labelConfig = {
		bold: true,
		bullet: { marginPt: 14.5 },
		// breakLine: true,
	};

	slide.addText(
		[
			{
				text: `Total revenue (FY${latestYear}): `,
				options: labelConfig,
			},
			{
				text: `${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
					revenue,
				)}m`,
			},
			{
				text: `Operating income (FY${latestYear}): `,
				options: labelConfig,
			},
			{
				text: `${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
					operatingIncome,
				)}m`,
			},
			{ text: `EBITDA (FY${latestYear}): `, options: labelConfig },
			{
				text: `${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
					totalEbitda,
				)}m`,
			},
			{
				text: `Net income (FY${latestYear}): `,
				options: labelConfig,
			},
			{
				text: `${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
					netIncome,
				)}m`,
			},
			{
				text: 'Listed: ',
				options: labelConfig,
			},
			{
				text: truncateText(keyOwnership, 170),
			},
			{
				text: 'Key Leadership: ',
				options: labelConfig,
			},
			{
				text: truncateText(leadership, 110),
			},
			{
				text: 'Total number of employees: ',
				options: labelConfig,
			},
			{
				text: `~${numberWithCommas(employees)}`,
			},
		],
		KEY_FACTS_CONFIG,
	);

	slide.addText('Confirm peers and check Listed', {
		x: 6.86,
		y: 0.41,
		w: 2.48,
		h: 0.89,
		...STICKER_CONFIG,
	});

	generatePeersTable(competitorNames.slice(0, MAX_PEERS_AMOUNT), slide);
};
