import pptxgen from 'pptxgenjs';

import { IPeople } from '../types';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
} from './common';

export const appendPeopleConnectionsSlide = (
	deckInstance: pptxgen,
	companyName: string,
	connectionsData: IPeople[],
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`Connections`, SLIDE_TITLE_CONFIG_WIDE);
	slide.addText(`MANAGEMENT AND BOD`, SLIDE_TOP_LABEL_WIDE);

	const headerConfig = {
		fill: '383838',
		color: 'FFFFFF',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRowBoard = [
		{ text: companyName.toUpperCase(), options: headerConfig },
		{ text: 'ALIXPARTNERS', options: { ...headerConfig, fill: '5CB335' } },
	];

	slide.addTable(
		[
			headersRowBoard,
			...connectionsData
				.sort((a: IPeople, b: IPeople) => {
					if (a.relationships.length < b.relationships.length) {
						return 1;
					}
					if (a.relationships.length > b.relationships.length) {
						return -1;
					}
					return 0;
				})
				.map((data) => {
					return [
						{ text: `${data.name} (${data.role})` },
						{
							text: data.relationships
								.map((r) => `${r.name} (${r.role})`)
								.join(' '),
							// .join('\n\n'),
						},
					];
				}),
		],
		{
			autoPage: true,
			autoPageRepeatHeader: true,
			newSlideStartY: 1,
			x: 0.4,
			y: 1.65,
			w: 12.52,
			border: { type: 'solid', pt: 1, color: 'F7F7F7' },
			colW: [6, 27, 6, 27],
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7], // Adjusted to fit long roles
			valign: 'middle',
			align: 'left',
		},
	);
};
