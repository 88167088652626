import React from 'react';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
	ApIcon,
	ApSimpleDropdown,
	IOption,
	ApInput,
	ApButtonIcon,
	ApButtonSecondary,
} from '@alixpartners/ui-components';

import { IMaterialDebt, monthsMap } from '../../../types';
import { range } from '../../../utils';

import './DebtModal.scss';

interface IDebtModalProps {
	currencyCode: string;
	materialDebt: IMaterialDebt[];
	onDateChange(id: number, value: string, key: string): void;
	onDebtChange(id: number, value: string): void;
	onAddNew(): void;
	onDelete(id: number): void;
}

const YEARS_RANGE = 30;

export const DebtModal: React.FC<IDebtModalProps> = ({
	currencyCode,
	materialDebt,
	onDateChange,
	onDebtChange,
	onDelete,
	onAddNew,
}) => {
	const handleSelectMonth = (
		option: IOption | IOption[] | undefined,
		id: number,
	) => {
		const month = (option as IOption).value;
		onDateChange(id, month, 'month');
	};

	const handleSelectYear = (option: IOption | IOption[], id: number) => {
		const year = (option as IOption).value;
		onDateChange(id, year, 'year');
	};

	const currentYear = moment().format('YYYY');

	const yearsOptions = range(
		parseInt(currentYear),
		parseInt(currentYear) + YEARS_RANGE,
		1,
	).map((y) => ({
		label: `${y}`,
		value: `${y}`,
	}));

	return (
		<div className="debt-form">
			<div className="debt-form__title">Edit Debt Maturity</div>
			<div className="debt-form__alert">
				<ApIcon iconName="baseline_info" />
				Changes will be applied globally. Your name will be associated with
				these changes.
			</div>
			<div className="debt-form__heading">Next Material Debt Maturity</div>
			<div className="debt-form__form">
				{materialDebt.length
					? materialDebt.map((d: IMaterialDebt) => {
							const { date } = d;
							const monthNumeric: string = date.split('/')[0];
							const yearNumeric: string = date.split('/')[2];
							const currentMonth = new Date().getMonth();
							const monthsOptions =
								currentYear === yearNumeric
									? monthsMap.filter((o) => {
											return parseInt(o.value) >= currentMonth;
									  })
									: monthsMap;

							return (
								<div key={d.id} className="debt-form__row">
									<div className="debt-form__row__month">
										<div className="modal__label">Month</div>
										<ApSimpleDropdown
											onChange={(option) => {
												const { label, value } = option as IOption;
												handleSelectMonth(
													{ label, value: `${parseInt(value) + 1}` },
													d.id,
												);
											}}
											options={monthsOptions}
											// If we select current year we need to update a month to be in future
											selected={
												yearNumeric === currentYear &&
												parseInt(monthNumeric) - 1 < currentMonth
													? monthsMap.find(
															(o) => parseInt(o.value) === currentMonth,
													  )
													: monthsOptions.find(
															(m) =>
																m.value === `${parseInt(monthNumeric) - 1}`,
													  )
											}
										/>
									</div>
									<div className="debt-form__row__year">
										<div className="modal__label">Year</div>
										<ApSimpleDropdown
											onChange={(option) => {
												const { value } = option as IOption;
												handleSelectYear(option, d.id);

												// If we select current year we need to update a month to be in future
												if (
													value === currentYear &&
													parseInt(monthNumeric) - 1 < currentMonth
												) {
													const { label, value } = monthsMap.find(
														(o) => parseInt(o.value) === currentMonth,
													) as IOption;
													handleSelectMonth(
														{ label, value: `${parseInt(value) + 1}` },
														d.id,
													);
												}
											}}
											options={yearsOptions}
											selected={{
												label: yearNumeric,
												value: yearNumeric,
											}}
										/>
									</div>
									<div className="debt-form__row__value">
										<div className="modal__label">Value</div>
										<ApInput
											onChange={(value) => {
												onDebtChange(d.id, value);
											}}
											symbol={
												currencyCode ? getSymbolFromCurrency(currencyCode) : ''
											}
											value={`${d.debt}`}
											name={`${d.id}`}
										/>
									</div>
									<div className="debt-form__row__delete">
										<ApButtonIcon
											hoverColor="#5CB335"
											iconName="baseline_close"
											onClick={() => {
												onDelete(d.id);
											}}
										/>
									</div>
								</div>
							);
					  })
					: 'No Data'}
			</div>
			<div className="debt-form__add-new">
				<ApButtonSecondary onClick={onAddNew}>
					<div className="button-wrapper">
						<ApIcon iconName="baseline_add" iconColor="#333333" />
						<div>Add Another</div>
					</div>
				</ApButtonSecondary>
			</div>
		</div>
	);
};
