import React from 'react';

import Select, { components } from 'react-select';
import { ApIcon, IOption } from '@alixpartners/ui-components';

import './MultiSelect.scss';

interface IMultiSelectProps {
	options: IOption[];
	value?: IOption[];
	isSingle?: boolean;
	isClearable?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onChange(value: any): void;
}

const CaretDownIcon = () => {
	return <ApIcon iconName="caret" />;
};

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<CaretDownIcon />
		</components.DropdownIndicator>
	);
};

export class MultiSelect extends React.Component<IMultiSelectProps, unknown> {
	state = {};

	render() {
		const {
			options,
			value,
			onChange,
			isSingle,
			isClearable,
			disabled,
			placeholder,
		} = this.props;

		return (
			<Select
				isDisabled={disabled}
				isMulti={!isSingle}
				options={options}
				className="multi-select"
				classNamePrefix="multi-select"
				components={{ DropdownIndicator }}
				value={value}
				placeholder={
					placeholder || placeholder === ''
						? placeholder
						: 'Select from the list'
				}
				onChange={onChange}
				isClearable={isClearable}
				hideSelectedOptions
			/>
		);
	}
}
