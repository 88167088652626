import pptxgen from 'pptxgenjs';

import { ICompetitor } from '../types';

import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	NO_DATA_CONFIG,
} from './common';

export const appendSharePriceSlide = (
	deckInstance: pptxgen,
	companyName: string,
	competitors: ICompetitor[],
	sharePriceChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`SHARE PRICE`, SLIDE_TOP_LABEL);
	slide.addText(`${companyName} Stock performance`, SLIDE_TITLE_CONFIG);
	const isNoSharePriceData = competitors
		.map((c) => c.sharePriceData.length)
		.every((item) => item === 0);

	slide.addText(
		[
			{
				text: 'Stock performance vs. major competitors ',
				options: { bold: true, breakLine: false },
			},
			{
				text: '(last 3 years)',
				options: { italic: true, breakLine: false },
			},
		],
		{
			x: 0.3,
			y: 1.26,
			w: 9.4,
			h: 0.4,
			shape: 'rect',
			fill: { type: 'solid', color: '006176' },
			fontSize: 12,
			fontFace: 'Verdana (Body)',
			color: 'FFFFFF',
		},
	);

	const colors = [
		'd12724',
		'ff930f',
		'15723c',
		'0e053d',
		'004299',
		'9d9c9c',
		'f2d815',
	];

	const rowsData = competitors
		.map((c, currentIndex) => {
			const { sharePriceGrowth } = c;

			return [
				{
					text: c.name,
					options: { fill: { color: colors[currentIndex] }, color: 'FFFFFF' },
				},
				{
					text: sharePriceGrowth ? `${sharePriceGrowth}%` : 'N/A',
					sortValue: sharePriceGrowth,
				},
			];
		})
		.sort((a: any, b: any) => {
			if (a[1].sortValue < b[1].sortValue) {
				return 1;
			}
			if (a[1].sortValue > b[1].sortValue) {
				return -1;
			}
			return 0;
		});

	const headerConfig = {
		color: '383838',
		bold: true,
		margin: [0, 7, 0, 7],
		paraSpaceAfter: 6,
	};

	const headersRow = [
		{ text: 'Company', options: headerConfig },
		{ text: 'Return', options: headerConfig },
	];

	if (!isNoSharePriceData) {
		slide.addTable(
			[
				// @ts-ignore
				headersRow,
				...rowsData,
			],
			{
				x: 7.3,
				y: 2.13,
				w: 2.4,
				border: { type: 'solid', pt: 1, color: 'FFFFFF' },
				colW: [1.6, 0.8],
				// @ts-ignore
				rowH: [0.3],
				fontFace: 'Verdana (Body)',
				fontSize: 10,
				color: '383838',
				margin: [3, 0, 3, 7],
				valign: 'middle',
				align: 'left',
			},
		);
	}

	if (!isNoSharePriceData) {
		slide.addImage({
			data: sharePriceChart,
			x: 0.3,
			y: 2.13,
			h: 4.08,
			w: 7,
		});
	} else {
		slide.addText('No data', { ...NO_DATA_CONFIG, x: 0.3, y: 1.83 });
	}
};
