import React from 'react';
import moment from 'moment';
import download from 'downloadjs';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
	ApButtonGroup,
	ApButtonLink,
	ApButtonMain,
	ApButtonSecondary,
	ApButtonTab,
	ApCheckbox,
	ApChip,
	ApIcon,
	ApInput,
	ApLoaderDefault,
	ApModal,
	ApRadio,
	ApSimpleDropdown,
	IOption,
	snack,
} from '@alixpartners/ui-components';

import {
	addCompanyLinkedFiles,
	addCompanyOwner,
	addCompanyTag,
	deleteCompanyLinkedFiles,
	deleteCompanyOwner,
	deleteCompanyTag,
	deleteProspectFile,
	deleteWatchlistComment,
	downloadProspectFile,
	editWatchlistComment,
	getAllTags,
	getCompanyTag,
	getProspectById,
	getWatchlistComments,
	getWatchlistCurrencies,
	markWatchlistCommentObsolete,
	postWatchlistComment,
	reviewComment,
	syncFinancialSummaryWithCapIQ,
	updateFinancialSummary,
	updateMaterialDebt,
	updateProspect,
	uploadProspectFile,
} from '../../../api';
import {
	ICurrentUser,
	IFinancialSummary,
	IMaterialDebt,
	IPerson,
	IProspectDocument,
	IProspectLinkedFiles,
	ITag,
	IUser,
	IWatchlistComment,
	IWatchlistCurrency,
	monthsList,
} from '../../../types';
import { isEmpty, numberWithCommas } from '../../../utils';
import { MultiSelect } from '../../MultiSelect';
import { MultiSelectCreatable } from '../../MultiSelectCreatable';
import { FinancialModal } from '../FinancialModal';
import { FinancialWidget } from '../FinancialWidget';
import { DebtModal } from '../DebtModal';

import avatar from './avatar.svg';
import prospectFile from './prospectFile.svg';

import './Overview.scss';

interface IOverviewProps {
	companyId: number;
	companyName: string;
	currentUser: ICurrentUser;
	subscribers: IPerson[];
	owners: IPerson[];
	users: IUser[];
	status: string;
	documents: IProspectDocument[];
	watchlists: {
		id: number;
		name: string;
		status: string;
	}[];
	isExistInBIC: boolean;
	financialSummary: IFinancialSummary;
	isSuperAdmin: boolean;
	linkedFiles: IProspectLinkedFiles[];

	onWatchlistClick(watchlistId: number): void;

	onOwnerRemove(user: IPerson): void;

	onOwnerAdd(user: IPerson): void;
}

interface IOverviewState {
	isLoading: boolean;
	isAddingComment: boolean;
	isAddingTag: boolean;
	isAddTagModalOpen: boolean;
	isAddingLinkedFile: boolean;
	isAddLinkedFileModalOpen: boolean;
	isAddOwnerModalOpen: boolean;
	isAddTeamModalOpen: boolean;
	isEditFinancialsOpen: boolean;
	isEditDebtOpen: boolean;
	isAddEditCommentModalOpen: boolean;
	isRemoveCommentModalOpen: boolean;
	isRemoveFileModalOpen: boolean;
	isRemoveLinkedFileModalOpen: boolean;
	tags: ITag[];
	companyTags: ITag[];
	newTag: string;
	documents: IProspectDocument[];
	subscribers: IPerson[];
	owners: IPerson[];
	comments: IWatchlistComment[];
	filteredComments: IWatchlistComment[];
	commentsCopy: IWatchlistComment[];
	newComment: IWatchlistComment;
	commentToEdit: IWatchlistComment;
	commentToDeleteId: number;
	currentTab: string;
	watchlists: {
		id: number;
		name: string;
		status: string;
	}[];
	currencies: IWatchlistCurrency[];
	financialData: IFinancialSummary;
	financialDataTemp: IFinancialSummary;
	isFinancialDataSyncing: boolean;
	materialDebt: IMaterialDebt[];
	materialDebtBackup: IMaterialDebt[];
	materialDebtCopy: IMaterialDebt[]; // For modal form
	isReviewLoading: boolean;
	isFileUploading: boolean;
	fileNameToDelete: string;
	fileNameToUpload: string;
	linkedFiles: IProspectLinkedFiles[];
	title: string;
	url: string;
	targetType: string;
	linkedFileToDeleteId: number;
}

const sortByDate = (a: IWatchlistComment, b: IWatchlistComment) => {
	const dateA = new Date(a.createdOn).getTime();
	const dateB = new Date(b.createdOn).getTime();
	return dateA > dateB ? -1 : 1;
};

const getStatusIcon = (status: string) => {
	let iconName = 'outline_check_circle_outline';

	if (status === 'Monitor') {
		iconName = 'radial_chart';
	} else if (status === 'Drop') {
		iconName = 'outline_cancel';
	}
	return iconName;
};

const STATUS_OPTIONS = [
	{
		label: 'Monitor',
		value: 'Monitor',
	},
	{
		label: 'Pursuit',
		value: 'Pursuit',
	},
	{
		label: 'Drop',
		value: 'Drop',
	},
];

const formatMaterialDebt = (materialDebt: IMaterialDebt[]): IMaterialDebt[] =>
	materialDebt.map((d) => {
		return {
			...d,
			date: `${moment(d.date).format('M')}/01/${moment(d.date).format('YYYY')}`,
		};
	});

export class Overview extends React.Component<IOverviewProps, IOverviewState> {
	newComment: IWatchlistComment = {
		id: 0,
		analysis: '',
		companyId: this.props.companyId,
		companyName: this.props.companyName,
		type: 'CurrentSituation',
		createdOn: '',
		updatedOn: '',
		createdBy: {} as ICurrentUser,
		isObsolete: false,
		reviewedOn: '',
		reviewedBy: {
			email: '',
			firstName: '',
			lastName: '',
			userId: '',
		},
		isReviewed: false,
	};

	state = {
		isLoading: true,
		isAddingComment: false,
		isAddingTag: false,
		isAddTagModalOpen: false,
		isAddingLinkedFile: false,
		isAddLinkedFileModalOpen: false,
		isAddOwnerModalOpen: false,
		isAddTeamModalOpen: false,
		isAddEditCommentModalOpen: false,
		isEditFinancialsOpen: false,
		isEditDebtOpen: false,
		isRemoveCommentModalOpen: false,
		isRemoveFileModalOpen: false,
		isRemoveLinkedFileModalOpen: false,
		tags: [] as ITag[],
		companyTags: [] as ITag[],
		newTag: '',
		subscribers: this.props.subscribers,
		owners: this.props.owners,
		documents: this.props.documents,
		linkedFiles: this.props.linkedFiles,
		comments: [] as IWatchlistComment[],
		filteredComments: [] as IWatchlistComment[],
		commentsCopy: [] as IWatchlistComment[],
		newComment: this.newComment,
		commentToEdit: {} as IWatchlistComment,
		commentToDeleteId: -1,
		currentTab: '',
		watchlists: this.props.watchlists,
		currencies: [] as IWatchlistCurrency[],
		financialData: this.props.financialSummary,
		financialDataTemp: this.props.financialSummary,
		isFinancialDataSyncing: false,
		materialDebt: formatMaterialDebt(this.props.financialSummary.materialDebt),
		materialDebtBackup: formatMaterialDebt(
			this.props.financialSummary.materialDebt,
		),
		materialDebtCopy: formatMaterialDebt(
			this.props.financialSummary.materialDebt,
		),
		isReviewLoading: false,
		isFileUploading: false,
		fileNameToDelete: '',
		fileNameToUpload: '',
		title: '',
		url: '',
		targetType: '_blank',
		linkedFileToDeleteId: -1,
		linkFileNameToDelete: '',
	};

	fileInputRef = React.createRef<HTMLInputElement>();
	dropzoneRef = React.createRef<DropzoneRef>();

	async componentDidMount() {
		const { companyId } = this.props;
		window.scrollTo(0, 0);

		try {
			const [
				allTagsResponse,
				companyTagsResponse,
				commentsResponse,
				currenciesResponse,
			] = await Promise.all([
				getAllTags(),
				getCompanyTag(companyId),
				getWatchlistComments(companyId),
				getWatchlistCurrencies(),
			]);
			if (
				allTagsResponse.data &&
				companyTagsResponse.data &&
				commentsResponse.data &&
				currenciesResponse.data
			) {
				this.setState({
					tags: allTagsResponse.data,
					companyTags: companyTagsResponse.data,
					comments: commentsResponse.data.sort(sortByDate),
					filteredComments: commentsResponse.data.sort(sortByDate),
					commentsCopy: commentsResponse.data.sort(sortByDate),
					currencies: currenciesResponse.data,
					isLoading: false,
				});
			} else {
				throw new Error('Error loading prospect data');
			}
		} catch (error) {
			console.log('Error loading prospect data');
		}
	}

	handleSelectTab = (currentTab: string) => {
		this.setState(
			(prevState) => {
				return {
					currentTab,
				};
			},
			() => {
				this.handleApplyFilters();
			},
		);
	};

	handleApplyFilters = () => {
		const { currentTab } = this.state;

		if (currentTab === 'obsolete') {
			this.setState((prevState) => {
				return {
					filteredComments: prevState.comments
						.filter((c) => c.isObsolete)
						.sort(sortByDate),

					newComment: {
						...prevState.newComment,
						type: !['', 'obsolete'].includes(currentTab)
							? currentTab
							: 'CurrentSituation',
					},
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					filteredComments: currentTab
						? prevState.comments
								.filter((c) => c.type === currentTab)
								.sort(sortByDate)
						: prevState.comments,

					newComment: {
						...prevState.newComment,
						type: !['', 'obsolete'].includes(currentTab)
							? currentTab
							: 'CurrentSituation',
					},
				};
			});
		}
	};

	handleEditComment = async () => {
		const { commentToEdit } = this.state;

		try {
			this.setState(
				(prevState) => {
					return {
						isAddEditCommentModalOpen: false,
						commentToEdit: {} as IWatchlistComment,
						comments: prevState.comments.map((c) => {
							return c.id === commentToEdit.id
								? {
										...commentToEdit,
										isReviewed: false,
										reviewedBy: {
											email: '',
											firstName: '',
											lastName: '',
											userId: '',
										},
										reviewedOn: '',
								  }
								: c;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await editWatchlistComment(
				commentToEdit.id,
				commentToEdit.analysis,
				commentToEdit.type,
			);

			this.setState((prevState) => {
				return {
					commentsCopy: prevState.comments,
				};
			});
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddEditCommentModalOpen: false,
						commentToEdit: {} as IWatchlistComment,
						comments: [...prevState.commentsCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while saving comment',
					});
				},
			);
		}
	};

	handleFetchFinancialSummary = async () => {
		const { companyId } = this.props;

		try {
			const prospectResponse = await getProspectById(`${companyId}`);
			if (prospectResponse.data) {
				this.setState((prevState) => {
					return {
						financialData: prospectResponse.data.financialSummary,
						financialDataTemp: prospectResponse.data.financialSummary,
					};
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	handleCloseEditFinancialsModal = () => {
		this.setState((prevState) => {
			return {
				isEditFinancialsOpen: false,
				financialDataTemp: prevState.financialData,
			};
		});
	};

	handleCloseEditDebtModal = () => {
		this.setState((prevState) => {
			return {
				isEditDebtOpen: false,
				materialDebtCopy: prevState.materialDebt,
			};
		});
	};

	handleSyncFinancialData = async () => {
		const { companyId, companyName } = this.props;
		try {
			this.setState({ isFinancialDataSyncing: true });

			await syncFinancialSummaryWithCapIQ(companyId).then(
				this.handleFetchFinancialSummary,
			);

			this.setState({
				isFinancialDataSyncing: false,
			});
			snack.success({
				message: 'Financial summary is succesfully synced with CAP IQ',
			});
		} catch (error) {
			if (error && error.statusCode && error.statusCode === 400) {
				snack.warning({
					message: `No data found for '${companyName}' company in Cap IQ`,
				});
			} else {
				snack.error({
					message: `There was an error saving financial data for '${companyName}' company`,
				});
			}
			this.setState({ isFinancialDataSyncing: false });
		}
	};

	handleRemoveComment = async () => {
		const { commentToDeleteId } = this.state;
		const id = commentToDeleteId;
		try {
			this.setState(
				(prevState) => {
					return {
						commentToDeleteId: -1,
						isRemoveCommentModalOpen: false,
						comments: [...prevState.comments.filter((c) => c.id !== id)],
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await deleteWatchlistComment(id);
			this.setState((prevState) => {
				return {
					commentsCopy: [...prevState.commentsCopy.filter((c) => c.id !== id)],
				};
			});
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isRemoveCommentModalOpen: false,
						comments: [...prevState.commentsCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while removing comment',
					});
				},
			);
		}
	};

	handleChangeCommentType = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { commentToEdit } = this.state;
		const { value } = event.target as HTMLInputElement;

		this.setState((prevState) => {
			return {
				newComment: { ...prevState.newComment, type: value },
			};
		});

		if (isEmpty(commentToEdit)) {
			this.setState((prevState) => {
				return {
					newComment: { ...prevState.newComment, type: value },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					commentToEdit: { ...prevState.commentToEdit, type: value },
				};
			});
		}
	};

	handleChangeCommentAnalysis = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { commentToEdit } = this.state;
		const { value } = event.target as HTMLInputElement;

		if (isEmpty(commentToEdit)) {
			this.setState((prevState) => {
				return {
					newComment: { ...prevState.newComment, analysis: value },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					commentToEdit: { ...prevState.commentToEdit, analysis: value },
				};
			});
		}
	};

	handleMarkAsObsolete = async (commentId: number) => {
		try {
			this.setState(
				(prevState) => {
					return {
						comments: prevState.comments.map((c) => {
							return c.id === commentId
								? { ...c, isObsolete: !c.isObsolete }
								: c;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await markWatchlistCommentObsolete(commentId);
		} catch (error) {
			snack.error({
				message: 'There was an error while updating comment',
			});
		}
	};

	handleAddComment = async () => {
		const {
			newComment: { analysis, type },
			comments,
		} = this.state;
		const { companyId, companyName } = this.props;

		const newCommentId = Math.max(...comments.map((c) => c.id)) * 10 + 1;
		const newCommentDate = moment.utc().format();
		const { currentUser } = this.props;

		const newWatchlistComment: IWatchlistComment = {
			id: newCommentId,
			createdBy: currentUser,
			companyId,
			companyName,
			analysis,
			createdOn: newCommentDate,
			updatedOn: newCommentDate,
			type,
			isObsolete: false,
			reviewedOn: '',
			reviewedBy: {
				email: '',
				firstName: '',
				lastName: '',
				userId: '',
			},
			isReviewed: false,
		};

		try {
			this.setState(
				(prevState) => {
					return {
						isAddingComment: true,
						isAddEditCommentModalOpen: false,
						comments: [newWatchlistComment, ...prevState.comments],
						newComment: {
							...this.newComment,
							type: !['', 'obsolete'].includes(prevState.currentTab)
								? prevState.currentTab
								: 'CurrentSituation',
						},
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
			const { data } = await postWatchlistComment(
				companyId,
				newWatchlistComment.analysis,
				newWatchlistComment.type,
			);

			this.setState(
				(prevState) => {
					const newComments = prevState.comments.map((c) => {
						return c.id === newCommentId
							? {
									...c,
									id: data,
							  }
							: c;
					});

					return {
						isAddingComment: false,
						comments: newComments,
						commentsCopy: newComments,
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddEditCommentModalOpen: false,
						isAddingComment: false,
						comments: [...prevState.commentsCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while adding comment',
					});
				},
			);
		}
	};

	handleCloseAddEditCommentModal = () => {
		this.setState(
			(prevState) => {
				return {
					isAddEditCommentModalOpen: false,
					newComment: {
						...this.newComment,
						type: !['', 'obsolete'].includes(prevState.currentTab)
							? prevState.currentTab
							: 'CurrentSituation',
					},
				};
			},
			() => {
				this.setState({
					commentToEdit: {} as IWatchlistComment,
				});
			},
		);
	};

	handleCloseDeleteModal = () => {
		this.setState({ isRemoveCommentModalOpen: false, commentToDeleteId: -1 });
	};

	handleCloseDeleteFileModal = () => {
		this.setState({ isRemoveFileModalOpen: false, fileNameToDelete: '' });
	};

	handleOpenDeleteCommentModal = (id: number) => {
		this.setState({ isRemoveCommentModalOpen: true, commentToDeleteId: id });
	};

	handleOpenAddCommentModal = () => {
		this.setState({ isAddEditCommentModalOpen: true });
	};

	handleOpenEditCommentModal = (commentToEdit: IWatchlistComment) => {
		this.setState({
			isAddEditCommentModalOpen: true,
			commentToEdit,
		});
	};

	handleAddOwner = async (option: any) => {
		const { companyId, companyName } = this.props;

		const { users } = this.props;

		const user = users.find((u) => u.id === option.value);

		if (user) {
			const { firstName, lastName, email, id } = user;
			const userType: number = this.state.isAddTeamModalOpen ? 0 : 1;

			if (this.state.isAddTeamModalOpen) {
				this.setState((prevState) => {
					return {
						isAddTeamModalOpen: false,
						subscribers: [
							...prevState.subscribers,
							{ userId: id, email, firstName, lastName },
						],
					};
				});
			} else {
				this.setState((prevState) => {
					return {
						isAddOwnerModalOpen: false,
						owners: [
							...prevState.owners,
							{ userId: id, email, firstName, lastName },
						],
					};
				});
			}

			try {
				await addCompanyOwner(companyId, user.id, userType);
				this.props.onOwnerAdd({ userId: id, email, firstName, lastName });
				snack.success({
					message: `User ${firstName} ${lastName} is added to '${companyName}'`,
				});
			} catch (error) {
				snack.error({
					message: `Error while adding user ${firstName} ${lastName} to '${companyName}'`,
				});

				if (this.state.isAddTeamModalOpen) {
					this.setState((prevState) => {
						return {
							isAddTeamModalOpen: false,
							subscribers: [
								...prevState.subscribers.filter((s) => s.userId !== id),
							],
						};
					});
				} else {
					this.setState((prevState) => {
						return {
							isAddOwnerModalOpen: false,
							owners: [...prevState.owners.filter((s) => s.userId !== id)],
						};
					});
				}
			}
		}
	};

	handleRemoveOwner = async (user: IPerson) => {
		const { companyId, companyName } = this.props;
		const { userId, firstName, lastName } = user;

		snack.success({
			message: `User ${firstName} ${lastName} is successfully removed from '${companyName}'`,
		});

		this.setState((prevState) => {
			return {
				subscribers: [
					...prevState.subscribers.filter((s) => s.userId !== userId),
				],
			};
		});

		if (this.state.isAddTeamModalOpen) {
			this.setState((prevState) => {
				return {
					isAddTeamModalOpen: false,
					subscribers: [
						...prevState.subscribers.filter((s) => s.userId !== userId),
					],
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					isAddOwnerModalOpen: false,
					owners: [...prevState.owners.filter((s) => s.userId !== userId)],
				};
			});
		}

		try {
			await deleteCompanyOwner(companyId, userId);
			this.props.onOwnerRemove(user);
		} catch (error) {
			snack.error({
				message: `Error while removiong user ${firstName} ${lastName} ffrom '${companyName}'`,
			});

			if (this.state.isAddTeamModalOpen) {
				this.setState((prevState) => {
					return {
						subscribers: [...prevState.subscribers, { ...user }],
					};
				});
			} else {
				this.setState((prevState) => {
					return {
						owners: [...prevState.owners, { ...user }],
					};
				});
			}
		}
	};

	handleCloseTagModal = () => {
		this.setState({ isAddTagModalOpen: false, newTag: '' });
	};

	handleCloseLinkedFileModal = () => {
		this.setState({ isAddLinkedFileModalOpen: false, newTag: '' });
	};

	handleCloseAddOwnerModal = () => {
		this.setState({ isAddOwnerModalOpen: false, isAddTeamModalOpen: false });
	};

	handleAddTag = (option: any) => {
		if (option) {
			const newTag: string = option.label;

			this.setState({ newTag, isAddTagModalOpen: false }, () => {
				this.handleAddCompanyTag();
			});
		}
	};

	handleAddNewTag = (tag: string) => {
		const newTag: string = tag;

		this.setState({ newTag, isAddTagModalOpen: false }, () => {
			this.handleAddCompanyTag();
		});
	};

	handleReviewComment = async (commentId: number) => {
		const { currentUser } = this.props;

		this.setState(
			(prevState) => {
				return {
					isReviewLoading: true,
					comments: prevState.comments.map((c) => {
						return c.id === commentId
							? {
									...c,
									isReviewed: !c.isReviewed,
									reviewedBy: {
										email: 'string',
										firstName: currentUser.firstName,
										lastName: currentUser.lastName,
										userId: '',
									},
									reviewedOn: moment().format(),
							  }
							: c;
					}),
				};
			},
			() => {
				this.handleApplyFilters();
			},
		);

		try {
			await reviewComment(commentId);
			this.setState({ isReviewLoading: false });
		} catch (error) {
			this.setState({ isReviewLoading: false });
		}
	};

	handleAddCompanyTag = async () => {
		const { tags, companyTags, newTag } = this.state;

		this.setState({ isAddingTag: true });

		const { companyId } = this.props;

		const maxTagId = Math.max(...tags.map((t) => t.tagId));
		const maxCompanyTagId = Math.max(...companyTags.map((t) => t.tagId));
		const isExistingTag = tags.find(
			(t) => t.name.trim().toLowerCase() === newTag.toLowerCase().trim(),
		);

		if (
			companyTags
				.map((t) => t.name.trim().toLowerCase())
				.includes(newTag.trim().toLowerCase())
		) {
			this.setState({ isAddTagModalOpen: false, newTag: '' });
			return;
		}

		this.setState((prevState) => {
			return {
				tags: isExistingTag
					? prevState.tags
					: [
							...prevState.tags,
							{
								tagId: maxTagId + 1,
								name: newTag.trim().toLowerCase(),
							},
					  ],
				companyTags: [
					...prevState.companyTags,
					{
						tagId: maxCompanyTagId + 1,
						name: newTag.trim().toLowerCase(),
					},
				],

				isAddTagModalOpen: false,
				newTag: '',
			};
		});

		try {
			await addCompanyTag(companyId, [newTag]);
			const { data } = await getCompanyTag(companyId);

			const tag = data.find((t) => {
				return t.name.toLowerCase() === newTag.toLowerCase();
			});

			if (tag) {
				this.setState((prevState) => {
					return {
						isAddingTag: false,
						companyTags: prevState.companyTags.map((t) => {
							return t.name.toLowerCase() === newTag.toLowerCase()
								? { ...t, tagId: tag.tagId }
								: t;
						}),
					};
				});
			}

			snack.success({
				message: `Tag '${newTag}' was successfully added`,
			});
		} catch (error) {
			snack.error({
				message: `Error while adding tag '${newTag}'`,
			});
			this.setState((prevState) => {
				return {
					tags: prevState.tags.filter((t) => t.name !== newTag),
					isAddingTag: false,
				};
			});
		}
	};

	handleRemoveTag = async (tag: ITag) => {
		const { companyId } = this.props;

		try {
			this.setState((prevState) => {
				return {
					companyTags: prevState.companyTags.filter((t) => t.name !== tag.name),
				};
			});
			await getAllTags().then((response) => {
				return deleteCompanyTag(
					response.data.find((t) => t.name === tag.name)!.tagId,
					companyId,
				);
			});
			snack.success({
				message: `Tag '${tag.name}' was successfully removed`,
			});
		} catch (error) {
			snack.error({
				message: `Error while removing '${tag.name}'`,
			});
			this.setState((prevState) => {
				return {
					companyTags: [...prevState.companyTags, tag],
				};
			});
		}
	};

	handleStatusChange = async (
		watchlistId: number,
		watchlistName: string,
		currentStatus: string,
		option: IOption | IOption[],
	) => {
		const newStatus = (option as IOption).value;
		const { companyId, companyName } = this.props;

		if (currentStatus !== newStatus) {
			try {
				this.setState((prevState) => {
					return {
						watchlists: prevState.watchlists.map((w) => {
							return w.id === watchlistId ? { ...w, status: newStatus } : w;
						}),
					};
				});

				await updateProspect(watchlistId, companyId, newStatus);
				snack.success({
					message: `Status is updated from '${currentStatus}' to '${newStatus}' for '${companyName}' company in '${watchlistName}' watchlist`,
				});
			} catch (error) {
				snack.error({
					message: `Error while updating status for '${companyName}' company`,
				});
				this.setState((prevState) => {
					return {
						watchlists: prevState.watchlists.map((w) => {
							return w.id === watchlistId ? { ...w, status: currentStatus } : w;
						}),
					};
				});
			}
		}
	};

	handleChangeFinancial = (value: string | number, name: string) => {
		this.setState((prevState) => {
			return {
				financialDataTemp: {
					...prevState.financialDataTemp,
					[name]: value,
				},
			};
		});
	};

	handleSaveFinancials = async () => {
		const { companyId, companyName, currentUser } = this.props;
		const { currencies } = this.state;
		const {
			calendarYear,
			currencyCode,
			marketCap,
			totalRevenue,
			totalEBITDA,
			totalDebt,
			cash,
			revenueGrowth,
			ebitdaGrowth,
			totalDebtOverEBITDA,
			interestCoverage,
			periodEndDate,
		} = this.state.financialDataTemp;

		const validFinancialData: IFinancialSummary = {
			...this.state.financialDataTemp,
			marketCap: marketCap ? +marketCap : 0,
			totalRevenue: totalRevenue ? +totalRevenue : 0,
			totalEBITDA: totalEBITDA ? +totalEBITDA : 0,
			totalDebt: totalDebt ? +totalDebt : 0,
			cash: cash ? +cash : 0,
			revenueGrowth: revenueGrowth ? +revenueGrowth : 0,
			ebitdaGrowth: ebitdaGrowth ? +ebitdaGrowth : 0,
			totalDebtOverEBITDA: totalDebtOverEBITDA ? +totalDebtOverEBITDA : 0,
			interestCoverage: interestCoverage ? +interestCoverage : 0,
			isManualyEdited: true,
		};

		const fyMonth = parseInt(moment(periodEndDate).format('M'));
		const currencyId =
			currencies.find((c) => {
				return c.currencyCode === currencyCode;
			})!.currencyId || 160;

		this.setState((prevState) => {
			return {
				isEditFinancialsOpen: false,
				isFinancialDataSyncing: true,
			};
		});

		try {
			await updateFinancialSummary(
				companyId,
				calendarYear,
				fyMonth,
				currencyId,
				validFinancialData.marketCap,
				validFinancialData.totalRevenue,
				validFinancialData.totalEBITDA,
				validFinancialData.totalDebt,
				validFinancialData.cash,
				validFinancialData.revenueGrowth,
				validFinancialData.ebitdaGrowth,
				validFinancialData.totalDebtOverEBITDA,
				validFinancialData.interestCoverage,
			);

			this.setState((prevState) => {
				return {
					financialData: validFinancialData,
					financialDataTemp: validFinancialData,
					isEditFinancialsOpen: false,
					isFinancialDataSyncing: false,
				};
			});
			snack.success({
				message: `Financial summary is succesfully updated by ${currentUser.firstName} ${currentUser.lastName}`,
			});
		} catch (error) {
			this.setState((prevState) => {
				snack.error({
					message: `There was an error saving financial data for '${companyName}' company`,
				});
				return {
					isEditFinancialsOpen: true,
					isFinancialDataSyncing: false,
				};
			});
		}
	};

	handleChangeMaterialDebtDate = (id: number, value: string, key: string) => {
		this.setState((prevState) => {
			return {
				materialDebtCopy: prevState.materialDebtCopy.map((d) => {
					const { date } = d;
					const month = key === 'month' ? value : date.split('/')[0];
					const year = key === 'year' ? value : date.split('/')[2];

					return d.id === id
						? {
								...d,
								date: `${month}/01/${year}`,
						  }
						: d;
				}),
			};
		});
	};

	handleChangeMaterialDebtValue = (id: number, value: string) => {
		// @ts-ignore
		if (isNaN(value) || value.length > 20) {
			return;
		}

		// @ts-ignore
		this.setState((prevState) => {
			return {
				materialDebtCopy: prevState.materialDebtCopy.map((d) => {
					return d.id === id
						? {
								...d,
								debt: value,
						  }
						: d;
				}),
			};
		});
	};

	handleDeleteMaterialDebt = (id: number) => {
		this.setState((prevState) => {
			return {
				materialDebtCopy: prevState.materialDebtCopy.filter((d) => {
					return d.id !== id;
				}),
			};
		});
	};

	handleAddMaterialDebt = () => {
		this.setState((prevState) => {
			const maxId = prevState.materialDebtCopy.length
				? Math.max(...prevState.materialDebtCopy.map((d) => d.id))
				: 0;
			return {
				materialDebtCopy: [
					...prevState.materialDebtCopy,
					{
						id: maxId + 1,
						debt: 0,
						date: `${moment().format('M')}/01/${moment().format('YYYY')}`,
					},
				],
			};
		});
	};

	handleSaveMaterialDebt = async () => {
		const { materialDebtCopy } = this.state;
		const { companyId, companyName } = this.props;

		try {
			this.setState((prevState) => {
				const materialDebtCopy = prevState.materialDebtCopy
					.filter((d) => d.debt !== 0)
					.map((d) => ({ ...d, id: 0, debt: +d.debt }));

				return {
					isEditDebtOpen: false,
					materialDebt: materialDebtCopy,
					materialDebtCopy,
				};
			});

			await updateMaterialDebt(
				companyId,
				materialDebtCopy
					.filter((d) => d.debt !== 0)
					.map((d) => ({ ...d, id: 0, debt: +d.debt })),
			);

			snack.success({
				message: `Material debt data is succesfully updated for '${companyName}' company`,
			});

			this.setState((prevState) => {
				return {
					materialDebtBackup: prevState.materialDebt,
				};
			});
		} catch (error) {
			snack.error({
				message: `There was an error updating material debt data for '${companyName}' company`,
			});
			this.setState((prevState) => {
				return {
					materialDebt: prevState.materialDebtBackup,
				};
			});
		}
	};

	handleFileUpload = async (files: File[]) => {
		const { companyId } = this.props;

		if (files.length === 0) {
			return;
		}

		const documentsCopy = [...this.state.documents];
		const fileName = files[0].name;

		this.setState(() => {
			return {
				fileNameToUpload: fileName,
			};
		});

		this.setState((prevState) => {
			return {
				isFileUploading: true,
				fileNameToUpload: fileName,
				documents: [
					...prevState.documents,
					{
						id: -1,
						companyId,
						fileName,
						createdBy: {
							firstName: this.props.currentUser.firstName,
							lastName: this.props.currentUser.lastName,
							id: this.props.currentUser.userId,
							email: this.props.currentUser.email,
						},
						createdOn: moment.utc().format(),
						updatedOn: '',
					},
				],
			};
		});

		const formData = new FormData();
		formData.append('file', files[0]);

		snack.success({
			message: `File upload for '${fileName}' is in progress...`,
		});

		this.setState(() => {
			return {
				isFileUploading: false,
			};
		});

		try {
			await uploadProspectFile(companyId, formData);

			this.setState(() => {
				return {
					fileNameToUpload: '',
					isFileUploading: false,
				};
			});

			snack.success({
				message: `File upload for '${fileName}' is completed`,
			});
		} catch (error) {
			this.setState(() => {
				return {
					fileNameToUpload: '',
					documents: documentsCopy,
				};
			});
			snack.error({
				message: error.message
					? error.message
					: `There was an error while uploading file`,
			});
		}
	};

	handleDownloadFile = async (document: IProspectDocument) => {
		const { companyId } = this.props;
		const { fileName } = document;

		try {
			const response = await downloadProspectFile(companyId, fileName);
			const blob = await response.blob();
			download(blob, fileName);
		} catch (error) {}
	};

	handleOpenDeleteFileModal = (fileName: string) => {
		this.setState({ isRemoveFileModalOpen: true, fileNameToDelete: fileName });
	};

	handleFileDelete = () => {
		const { fileNameToDelete } = this.state;
		const { companyId } = this.props;

		const documentsCopy = [...this.state.documents];

		this.setState((prevState) => {
			return {
				isRemoveFileModalOpen: false,
				documents: prevState.documents.filter(
					(f) => f.fileName !== fileNameToDelete,
				),
			};
		});

		try {
			deleteProspectFile(companyId, fileNameToDelete);
			snack.success({
				message: `File '${fileNameToDelete}' is removed succesfully`,
			});
		} catch (error) {
			snack.error({
				message: `Error while removing '${fileNameToDelete}' file`,
			});
			this.setState(() => {
				return {
					documents: documentsCopy,
				};
			});
		}
	};

	// Linked file

	handleTitleChange = (
		title: string,
		e?: React.ChangeEvent<HTMLInputElement> | undefined,
	): void => {
		this.setState({ title });
	};

	handleUrlChange = (
		url: string,
		e?: React.ChangeEvent<HTMLInputElement>,
	): void => {
		this.setState({ url });
	};

	isValidUrl = (url: string) => {
		const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
		return urlRegex.test(url);
	};

	handleAddLinkedFile = async () => {
		const { companyId } = this.props;
		this.setState({
			isAddingLinkedFile: true,
		});
		const { title, url, targetType } = this.state;

		if (!url || !this.isValidUrl(url)) {
			snack.error({
				message: 'Invalid URL, please check and try again',
			});
			return;
		}

		this.setState((prevState) => {
			return {
				isAddingLinkedFile: false,
				isAddLinkedFileModalOpen: false,
				linkedFiles: [
					...prevState.linkedFiles,
					{ id: -1, companyId, title, url, targetType },
				],
			};
		});

		try {
			await addCompanyLinkedFiles(companyId, title, url, targetType);
			snack.success({
				message: 'File link added successfully',
			});
		} catch (error) {
			snack.error({
				message: 'There was an error while adding file link',
			});
			this.setState(() => {
				return {
					isAddingLinkedFile: false,
					isAddLinkedFileModalOpen: false,
				};
			});
		}
	};

	handleOpenDeleteLinkedFileModal = (linkedFileId: number) => {
		this.setState({
			isRemoveLinkedFileModalOpen: true,
			linkedFileToDeleteId: linkedFileId,
		});
	};

	handleCloseDeleteLinkedFileModal = () => {
		this.setState({
			isRemoveLinkedFileModalOpen: false,
			linkedFileToDeleteId: -1,
		});
	};

	handleLinkedFileDelete = async () => {
		const { linkedFileToDeleteId } = this.state;
		const linkedFileId = linkedFileToDeleteId;

		try {
			await deleteCompanyLinkedFiles(linkedFileId);

			this.setState((prevState) => {
				return {
					linkedFileToDeleteId: -1,
					isRemoveLinkedFileModalOpen: false,
					linkedFiles: [
						...prevState.linkedFiles.filter((f) => f.id !== linkedFileId),
					],
				};
			});

			snack.success({
				message: 'File link removed successfully',
			});
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isRemoveLinkedFileModalOpen: false,
						linkedFiles: [...prevState.linkedFiles],
					};
				},
				() => {
					snack.error({
						message: 'There was an error while removing file link',
					});
				},
			);
		}
	};

	render() {
		const {
			isLoading,
			isAddTagModalOpen,
			isAddLinkedFileModalOpen,
			isAddOwnerModalOpen,
			isAddTeamModalOpen,
			isEditFinancialsOpen,
			isEditDebtOpen,
			tags,
			companyTags,
			subscribers,
			owners,
			isAddEditCommentModalOpen,
			isRemoveCommentModalOpen,
			isRemoveFileModalOpen,
			isRemoveLinkedFileModalOpen,
			newComment,
			commentToEdit,
			comments,
			documents,
			linkedFiles,
			filteredComments,
			isAddingComment,
			isAddingTag,
			currentTab,
			watchlists,
			financialData,
			financialDataTemp,
			isFinancialDataSyncing,
			materialDebtCopy,
			materialDebt,
			currencies,
			isReviewLoading,
			isFileUploading,
		} = this.state;
		const {
			users,
			onWatchlistClick,
			currentUser,
			isExistInBIC,
			isSuperAdmin,
		} = this.props;

		const isEditMode = !isEmpty(commentToEdit);
		const comment = isEditMode ? commentToEdit : newComment;

		if (isLoading) {
			return (
				<div>
					<ApLoaderDefault />
				</div>
			);
		}

		const isOwner = owners.map((o) => o.userId).includes(currentUser.userId);

		const isEditable = isOwner || isSuperAdmin;

		const { csCount, apCount } = comments.reduce(
			(
				acc: {
					csCount: number;
					apCount: number;
				},
				cv,
			) => {
				if (cv.type === 'CurrentSituation') {
					acc.csCount++;
				} else if (cv.type === 'APView') {
					acc.apCount++;
				}

				return acc;
			},
			{
				csCount: 0,
				apCount: 0,
			},
		);

		const obsoleteCount = comments.filter((c) => c.isObsolete).length;
		const isFinancialFormValid =
			financialDataTemp.calendarYear &&
			financialDataTemp.currencyCode &&
			financialDataTemp.periodEndDate;
		const { currencyCode, isManualyEdited } = financialData;

		const isMaterialDebtFormValid =
			// @ts-ignore
			[...new Set(materialDebtCopy.map((d) => d.date))].length ===
			materialDebtCopy.length;

		return (
			<div className="prospect-overview">
				<div className="prospect-overview__main">
					<div className="prospect-widget prospect-widget--full-length">
						<div className="prospect-widget__title">
							<div>Comments</div>
							<div>
								<ApButtonSecondary
									onClick={this.handleOpenAddCommentModal}
									disabled={isAddingComment}
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Add Comment</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div className="watchlist-comments__tabs">
							<ApButtonGroup justifyContent="initial" spacingSize={0}>
								<ApButtonTab
									isSelected={currentTab === ''}
									onClick={() => {
										this.handleSelectTab('');
									}}
								>
									All ({comments.length})
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === 'CurrentSituation'}
									onClick={() => {
										this.handleSelectTab('CurrentSituation');
									}}
									disabled={csCount === 0}
								>
									Current Situation {csCount > 0 ? `(${csCount})` : ''}
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === 'APView'}
									onClick={() => {
										this.handleSelectTab('APView');
									}}
									disabled={apCount === 0}
								>
									AP View {apCount > 0 ? `(${apCount})` : ''}
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === 'obsolete'}
									onClick={() => {
										this.handleSelectTab('obsolete');
									}}
									disabled={obsoleteCount === 0}
								>
									Obsolete {obsoleteCount > 0 ? `(${obsoleteCount})` : ''}
								</ApButtonTab>
							</ApButtonGroup>
						</div>
						<div className="watchlist-comments">
							{filteredComments.length ? (
								filteredComments.map((c) => {
									const utcDate = moment.utc(c.createdOn).toDate();
									const localDate = moment(utcDate)
										.local()
										.format('Do MMM YYYY, HH:mm');
									return (
										<div
											key={c.id}
											className={`comment${
												c.isObsolete ? ' comment--obsolete' : ''
											}`}
										>
											<div className="comment__avatar">
												<img src={avatar} alt="Avatar" />
											</div>
											<div className="comment__content">
												<div className="comment__wrapper">
													<div className="comment__title">
														<strong>{`${c.createdBy.firstName} ${c.createdBy.lastName}`}</strong>
													</div>
													<div className="comment__date">{localDate}</div>
													<div className="comment__actions">
														{!isAddingComment && (
															<div
																onClick={() => {
																	this.handleMarkAsObsolete(c.id);
																}}
															>
																<ApIcon
																	iconName="outline_visability_off"
																	iconColor="#999999"
																/>
															</div>
														)}
														{(c.createdBy.userId === currentUser.userId ||
															isSuperAdmin) &&
															!isAddingComment && (
																<div
																	onClick={() => {
																		this.handleOpenEditCommentModal(c);
																	}}
																>
																	<ApIcon
																		iconName="outline_edit"
																		iconColor="#999999"
																	/>
																</div>
															)}
														{(c.createdBy.userId === currentUser.userId ||
															isEditable) &&
															!isAddingComment && (
																<div
																	onClick={() => {
																		this.handleOpenDeleteCommentModal(c.id);
																	}}
																>
																	<ApIcon
																		iconName="delete_outline"
																		iconColor="#999999"
																	/>
																</div>
															)}
													</div>
												</div>
												<div className="comment__text">{c.analysis}</div>
												<div className="comment__review">
													<ApCheckbox
														id={`${c.id}`}
														name={`${c.id}`}
														type="checkbox"
														checked={c.isReviewed}
														disabled={isReviewLoading}
														onChange={() => this.handleReviewComment(c.id)}
													>
														{c.isReviewed
															? `Reviewed by ${c.reviewedBy.firstName} ${
																	c.reviewedBy.lastName
															  } on ${moment(moment.utc(c.reviewedOn).toDate())
																	.local()
																	.format('Do MMM YYYY, HH:mm')}`
															: 'Mark as reviewed'}
													</ApCheckbox>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<div className="comment__no-comments">No comments</div>
							)}
						</div>
					</div>
				</div>
				<div className="prospect-overview__ahead">
					<div className="prospect-widget prospect-widget--no-padding">
						<div className="padding-wrapper">
							{isFinancialDataSyncing && <ApLoaderDefault />}
							<div className="prospect-widget__title">
								<div>
									Financial Summary
									{(!isExistInBIC || isManualyEdited) && (
										<span className="prospect-widget__info"> (Manual)</span>
									)}
								</div>
								<div>
									<ApButtonSecondary
										onClick={() => {
											this.setState((prevState) => {
												return {
													isEditFinancialsOpen: true,
												};
											});
										}}
										disabled={!isEditable || isFinancialDataSyncing}
									>
										<div className="button-wrapper">
											<div>Edit</div>
										</div>
									</ApButtonSecondary>
								</div>
							</div>
							<div className="prospect-widget__timestamp">
								{isExistInBIC ? (
									<span>
										{isManualyEdited
											? `Period End Date: ${moment(
													financialData.periodEndDate,
											  ).format('Do MMM YYYY')}`
											: `Synced with CAP IQ. Period End Date: ${moment(
													financialData.periodEndDate,
											  ).format('Do MMM YYYY')}`}
									</span>
								) : (
									<span>
										{`Period End Date: ${moment(
											financialData.periodEndDate,
										).format('Do MMM YYYY')}`}
									</span>
								)}
							</div>
							<FinancialWidget
								currencyCode={currencyCode || ''}
								financialData={financialData}
							/>
						</div>
						<div className="financial__separator" />
						<div className="padding-wrapper">
							<div className="financial__row financial__row--separator">
								<div className="prospect-widget__title">
									<div>
										Debt Maturity
										<span className="prospect-widget__info"> (Manual)</span>
									</div>
									<div>
										<ApButtonSecondary
											onClick={() => {
												this.setState({ isEditDebtOpen: true });
											}}
											disabled={!isEditable || isFinancialDataSyncing}
										>
											<div className="button-wrapper">
												<div>Edit</div>
											</div>
										</ApButtonSecondary>
									</div>
								</div>
								<div>
									<div className="financial__label">
										Next Material Debt Maturity
									</div>
									<div className="financial__value">
										{materialDebt.length ? (
											<div className="financial__debt">
												{materialDebt.map((d, index) => {
													const { date, debt } = d;
													const month = date.split('/')[0];
													const year = date.split('/')[2];
													return (
														<div key={d.id} className="financial__debt__row">
															{`${index + 1}. ${
																monthsList[parseInt(month)]
															} ${year}, ${
																currencyCode
																	? getSymbolFromCurrency(currencyCode)
																	: ''
															}`}
															<span className="numeric">
																{numberWithCommas(debt)}
															</span>
														</div>
													);
												})}
											</div>
										) : (
											'No data'
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="financial__sync">
							<div className="financial__sync__text">
								<div className="financial__sync__label">
									Sync this entry with CAP IQ
								</div>
								{/* <div>There is data available to be synced.</div> */}
							</div>
							<div className="financial__sync__button">
								<ApButtonMain
									disabled={isFinancialDataSyncing || !isExistInBIC}
									onClick={this.handleSyncFinancialData}
								>
									Sync
								</ApButtonMain>
							</div>
						</div>
					</div>
					<div className="prospect-widget">
						<div className="prospect-widget__title">
							<div>Owners</div>
							<div>
								<ApButtonSecondary
									onClick={() => {
										this.setState({ isAddOwnerModalOpen: true });
									}}
									disabled={!isEditable}
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Add Owners</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div>
							{owners.map((s) => {
								return (
									<div className="prospect-owner" key={s.userId}>
										{owners.length > 1 && isEditable && (
											<div
												className="prospect-owner__remove"
												onClick={() => {
													this.handleRemoveOwner(s);
												}}
											>
												<ApIcon iconName="delete_outline" iconColor="#999999" />
											</div>
										)}
										<div className="prospect-owner__avatar">
											<img src={avatar} alt={`${s.firstName} ${s.lastName}`} />
										</div>
										<div className="prospect-owner__info">
											<div className="prospect-owner__name">{`${s.firstName} ${s.lastName}`}</div>
											<div>{s.email}</div>
										</div>
									</div>
								);
							})}
						</div>
						<br />
						<div className="prospect-widget__title">
							<div>Team</div>
							<div>
								<ApButtonSecondary
									disabled={!isEditable}
									onClick={() => {
										this.setState({ isAddTeamModalOpen: true });
									}}
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Add Team</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div>
							{subscribers.map((s) => {
								return (
									<div className="prospect-member" key={s.userId}>
										{isEditable && (
											<div
												className="prospect-member__remove"
												onClick={() => {
													this.handleRemoveOwner(s);
												}}
											>
												<ApIcon iconName="delete_outline" iconColor="#999999" />
											</div>
										)}
										<div className="prospect-member__avatar">
											<img src={avatar} alt={`${s.firstName} ${s.lastName}`} />
										</div>
										<div className="prospect-member__info">
											<div className="prospect-member__name">{`${s.firstName} ${s.lastName}`}</div>
											<div>{s.email}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<Dropzone
						ref={this.dropzoneRef}
						noClick
						noKeyboard
						multiple={false}
						onDrop={this.handleFileUpload}
					>
						{({ getRootProps, getInputProps, isDragAccept }) => {
							return (
								<div
									{...getRootProps({
										className: `dropzone prospect-widget${
											isDragAccept ? ' prospect-widget--focused' : ''
										}`,
									})}
								>
									<div className="prospect-widget__drop-message">
										Release to start file upload
									</div>
									<div className="prospect-widget__drop-area">
										<div className="prospect-widget__title">
											<input {...getInputProps()} />
											<div>Files</div>
											<div>
												<ApButtonSecondary
													onClick={() => {
														if (this.dropzoneRef.current) {
															this.dropzoneRef.current.open();
														}
													}}
													disabled={!isEditable || isFileUploading}
												>
													<div className="button-wrapper">
														<ApIcon
															iconName="baseline_add"
															iconColor="#333333"
														></ApIcon>
														<div>Upload files</div>
													</div>
												</ApButtonSecondary>
											</div>
										</div>
										{documents.length > 0 ? (
											<div>
												{documents.map((f, index) => {
													return (
														<div
															className={`prospect-file${
																f.fileName === this.state.fileNameToUpload &&
																index === documents.length - 1
																	? ' prospect-file--inactive'
																	: ''
															}`}
															key={index}
														>
															<div
																className="prospect-file__remove"
																onClick={() =>
																	this.handleOpenDeleteFileModal(f.fileName)
																}
															>
																<ApIcon
																	iconName="delete_outline"
																	iconColor="#999999"
																/>
															</div>
															<div className="prospect-file__avatar">
																<img src={prospectFile} alt={f.fileName} />
															</div>
															<div className="prospect-file__info">
																<div
																	className="prospect-file__name"
																	onClick={() => this.handleDownloadFile(f)}
																>
																	{f.fileName}
																</div>
																<div>{`${f.createdBy.firstName} ${f.createdBy.lastName}`}</div>
																<div>
																	{moment(f.createdOn).format(
																		'Do MMM YYYY, HH:mm',
																	)}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										) : (
											<div>No files</div>
										)}
									</div>
								</div>
							);
						}}
					</Dropzone>
					<div className="prospect-widget">
						<div className="prospect-widget__title">
							<div>Watchlists</div>
							<div></div>
						</div>
						<div className="prospect-widget__content">
							<div className="prospect-watchlists">
								<div className="prospect-watchlists__headers">
									<div>Watchlist name</div>
									<div>Status</div>
								</div>
								{watchlists.map((w) => {
									return (
										<div key={w.id} className="prospect-watchlists__items">
											<div>
												<ApButtonLink onClick={() => onWatchlistClick(w.id)}>
													{w.name}
												</ApButtonLink>
											</div>
											<div>
												<ApSimpleDropdown
													disabled={!isEditable}
													iconName={getStatusIcon(w.status)}
													onChange={(option: IOption | IOption[]) => {
														this.handleStatusChange(
															w.id,
															w.name,
															w.status,
															option,
														);
													}}
													options={STATUS_OPTIONS}
													selected={{
														label: w.status,
														value: w.status,
													}}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div className="prospect-widget">
						<div className="prospect-widget__title">
							<div>Tags</div>
							<div>
								<ApButtonSecondary
									onClick={() => {
										this.setState({ isAddTagModalOpen: true });
									}}
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Add Tag</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div className="prospect-widget__content">
							{companyTags.map((t) => {
								return (
									<ApChip
										onClick={() => this.handleRemoveTag(t)}
										className="prospect-widget__tag"
										key={t.tagId}
										iconName="outline_cancel"
										disabled={isAddingTag}
									>
										{t.name}
									</ApChip>
								);
							})}
						</div>
					</div>

					<div className="prospect-widget">
						<div className="prospect-widget__title">
							<div>Linked Files</div>
							<div>
								<ApButtonSecondary
									onClick={() => {
										this.setState({ isAddLinkedFileModalOpen: true });
									}}
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Add Linked File</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div className="prospect-widget__content">
							{linkedFiles?.map((f: IProspectLinkedFiles) => {
								return (
									<div className="linked-file" key={f.id}>
										<div
											className="linked-file__remove"
											onClick={() => this.handleOpenDeleteLinkedFileModal(f.id)}
										>
											<ApIcon iconName="delete_outline" iconColor="#999999" />
										</div>
										<div className="linked-file__name">
											<a
												href={f.url}
												target={f.targetType}
												rel="noopener noreferrer"
											>
												{f.title && f.title.includes('http')
													? f.title.split('/')[2].replace('www.', '')
													: f.title || f.url.split('/')[2].replace('www.', '')}
											</a>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<ApModal
					className="comment-modal"
					isOpen={isAddLinkedFileModalOpen}
					header={'Add Linked File'}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseLinkedFileModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain onClick={this.handleAddLinkedFile}>
								Add Linked File
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					<div className="comment-modal__body">
						<div className="comment-modal__analysis flex-column">
							<label>Title</label>
							<ApInput
								onChange={this.handleTitleChange}
								placeholder={'Add title'}
							/>
						</div>

						<div className="comment-modal__analysis flex-column">
							<label>URL</label>
							<ApInput
								onChange={this.handleUrlChange}
								placeholder={'Add URL'}
							/>
						</div>
					</div>
				</ApModal>

				<ApModal
					isDismissible
					className="prospect-widget__tag-modal"
					isOpen={isAddTagModalOpen}
					hasClosed={this.handleCloseTagModal}
					header="Add Tag to a Company"
				>
					<div className="modal__content">
						<p>
							Please find a tag name in existing list. If you want to create a
							new one, just type new name in the field and press create.
						</p>
						<label className="comment-modal__label">Tags</label>
						<MultiSelectCreatable
							isClearable
							options={tags
								.filter((t) => t.name)
								.filter(
									(t) => !companyTags.map((ct) => ct.name).includes(t.name),
								)
								.map((t) => ({
									label: t.name,
									value: t.tagId + '',
								}))}
							onCreateOption={this.handleAddNewTag}
							onChange={this.handleAddTag}
						/>
					</div>
				</ApModal>
				<ApModal
					isDismissible
					className="prospect-widget__team-modal"
					isOpen={isAddOwnerModalOpen || isAddTeamModalOpen}
					hasClosed={this.handleCloseAddOwnerModal}
					header="Assign User to a Company"
				>
					<div className="modal__content">
						<MultiSelect
							isSingle
							isClearable
							options={users
								.filter(
									(u) =>
										![...subscribers, ...owners]
											.map((s) => s.userId)
											.includes(u.id),
								)
								.map((u) => ({
									label: `${u.firstName} ${u.lastName}`,
									value: u.id,
								}))}
							onChange={this.handleAddOwner}
						/>
					</div>
				</ApModal>
				<ApModal
					className="comment-modal"
					isOpen={isAddEditCommentModalOpen}
					hasClosed={this.handleCloseAddEditCommentModal}
					header={isEditMode ? 'Edit Comment' : 'Add Comment'}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseAddEditCommentModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								onClick={
									isEditMode ? this.handleEditComment : this.handleAddComment
								}
								disabled={comment.analysis === ''}
							>
								{isEditMode ? 'Update Comment' : 'Add Comment'}
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					<div className="comment-modal__body">
						<label className="comment-modal__label">Type</label>
						<div className="comment-modal__types">
							<ApRadio
								id="current-situation"
								value={'CurrentSituation'}
								type="radio"
								checked={comment.type === 'CurrentSituation'}
								onChange={this.handleChangeCommentType}
							>
								Current Situation
							</ApRadio>
							<ApRadio
								id="ap-view"
								value={'APView'}
								type="radio"
								checked={comment.type === 'APView'}
								onChange={this.handleChangeCommentType}
							>
								AP View
							</ApRadio>
						</div>
						<label className="comment-modal__label" htmlFor="analysis-comment">
							Notes
						</label>
						<div className="comment-modal__analysis">
							<textarea
								id="analysis-comment"
								maxLength={2000}
								tabIndex={2}
								rows={8}
								value={comment.analysis}
								onChange={this.handleChangeCommentAnalysis}
								onFocus={(e) => {
									const val = e.target.value;
									e.target.value = '';
									e.target.value = val;
								}}
							/>
						</div>
					</div>
				</ApModal>
				<ApModal
					className="delete-comment-modal"
					isOpen={isRemoveCommentModalOpen}
					hasClosed={this.handleCloseDeleteModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseDeleteModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain onClick={this.handleRemoveComment}>
								Delete Analysis
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					Are you sure you want to delete comment?
				</ApModal>
				<ApModal
					className="delete-file-modal"
					isOpen={isRemoveFileModalOpen}
					hasClosed={this.handleCloseDeleteFileModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseDeleteFileModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain onClick={this.handleFileDelete}>
								Delete File
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					{`Are you sure you want to delete '${this.state.fileNameToDelete}' file?`}
				</ApModal>
				<ApModal
					className="delete-link-file-modal"
					isOpen={isRemoveLinkedFileModalOpen}
					hasClosed={this.handleCloseDeleteLinkedFileModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary
								onClick={this.handleCloseDeleteLinkedFileModal}
							>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain onClick={this.handleLinkedFileDelete}>
								Delete Link
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					{`Are you sure you want to delete file link?`}
				</ApModal>
				<ApModal
					className="edit-financials"
					isOpen={isEditFinancialsOpen}
					hasClosed={this.handleCloseEditFinancialsModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseEditFinancialsModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								disabled={!isFinancialFormValid}
								onClick={this.handleSaveFinancials}
							>
								Save
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					<FinancialModal
						financialDataTemp={financialDataTemp}
						allCurrencies={currencies}
						handleChangeFinancial={this.handleChangeFinancial}
					/>
				</ApModal>
				<ApModal
					className="edit-debt"
					isOpen={isEditDebtOpen}
					hasClosed={this.handleCloseEditDebtModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseEditDebtModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								disabled={!isMaterialDebtFormValid}
								onClick={this.handleSaveMaterialDebt}
							>
								Save
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					<DebtModal
						currencyCode={currencyCode || ''}
						materialDebt={materialDebtCopy}
						onDateChange={this.handleChangeMaterialDebtDate}
						onDebtChange={this.handleChangeMaterialDebtValue}
						onAddNew={this.handleAddMaterialDebt}
						onDelete={this.handleDeleteMaterialDebt}
					/>
				</ApModal>
			</div>
		);
	}
}
