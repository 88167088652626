import React from 'react';

import { IProfitAndLossData } from '../../../types';
import { isEmpty } from '../../../utils';
import { ProfitLossWidget } from '../ProfitLossWidget';

import './ProfitLossSection.scss';

interface IProfitLossSectionProps {
	profitLossData: IProfitAndLossData;
	companyName: string;
}

export const ProfitLossSection: React.FC<IProfitLossSectionProps> = ({
	profitLossData,
	companyName,
}) => {
	if (!profitLossData || isEmpty(profitLossData)) {
		return (
			<div className="profit-loss-section">
				<div className="profit-loss-section__empty">No data available</div>
			</div>
		);
	}

	const currencyCode = profitLossData.currencyCode;

	const description = `${companyName} has experienced ${
		profitLossData.revenue.growth > 0 ? 'growth' : 'decline'
	} in revenue and ${
		profitLossData.sga.growth > 0 ? 'increase' : 'decline'
	} in SG&A expenses over the last 5 years. ${companyName} has seen an ${
		profitLossData.ebitda.growth > 0 ? 'increase' : 'fall'
	} in its EBITDA and has seen gross profit ${
		profitLossData.grossProfit.growth > 0 ? 'rise' : 'fall'
	} over the same period.`;

	return (
		<div className="profit-loss-section">
			{description && (
				<div className="profit-loss-section__description">{description}</div>
			)}
			<div className="profit-loss-section__container">
				<div className="profit-loss-section__wrapper">
					<div className="profit-loss-section__chart">
						<ProfitLossWidget
							data={profitLossData.revenue.data}
							name="Revenue"
							growth={profitLossData.revenue.growth}
							currency={currencyCode}
						/>
					</div>
					<div className="profit-loss-section__chart">
						<ProfitLossWidget
							data={profitLossData.grossProfit.data}
							name="Gross Profit"
							growth={profitLossData.grossProfit.growth}
							chartColor="#032E45"
							currency={currencyCode}
						/>
					</div>
				</div>
				<div className="profit-loss-section__wrapper">
					<div className="profit-loss-section__chart">
						<ProfitLossWidget
							data={profitLossData.sga.data}
							name="SG&A"
							growth={profitLossData.sga.growth}
							chartColor="#2D9CDB"
							currency={currencyCode}
						/>
					</div>
					<div className="profit-loss-section__chart">
						<ProfitLossWidget
							data={profitLossData.ebitda.data}
							name="EBITDA"
							chartColor="#15723B"
							growth={profitLossData.ebitda.growth}
							currency={currencyCode}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
