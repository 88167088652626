import React from 'react';
import moment from 'moment';
import { ApButtonFilter } from '@alixpartners/ui-components';

import { INews } from '../../types';

import './News.scss';

interface INewsProps {
	news: INews[];
}

interface INewsState {
	news: INews[];
	selectedCategory: string;
}

export class News extends React.Component<INewsProps, INewsState> {
	newsList: any;
	state = {
		news: this.props.news,
		categories: this.props.news.reduce((acc: string[], v) => {
			if (!acc.includes(v.category) && v.category) {
				acc.push(v.category);
			}

			return acc;
		}, []),
		selectedCategory: 'All',
	};

	handleSelectCategory = (category: string) => {
		const { news } = this.props;

		this.setState(
			(prevState: INewsState) => {
				return {
					selectedCategory: category,
					news:
						category === 'All'
							? news
							: news.filter((n) => n.category === category),
				};
			},
			() => {
				this.newsList.scrollTo(0, 0);
			},
		);
	};

	render() {
		const { categories, news, selectedCategory } = this.state;

		return (
			<div className="news">
				<div className="news__title">
					<div>News:</div>
					<div className="news__info">powered by Bing</div>
				</div>
				<div className="news__categories">
					{['All', ...categories].map((c, i) => {
						const categoryNameMatch = c.match(/[A-Z][a-z]+|[0-9]+/g);
						const categoryName = categoryNameMatch
							? categoryNameMatch.join(' ')
							: c;
						return (
							<ApButtonFilter
								isSelected={c === selectedCategory}
								key={i}
								className="news__category"
								onClick={() => this.handleSelectCategory(c)}
							>
								{categoryName}
							</ApButtonFilter>
						);
					})}
				</div>
				<div
					className="news__list"
					ref={(newsList) => (this.newsList = newsList)}
				>
					{news.map((n, i) => {
						return (
							<div className="news__item" key={n.name + i}>
								<div className="news__item__date">
									{moment(n.datePublished).format('D MMM YYYY').toUpperCase()}
								</div>
								<div className="news__item__data">
									{/* {n.image ? (
										<img src={n.image.thumbnail.contentUrl} alt={n.name} />
									) : (
										<div className="news__image-placeholder" />
									)} */}
									{n.image && (
										<img src={n.image.thumbnail.contentUrl} alt={n.name} />
									)}

									<div className="news__wrapper">
										<a
											className="news__link"
											href={n.url}
											target="_blank"
											rel="noopener noreferrer"
										>
											{n.name}
										</a>
										<span className="news__source">
											{n.url.split('/')[2].replace('www.', '')}
										</span>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				{/* <div className="news__load-more">
					<ApButtonSecondary
						onClick={() => {
							this.setState((prevState) => {
								return {
									newsCount: prevState.newsCount + 5,
								};
							});
						}}
					>
						Load More
					</ApButtonSecondary>
				</div> */}
			</div>
		);
	}
}
