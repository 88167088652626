import { ICategory } from '../types';

export const getCurvePath = (
	startX: number,
	startY: number,
	endX: number,
	endY: number,
) => {
	// M
	const AX = startX;
	const AY = startY;

	// L
	let BX = Math.abs(endX - startX) * 0.05 + startX;
	if (startY === endY) {
		BX = startX;
	}
	const BY = startY;

	// C
	let CX = startX + Math.abs(endX - startX) * 0.5;
	let DX = endX - Math.abs(endX - startX) * 0.5;
	let CY = startY;
	let DY = endY;
	let EX = -Math.abs(endX - startX) * 0.05 + endX;
	let EY = endY;

	if (startY === endY) {
		// CX = startX + 30;
		// DX = endX - 30	;
		// CY = startY + 15;
		// DY = CY;
		// EX = endX;
		// EY = endY;

		CX = startX + 40;
		DX = endX - 40;
		CY = startY - 8;
		DY = CY;
		EX = endX;
		EY = endY;
	}

	// L
	const FX = endX;
	const FY = endY;

	// if (startY === endY ) {
	// 	return `M ${AX},${AY} L${BX},${BY} C 20 20, 40 20, 50 10 L${FX},${FY}`
	// }

	return `M ${AX},${AY} L${BX},${BY} C${CX},${CY} ${DX},${DY} ${EX},${EY} L${FX},${FY}`;
};

export const numberWithCommas = (x: number) => {
	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const addBigNumberSign = (value: number, sign = 'M') => {
	return value && value > 0 ? `${sign}` : '';
};

export const debounce = (fn: any, time: number) => {
	let timeoutId: NodeJS.Timeout | null;
	return wrapper;

	function wrapper(...args: any[]) {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			timeoutId = null;
			fn(...args);
		}, time);
	}
};

export const saveAs = (url: string, filename: string) => {
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('download', filename);
	a.click();
};

export const getLogoUrl = (website: string): string => {
	if (!website) return '';
	const filteredWebsite = website.replace(/(www)./, '');
	return `//logo.clearbit.com/${filteredWebsite}`;
};

export const getImageDataUrl = (url: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		fetch(url)
			.then((response) => {
				if (response.status === 200) {
					return response.blob();
				} else {
					throw new Error('Error fetching logo');
				}
			})
			.then((blob) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					const { result } = reader;

					if (typeof result === 'string') {
						resolve(result);
					}
				};
				reader.readAsDataURL(blob);
			})
			.catch(() => {
				reject();
			});
	});
};

export const getImageSizeInInches = (
	dataUrl: string,
): Promise<{ width: number; height: number }> => {
	return new Promise((resolve) => {
		const image = new Image();
		image.src = dataUrl;
		image.onload = () => {
			resolve({ width: image.width / 96, height: image.height / 96 });
		};
	});
};

export const calculateMedian = (values: number[]) => {
	if (values.length === 0) return 0;

	values.sort((a, b) => {
		return a - b;
	});

	const half = Math.floor(values.length / 2);

	if (values.length % 2) return values[half];

	return (values[half - 1] + values[half]) / 2.0;
};

export const retry = (maxRetries: number, fn: any) => {
	return fn().catch((error: any) => {
		if (maxRetries <= 0) {
			throw error;
		}
		return retry(maxRetries - 1, fn);
	});
};

export const roundOneDecimal = (v: number) => {
	return Math.round((v + Number.EPSILON) * 10) / 10;
};

export const roundTwoDecimal = (v: number) => {
	return Math.round((v + Number.EPSILON) * 100) / 100;
};

export const isEmpty = (obj: any) => {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const insert = (arr: any[], index: number, newItem: any) => [
	...arr.slice(0, index),
	newItem,
	...arr.slice(index),
];

export const triggerInputChange = (node: any, value = '') => {
	// @ts-ignore
	const setValue = Object.getOwnPropertyDescriptor(node.__proto__, 'value').set;
	const event = new Event('input', { bubbles: true });

	// @ts-ignore
	setValue.call(node, value);
	node.dispatchEvent(event);
};

/**
 * @name Reorder Category Weightings
 * @summary Takes the source categories and reorders them according to CATEGORY_ORDER in CategoryWeightings.tsx.
 * @param sourceCats The ICategory[] fetched from the API.
 * @param newCatsOrder The string[] of the order the categories should be in (by name).
 * @returns A new array with the reordered categories.
 * @throws An Error object specifiyibg which category was not found in sourceCats.
 * @example const CATEGORY_ORDER = ['Sales',
	'Profitability',
	'Solvency',
	'Working capital',
	'Market data',
	];

	reorderCategoryWeightings(sourceCats, CATEGORY_ORDER);
 */
export const reorderCategoryWeightings = (
	sourceCats: ICategory[],
	newCatsOrder: string[],
): ICategory[] => {
	// The 'Points' category is required to be at the front for use further below.
	const reorderedNames = ['Points', ...newCatsOrder];
	const output: ICategory[] = [];

	reorderedNames.forEach((name) => {
		const foundCat: ICategory | undefined = sourceCats.find(
			(cat) => cat.categoryName === name,
		);

		if (foundCat !== undefined) output.push(foundCat);
		else throw new Error(`Category '${name}' was not found in sourceCats`);
	});

	return output;
};

export const omit = (obj: any, omitKey: string): any =>
	Object.keys(obj).reduce((result: any, key: string) => {
		if (key !== omitKey) {
			result[key] = obj[key];
		}
		return result;
	}, {});

export const range = (start: number, stop: number, step: number) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

// k-means algorithm implementation from https://gist.github.com/jeremy-rifkin/1afea662c023d2ca6f65aa53a0fbc971

// compute the average of an array
const average = (data: number[]) => {
	let sum = 0;
	for (let i = 0, l = data.length; i < l; i++) sum += data[i];
	return sum / data.length;
};

// // compute the variation of an array
// const variation = (data: number[]) => {
// 	// sum of squares would be most correct but range can also work well for k-means
// 	return Math.max(...data) - Math.min(...data);
// };

// // find the max variation of all clusters
// const max_variation = (clusters: { mean: number; data: number[] }[]) => {
// 	let max = variation(clusters[0].data);
// 	for (let i = 1, l = clusters.length; i < l; i++) {
// 		const v = variation(clusters[i].data);
// 		if (v > max) max = v;
// 	}
// 	return max;
// };

// k-means core - compute k-means for 1d data
const kmeans_1d = (data: number[], k: number) => {
	// there need to be at least k unique values in data, but that isn't explicitly checked here
	// pick k random starting points
	const used_values: any = {}, // keep track of the values we've used
		clusters: { mean: number; data: number[] }[] = []; // store clusters and their associated data points
	let _ic = 0; // counter to make sure we don't get an infinite loop
	while (clusters.length < k) {
		const i = Math.floor(Math.random() * data.length);
		if (!used_values[data[i]]) {
			// don't pick a value twice (it'll result in a cluster with 0 data points, which will be bad)
			clusters.push({
				mean: data[i],
				data: [],
			});
			used_values[data[i]] = true;
		}
		if (_ic++ >= k * 200)
			// safeguard infinite loop
			throw new Error('error');
	}
	// iterate k-means
	while (true) {
		// clear potential cluster data points from previous iteration
		for (let i = 0, l = clusters.length; i < l; i++) clusters[i].data = [];
		// assign points to their nearest clusters
		for (let i = 0, l = data.length; i < l; i++) {
			let min_dist = Math.abs(data[i] - clusters[0].mean),
				min_dist_i = 0;
			for (let j = 1, lm = clusters.length; j < lm; j++) {
				const d = Math.abs(data[i] - clusters[j].mean);
				if (d < min_dist) {
					min_dist = d;
					min_dist_i = j;
				}
			}
			clusters[min_dist_i].data.push(data[i]);
		}
		// recalculate centroids
		let did_move = false;
		for (let i = 0, l = clusters.length; i < l; i++) {
			const centroid = average(clusters[i].data);
			if (centroid !== clusters[i].mean) {
				clusters[i].mean = centroid;
				did_move = true;
			}
		}
		// stop when the centroids stop moving
		if (!did_move) break;
	}
	// return final solution
	return clusters;
};

// // calculate the best k-means output of m runs
// const kmeans_1d_m = (data: number[], k: number, m: number) => {
// 	// run k-means m times
// 	let clusters = kmeans_1d(data, k),
// 		current_best_var = max_variation(clusters);
// 	while (m-- - 1) {
// 		const _clusters = kmeans_1d(data, k),
// 			_variation = max_variation(_clusters);
// 		if (_variation < current_best_var) {
// 			current_best_var = _variation;
// 			clusters = _clusters;
// 		}
// 	}
// 	// return best of m runs
// 	return clusters;
// };

export const splitToGroups = (list: number[], n: number) => {
	const groups = kmeans_1d(list, n);

	return groups
		.map((g) =>
			g.data.sort((a, b) => {
				return a - b;
			}),
		)
		.sort((a, b) => {
			return a[0] - b[0];
		});
};

export const addZeroes = (num: string) => {
	const dec = num.split('.')[1];
	const len = dec && dec.length > 2 ? dec.length : 2;
	return Number(num)
		.toFixed(len)
		.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
