const gutterXs = 16;
const gutterSm = 16;
const gutterMd = 16;
const gutterLg = 0;
const gutterXl = 0;

const maxWidth = 1632;

export const gutters = {
	xs: {
		gutterWidth: gutterXs,
	},
	sm: {
		gutterWidth: gutterSm,
	},
	md: {
		gutterWidth: gutterMd,
	},
	lg: {
		gutterWidth: gutterLg,
	},
	xl: {
		gutterWidth: gutterXl,
	},
};

export const getGridConfig = (screenClass: keyof typeof gutters) => {
	return {
		defaultScreenClass: 'sm',
		gutterWidth: gutters[screenClass].gutterWidth,
		containerWidths: [maxWidth, maxWidth, maxWidth, maxWidth],
	};
};
