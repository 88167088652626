import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas, roundOneDecimal } from '../../../utils';
import { IQuarterlyFinancialData } from '../../../types';
import './QuarterlyDataWidget.scss';

interface IQuarterlyDataWidgetProps {
	data: IQuarterlyFinancialData[];
	latestYear: number;
	netSalesValue: number;
	currency: string;
}

export const QuarterlyDataWidget: React.FC<IQuarterlyDataWidgetProps> = ({
	data,
	latestYear,
	netSalesValue,
	currency,
}) => {
	const colors = ['#77B0BE', '#0696A6', '#006176', '#032E45', '#ADAFB2'];

	const chartOptions: Highcharts.Options = {
		chart: {
			type: 'bar',
			height: 285,
			marginRight: 40,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: {
			min: 0,
			visible: false,
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			type: 'category',
			labels: {
				useHTML: true,
				style: {
					color: '#737373',
					fontFamily: 'Roboto',
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			lineColor: '#ffffff',
		},
		tooltip: {
			enabled: false,
		},
		// @ts-ignore
		plotOptions: {
			bar: {
				// pointWidth: 21,
				groupPadding: 0,
				pointPadding: 0.1,
				borderWidth: 0,
			},
			series: {
				dataLabels: {
					// useHTML: true,
					allowOverlap: true,
					crop: false,
					overflow: 'none',
					enabled: true,
					formatter: function () {
						return this.y && this.y !== 0
							? `${getSymbolFromCurrency(currency)}${numberWithCommas(
									roundOneDecimal(this.y),
							  )}M`
							: 'N/A';
					},
					style: {
						color: '#1A1A1A',
						fontFamily: 'Roboto',
						fontSize: '13px',
						lineHeight: '19px',
						fontWeight: 'normal',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};

	const years: number[] = data.reduce((acc: number[], c) => {
		if (!acc.includes(c.fiscalYear)) {
			acc.push(c.fiscalYear);
		}
		return acc;
	}, []);

	const ltmRevenue = data
		.slice(Math.max(data.length - 4, 0))
		.reduce((sum: number, item) => {
			if (item && item.revenue) {
				sum += item.revenue;
			}
			return sum;
		}, 0);

	const options: Highcharts.Options = {
		...chartOptions,
		series: [
			{
				data: data.map((d) => {
					const currentYearIndex = years.findIndex((y) => y === d.fiscalYear);
					return {
						y: d.revenue,
						name: `${d.fiscalYear} Q${d.fiscalQuarter}`,
						color: colors[currentYearIndex >= 0 ? currentYearIndex : 4],
					};
				}),
				type: 'bar',
			},
		],
	};

	return (
		<div className="quarterly-chart">
			<div className="quarterly-chart__title">
				LATEST QUARTERLY TOTAL REVENUE
			</div>
			<div className="quarterly-chart__data">
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					immutable
					updateArgs={[true, true, true]}
				/>
			</div>
			<div className="quarterly-chart__note">
				<div>{`LTM revenue, ${getSymbolFromCurrency(
					currency,
				)}: ${numberWithCommas(
					roundOneDecimal(ltmRevenue) || roundOneDecimal(netSalesValue),
				)}M`}</div>
				<div>{`Latest fiscal year: ${latestYear}`}</div>
			</div>
		</div>
	);
};
