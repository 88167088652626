import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import {
	ApLoaderDefault,
	ApButtonSecondary,
	ApIcon,
	ApModal,
	ApButtonMain,
	ApSimpleDropdown,
	snack,
	IOption,
	ApButtonGroup,
	ApButtonTab,
	ApInput,
} from '@alixpartners/ui-components';

import { Task } from '../Task';
import { MultiSelect } from '../../MultiSelect';
import { isEmpty } from '../../../utils';

import {
	getCompanyTasks,
	addCompanyTask,
	updateCompanyTaskStatus,
	deleteCompanyTask,
	updateCompanyTask,
} from '../../../api';
import { IUser, ICurrentUser, ITask, TaskStatus } from '../../../types';

// import { UserSearch } from '../../UserSearch';

import './CompanyTasks.scss';

interface ICompanyTasksProps {
	companyId: number;
	companyName: string;
	// watchlistId: number;
	users: IUser[];
	currentUser: ICurrentUser;
	isCompanyOwner: boolean;
}

interface ICompanyTasksState {
	isLoading: boolean;
	isAddingTask: boolean;
	isAddTaskModalOpen: boolean;
	isRemoveTaskModalOpen: boolean;
	companyTasks: ITask[];
	companyTasksCopy: ITask[];
	filteredTasks: ITask[];
	currentTab: string;
	newTask: INewTask;
	taskToEdit: ITask;
	taskToDeleteId: number;
}

interface INewTask {
	companyId: number;
	dueDate: string;
	assignedToId: string;
	assignedToFullName: string;
	assignedToEmail: string;
	name: string;
	description: string;
	createdById: string;
	status: string;
}

const taskStatusList = {
	[TaskStatus.TODO]: 'To Do	',
	[TaskStatus.IN_PROGRESS]: 'In Progress',
	[TaskStatus.DONE]: 'Done',
};

const taskOptions = [
	{
		label: taskStatusList[TaskStatus.TODO],
		value: TaskStatus.TODO,
	},
	{
		label: taskStatusList[TaskStatus.IN_PROGRESS],
		value: TaskStatus.IN_PROGRESS,
	},
	{
		label: taskStatusList[TaskStatus.DONE],
		value: TaskStatus.DONE,
	},
];

const newTask = {
	status: TaskStatus.TODO,
	name: '',
	description: '',
	dueDate: '',
	assignedToId: '',
	assignedToFullName: '',
	assignedToEmail: '',
	companyId: -1,
	createdById: '',
} as INewTask;

const sortByDate = (a: ITask, b: ITask) => {
	const dateA = new Date(a.createdDate).getTime();
	const dateB = new Date(b.createdDate).getTime();
	return dateA > dateB ? -1 : 1;
};

const today = new Date();

export class CompanyTasks extends React.Component<
	ICompanyTasksProps,
	ICompanyTasksState
> {
	state = {
		isLoading: true,
		isAddTaskModalOpen: false,
		isRemoveTaskModalOpen: false,
		companyTasks: [] as ITask[],
		companyTasksCopy: [] as ITask[],
		filteredTasks: [] as ITask[],
		currentTab: '',
		newTask,
		isAddingTask: false,
		taskToEdit: {} as ITask,
		taskToDeleteId: -1,
	};

	async componentDidMount() {
		const { companyId } = this.props;
		window.scrollTo(0, 0);

		try {
			const [companyTasksResponse] = await Promise.all([
				getCompanyTasks(companyId),
			]);
			if (companyTasksResponse.data) {
				this.setState({
					companyTasks: companyTasksResponse.data.sort(sortByDate),
					companyTasksCopy: companyTasksResponse.data.sort(sortByDate),
					filteredTasks: companyTasksResponse.data.sort(sortByDate),
					isLoading: false,
				});
			} else {
				throw new Error('Error loading prospect data');
			}
		} catch (error) {
			console.log('Error loading prospect data');
		}
	}

	handleCloseTaskModal = () => {
		const { currentTab } = this.state;
		this.setState(
			{
				isAddTaskModalOpen: false,
				newTask: {
					...newTask,
					status: currentTab !== '' ? currentTab : TaskStatus.TODO,
				},
			},
			() => {
				this.setState({
					taskToEdit: {} as ITask,
				});
			},
		);
	};

	handleApplyFilters = () => {
		const { currentTab } = this.state;

		this.setState((prevState) => {
			return {
				filteredTasks:
					currentTab === ''
						? prevState.companyTasks
						: prevState.companyTasks
								.filter((c) => c.status === currentTab)
								.sort(),
			};
		});
	};

	handleAddCompanyTask = async () => {
		const {
			companyTasks,
			currentTab,
			newTask: {
				assignedToId,
				assignedToFullName,
				assignedToEmail,
				name,
				description,
				dueDate,
				status,
			},
		} = this.state;

		const { companyId, companyName, currentUser } = this.props;
		this.setState({ isAddingTask: true, isAddTaskModalOpen: false });

		const newTaskTempId = Math.max(...companyTasks.map((c) => c.id)) * 10 + 1;
		const newTaskTempDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS');

		const newTempCompanyTask: ITask = {
			id: newTaskTempId,
			name,
			description,
			companyId,
			companyName,
			createdById: currentUser.userId,
			createdByFullName: `${currentUser.firstName} ${currentUser.lastName}`,
			createdByEmail: currentUser.email,
			assignedToId,
			assignedToFullName,
			assignedToEmail,
			createdDate: newTaskTempDate,
			dueDate,
			startedDate: newTaskTempDate,
			completedDate: '',
			status,
		};

		this.setState(
			(prevState) => {
				return {
					isAddingTask: true,
					isAddTaskModalOpen: false,
					companyTasks: [newTempCompanyTask, ...prevState.companyTasks],
					newTask: {
						...newTask,
						status: currentTab !== '' ? currentTab : TaskStatus.TODO,
					},
				};
			},
			() => {
				this.handleApplyFilters();
			},
		);

		try {
			const { data } = await addCompanyTask(
				companyId,
				dueDate,
				assignedToId,
				name,
				description,
				status,
			);

			this.setState(
				(prevState) => {
					const newCompanyTasks = prevState.companyTasks.map((c) => {
						return c.id === newTaskTempId
							? {
									...c,
									id: data,
							  }
							: c;
					});

					return {
						isAddingTask: false,
						companyTasks: newCompanyTasks,
						companyTasksCopy: newCompanyTasks,
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddTaskModalOpen: false,
						isAddingTask: false,
						companyTasks: [...prevState.companyTasksCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while adding task',
					});
				},
			);
		}
	};

	handleEditCompanyTask = async () => {
		const { taskToEdit } = this.state;

		try {
			this.setState(
				(prevState) => {
					return {
						isAddTaskModalOpen: false,
						taskToEdit: {} as ITask,
						companyTasks: prevState.companyTasks.map((t) => {
							return t.id === taskToEdit.id ? { ...taskToEdit } : t;
						}),
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await updateCompanyTask(
				taskToEdit.id,
				taskToEdit.dueDate,
				taskToEdit.assignedToId,
				taskToEdit.name,
				taskToEdit.description,
				taskToEdit.status,
			);

			this.setState((prevState) => {
				return {
					companyTasksCopy: prevState.companyTasks,
				};
			});
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isAddTaskModalOpen: false,
						taskToEdit: {} as ITask,
						companyTasks: [...prevState.companyTasksCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while saving task',
					});
				},
			);
		}
	};

	handleRemoveTask = async () => {
		const { taskToDeleteId } = this.state;
		const id = taskToDeleteId;
		try {
			this.setState(
				(prevState) => {
					return {
						taskToDeleteId: -1,
						isRemoveTaskModalOpen: false,
						companyTasks: [
							...prevState.companyTasks.filter((t) => t.id !== id),
						],
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);

			await deleteCompanyTask(id);
			this.setState((prevState) => {
				return {
					companyTasksCopy: [
						...prevState.companyTasksCopy.filter((t) => t.id !== id),
					],
				};
			});
		} catch (error) {
			this.setState(
				(prevState) => {
					return {
						isRemoveTaskModalOpen: false,
						companyTasks: [...prevState.companyTasksCopy],
					};
				},
				() => {
					this.handleApplyFilters();
					snack.error({
						message: 'There was an error while removing task',
					});
				},
			);
		}
	};

	handleCloseDeleteModal = () => {
		this.setState({ isRemoveTaskModalOpen: false, taskToDeleteId: -1 });
	};

	handleOpenDeleteModal = (id: number) => {
		this.setState({ isRemoveTaskModalOpen: true, taskToDeleteId: id });
	};

	handleAddAssignee = (option: any) => {
		const { taskToEdit } = this.state;

		if (!option) {
			if (isEmpty(taskToEdit)) {
				this.setState((prevState) => {
					return {
						newTask: {
							...prevState.newTask,
							assignedToId: '',
							assignedToFullName: '',
							assignedToEmail: '',
						},
					};
				});
			} else {
				this.setState((prevState) => {
					return {
						taskToEdit: {
							...prevState.taskToEdit,
							assignedToId: '',
							assignedToFullName: '',
							assignedToEmail: '',
						},
					};
				});
			}

			return;
		}

		const { users } = this.props;

		const user = users.find((u) => u.id === option.value);

		if (user) {
			if (isEmpty(taskToEdit)) {
				this.setState((prevState) => {
					return {
						newTask: {
							...prevState.newTask,
							assignedToId: user.id,
							assignedToFullName: `${user.firstName} ${user.lastName}`,
							assignedToEmail: user.email,
						},
					};
				});
			} else {
				this.setState((prevState) => {
					return {
						taskToEdit: {
							...prevState.taskToEdit,
							assignedToId: user.id,
							assignedToFullName: `${user.firstName} ${user.lastName}`,
							assignedToEmail: user.email,
						},
					};
				});
			}
		}
	};

	handleNewTaskStatusChange = (option: IOption | IOption[]) => {
		const status = (option as IOption).value;
		const { taskToEdit } = this.state;

		if (isEmpty(taskToEdit)) {
			this.setState((prevState) => {
				return {
					newTask: { ...prevState.newTask, status },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					taskToEdit: { ...prevState.taskToEdit, status },
				};
			});
		}
	};

	handleTaskNameChange = (name: string) => {
		const { taskToEdit } = this.state;

		if (isEmpty(taskToEdit)) {
			this.setState((prevState) => {
				return {
					newTask: { ...prevState.newTask, name },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					taskToEdit: { ...prevState.taskToEdit, name },
				};
			});
		}
	};

	handleTaskDueDayChange = (selectedDay: any) => {
		const { taskToEdit } = this.state;

		if (isEmpty(taskToEdit)) {
			this.setState((prevState) => {
				return {
					newTask: {
						...prevState.newTask,
						dueDate: moment(selectedDay).format('YYYY-MM-DDTHH:mm:ss.SSS'),
					},
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					taskToEdit: {
						...prevState.taskToEdit,
						dueDate: moment(selectedDay).format('YYYY-MM-DDTHH:mm:ss.SSS'),
					},
				};
			});
		}
	};

	handleTaskDescriptionChange = (
		event: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<Element>,
	) => {
		const { value } = event.target as HTMLInputElement;

		const { taskToEdit } = this.state;

		if (isEmpty(taskToEdit)) {
			this.setState((prevState) => {
				return {
					newTask: { ...prevState.newTask, description: value },
				};
			});
		} else {
			this.setState((prevState) => {
				return {
					taskToEdit: { ...prevState.taskToEdit, description: value },
				};
			});
		}
	};

	handleFilterTasks = (status: string) => {
		this.setState((prevState) => {
			return {
				currentTab: status,
				filteredTasks:
					status !== ''
						? prevState.companyTasks.filter((t) => t.status === status)
						: prevState.companyTasks,
				newTask: {
					...prevState.newTask,
					status: status !== '' ? status : TaskStatus.TODO,
				},
			};
		});
	};

	handleUpdateStatus = async (task: ITask, newStatus: string) => {
		const currentStatus = task.status;
		// const taskStatusList = ['To do', 'In progress', 'Done'];
		try {
			this.setState(
				(prevState) => {
					return {
						companyTasks: [
							...prevState.companyTasks.map((t) => {
								if (t.id === task.id) {
									return {
										...t,
										status: newStatus,
									};
								} else {
									return t;
								}
							}),
						],
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
			await updateCompanyTaskStatus(task.id, newStatus);
			snack.success({
				message: `Status is changed from '${taskStatusList[currentStatus]}' to '${taskStatusList[newStatus]}' for '${task.name}' task`,
			});
			this.setState((prevState) => {
				return {
					companyTasksCopy: [
						...prevState.companyTasksCopy.map((t) => {
							if (t.id === task.id) {
								return {
									...t,
									status: newStatus,
								};
							} else {
								return t;
							}
						}),
					],
				};
			});
		} catch (error) {
			snack.error({
				message: `There was an error while updating status for '${task.name}' task`,
			});
			this.setState(
				(prevState) => {
					return {
						companyTasks: prevState.companyTasksCopy,
					};
				},
				() => {
					this.handleApplyFilters();
				},
			);
		}
	};

	handleOpenEditTaskModal = (taskToEdit: ITask) => {
		this.setState({
			isAddTaskModalOpen: true,
			taskToEdit,
		});
	};

	render() {
		const {
			isLoading,
			isAddTaskModalOpen,
			isRemoveTaskModalOpen,
			isAddingTask,
			filteredTasks,
			currentTab,
			newTask,
			taskToEdit,
		} = this.state;
		const { users, currentUser, isCompanyOwner } = this.props;

		// const taskStatusList = ['To do', 'In progress', 'Done'];
		const isEditMode = !isEmpty(taskToEdit);
		const task = isEditMode ? taskToEdit : newTask;

		const isDisabled =
			task.assignedToId === '' ||
			task.name === '' ||
			!moment(task.dueDate).isValid() ||
			task.dueDate === '';

		if (isLoading) {
			return (
				<div>
					<ApLoaderDefault />
				</div>
			);
		}

		return (
			<div className="prospect-tasks">
				<div className="prospect-tasks__main">
					<div className="prospect-widget">
						<div className="prospect-widget__title">
							<div>Tasks</div>
							<div>
								<ApButtonSecondary
									onClick={() => {
										this.setState({ isAddTaskModalOpen: true });
									}}
									// TODO: disabled when adding task
								>
									<div className="button-wrapper">
										<ApIcon
											iconName="baseline_add"
											iconColor="#333333"
										></ApIcon>
										<div>Create new task</div>
									</div>
								</ApButtonSecondary>
							</div>
						</div>
						<div className="watchlist-comments__tabs">
							<ApButtonGroup justifyContent="initial" spacingSize={0}>
								<ApButtonTab
									isSelected={currentTab === ''}
									onClick={() => {
										this.handleFilterTasks('');
									}}
								>
									All Tasks
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === TaskStatus.TODO}
									onClick={() => {
										this.handleFilterTasks(TaskStatus.TODO);
									}}
								>
									To Do
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === TaskStatus.IN_PROGRESS}
									onClick={() => {
										this.handleFilterTasks(TaskStatus.IN_PROGRESS);
									}}
								>
									In Progress
								</ApButtonTab>
								<ApButtonTab
									isSelected={currentTab === TaskStatus.DONE}
									onClick={() => {
										this.handleFilterTasks(TaskStatus.DONE);
									}}
								>
									Done
								</ApButtonTab>
							</ApButtonGroup>
						</div>
						<div className="watchlist-tasks">
							{filteredTasks.map((t) => {
								return (
									<Task
										task={t}
										onStatusChange={(newStatus: IOption) => {
											this.handleUpdateStatus(t, newStatus.value);
										}}
										key={t.id}
										isEditEnabled={
											(t.createdById === currentUser.userId ||
												t.assignedToId === currentUser.userId ||
												isCompanyOwner) &&
											!isAddingTask
										}
										isDeleteEnabled={
											(t.createdById === currentUser.userId ||
												t.assignedToId === currentUser.userId ||
												isCompanyOwner) &&
											!isAddingTask
										}
										isStatusChangeEnabled={
											(t.createdById === currentUser.userId ||
												t.assignedToId === currentUser.userId ||
												isCompanyOwner) &&
											!isAddingTask
										}
										onTaskEdit={(task) => {
											this.handleOpenEditTaskModal(task);
										}}
										onTaskDelete={(taskId) => {
											this.handleOpenDeleteModal(taskId);
										}}
									/>
								);
							})}
						</div>
					</div>
				</div>
				<ApModal
					isDismissible
					className="task-modal"
					isOpen={isAddTaskModalOpen}
					hasClosed={this.handleCloseTaskModal}
					header={isEditMode ? 'Edit Task' : 'Create task'}
					footer={() => (
						<div className="modal__footer">
							<ApButtonSecondary onClick={this.handleCloseTaskModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain
								onClick={
									isEditMode
										? this.handleEditCompanyTask
										: this.handleAddCompanyTask
								}
								autoFocus
								disabled={isDisabled}
							>
								Save task
							</ApButtonMain>
						</div>
					)}
				>
					<div className="modal__content">
						<div className="task__wrapper">
							<div className="task__status">
								<div className="modal__label">Status</div>
								<ApSimpleDropdown
									onChange={this.handleNewTaskStatusChange}
									options={taskOptions}
									selected={{
										label: taskStatusList[task.status],
										value: task.status,
									}}
								/>
							</div>
							<div className="task__date">
								<div className="modal__label">Due date *</div>
								<DayPickerInput
									format="D/M/YYYY"
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder="DD/MM/YYYY"
									onDayChange={this.handleTaskDueDayChange}
									dayPickerProps={{ disabledDays: { before: today } }}
									inputProps={{ readOnly: true }}
									value={
										task.dueDate ? moment(task.dueDate).format('D/M/YYYY') : ''
									}
								/>
							</div>
						</div>
						<div className="task__name">
							<div className="modal__label">Task name *</div>
							<ApInput
								// @ts-ignore
								tabIndex={1}
								id="task-name"
								name="name"
								onChange={this.handleTaskNameChange}
								value={task.name}
							/>
						</div>
						<div className="task__assignee">
							<div className="modal__label">Assignee *</div>
							{/* <UserSearch
								users={users.filter((u) => u.id !== currentUser.userId)}
								onUserSelect={this.handleAddAssignee}
								onChange={this.handleAddAssigneeChange}
								value={task.assignedToFullName}
								showSelectedVal
								ue
							/> */}
							<MultiSelect
								isSingle
								isClearable
								options={users
									.filter((u) => u.id !== currentUser.userId)
									.map((u) => ({
										label: `${u.firstName} ${u.lastName}`,
										value: u.id,
									}))}
								value={
									isEditMode
										? [
												{
													label: task.assignedToFullName,
													value: task.assignedToId,
												},
										  ]
										: undefined
								}
								onChange={this.handleAddAssignee}
							/>
						</div>
						<div className="task__description">
							<label className="modal__label" htmlFor="task-description">
								<div>Task description</div>
								<div>{`${task.description.length}/150`}</div>
							</label>
							<textarea
								id="task-description"
								maxLength={150}
								tabIndex={2}
								rows={8}
								value={task.description}
								onChange={this.handleTaskDescriptionChange}
								onFocus={(e) => {
									const val = e.target.value;
									e.target.value = '';
									e.target.value = val;
								}}
							/>
						</div>
					</div>
				</ApModal>
				<ApModal
					className="delete-task-modal"
					isOpen={isRemoveTaskModalOpen}
					hasClosed={this.handleCloseDeleteModal}
					footer={() => (
						<ApButtonGroup justifyContent="flex-end">
							<ApButtonSecondary onClick={this.handleCloseDeleteModal}>
								Cancel
							</ApButtonSecondary>
							<ApButtonMain onClick={this.handleRemoveTask}>
								Delete Task
							</ApButtonMain>
						</ApButtonGroup>
					)}
				>
					Are you sure you want to delete task?
				</ApModal>
			</div>
		);
	}
}
