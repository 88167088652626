import * as React from 'react';
import { ApIcon } from '@alixpartners/ui-components';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas } from '../../../utils';

import './SummaryWidget.scss';

interface ISummaryWidget {
	name: string;
	currencyCode: string;
	currentValue: number;
	growth: number;
	latestYear: number;
}

export const SummaryWidget: React.FC<ISummaryWidget> = ({
	name,
	currentValue,
	growth,
	currencyCode,
	latestYear,
}) => {
	return (
		<div className="summary-widget">
			<div className="summary-widget__title">{`${name}, FY${latestYear}`}</div>
			<div className="summary-widget__data">
				{`${getSymbolFromCurrency(currencyCode)}`}
				<span>{numberWithCommas(currentValue)}</span>M
			</div>
			<div className="summary-widget__separator" />
			<div className="summary-widget__change">
				<ApIcon
					className={`summary-widget__change-icon${
						growth < 0 ? ' summary-widget__change-icon--negative' : ''
					}`}
					iconName="trending_flat"
					iconColor="#5CB335"
				/>
				<div>{`${growth}% Y/Y`}</div>
			</div>
		</div>
	);
};
