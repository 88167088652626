import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { IPeersRevenueWidget } from '../../types';

interface IDeckGrowthChartProps {
	name: string;
	companyId: number;
	competitorsData: IPeersRevenueWidget;
}

export class DeckGrowthChart extends React.Component<
	IDeckGrowthChartProps,
	unknown
> {
	render() {
		const {
			name,
			companyId,
			competitorsData: {
				data,
				revenueGrowthAverage,
				revenueGrowthMedian,
				ebitAverage,
				ebitMedian,
			},
		} = this.props;
		const currentCompanyColor = 'rgba(92, 179, 53)';
		const companyColors = [
			'rgba(3,46,69)',
			'rgba(0,97,118)',
			'rgba(6,150,166)',
			'rgba(119,176,190)',
			'rgba(89,89,89)',
			'rgba(173,175,178)',
		];

		const seriesData = data.map((d, index) => {
			return {
				name: d.companyName,
				x: d.ebitMargin,
				y: d.revenueGrowth,
				z: d.revenueUSD,
				color: {
					linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
					stops: [
						[
							0,
							d.companyId === companyId
								? currentCompanyColor
								: companyColors[index],
						],
						[
							1,
							d.companyId === companyId
								? currentCompanyColor
								: companyColors[index],
						],
					],
				},
			};
		});

		const chartOptions: Highcharts.Options = {
			chart: {
				type: 'bubble',
				height: 550,
				width: 1300,
				spacingTop: 30,
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			yAxis: {
				plotLines: [
					{
						color: '#C00000',
						dashStyle: 'LongDash',
						value: revenueGrowthMedian,
						label: {
							align: 'right',
							style: {
								fontStyle: 'italic',
							},
							text: 'Median',
						},
						zIndex: 31,
					},
					{
						color: '#7AB800',
						dashStyle: 'LongDash',
						value: revenueGrowthAverage,
						label: {
							style: {
								fontStyle: 'italic',
							},
							text: 'Average',
						},
						zIndex: 31,
					},
				],
				gridLineWidth: 1,
				minorGridLineWidth: 0,
				title: {
					text: 'Revenue Growth',
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				labels: {
					format: '{value}%',
					useHTML: true,
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				lineColor: '#ADAFB2',
				lineWidth: 0,
				tickLength: 0,
			},
			legend: {
				enabled: false,
			},
			xAxis: {
				plotLines: [
					{
						color: '#C00000',
						dashStyle: 'LongDash',
						value: ebitMedian,
						label: {
							style: {
								fontStyle: 'italic',
							},
							text: 'Median',
						},
						zIndex: 31,
					},
					{
						color: '#7AB800',
						dashStyle: 'LongDash',
						value: ebitAverage,
						label: {
							verticalAlign: 'bottom',
							y: -55,
							style: {
								fontStyle: 'italic',
							},
							text: 'Average',
						},
						zIndex: 31,
					},
				],
				title: {
					text: 'EBIT Margin',
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				labels: {
					format: '{value}%',
					useHTML: true,
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				lineColor: '#ADAFB2',
				tickLength: 0,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				bubble: {
					zMin: 1,
				},
				column: {
					pointWidth: 130,
				},
				series: {
					dataLabels: {
						enabled: true,
						// format: '{point.name}',
						formatter: function () {
							// TODO: validate labels
							// return this.point.name
							// 	? this.point.name.substring(0, 6).toUpperCase()
							// 	: '';
							return this.point.name ? this.point.name.toUpperCase() : '';
						},
						y: 30,
						style: {
							color: '#383838',
							fontFamily: 'Verdana',
							fontSize: '12px',
							letterSpacing: '0.3px',
							lineHeight: '19px',
							textAlign: 'right',
							fontWeight: 'normal',
						},
					},
					states: {
						inactive: {
							opacity: 1,
						},
						hover: {
							enabled: false,
						},
					},
					animation: false,
				},
			},
		};

		const options: Highcharts.Options = {
			...chartOptions,
			// @ts-ignore
			series: [
				{
					data: seriesData,
					sizeBy: 'width',
				},
			],
		};

		return (
			<div
				className={`deck-${name
					.toLowerCase()
					.replace(/ /g, '-')
					.replace('&', 'and')}-chart deck-chart`}
			>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</div>
		);
	}
}
