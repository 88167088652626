import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { ICashPositionData } from '../../../types';
import { numberWithCommas, isEmpty, roundOneDecimal } from '../../../utils';
import { CashPositionWidget } from '../CashPositionWidget';

import './CashPositionSection.scss';

interface ICashPositionSectionProps {
	data: ICashPositionData;
	companyName: string;
}

export const CashPositionSection: React.FC<ICashPositionSectionProps> = ({
	data,
	companyName,
}) => {
	if (!data || isEmpty(data)) {
		return (
			<div className="cash-position-section">
				<div className="cash-position-section__empty">No data available</div>
			</div>
		);
	}

	const currencyCode = data.currencyCode;
	const lastYearData = data.data[data.data.length - 1];
	const prevYearData = data.data[data.data.length - 2];
	const fiveYearsOldData = data.data[0];

	const description = `${companyName} currently has ${getSymbolFromCurrency(
		currencyCode,
	)}${numberWithCommas(
		roundOneDecimal(lastYearData.cash),
	)} million on hand as of the end of ${
		lastYearData.fiscalYear
	} leading to its cash position ${
		lastYearData.cash > fiveYearsOldData.cash ? 'increasing' : 'deteriorating'
	} over the last 5 years. Operating cash flow ${
		lastYearData.operatingCashFlow > prevYearData.operatingCashFlow
			? 'generated'
			: 'deteriorated'
	} to ${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
		roundOneDecimal(lastYearData.operatingCashFlow),
	)} million in ${lastYearData.fiscalYear}. In regard to Capex, spend for ${
		lastYearData.fiscalYear
	} amounted to ${getSymbolFromCurrency(currencyCode)}${numberWithCommas(
		roundOneDecimal(lastYearData.capex),
	)} million, an ${
		lastYearData.capex > prevYearData.capex ? 'increase' : 'decrease'
	} on initial investment.`;

	return (
		<div className="cash-position-section">
			{description && (
				<div className="cash-position-section__description">{description}</div>
			)}
			<div className="cash-position-section__container">
				<div className="cash-position-section__wrapper">
					<div className="cash-position-section__chart">
						<CashPositionWidget data={data.data} currency={currencyCode} />
					</div>
				</div>
				<div className="cash-position-section__wrapper"></div>
			</div>
		</div>
	);
};
