import { numberWithCommas } from '../../utils';
import { FinancialType } from '../../types';

interface ISeriesWithColor extends Highcharts.Series {
	color: string;
}

export const getChartOptions = (
	totalRevenue: number,
	currencySymbol: string,
): Highcharts.Options => ({
	chart: {
		height: 370,
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	yAxis: {
		title: {
			text: '',
		},
		gridLineColor: '#E6E6E6',
		labels: {
			style: {
				color: '#737373',
				fontFamily: 'Roboto, Verdana',
				fontSize: '11px',
			},
			formatter: function () {
				return `${currencySymbol}${numberWithCommas(this.value)}M`;
			},
		},
	},
	legend: {
		enabled: false,
	},
	xAxis: {
		labels: {
			style: {
				color: '#737373',
				fontFamily: 'Roboto, Verdana',
				fontSize: '13px',
			},
		},
		lineColor: 'transparent',
		tickLength: 0,
		gridLineWidth: 0,
		tickInterval: 1,
		crosshair: {
			color: '#2C92D1',
			width: 1,
		},
	},
	plotOptions: {
		series: {
			animation: false,
			stickyTracking: true,
			states: {
				inactive: {
					opacity: 0.8,
				},
				hover: {
					lineWidth: 2,
					halo: {
						size: 2,
					},
				},
			},
			marker: {
				lineWidth: 2,
				symbol: 'circle',
				enabled: false,
				states: {
					hover: {
						enabled: true,
						radius: 6,
					},
				},
			},
			label: {
				connectorAllowed: false,
			},
		},
	},
	tooltip: {
		outside: true,
		shared: true,
		backgroundColor: undefined,
		borderWidth: 0,
		useHTML: true,
		formatter: function () {
			const addTooltipSeries = (
				label: string,
				value: number,
				color:
					| string
					| Highcharts.GradientColorObject
					| Highcharts.PatternObject,
				currentRevenue: number | null = null,
			): string => {
				return `<span class="custom-tooltip__point" style="color:${color}">\u25CF</span> ${label}: <span class="custom-tooltip__yvalue">${currencySymbol}${numberWithCommas(
					value,
				)}</span> ${
					currentRevenue
						? '(' + Math.round((value / currentRevenue) * 100) + '%)'
						: ''
				}<br/>`;
			};

			let tooltipMarkup = '<div class="custom-tooltip">';

			const revenue = this.points?.find(
				(p) => p.series.name === FinancialType.REVENUE,
			);
			const totalDebt = this.points?.find(
				(p) => p.series.name === FinancialType.TOTAL_DEBT,
			);
			const grossProfit = this.points?.find(
				(p) => p.series.name === FinancialType.GROSS_PROFIT,
			);
			const sgAndA = this.points?.find(
				(p) => p.series.name === FinancialType.SG_AND_A,
			);
			const opratingIncome = this.points?.find(
				(p) => p.series.name === FinancialType.OPERATING_INCOME,
			);
			const ebitda = this.points?.find(
				(p) => p.series.name === FinancialType.EBITDA,
			);

			let currentRevenue: number | null = null;

			if (revenue) {
				currentRevenue = revenue.y;
				tooltipMarkup += addTooltipSeries(
					FinancialType.REVENUE,
					revenue.y,
					'#25B3C0',
				);
			}

			tooltipMarkup += addTooltipSeries(
				FinancialType.GROSS_PROFIT,
				grossProfit ? grossProfit.y : 0,
				'#7053C5',
				currentRevenue,
			);

			tooltipMarkup += addTooltipSeries(
				FinancialType.SG_AND_A,
				sgAndA ? sgAndA.y : 0,
				'#2D93D2',
				currentRevenue,
			);

			tooltipMarkup += addTooltipSeries(
				FinancialType.EBITDA,
				ebitda ? ebitda.y : 0,
				'#A7C76B',
				currentRevenue,
			);

			tooltipMarkup += addTooltipSeries(
				FinancialType.OPERATING_INCOME,
				opratingIncome ? opratingIncome.y : 0,
				'#6BC7B6',
				currentRevenue,
			);

			tooltipMarkup += addTooltipSeries(
				FinancialType.TOTAL_DEBT,
				totalDebt ? totalDebt.y : 0,
				'#C76B7A',
				currentRevenue,
			);

			return tooltipMarkup + '</div>';
		},
	},
});
