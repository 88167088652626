import React from 'react';

import { components } from 'react-select';
import { ApIcon, IOption } from '@alixpartners/ui-components';
import Creatable from 'react-select/creatable';

import './MultiSelectCreatable.scss';

interface IMultiSelectCreatableProps {
	options: IOption[];
	isClearable?: boolean;
	onChange(value: any): void;
	onCreateOption(value: any): void;
}

const CaretDownIcon = () => {
	return <ApIcon iconName="caret" />;
};

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<CaretDownIcon />
		</components.DropdownIndicator>
	);
};

export class MultiSelectCreatable extends React.Component<
	IMultiSelectCreatableProps,
	{}
> {
	state = {};

	render() {
		const { options, onChange, isClearable, onCreateOption } = this.props;

		return (
			<Creatable
				options={options}
				className="multi-select"
				classNamePrefix="multi-select"
				components={{ DropdownIndicator }}
				placeholder="Select from the list"
				onChange={onChange}
				isClearable={isClearable}
				onCreateOption={onCreateOption}
				hideSelectedOptions
			/>
		);
	}
}
