import pptxgen from 'pptxgenjs';

import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
} from './common';

export const appendGrowthProfitabilityLastYearSlide = (
	deckInstance: pptxgen,
	companyName: string,
	growsProfitabilityChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`BENCHMARKS`, SLIDE_TOP_LABEL);
	slide.addText(
		'Growth (Previous year to current year) vs Profitability Chart',
		SLIDE_TITLE_CONFIG,
	);

	slide.addText(
		[
			{
				text: 'Revenue Growth ',
				options: { bold: true, breakLine: false },
			},
			{
				text: '(y/y growth) ',
				options: { italic: true, breakLine: false },
			},
			{ text: 'vs. Profitability ', options: { bold: true, breakLine: false } },
			{
				text: '(EBIT margins)',
				options: { italic: true },
			},
		],
		{
			x: 0.3,
			y: 1.26,
			w: 9.4,
			h: 0.4,
			shape: 'rect',
			fill: { type: 'solid', color: '006176' },
			fontSize: 12,
			fontFace: 'Verdana (Body)',
			color: 'FFFFFF',
		},
	);

	slide.addImage({
		data: growsProfitabilityChart,
		x: 0.3,
		y: 2.36,
		h: 3.97,
		w: 9.39,
	});

	slide.addText(
		'Solid colours represent current year revenue, opaque colours represent revenue for the previous year',
		{
			x: 0.3,
			y: 6.61,
			h: 0.38,
			w: 9.4,
			fontSize: 8,
			fontFace: 'Verdana (Body)',
			color: '808083',
			valign: 'bottom',
		},
	);
};
