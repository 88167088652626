import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import getSymbolFromCurrency from 'currency-symbol-map';

import { numberWithCommas, roundOneDecimal } from '../../../utils';

import './CashPositionWidget.scss';

interface ICashPositionWidgetProps {
	data: any[];
	currency: string;
}

export const CashPositionWidget: React.FC<ICashPositionWidgetProps> = ({
	data,
	currency,
}) => {
	const chartOptions: Highcharts.Options = {
		chart: {
			// height: 240,
			marginLeft: 0,
			marginRight: 0,
			marginTop: 0,
			// margin: 0,
			// width: 512,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: {
			visible: false,
		},
		legend: {
			enabled: true,
			itemStyle: {
				color: '#1A1A1A',
				fontFamily: 'Roboto',
				fontSize: '13px',
				fontWeight: 'normal',
			},
		},
		xAxis: {
			labels: {
				useHTML: true,
				style: {
					color: '#737373',
					fontFamily: 'Roboto',
					fontSize: '16px',
					lineHeight: '24px',
				},
			},
			lineColor: '#CCCCCC',
			crosshair: {
				color: '#CCCCCC',
				width: 1,
			},
		},
		tooltip: {
			outside: true,
			shared: true,
			backgroundColor: undefined,
			borderWidth: 0,
			useHTML: true,
			formatter: function () {
				const addTooltipSeries = (
					label: string,
					value: number,
					color:
						| string
						| Highcharts.GradientColorObject
						| Highcharts.PatternObject,
					currentRevenue: number | null = null,
				): string => {
					return `<span class="custom-tooltip__point" style="color:${color}">\u25CF</span> ${label}: <span class="custom-tooltip__yvalue">${getSymbolFromCurrency(
						currency,
					)}${numberWithCommas(roundOneDecimal(value))}M</span><br/>`;
				};

				let tooltipMarkup = '<div class="custom-tooltip">';

				const cash = this.points?.find((p) => p.series.name === 'Cash');
				const opCash = this.points?.find(
					(p) => p.series.name === 'Operating Cash Flow',
				);
				const fcf = this.points?.find((p) => p.series.name === 'FCF');
				const capex = this.points?.find((p) => p.series.name === 'Capex');

				if (cash) {
					tooltipMarkup += addTooltipSeries('Cash', cash.y, '#022E45');
				}

				if (opCash) {
					tooltipMarkup += addTooltipSeries(
						'Operating Cash Flow',
						opCash.y,
						'#0896A6',
					);
				}

				if (fcf) {
					tooltipMarkup += addTooltipSeries('FCF', fcf.y, '#77B0BE');
				}

				if (capex) {
					tooltipMarkup += addTooltipSeries('Capex', capex.y, '#808083');
				}

				return tooltipMarkup + '</div>';
			},
		},
		// @ts-ignore
		plotOptions: {
			column: {
				// pointWidth: 62,
				// groupPadding: 0,
				pointPadding: 0,
				borderWidth: 0,
			},
			series: {
				marker: {
					symbol: 'square',
				},
				// color: '#0696A6',
				dataLabels: {
					crop: false,
					// @ts-ignore
					overflow: 'none',

					// useHTML: true,
					allowOverlap: true,
					// inside: true,
					// verticalAlign: 'top',
					// crop: false,
					// overflow: 'none',
					// y: -5,
					enabled: false,
					formatter: function () {
						if (this.y) {
							return `${getSymbolFromCurrency(currency)}${numberWithCommas(
								this.y,
							)}`;
						}
					},
					style: {
						color: '#1A1A1A',
						fontFamily: 'Roboto',
						fontSize: '16px',
						lineHeight: '24px',
						fontWeight: 'normal',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};

	const options: Highcharts.Options = {
		...chartOptions,
		xAxis: {
			...chartOptions.xAxis,
			categories: data.map((d) => d.fiscalYear.toString()),
		},
		series: [
			{
				data: data.map((d) => d.cash),
				type: 'column',
				color: '#022E45',
				name: 'Cash',
			},
			{
				data: data.map((d) => d.operatingCashFlow),
				type: 'line',
				color: '#0896A6',
				name: 'Operating Cash Flow',
			},
			{
				data: data.map((d) => d.capex),
				type: 'line',
				color: '#808083',
				name: 'Capex',
			},
			{
				data: data.map((d) => d.fcf),
				type: 'line',
				color: '#77B0BE',
				name: 'FCF',
			},
		],
	};

	return (
		<div className="cash-position-widget">
			<HighchartsReact highcharts={Highcharts} options={options} />
			<p className="cash-position-widget__note">
				Please hover over graph for values
			</p>
		</div>
	);
};
