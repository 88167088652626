import pptxgen from 'pptxgenjs';
// import moment from 'moment';

import { ICompetitor } from '../types';

import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TOP_LABEL_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
} from './common';

export const appendGrowthProfitabilitySlide = (
	deckInstance: pptxgen,
	companyName: string,
	ltm: number,
	competitors: ICompetitor[],
	growsProfitabilityChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addText(`BENCHMARKS`, SLIDE_TOP_LABEL_WIDE);
	slide.addText(
		'5-year Revenue Growth vs Profitability Chart',
		SLIDE_TITLE_CONFIG_WIDE,
	);

	slide.addText(
		[
			{
				text: 'Operating revenue growth ',
				options: { bold: true, breakLine: false },
			},
			{
				text: `(${ltm - 5}-${ltm}) `,
				options: { italic: true, breakLine: false },
			},
			{ text: 'vs. EBIT margin ', options: { bold: true, breakLine: false } },
			{
				text: `(FY ${ltm})`,
				options: { italic: true },
			},
		],
		{
			x: 0.4,
			y: 1.13,
			w: 9.4,
			h: 0.4,
			fontSize: 14,
			fontFace: 'Verdana (Body)',
			align: 'left',
			valign: 'top',
			bold: true,
			color: '383838',
			margin: [0, 0, 0, 0],
		},
	);

	slide.addImage({
		data: growsProfitabilityChart,
		x: 0.4,
		y: 1.5,
		h: 5.27,
		w: 12.46,
	});

	// const companiesEndDateNotCurrent = competitors.filter((c) => {
	// 	const latestYearEndDate =
	// 		c.financialData[c.financialData.length - 1].periodEndDate;
	// 	return (
	// 		moment(+latestYearEndDate * 1000).format('YYYY') !==
	// 		moment().format('YYYY')
	// 	);
	// });

	// if (companiesEndDateNotCurrent.length) {
	// 	slide.addText(
	// 		`LTM ends in ${moment().format(
	// 			'YYYY',
	// 		)} except for ${companiesEndDateNotCurrent
	// 			.map((c) => c.name)
	// 			.join(', ')}`,
	// 		{
	// 			x: 0.3,
	// 			y: 6.61,
	// 			h: 0.38,
	// 			w: 9.4,
	// 			fontSize: 8,
	// 			fontFace: 'Verdana (Body)',
	// 			color: '808083',
	// 			valign: 'bottom',
	// 		},
	// 	);
	// }
};
