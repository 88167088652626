import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from 'highcharts-pattern-fill';

import { IPeersRevenueYoYWidget } from '../../types';

interface IDeckGrowthChartLastYearProps {
	name: string;
	companyId: number;
	competitorsData: IPeersRevenueYoYWidget;
}

HC_patternFill(Highcharts);

export class DeckGrowthChartLastYear extends React.Component<
	IDeckGrowthChartLastYearProps,
	unknown
> {
	render() {
		const {
			name,
			companyId,
			competitorsData: {
				data: competitorsData,
				ebitAverage,
				ebitMedian,
				revenueGrowthAverage,
				revenueGrowthMedian,
			},
		} = this.props;
		const colors = [
			'3,46,69',
			'0,97,118',
			'6,150,166',
			'119,176,190',
			'89,89,89',
			'173,175,178',
		];

		const seriesData = competitorsData
			.map((value, currentIndex) => {
				return {
					name: value.companyName,
					color:
						value.companyId === companyId
							? 'rgba(255, 0, 0)'
							: `rgba(${colors[currentIndex]})`,
					data: [
						{
							y: value.currentYear ? value.currentYear?.revenueGrowth : null,
							x: value.currentYear ? value.currentYear.ebitMargin : null,
							z: value.currentYear ? value.currentYear.revenue : null,
							name: value.companyName,
							color: {
								linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
								stops: [
									[
										0,
										value.companyId === companyId
											? 'rgba(92, 179, 53)'
											: `rgba(${colors[currentIndex]}, 1)`,
									],
									[
										1,
										value.companyId === companyId
											? 'rgba(92, 179, 53)'
											: `rgba(${colors[currentIndex]}, 1)`,
									],
								],
							},
						},
						{
							marker: {
								lineColor:
									value.companyId === companyId
										? 'rgba(92, 179, 53)'
										: `rgba(${colors[currentIndex]}, 1)`,
								lineWidth: 1,
							},
							y: value.previousYear ? value.previousYear?.revenueGrowth : null,
							x: value.previousYear ? value.previousYear.ebitMargin : null,
							z: value.previousYear ? value.previousYear.revenue : null,
							// color: {
							// 	linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
							// 	stops: [
							// 		[
							// 			0,
							// 			value.companyId === companyId
							// 				? 'rgba(92, 179, 53, 0.2)'
							// 				: `rgba(${colors[currentIndex]}, 0.2)`,
							// 		],
							// 		[
							// 			1,
							// 			value.companyId === companyId
							// 				? 'rgba(92, 179, 53, 0.2)'
							// 				: `rgba(${colors[currentIndex]}, 0.2)`,
							// 		],
							// 	],
							// },
							color:
								value.companyId === companyId
									? 'url(#pattern-current-company)'
									: `url(#pattern-${currentIndex})`,
						},
					],
					sizeBy: 'width',
					lineWidth: 0.5,
					lineColor:
						value.companyId === companyId
							? 'rgba(92, 179, 53)'
							: `rgba(${colors[currentIndex]})`,
				};
			})
			.sort((a: any, b: any) => {
				if (a.data[0].z < b.data[0].z) {
					return 1;
				}
				if (a.data[0].z > b.data[0].z) {
					return -1;
				}
				return 0;
			});

		const chartOptions: Highcharts.Options = {
			// @ts-ignore
			defs: {
				patterns: [
					...colors.map((c, index) => ({
						id: `pattern-${index}`,
						path: {
							d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
							stroke: `rgba(${c})`,
							strokeWidth: 0.3,
						},
					})),
					{
						id: `pattern-current-company`,
						path: {
							d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
							stroke: 'rgba(92, 179, 53, 0.2)',
							strokeWidth: 0.3,
						},
					},
				],
			},
			chart: {
				type: 'bubble',
				height: 550,
				width: 1300,
				spacingTop: 30,
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			yAxis: {
				plotLines: [
					{
						color: '#C00000',
						dashStyle: 'LongDash',
						value: revenueGrowthMedian,
						label: {
							align: 'right',
							style: {
								fontStyle: 'italic',
							},
							text: 'Median',
						},
						zIndex: 31,
					},
					{
						color: '#7AB800',
						dashStyle: 'LongDash',
						value: revenueGrowthAverage,
						label: {
							style: {
								fontStyle: 'italic',
							},
							text: 'Average',
						},
						zIndex: 31,
					},
				],
				gridLineWidth: 1,
				minorGridLineWidth: 0,
				title: {
					text: 'Y/Y Revenue Growth',
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				labels: {
					format: '{value}%',
					useHTML: true,
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				lineColor: '#ADAFB2',
				lineWidth: 0,
				tickLength: 0,
			},
			legend: {
				// symbolPadding: 15,
				// symbolHeight: 18,
				enabled: false,
				labelFormatter: function () {
					// TODO: validate labels
					// return `${this.name} (${this.name.substring(0, 6).toUpperCase()})`;
					return `${this.name} (${this.name
						// .replace(/[aeiou]/g, '')
						.toUpperCase()})`;
				},
				itemStyle: {
					color: '#383838',
					fontFamily: 'Verdana',
					fontSize: '12px',
					letterSpacing: '0.3px',
					lineHeight: '19px',
					textAlign: 'right',
					fontWeight: 'normal',
				},
			},
			xAxis: {
				plotLines: [
					{
						color: '#C00000',
						dashStyle: 'LongDash',
						value: ebitMedian,
						label: {
							style: {
								fontStyle: 'italic',
							},
							text: 'Median',
						},
						zIndex: 31,
					},
					{
						color: '#7AB800',
						dashStyle: 'LongDash',
						value: ebitAverage,
						label: {
							verticalAlign: 'bottom',
							y: -55,
							style: {
								fontStyle: 'italic',
							},
							text: 'Average',
						},
						zIndex: 31,
					},
				],
				lineWidth: 0,
				title: {
					text: 'EBIT Margin',
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				labels: {
					format: '{value}%',
					useHTML: true,
					style: {
						color: '#383838',
						fontFamily: 'Verdana',
						fontSize: '12px',
						letterSpacing: '0.3px',
						lineHeight: '19px',
						textAlign: 'right',
					},
				},
				lineColor: '#ADAFB2',
				tickLength: 0,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				bubble: {
					zMin: 1,
				},
				series: {
					dataLabels: {
						enabled: true,
						formatter: function () {
							// TODO: validate labels
							// return this.point.name
							// 	? this.point.name.substring(0, 6).toUpperCase()
							// 	: '';
							return this.point.name
								? // ? this.point.name.replace(/[aeiou]/g, '').toUpperCase()
								  this.point.name.toUpperCase()
								: '';
						},
						y: 30,
						style: {
							color: '#383838',
							fontFamily: 'Verdana',
							fontSize: '12px',
							letterSpacing: '0.3px',
							lineHeight: '19px',
							textAlign: 'right',
							fontWeight: 'normal',
						},
					},
					states: {
						inactive: {
							opacity: 1,
						},
						hover: {
							enabled: false,
						},
					},
					animation: false,
				},
			},
		};

		const options: Highcharts.Options = {
			...chartOptions,
			// @ts-ignore
			series: seriesData,
		};

		return (
			<div
				className={`deck-${name
					.toLowerCase()
					.replace(/ /g, '-')
					.replace('&', 'and')}-chart deck-chart`}
			>
				<HighchartsReact highcharts={Highcharts} options={options} />
			</div>
		);
	}
}
