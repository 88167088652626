import moment from 'moment';
import * as React from 'react';
import {
	ApLoaderDefault,
	ApChip,
	ApButtonGroup,
	ApButtonTab,
} from '@alixpartners/ui-components';

import { PeerSearch } from '../PeerSearch';
import { SharePriceWidget } from './SharePriceWidget';
import { RevenueYoYWidget } from './RevenueYoYWidget';
import { RevenueWidget } from './RevenueWidget';
import { BarChartWidget } from './BarChartWidget';
import { SectionAnalysis } from '../SectionAnalysis';

import {
	ICompetitorMeta,
	IPeersSharePriceWidget,
	IPeersRevenueWidget,
	IPeersRevenueYoYWidget,
	IPeersFinancialWidget,
	ICurrentUser,
	SectionType,
	IAnalysisComment,
	ITag,
	IPeersRevenueYoYData,
} from '../../types';
import {
	getComparisonSharePrice,
	getComparisonRevenueYoY,
	getComparisonRevenue,
	getComparisonFinancialData,
	getSectionComments,
	getAllAnalysisTags,
} from '../../api';
import { roundTwoDecimal } from '../../utils';

import './CompanyComparison.scss';

interface ICompanyComparisonProps {
	companyId: number;
	currentUser: ICurrentUser;
	companyName: string;
	// latestYear: number;
	currencySymbol: string;
	companyPeers: ICompetitorMeta[];
	onAddPeer(competitor: ICompetitorMeta): void;
	onRemovePeer(competitor: ICompetitorMeta): void;
	onPptDataLoaded(): void;
	isPptDataLoaded: boolean;
}

interface ICompanyComparisonState {
	isLoading: boolean;
	// isUpdatingPeersData: boolean;
	selectedPeers: ICompetitorMeta[];
	sharePriceWidgetData: IPeersSharePriceWidget[];
	revenueWidgetData: IPeersRevenueWidget;
	revenueYoYWidgetData: IPeersRevenueYoYWidget;
	barChartWidgetData: IPeersFinancialWidget[];
	sectionCommentsSharePrice: IAnalysisComment[];
	sectionCommentsRevenue: IAnalysisComment[];
	sectionCommentsBarChart: IAnalysisComment[];
	analysisTags: ITag[];
	isRevenueYoYTab: boolean;
}

export class CompanyComparison extends React.Component<
	ICompanyComparisonProps,
	ICompanyComparisonState
> {
	currentCompanyColor = '#032E45';
	companyColors = [
		// '#032E45',
		'#0696A6',
		'#ADAFB2',
		'#FF600F',
		'#15723B',
		'#5680EB',
		'#B67AC5',
	];

	getSelectedPeers = (): ICompetitorMeta[] => {
		const selectedPeers = sessionStorage.getItem('selectedPeers');

		return selectedPeers ? JSON.parse(selectedPeers) : this.props.companyPeers;
	};

	state = {
		isLoading: true,
		selectedPeers: this.getSelectedPeers(),
		sharePriceWidgetData: [] as IPeersSharePriceWidget[],
		revenueWidgetData: {} as IPeersRevenueWidget,
		revenueYoYWidgetData: {} as IPeersRevenueYoYWidget,
		barChartWidgetData: [] as IPeersFinancialWidget[],
		sectionCommentsSharePrice: [] as IAnalysisComment[],
		sectionCommentsRevenue: [] as IAnalysisComment[],
		sectionCommentsBarChart: [] as IAnalysisComment[],
		analysisTags: [] as ITag[],
		isRevenueYoYTab: true,
	};

	componentDidMount() {
		const { isPptDataLoaded, onPptDataLoaded } = this.props;

		if (!isPptDataLoaded) {
			try {
				onPptDataLoaded();
			} catch (error) {
				console.log(error);
			}
		}

		this.loadPeersData();
	}

	loadPeersData = async () => {
		const { selectedPeers } = this.state;
		const { companyId } = this.props;

		const peers = [companyId, ...selectedPeers.map((p) => p.companyId)];
		try {
			const [
				{ data: sharePriceWidgetData },
				{ data: revenueWidgetData },
				{ data: revenueYoYWidgetData },
				{ data: barChartWidgetData },
				{ data: sectionCommentsSharePrice },
				{ data: sectionCommentsRevenue },
				{ data: sectionCommentsBarChart },
				{ data: analysisTags },
			] = await Promise.all([
				getComparisonSharePrice(peers),
				getComparisonRevenue(peers),
				getComparisonRevenueYoY(peers),
				getComparisonFinancialData(peers),
				getSectionComments(companyId, SectionType.PEERS_SHARE_PRICE),
				getSectionComments(companyId, SectionType.PEERS_REVENUE_COMPARISON),
				getSectionComments(companyId, SectionType.PEERS_FINANCIAL_DATA),
				getAllAnalysisTags(),
			]);

			// // @ts-ignore
			// revenueYoYWidgetData[2].currentYear = null;
			// // @ts-ignore
			// revenueYoYWidgetData[2].previousYear = null;

			this.setState({
				isLoading: false,
				// isUpdatingPeersData: false,
				sharePriceWidgetData,
				revenueWidgetData,
				revenueYoYWidgetData,
				barChartWidgetData,
				sectionCommentsSharePrice: sectionCommentsSharePrice.sort((a, b) => {
					return moment.utc(b.createdOn).diff(moment.utc(a.createdOn));
				}),
				sectionCommentsRevenue: sectionCommentsRevenue.sort((a, b) => {
					return moment.utc(b.createdOn).diff(moment.utc(a.createdOn));
				}),
				sectionCommentsBarChart: sectionCommentsBarChart.sort((a, b) => {
					return moment.utc(b.createdOn).diff(moment.utc(a.createdOn));
				}),
				analysisTags,
			});
		} catch (error) {
			this.setState({
				isLoading: false,
			});
			console.log('Error fetching peers data');
		}
	};

	handleAddPeer = (newPeer: ICompetitorMeta) => {
		const { onAddPeer } = this.props;

		this.setState((prevState) => {
			const selectedPeers = [...prevState.selectedPeers, newPeer];
			sessionStorage.setItem('selectedPeers', JSON.stringify(selectedPeers));
			return {
				selectedPeers,
			};
		}, this.handleLoadPeersData);

		onAddPeer(newPeer);
	};

	handleRemovePeer = (peer: ICompetitorMeta) => {
		const { onRemovePeer } = this.props;
		this.setState((prevState) => {
			const selectedPeers = prevState.selectedPeers.filter(
				(p) => p.companyId !== peer.companyId,
			);
			sessionStorage.setItem('selectedPeers', JSON.stringify(selectedPeers));
			return {
				selectedPeers,
			};
		}, this.handleLoadPeersData);

		onRemovePeer(peer);
	};

	handleLoadPeersData = () => {
		this.setState({ isLoading: true });
		this.loadPeersData();
	};

	renderSectionComments = (
		sectionName: string,
		sectionComments: IAnalysisComment[],
	) => {
		const { currentUser, companyId } = this.props;
		const { analysisTags } = this.state;
		return (
			<div className="comparison__section__analysis">
				<SectionAnalysis
					currentUser={currentUser}
					companyId={companyId}
					sectionType={sectionName}
					analysisComments={sectionComments}
					analysisTags={analysisTags}
				/>
			</div>
		);
	};

	render() {
		const { companyId, companyName, currencySymbol } = this.props;
		// const { companyId, companyName, currencySymbol, latestYear } = this.props;
		const {
			selectedPeers,
			isLoading,
			sharePriceWidgetData,
			revenueYoYWidgetData,
			revenueWidgetData,
			isRevenueYoYTab,
			barChartWidgetData,
			sectionCommentsSharePrice,
			sectionCommentsRevenue,
			sectionCommentsBarChart,
		} = this.state;

		const revenueYoYData = isLoading
			? ([] as IPeersRevenueYoYData[])
			: revenueYoYWidgetData.data || [];
		// Share Price
		const sharePriceData = sharePriceWidgetData.find(
			(d) => d.companyId === companyId,
		);
		const growth = sharePriceData?.growth;

		// Revenue Year on Year
		const yoyRevenueData = revenueYoYData.find(
			(d) => d.companyId === companyId,
		);

		const revenueYoYGrowth =
			(yoyRevenueData?.currentYear &&
				yoyRevenueData?.currentYear.revenueGrowth) ||
			0;
		const ebitMargin =
			(yoyRevenueData?.currentYear && yoyRevenueData?.currentYear.ebitMargin) ||
			0;

		const maxGrowth = Math.max(
			...revenueYoYData
				.filter((d) => d.currentYear && d.previousYear)
				.map((d) => d.currentYear.revenueGrowth),
		);
		const minGrowth = Math.min(
			...revenueYoYData
				.filter((d) => d.currentYear && d.previousYear)
				.map((d) => d.currentYear.revenueGrowth),
		);
		const maxEbit = Math.max(
			...revenueYoYData
				.filter((d) => d.currentYear && d.previousYear)
				.map((d) => d.currentYear.ebitMargin),
		);
		const minEbit = Math.min(
			...revenueYoYData
				.filter((d) => d.currentYear && d.previousYear)
				.map((d) => d.currentYear.ebitMargin),
		);

		// Revenue
		const { revenueGrowthAverage, ebitMedian } = revenueWidgetData;
		const data = revenueWidgetData.data || [];
		const revenueData = data.find((d) => d.companyId === companyId);
		const revenueGrowth = revenueData?.revenueGrowth || 0;

		return (
			<div className="comparison">
				<div className="comparison__title">Competitor Selection</div>
				<div className="comparison__wrapper">
					<div className="comparison__search">
						<PeerSearch
							onPeerSelect={({ companyId, name }) => {
								this.handleAddPeer({ companyId, name });
							}}
							companiesToExclude={[
								companyId,
								...selectedPeers.map((p) => p.companyId),
							]}
							placeholder="Add Company"
							disabled={selectedPeers.length > 4 || isLoading}
							showIcon
						/>

						{selectedPeers.length < 5 && (
							<span className="max-number-info">
								{5 - selectedPeers.length} more{' '}
								{5 - selectedPeers.length == 1 ? 'company' : 'companies'}{' '}
								allowed for comparison.
							</span>
						)}

						{selectedPeers.length > 4 && (
							<span className="max-number-info warning">
								Maximum companies for comparison is reached{' '}
							</span>
						)}
					</div>
					<div className="comparison__list">
						<ApChip className="comparison__peer">{companyName}</ApChip>
						{selectedPeers.map((p) => {
							return (
								<ApChip
									onClick={() => {
										this.handleRemovePeer(p);
									}}
									className="comparison__peer"
									key={p.companyId}
									iconName="outline_cancel"
									disabled={isLoading}
								>
									{p.name}
								</ApChip>
							);
						})}
					</div>
				</div>
				<div className="comparison__container">
					<div className="comparison__section comparison__section--share-price">
						<div className="comparison__section__title">
							Share Price Performance Compared to Competitors
						</div>
						{isLoading ? (
							<ApLoaderDefault className="comparison__spinner" />
						) : (
							<>
								{growth && (
									<div className="comparison__section__text">
										{`${companyName} had achieved ${roundTwoDecimal(growth)}% ${
											growth > 0 ? 'growth' : 'decrease'
										} in it’s share price over the past 3 years.`}
									</div>
								)}
								<div className="comparison__section__data">
									<div className="comparison__section__chart">
										<SharePriceWidget
											companyId={companyId}
											currencySymbol={currencySymbol}
											peersSharePriceData={sharePriceWidgetData}
											currentCompanyColor={this.currentCompanyColor}
											companyColors={this.companyColors}
										/>
									</div>
									{this.renderSectionComments(
										SectionType.PEERS_SHARE_PRICE,
										sectionCommentsSharePrice,
									)}
								</div>
							</>
						)}
					</div>
					<div className="comparison__section comparison__section--revenue">
						<div className="comparison__section__title">Revenue Comparison</div>
						<div className="comparison__section__nav">
							<ApButtonGroup justifyContent="initial" spacingSize={0}>
								<ApButtonTab
									isSelected={isRevenueYoYTab}
									onClick={() => {
										this.setState({ isRevenueYoYTab: true });
									}}
								>
									Year on Year
								</ApButtonTab>
								<ApButtonTab
									isSelected={!isRevenueYoYTab}
									onClick={() => {
										this.setState({ isRevenueYoYTab: false });
									}}
								>
									Cumulative
								</ApButtonTab>
							</ApButtonGroup>
						</div>
						<div className="comparison__section__separator" />
						{isLoading ? (
							<ApLoaderDefault className="comparison__spinner" />
						) : (
							<>
								{isRevenueYoYTab ? (
									<div>
										<div className="comparison__section__text">
											{`${companyName} had year on year ${
												revenueYoYGrowth > 0 ? 'revenue growth' : 'decline'
											} of ${roundTwoDecimal(revenueYoYGrowth)}%${
												revenueYoYGrowth === maxGrowth
													? 'putting it ahead of its peers'
													: ''
											}${
												revenueYoYGrowth === minGrowth
													? 'putting it behind of its peers'
													: ''
											}, and its EBIT margin of ${roundTwoDecimal(
												ebitMargin,
											)}% is ${ebitMargin === maxEbit ? 'stronger than' : ''}${
												ebitMargin === minEbit ? 'weaker than' : ''
											}${
												ebitMargin > minEbit && ebitMargin < maxEbit
													? 'comparable to'
													: ''
											} it’s peers`}
										</div>
										<RevenueYoYWidget
											companyId={companyId}
											peersRevenueYoYData={{
												...revenueYoYWidgetData,
												data: revenueYoYWidgetData?.data?.filter(
													(d) => d.currentYear && d.previousYear,
												),
											}}
											currentCompanyColor={this.currentCompanyColor}
											companyColors={this.companyColors}
										/>
									</div>
								) : (
									<div>
										<div className="comparison__section__text">
											{`${companyName} experienced 5 year ${
												revenueGrowth > 0 ? 'growth' : 'decline'
											} of ${roundTwoDecimal(revenueGrowth)}% putting it ${
												revenueGrowth > revenueGrowthAverage ? 'above' : 'below'
											} the average, and its EBIT margin of ${roundTwoDecimal(
												ebitMargin,
											)}% was ${
												ebitMargin > ebitMedian ? 'higher' : 'lower'
											} than the median.`}
										</div>
										<RevenueWidget
											companyId={companyId}
											peersRevenueData={revenueWidgetData}
											currentCompanyColor={this.currentCompanyColor}
											companyColors={this.companyColors}
										/>
									</div>
								)}
								{this.renderSectionComments(
									SectionType.PEERS_REVENUE_COMPARISON,
									sectionCommentsRevenue,
								)}
							</>
						)}
					</div>
					<div className="comparison__section">
						<div className="comparison__section__title">
							Financial Benchmarks
						</div>
						{isLoading ? (
							<ApLoaderDefault className="comparison__spinner" />
						) : (
							<>
								<div className="comparison__section__data">
									<div>
										<BarChartWidget
											companyId={companyId}
											// latestYear={latestYear}
											peersBarChartData={barChartWidgetData}
										/>
									</div>
									{this.renderSectionComments(
										SectionType.PEERS_FINANCIAL_DATA,
										sectionCommentsBarChart,
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}
