/**
 * @summary Takes a comma delimited years string and returns a multi-dimensional array of those years converted into numbers and grouped by sequential dates.
 * @param dates is a string of format: '2019, 2020, 2021' or null or empty.
 * @throws An Error object if any of the dates can't be parsed into a number with the details of which date failed.
 * @example groupDates('2019, 2020, 2025') would return [ [2019, 2020], [2025]]
 */

export const groupDates = (dates: string | null): number[][] | null => {
  if (!dates) return null;

  let dateNums: number[];

  dateNums = dates.split(',').map(d => {
    let dn = parseInt(d);
    if (isNaN(dn) === true) throw new Error(`${d} was not a valid year.`);
    return dn;
  });

  if (!dateNums.length) return null;

  let groups: number[][] = [[dateNums[0]]];

  dateNums.slice(1).forEach(dn => {
    let lastGroup = groups[groups.length - 1];
    let lastNum = lastGroup[lastGroup.length - 1];

    if (dn - lastNum === 1) {
      lastGroup.push(dn);
    }
    else {
      groups.push([dn]);
    }
  });

  return groups;
};

/**
 * @summary Takes a comma delimted string of years and formats them into a summarised format by firstly calling groupDates().
 * @param dates
 * @returns
 * @example formatAndGroupDates('2016, 2017, 2018, 2021') will return '2016 - 2018, 2021'
 */
export const formatAndGroupDates = (dates: string | null): string | null => {
  let dateGroups = groupDates(dates);
  if (!dateGroups) return null;

  let output = '';

  for (let dg of dateGroups) {
    if (output.length !== 0) output += ', ';

    switch (dg.length) {
      case 1: output += dg[0]; break;
      case 2: output += dg[0] + ', ' + dg[1]; break;
      default: output += dg[0] + ' - ' + dg[dg.length - 1]; break;
    }
  }

  return output;
};
