import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { IPeersFinancialWidget } from '../../../types';

import './BarChartWidget.scss';

interface IBarChartWidgetProps {
	companyId: number;
	// latestYear: number;
	peersBarChartData: IPeersFinancialWidget[];
}

export class BarChartWidget extends React.PureComponent<
	IBarChartWidgetProps,
	unknown
> {
	render() {
		const getOptions = (unit = ''): Highcharts.Options => ({
			chart: {
				type: 'column',
				animation: false,
				marginLeft: 15,
			},
			title: {
				align: 'left',
				style: {
					fontFamily: 'Roboto',
					fontSize: '19px',
					fontWeight: '500',
					lineHeight: '27px',
					color: '#000000',
				},
			},
			credits: {
				enabled: false,
			},
			// @ts-ignore
			xAxis: {
				type: 'category',
				offset: 20,
				labels: {
					useHTML: true,
					rotation: -45,
					style: {
						fontFamily: 'Roboto',
						fontSize: '13px',
						color: '#4D4D4D',
					},
					formatter: function () {
						if (this.isFirst) {
							return (
								'<div align="center" style="white-space: normal">' +
								this.value +
								'</div>'
							);
						} else {
							return this.value;
						}
					},
				},
				lineColor: '#CCCCCC',
			},
			yAxis: {
				labels: {
					enabled: false,
				},
				title: {
					text: '',
				},
			},
			legend: {
				enabled: false,
			},
			tooltip: {
				enabled: false,
			},
			colors: [
				'#188B9A',
				'#08566A',
				'#08566A',
				'#08566A',
				'#08566A',
				'#08566A',
			],
			// @ts-ignore
			plotOptions: {
				column: {
					colorByPoint: true,
					pointWidth: 50,
				},
				series: {
					dataLabels: {
						// allowOverlap: false,
						crop: false,
						overflow: false,
						enabled: true,
						formatter: function () {
							if (this.y) {
								return `${
									Math.round((this.y + Number.EPSILON) * 100) / 100
								}${unit}`;
							}
						},
						style: {
							color: '#1A1A1A',
							fontFamily: 'Roboto',
							fontSize: '16px',
							lineHeight: '24px',
							fontWeight: '500',
						},
					},
					states: {
						inactive: {
							opacity: 1,
						},
						hover: {
							enabled: false,
						},
					},
					animation: false,
				},
			},
		});

		// const { companyId, peersBarChartData, latestYear } = this.props;
		const { companyId, peersBarChartData } = this.props;

		const currentCompany = peersBarChartData.find(
			(d) => d.companyId === companyId,
		);

		const otherCompanies = peersBarChartData.filter(
			(d) => d.companyId !== companyId,
		);

		const seriesEbitdaMargin = [
			{
				data: [
					[currentCompany?.companyName, currentCompany?.ebitdaMargin],
					...otherCompanies.map((d) => {
						return [d.companyName, d.ebitdaMargin];
					}),
				],
			},
		];
		const seriesLeverage = [
			{
				data: [
					[currentCompany?.companyName, currentCompany?.leverage],
					...otherCompanies.map((d) => {
						return [d.companyName, d.leverage];
					}),
				],
			},
		];
		const seriesRevenueCAGR = [
			{
				data: [
					[currentCompany?.companyName, currentCompany?.revenueCAGR],
					...otherCompanies.map((d) => {
						return [d.companyName, d.revenueCAGR];
					}),
				],
			},
		];
		const seriesRevenuePerEmployee = [
			{
				data: [
					[currentCompany?.companyName, currentCompany?.revenuePerEmployee],
					...otherCompanies.map((d) => {
						return [d.companyName, d.revenuePerEmployee];
					}),
				],
			},
		];
		const seriesSgaMargin = [
			{
				data: [
					[currentCompany?.companyName, currentCompany?.sgaMargin],
					...otherCompanies.map((d) => {
						return [d.companyName, d.sgaMargin];
					}),
				],
			},
		];
		const seriesCapexPercentageOfRevenue = [
			{
				data: [
					[
						currentCompany?.companyName,
						currentCompany?.capexPercentageOfRevenue,
					],
					...otherCompanies.map((d) => {
						return [d.companyName, d.capexPercentageOfRevenue];
					}),
				],
			},
		];

		return (
			<div className="peers-bar-chart">
				<div className="peers-bar-chart__wrapper">
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions('%'),
								title: {
									...getOptions('%').title,
									text: `Total revenue 3Y CAGR (%, FY${currentCompany?.year})`,
								},
								// @ts-ignore
								series: seriesRevenueCAGR,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions('%'),
								title: {
									...getOptions('%').title,
									text: `EBITDA Margin (%, FY${currentCompany?.year})`,
								},
								// @ts-ignore
								series: seriesEbitdaMargin,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions('%'),
								title: {
									...getOptions('%').title,
									text: `SG&A margin (%, FY${currentCompany?.year})`,
								},
								// @ts-ignore
								series: seriesSgaMargin,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions('%'),
								title: {
									...getOptions('%').title,
									text: 'Capex % of Revenue',
								},
								// @ts-ignore
								series: seriesCapexPercentageOfRevenue,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions('x'),
								title: {
									...getOptions().title,
									text: `Leverage (Net debt/EBITDA, FY${currentCompany?.year})`,
								},
								// @ts-ignore
								series: seriesLeverage,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
					<div className="peers-bar-chart__chart">
						<HighchartsReact
							highcharts={Highcharts}
							options={{
								...getOptions(),
								title: {
									...getOptions().title,
									text: `Revenue per Employee (USD, FY${currentCompany?.year})`,
								},
								// @ts-ignore
								series: seriesRevenuePerEmployee,
							}}
							immutable
							updateArgs={[true, false, false]}
						/>
					</div>
				</div>
			</div>
		);
	}
}
