import React from 'react';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
	ApAppBar,
	ApFooter,
	ApIpLogo,
	ApLoaderDefault,
} from '@alixpartners/ui-components';
import { ScreenClassRender, setConfiguration } from 'react-grid-system';
import {
	ApAuthenticationContextProvider,
	ApAuthenticationContextConsumer,
	ApRedirectRoute,
	Telemetry,
} from '@alixpartners/ui-utils';

import { gutters, getGridConfig } from './styles/gridConfig';
import { SearchContainer } from './components/SearchContainer';
import { WatchlistsContainer } from './components/WatchlistsContainer';
import { Watchlist } from './components/Watchlist';
import { Prospect } from './components/Prospect';
import { CompanyContainer } from './components/CompanyContainer';
import { ScreeningContainer } from './components/ScreeningContainer';
// import { ErrorPage } from './components/ErrorPage';
import {
	PermissionsContext,
	PermissionsConsumer,
} from './components/PermissionsContext';
import { PowerBiParamsContext } from './components/PowerBiParamsContext';
import { UsersContext, UsersConsumer } from './components/UsersContext';
import { ConnectionAnalyticsContext } from './components/ConnectionAnalyticsContext';
import { AccessNotification } from './components/AccessNotification';
import { NavBar } from './components/NavBar';

const history = createBrowserHistory({ basename: '' });
Telemetry.setup(process.env.REACT_APP_INSIGHT_KEY, history);

const HOME_URL = 'https://companies.alixip.com';
const SUPER_ADMIN_ROLE = 'WatchlistSuperAdmin';

function App() {
	return (
		<ApAuthenticationContextProvider
			errorComponent={({ errorType, errorDescription, onClick }) => (
				<AccessNotification
					title={errorType}
					description={errorDescription}
					onClick={onClick}
				/>
			)}
		>
			<div className="App">
				<Router history={history}>
					<PermissionsContext>
						<ApAppBar
							left={() => (
								<>
									<div
										onClick={() => {
											window.open(HOME_URL, '_self');
										}}
									>
										<ApIpLogo theme="dark" />
									</div>
									<div className="separator" />
									<div className="header-text">
										BIC - Company Profile Platform
									</div>
								</>
							)}
							right={() => <NavBar />}
						/>

						<main className="app">
							<ScreenClassRender
								render={(screenClass: keyof typeof gutters) => {
									setConfiguration({
										...(getGridConfig(screenClass) as any),
									});

									return (
										<UsersContext>
											<ConnectionAnalyticsContext>
												<PowerBiParamsContext>
													<PermissionsConsumer>
														{({ isLoading, watchlistDashboard, isOk }) => {
															if (isLoading) {
																return (
																	<>
																		<br />
																		<br />
																		<ApLoaderDefault />
																	</>
																);
															}

															const isTrsEnabled = watchlistDashboard;

															return (
																<ApAuthenticationContextConsumer>
																	{({ user }) => {
																		return (
																			<UsersConsumer>
																				{({ users, isLoading }) => {
																					const isSuperAdmin = user!.profile.roles.includes(
																						SUPER_ADMIN_ROLE,
																					);

																					return (
																						<Switch>
																							{isOk && (
																								<Redirect
																									from="/accessdenied"
																									to="/search"
																								/>
																							)}
																							<Route
																								path="/accessdenied"
																								exact
																								component={AccessNotification}
																							/>
																							<Route path="/signin" exact>
																								<ApRedirectRoute />
																							</Route>
																							<Route
																								path="/companies/:companyId"
																								component={CompanyContainer}
																							/>
																							<Route
																								path="/search"
																								exact
																								component={SearchContainer}
																							/>
																							<Route
																								path="/screening/:companyId?"
																								exact
																								component={ScreeningContainer}
																							/>
																							<Route
																								path="/control-panel"
																								exact
																								component={ScreeningContainer}
																							/>
																							{isTrsEnabled && (
																								<Route
																									path="/watchlists"
																									exact
																									render={() => {
																										return (
																											<WatchlistsContainer
																												isSuperAdmin={
																													isSuperAdmin
																												}
																											/>
																										);
																									}}
																								/>
																							)}
																							{isTrsEnabled && (
																								<Route
																									path="/watchlists/:id"
																									exact
																									render={() => {
																										return (
																											<Watchlist
																												currentUserId={
																													user!.profile.oid
																												}
																												currentUserFullName={`${
																													user!.profile
																														.given_name || ''
																												} ${
																													user!.profile
																														.family_name || ''
																												}`}
																												isSuperAdmin={
																													isSuperAdmin
																												}
																												users={users}
																												isUserListLoading={
																													isLoading
																												}
																											/>
																										);
																									}}
																								/>
																							)}
																							{isTrsEnabled && (
																								<Route
																									path="/prospects/:id"
																									render={() => {
																										return (
																											<Prospect
																												currentUserId={
																													user!.profile.oid
																												}
																												users={users}
																												isUserListLoading={
																													isLoading
																												}
																												isSuperAdmin={
																													isSuperAdmin
																												}
																											/>
																										);
																									}}
																								/>
																							)}
																							<Redirect from="/" to="/search" />
																						</Switch>
																					);
																				}}
																			</UsersConsumer>
																		);
																	}}
																</ApAuthenticationContextConsumer>
															);
														}}
													</PermissionsConsumer>
												</PowerBiParamsContext>
											</ConnectionAnalyticsContext>
										</UsersContext>
									);
								}}
							/>
						</main>
						<ApFooter
							right={() => {
								return (
									<a
										className="contact-us"
										href="mailto:bic@alixpartners.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										Contact BIC Team
									</a>
								);
							}}
						/>
					</PermissionsContext>
				</Router>
			</div>
		</ApAuthenticationContextProvider>
	);
}

export default App;
