import pptxgen from 'pptxgenjs';
import moment from 'moment';

import { ICompetitor } from '../types';

import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
} from './common';

export const appendGrowthProfitabilitySlide = (
	deckInstance: pptxgen,
	companyName: string,
	competitors: ICompetitor[],
	growsProfitabilityChart: string,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(`BENCHMARKS`, SLIDE_TOP_LABEL);
	slide.addText(
		`${companyName} Growth (Last 5 years) vs Profitability Chart`,
		SLIDE_TITLE_CONFIG,
	);

	slide.addText(
		[
			{ text: 'Growth ', options: { bold: true, breakLine: false } },
			{
				text: '(Operating revenue growth last 5 years) ',
				options: { italic: true, breakLine: false },
			},
			{ text: 'vs. Profitability ', options: { bold: true, breakLine: false } },
			{
				text: '(EBIT margin, LTM)',
				options: { italic: true },
			},
		],
		{
			x: 0.3,
			y: 1.26,
			w: 9.4,
			h: 0.4,
			shape: 'rect',
			fill: { type: 'solid', color: '006176' },
			fontSize: 12,
			fontFace: 'Verdana (Body)',
			color: 'FFFFFF',
		},
	);

	slide.addImage({
		data: growsProfitabilityChart,
		x: 0.3,
		y: 2.36,
		h: 3.97,
		w: 9.39,
	});

	const companiesEndDateNotCurrent = competitors.filter((c) => {
		const latestYearEndDate =
			c.financialData[c.financialData.length - 1].periodEndDate;
		return (
			moment(+latestYearEndDate * 1000).format('YYYY') !==
			moment().format('YYYY')
		);
	});

	if (companiesEndDateNotCurrent.length) {
		slide.addText(
			`LTM ends in ${moment().format(
				'YYYY',
			)} except for ${companiesEndDateNotCurrent
				.map((c) => c.name)
				.join(', ')}`,
			{
				x: 0.3,
				y: 6.61,
				h: 0.38,
				w: 9.4,
				fontSize: 8,
				fontFace: 'Verdana (Body)',
				color: '808083',
				valign: 'bottom',
			},
		);
	}
};
