import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import {
	ApButtonIcon,
	ApSimpleDropdown,
	IOption,
} from '@alixpartners/ui-components';

import { IWatchlistProspectList, IWatchlistProspectMeta } from '../../types';

import './WatchlistTable.scss';

interface IWatchlistTableProps {
	data: IWatchlistProspectMeta[];
	openDialog: (p: any) => any;
}

interface CurrencyAmount {
	currencySign: string;

	[key: string]: any;
}

export class WatchlistTable extends React.Component<
	IWatchlistTableProps,
	{
		columnNames: any;
		gridApi: any;
		gridColumnApi: any;
		selectedColumnsFiltered: any;
	}
> {
	constructor(props: IWatchlistTableProps) {
		super(props);

		const defaultColProps = {
			sortable: true,
			filter: true,
			resizable: true,
			singleClickEdit: false,
		};

		const editableField = [
			{
				headerName: 'Edit',
				field: 'edit',
				width: 70,
				cellRenderer: this.agCellDelete,
				cellRendererParams: {
					openDialog: this.props.openDialog,
				},
				...defaultColProps,
			},
		];

		this.state = {
			columnNames: [
				{
					headerName: 'Companies',
					field: 'companyName',
					pinned: 'left',
					cellRenderer: this.agCellCompany,
					...defaultColProps,
				},
				{
					headerName: 'Company Owners',
					field: 'owners',
					...defaultColProps,
				},
				{
					headerName: 'Last Time Comment Reviewed',
					field: 'lastCommentReviewedOn',
					...defaultColProps,
				},
				{
					headerName: 'Last Comment Created',
					field: 'latestCommentCreatedOn',
					...defaultColProps,
				},
				{
					headerName: '# Of Tasks',
					field: 'tasks',
					type: 'numericColumn',
					...defaultColProps,
				},
				{
					headerName: 'Industries',
					field: 'industry',
					...defaultColProps,
				},
				{
					headerName: 'Revenue $ (M)',
					field: 'revenueInUsd',
					colId: 'revenueInUsd',
					type: 'numericColumn',
					cellClass: 'numericCell',
					comparator: (
						valueA: string,
						valueB: string,
						nodeA: any,
						nodeB: any,
						isDescending: boolean,
					) =>
						this.sortingFunction(
							valueA,
							valueB,
							nodeA,
							nodeB,
							isDescending,
							'revenueInUsdValue',
						),
					...defaultColProps,
				},
				{
					headerName: 'Revenue (M)',
					field: 'revenue',
					colId: 'revenue',
					type: 'numericColumn',
					cellClass: 'numericCell',
					comparator: (
						valueA: string,
						valueB: string,
						nodeA: any,
						nodeB: any,
						isDescending: boolean,
					) =>
						this.currencySortingFunction(
							valueA,
							valueB,
							nodeA,
							nodeB,
							isDescending,
							'revenueValue',
						),
					...defaultColProps,
				},
				{
					headerName: 'EBITDA (M)',
					field: 'ebitda',
					colId: 'ebitda',
					type: 'numericColumn',
					cellClass: 'numericCell',
					comparator: (
						valueA: string,
						valueB: string,
						nodeA: any,
						nodeB: any,
						isDescending: boolean,
					) =>
						this.currencySortingFunction(
							valueA,
							valueB,
							nodeA,
							nodeB,
							isDescending,
							'ebitdaValue',
						),
					...defaultColProps,
				},
				{
					headerName: 'Total Debt (M)',
					field: 'totalDebt',
					type: 'numericColumn',
					cellClass: 'numericCell',
					comparator: (
						valueA: string,
						valueB: string,
						nodeA: any,
						nodeB: any,
						isDescending: boolean,
					) =>
						this.currencySortingFunction(
							valueA,
							valueB,
							nodeA,
							nodeB,
							isDescending,
							'totalDebtValue',
						),
					...defaultColProps,
				},
				{
					headerName: 'Other Watchlists',
					field: 'watchlists',
					cellRenderer: this.agCellWatchlists,
					...defaultColProps,
				},
				{
					headerName: 'Status',
					field: 'status',
					width: 80,
					...defaultColProps,
				},
				{
					headerName: 'ID',
					field: 'companyId',
					width: 80,
					...defaultColProps,
				},
				...editableField,
			],
			gridApi: null,
			gridColumnApi: null,

			selectedColumnsFiltered: [
				{ label: 'Company Name', value: 'companyName' },
				{ label: 'Company Owners', value: 'owners' },
				{ label: 'Last Time Comment Reviewed', value: 'lastCommentReviewedOn' },
				{ label: 'Last Comment Created', value: 'latestCommentCreatedOn' },
				{ label: 'No of Tasks', value: 'tasks' },
				{ label: 'Revenue ($)', value: 'revenueInUsd' },
				{ label: 'Revenue', value: 'revenue' },
				{ label: 'Industry', value: 'industry' },
				{ label: 'Ebitda', value: 'ebitda' },
				{ label: 'Total debt', value: 'totalDebt' },
				{ label: 'Watchlists', value: 'watchlists' },
				{ label: 'Status', value: 'status' },
				{ label: 'ID', value: 'companyId' },
				{ label: 'Edit', value: 'edit' },
			],
		};

		this.onGridReady = this.onGridReady.bind(this);
	}

	componentDidMount() {
		const savedColumnsWidth = localStorage.getItem('columnsWidth');
		let newColumnNames;
		if (savedColumnsWidth) {
			const parsedWidths = JSON.parse(savedColumnsWidth);
			newColumnNames = this.state.columnNames.map((column: any) => {
				const savedColumn = parsedWidths.find(
					(saved: any) => saved.field === column.field,
				);
				return {
					...column,
					width: savedColumn?.width || column,
				};
			});

			this.setState({ columnNames: newColumnNames });
		}

		const filteredColumns: any = localStorage.getItem('columnsFiltered');

		if (filteredColumns) {
			const mappedColumns = savedColumnsWidth
				? newColumnNames
				: this.state.columnNames;

			const updatedColumNames = mappedColumns.map((column: any) => {
				const matchingFilter = JSON.parse(filteredColumns).filter(
					(filteredColumn: any) =>
						filteredColumn.value.toString() === column.field.toString(),
				);

				if (matchingFilter.length === 0) {
					return { ...column, hide: true };
				} else {
					return column;
				}
			});

			this.setState({
				selectedColumnsFiltered: JSON.parse(filteredColumns),
				columnNames: updatedColumNames,
			});
		}
	}

	// Custom cells for the table
	agCellCompany(params: any) {
		const { companyId, companyName } = params.data;
		return (
			<a className="cell-link" href={`/prospects/${companyId}/overview`}>
				{companyName}
			</a>
		);
	}

	agCellWatchlists(params: any) {
		const { watchlists } = params.data;
		return (
			<>
				{watchlists.map(({ watchlistId, watchlistName }: any, i: number) => (
					<a
						className="cell-wishlist-link"
						href={`/watchlists/${watchlistId}`}
						key={`wishlist-${i}`}
					>
						${watchlistName}
					</a>
				))}
			</>
		);
	}

	agCellDelete(params: any) {
		return (
			<ApButtonIcon
				onClick={() => {
					params.openDialog(params);
				}}
				hoverColor="#5CB335"
				iconName="delete_outline"
				iconProps={{
					iconColor: '#999999',
				}}
			/>
		);
	}

	sortingFunction(
		valueA: string,
		valueB: string,
		nodeA: any,
		nodeB: any,
		isDescending: boolean,
		column: string,
	): number {
		const sign = isDescending ? -1 : 1;

		const a: CurrencyAmount = nodeA.data;
		const b: CurrencyAmount = nodeB.data;

		return isDescending
			? sign * (b[column] - a[column])
			: sign * (a[column] - b[column]);
	}

	currencySortingFunction(
		valueA: string,
		valueB: string,
		nodeA: any,
		nodeB: any,
		isDescending: boolean,
		column: string,
	): number {
		const sign = isDescending ? -1 : 1;

		const a: CurrencyAmount = nodeA.data;
		const b: CurrencyAmount = nodeB.data;

		if (a.currencySign === b.currencySign) {
			// Calculate the difference between the two values based on the sorting order
			return isDescending
				? sign * (b[column] - a[column])
				: sign * (a[column] - b[column]);
		}

		// Sort by currency sign if the signs are different
		return a.currencySign.localeCompare(b.currencySign);
	}

	// helper methods
	formatCurrency(value: number, currency: string) {
		if (!currency || (!value && value !== 0)) return '-';

		if (currency === 'CNY') {
			const formattedValue = Math.round(value).toLocaleString('en-US', {
				maximumFractionDigits: 0,
			});
			return `CN¥ ${formattedValue}`;
		} else {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: currency,
				currencyDisplay: 'narrowSymbol',
				maximumFractionDigits: 0,
			}).format(Math.round(value));
		}
	}

	formatCurrencySign(currency: string): string {
		if (!currency) return '-';
		return (0).toLocaleString('en-US', {
			style: 'currency',
			currency: currency,
			currencyDisplay: 'narrowSymbol',
			maximumFractionDigits: 0,
		})[0];
	}

	formatDate(value: string) {
		if (!value) return '-';
		const date = new Date(value);
		return date.toLocaleDateString();
	}

	formatLink(value: string, url: string) {
		return `<a href="${url}">${value}</a>`;
	}

	// Build up a row of data
	buildRowData(data: IWatchlistProspectMeta[] = []) {
		const rowData: IWatchlistProspectList[] = [];

		data.forEach((obj: IWatchlistProspectMeta) => {
			rowData.push({
				companyName: obj.companyName,
				companyId: obj.companyId,
				ebitda: this.formatCurrency(obj.ebitda, obj.currencyCode),
				ebitdaValue: obj.ebitda,
				industry: obj.industry,
				owners: obj.owners,
				status: obj.status,
				revenue: this.formatCurrency(obj.revenue, obj.currencyCode),
				revenueValue: obj.revenue,
				revenueInUsd: this.formatCurrency(obj.revenueInUsd, 'USD'),
				revenueInUsdValue: obj.revenueInUsd,
				totalDebt: this.formatCurrency(obj.totalDebt, obj.currencyCode),
				totalDebtValue: obj.totalDebt,
				tasks: obj.tasks,
				currencyCode: obj.currencyCode,
				currencySign: this.formatCurrencySign(obj.currencyCode),
				lastCommentReviewedOn: this.formatDate(obj.lastCommentReviewedOn),
				latestCommentCreatedOn: this.formatDate(obj.latestCommentCreatedOn),
				watchlists: obj.watchlists,
			});
		});

		return rowData;
	}

	onColumnResized(params: any) {
		const { columnApi } = params;
		const columns = columnApi.getAllColumns();
		const newColumnsWidth = columns.map((column: any) => ({
			field: column.colId,
			width: column.getActualWidth(),
		}));
		localStorage.setItem('columnsWidth', JSON.stringify(newColumnsWidth));
	}

	toggleColumn = (options: any) => {
		if (this.state.gridApi && this.state.gridColumnApi) {
			const columnsToShow = options.map((option: any) => option.value);

			if (columnsToShow.length === 0) {
				this.state.gridColumnApi.setColumnsVisible(
					this.state.columnNames.map((column: any) => column.field),
					false,
				);
			} else {
				this.state.gridColumnApi.getAllColumns().forEach((column: any) => {
					const isVisible = columnsToShow.includes(column.colId);

					this.state.gridColumnApi.setColumnVisible(column.colId, isVisible);
				});
			}
		}
	};

	onGridReady(params: any) {
		this.setState({ gridApi: params.api, gridColumnApi: params.columnApi });
	}

	handleSelectedColumns = (options: IOption | IOption[]) => {
		this.setState(
			{
				selectedColumnsFiltered: options as IOption[],
			},
			() => {
				localStorage.setItem(
					'columnsFiltered',
					JSON.stringify(this.state.selectedColumnsFiltered),
				);
			},
		);

		this.toggleColumn(options);
	};

	render() {
		const { data } = this.props;
		const rowData = this.buildRowData(data);

		const columnNameFields = [
			{ label: 'Company Name', value: 'companyName' },
			{ label: 'Company Owners', value: 'owners' },
			{ label: 'Last Time Comment Reviewed', value: 'lastCommentReviewedOn' },
			{ label: 'Last Comment Created', value: 'latestCommentCreatedOn' },
			{ label: 'No of Tasks', value: 'tasks' },
			{ label: 'Industry', value: 'industry' },
			{ label: 'Revenue ($)', value: 'revenueInUsd' },
			{ label: 'Revenue', value: 'revenue' },
			{ label: 'Ebitda', value: 'ebitda' },
			{ label: 'Total debt', value: 'totalDebt' },
			{ label: 'Watchlists', value: 'watchlists' },
			{ label: 'Status', value: 'status' },
			{ label: 'ID', value: 'companyId' },
			{ label: 'Edit', value: 'edit' },
		];
		return (
			<section className="table-panel">
				<div className="manage-columns-wrapper">
					<ApSimpleDropdown
						multiple
						label="Manage Columns"
						onChange={this.handleSelectedColumns}
						options={columnNameFields}
						selected={this.state.selectedColumnsFiltered}
					/>
				</div>

				<div className="table-panel__grid ag-theme-balham">
					<AgGridReact
						onGridReady={this.onGridReady}
						onColumnResized={this.onColumnResized}
						columnDefs={this.state.columnNames}
						rowData={rowData}
						modules={AllCommunityModules}
						rowHeight={36}
					/>
				</div>
			</section>
		);
	}
}
