import React from 'react';
import {
	ApButtonSecondary,
	ApLoaderDefault,
} from '@alixpartners/ui-components';

import { ActiveFilters } from '../ActiveFilters';
import { CompanyCard } from '../CompanyCard';
import { ICompanySearchResult, IFilter } from '../../types';

import './Content.scss';

import { defaultProps } from '../CompanyCard/props.mock';

const RESULTS_LIMIT = 50; // TODO: update to 50

interface IContentProps {
	searchResults: ICompanySearchResult[];
	activeFilters: IFilter[];
	isLoading: boolean;
	isLoadingMore: boolean;
	isLoadMoreHidden: boolean;

	onFilterRemove(filter: IFilter): void;

	onLoadMore(): void;
}

export const Content: React.FC<IContentProps> = ({
	activeFilters,
	isLoading,
	isLoadingMore,
	isLoadMoreHidden,
	searchResults = [],
	onFilterRemove,
	onLoadMore,
}) => {
	return (
		<div className="content">
			<div className="content__header">
				Companies
				<p className="content__note">
					Company universe includes all companies with revenue {`>  `}
					<span className="numeric">$150m</span>
				</p>
			</div>

			<ActiveFilters
				activeFilters={activeFilters}
				onFilterRemove={onFilterRemove}
			/>
			{isLoading ? (
				<ApLoaderDefault />
			) : (
				<>
					<div className={"content__search-results" + (isLoadMoreHidden && " end-reached")}>
						{searchResults.length > 0 ? (
							searchResults.map((company, index) => {
								const transitionIndex = index % RESULTS_LIMIT;

								return (
									<CompanyCard
										{...defaultProps}
										companyData={company}
										index={transitionIndex}
										key={company.companyId}
									/>
								);
							})
						) : (
							<span>No results</span>
						)}
					</div>
					{!isLoadMoreHidden && (
						<div className="content__load-more">
							{isLoadingMore ? (
								<ApLoaderDefault />
							) : (
								<ApButtonSecondary onClick={onLoadMore}>
									Load more results
								</ApButtonSecondary>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
};
