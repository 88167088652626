import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from 'highcharts-pattern-fill';

import { IPeersRevenueYoYWidget } from '../../../types';

import './RevenueYoYWidget.scss';

interface IRevenueYoYWidgetProps {
	companyId: number;
	peersRevenueYoYData: IPeersRevenueYoYWidget;
	companyColors: string[];
	currentCompanyColor: string;
}

HC_patternFill(Highcharts);

export const RevenueYoYWidget: React.FC<IRevenueYoYWidgetProps> = ({
	companyId,
	peersRevenueYoYData,
	companyColors,
	currentCompanyColor,
}) => {
	const {
		ebitAverage,
		ebitMedian,
		revenueGrowthAverage,
		revenueGrowthMedian,
		data,
	} = peersRevenueYoYData || [];

	const series = data
		.map((value, currentIndex) => {
			return {
				name: value.companyName,
				legendColor:
					value.companyId === companyId
						? currentCompanyColor
						: companyColors[currentIndex],
				// color:
				//  value.companyId === companyId
				//    ? 'rgba(255, 0, 0)'
				//    : `rgba(${companyColors[currentIndex]})`,
				data: [
					{
						marker: {
							lineColor:
								value.companyId === companyId
									? currentCompanyColor
									: companyColors[currentIndex],
							// lineColor:
							//  value.companyId === companyId
							//    ? 'rgba(235, 70, 60)'
							//    : `rgba(${companyColors[currentIndex]}, 1)`,
							lineWidth: 0,
						},
						y: value.currentYear.revenueGrowth,
						x: value.currentYear.ebitMargin,
						z: value.currentYear.revenue,
						name: value.companyName,
						// color: colors1[currentIndex],
						// color: colors1[currentIndex],
						color: {
							linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
							stops: [
								[
									0,
									value.companyId === companyId
										? currentCompanyColor
										: companyColors[currentIndex],
									// value.companyId === companyId
									//  ? 'rgba(235, 70, 60)'
									//  : `rgba(${companyColors[currentIndex]}, 1)`,
								],
								[
									1,
									value.companyId === companyId
										? currentCompanyColor
										: companyColors[currentIndex],
									// value.companyId === companyId
									//  ? 'rgba(235, 70, 60)'
									//  : `rgba(${companyColors[currentIndex]}, 1)`,
								],
							],
						},
					},
					{
						marker: {
							lineColor:
								value.companyId === companyId
									? currentCompanyColor
									: companyColors[currentIndex],
							// lineColor:
							//  value.companyId === companyId
							//    ? 'rgba(235, 70, 60)'
							//    : `rgba(${companyColors[currentIndex]}, 1)`,
							lineWidth: 3,
						},
						y: value.previousYear.revenueGrowth,
						x: value.previousYear.ebitMargin,
						z: value.previousYear.revenue,
						// color: {
						//  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
						//  stops: [
						//    [
						//      0,
						//      value.companyId === companyId
						//        ? 'rgba(235, 70, 60, 0.2)'
						//        : `rgba(${companyColors[currentIndex]}, 0.2)`,
						//    ],
						//    [
						//      1,
						//      value.companyId === companyId
						//        ? 'rgba(235, 70, 60, 0.2)'
						//        : `rgba(${companyColors[currentIndex]}, 0.2)`,
						//    ],
						//  ],
						// },
						color:
							value.companyId === companyId
								? 'url(#pattern-current-company)'
								: `url(#pattern-${currentIndex})`,
					},
				],
				sizeBy: 'width',
				lineWidth: 0.2,
				lineColor:
					value.companyId === companyId
						? currentCompanyColor
						: companyColors[currentIndex],
				// lineColor:
				//  value.companyId === companyId
				//    ? 'rgba(235, 70, 60)'
				//    : `rgba(${companyColors[currentIndex]})`,
			};
		})
		.sort((a: any, b: any) => {
			if (a.data[0].z < b.data[0].z) {
				return 1;
			}
			if (a.data[0].z > b.data[0].z) {
				return -1;
			}
			return 0;
		});

	const chartOptions: Highcharts.Options = {
		// @ts-ignore
		defs: {
			patterns: [
				...companyColors.map((c, index) => ({
					id: `pattern-${index}`,
					path: {
						d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
						stroke: c,
						strokeWidth: 0.3,
					},
				})),
				{
					id: `pattern-current-company`,
					path: {
						d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
						stroke: currentCompanyColor,
						strokeWidth: 0.3,
					},
				},
			],
		},

		chart: {
			type: 'bubble',
			animation: false,
			height: 600,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		yAxis: {
			plotLines: [
				{
					color: '#C00000',
					dashStyle: 'LongDash',
					value: revenueGrowthMedian,
					label: {
						align: 'right',
						style: {
							fontStyle: 'italic',
						},
						text: 'Median',
					},
					zIndex: 31,
				},
				{
					color: '#7AB800',
					dashStyle: 'LongDash',
					value: revenueGrowthAverage,
					label: {
						style: {
							fontStyle: 'italic',
						},
						text: 'Average',
					},
					zIndex: 31,
				},
			],
			gridLineWidth: 1,
			minorGridLineWidth: 0,
			title: {
				text: 'Y/Y Revenue Growth (USD)',
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			labels: {
				format: '{value}%',
				useHTML: true,
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			lineColor: '#ADAFB2',
			lineWidth: 1,
			tickLength: 0,
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			plotLines: [
				{
					color: '#C00000',
					dashStyle: 'LongDash',
					value: ebitMedian,
					label: {
						style: {
							fontStyle: 'italic',
						},
						text: 'Median',
					},
					zIndex: 31,
				},
				{
					color: '#7AB800',
					dashStyle: 'LongDash',
					value: ebitAverage,
					label: {
						verticalAlign: 'bottom',
						y: -55,
						style: {
							fontStyle: 'italic',
						},
						text: 'Average',
					},
					zIndex: 31,
				},
			],
			title: {
				text: 'EBIT Margin',
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			labels: {
				format: '{value:.2f}%',
				useHTML: true,
				style: {
					fontFamily: 'Roboto',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '40px',
					color: '#4D4D4D',
				},
			},
			lineColor: '#ADAFB2',
			tickLength: 0,
		},
		tooltip: {
			enabled: false,
		},
		// @ts-ignore
		plotOptions: {
			bubble: {
				zMin: 1,
			},
			series: {
				dataLabels: {
					inside: false,
					crop: false,
					overflow: false,
					enabled: true,
					formatter: function () {
						return this.point.name ? this.point.name.toUpperCase() : '';
					},
					y: -10,
					style: {
						fontFamily: 'Roboto',
						fontSize: '13px',
						fontWeight: '500',
						lineHeight: '40px',
						color: '#4D4D4D',
					},
				},
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				animation: false,
			},
		},
	};

	const options: Highcharts.Options = {
		...chartOptions,
		// @ts-ignore
		series: series,
	};

	return (
		<div className="peers-share-price">
			<div className="peers-share-price__chart">
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					immutable
					updateArgs={[true, false, false]}
				/>
			</div>
			<div className="peers-share-price__legend">
				{series.map((s, index) => {
					return (
						<div className="peers-share-price__legend__item" key={index}>
							<div
								className="peers-share-price__legend__mark"
								// @ts-ignore
								style={{ backgroundColor: s.legendColor }}
							/>

							<div className="peers-share-price__legend__value">{s.name}</div>
						</div>
					);
				})}
			</div>
			<div className="peers-share-price__legend">
				{series.map((s, index) => {
					return (
						<div className="peers-share-price__legend__item" key={index}>
							<div
								className="peers-share-price__legend__mark"
								style={{
									border: `2px solid ${s.legendColor}`,
									background: `repeating-linear-gradient(45deg, ${s.legendColor}, ${s.legendColor} 1px, white 1px, white 4px)`,
								}}
							></div>
							<div className="peers-share-price__legend__value">{`${s.name} (P/Y)`}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
