import * as React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import getSymbolFromCurrency from 'currency-symbol-map';

import { IPeersSharePriceWidget } from '../../../types';

import './SharePriceWidget.scss';

interface ISharePriceWidgetProps {
	companyId: number;
	currencySymbol: string;
	peersSharePriceData: IPeersSharePriceWidget[];
	companyColors: string[];
	currentCompanyColor: string;
}

const getChartOptions = (currencySymbol: string): Highcharts.Options => ({
	chart: {
		marginBottom: 0,
		marginLeft: 10,
		marginRight: 10,
		marginTop: 0,
		animation: false,
	},
	title: {
		text: '',
	},
	credits: {
		enabled: false,
	},
	yAxis: {
		title: {
			text: '',
		},
		gridLineColor: '#E6E6E6',
	},
	legend: {
		enabled: false,
	},
	rangeSelector: {
		buttons: [
			{
				type: 'month',
				count: 1,
				text: '1M',
			},
			{
				type: 'month',
				count: 3,
				text: '3M',
			},
			{
				type: 'month',
				count: 6,
				text: '6M',
			},
			{
				type: 'ytd',
				text: 'YTD',
			},
			{
				type: 'year',
				count: 1,
				text: '1Y',
			},
			{
				type: 'all',
				text: '3Y',
			},
		],

		enabled: true,
		buttonTheme: {
			padding: 10,
			fill: 'none',
			stroke: 'none',
			buttonSpacing: 32,
			r: 0,
			style: {
				fontFamily: 'Roboto',
				fontSize: '13px',
				fontWeight: '500',
				lineHeight: '40px',
				color: '#4D4D4D',
			},
			states: {
				hover: {
					r: 0,
					fill: 'rgb(236, 243, 234)',
				},

				select: {
					r: 0,
					fill: 'rgb(92, 179, 53)',
					style: {
						color: 'white',
					},
				},
			},
		},
		inputBoxBorderColor: '#999999',
		inputBoxWidth: 120,
		inputBoxHeight: 38,
		inputStyle: {
			fontFamily: 'Roboto',
			fontSize: '16px',
			color: '#1A1A1A',
		},
		labelStyle: {
			fontFamily: 'Roboto',
			fontSize: '16px',
			color: '#000000',
		},
	},
	scrollbar: {
		enabled: false,
	},
	navigator: {
		enabled: true,
		maskFill: 'rgb(92, 179, 53, 0.1)',
		outlineColor: 'white',
		outlineWidth: 0,
	},
	stockTools: {
		gui: {
			enabled: false,
		},
	},
	xAxis: {
		lineColor: 'transparent',
		tickLength: 0,
	},
	plotOptions: {
		line: {
			marker: {
				states: {
					hover: {
						enabled: false,
					},
				},
				enabled: false,
			},
			states: {
				hover: {
					enabled: false,
					animation: {},
				},
				inactive: {
					opacity: 1,
				},
			},
		},
		series: {
			animation: false,
			states: {
				hover: {
					enabled: false,
				},
			},
		},
	},
	tooltip: {
		enabled: true,
		outside: false,
		shared: false,
		backgroundColor: undefined,
		borderWidth: 0,
		useHTML: true,
		split: true,
		formatter: function () {
			if (!this.points || this.points.length === 0) {
				return null;
			}

			let tooltip = `<div class="tooltip"><div style="padding-bottom: 8px">Date: ${moment(
				this.x,
			).format('MMMM Do YYYY')}</div>`;
			this.points.forEach((p: any) => {
				tooltip += `<div>
        <span class="tooltip__point" style="color:${p.color}">\u25CF</span>
        <span> ${p.series.name} (Index: ${p.y}) - </span>
        <span class="tooltip__value">${getSymbolFromCurrency(
					p.point.currencyCode,
				)}${p.point.sharePrice}</span>
        </div>`;
			});

			tooltip += '</div>';
			return tooltip;
		},
	},
});

export class SharePriceWidget extends React.PureComponent<
	ISharePriceWidgetProps,
	{}
> {
	render() {
		const {
			companyId,
			currencySymbol,
			peersSharePriceData,
			companyColors,
			currentCompanyColor,
		} = this.props;

		const chartOptions = getChartOptions(currencySymbol);
		const series = peersSharePriceData.map((peer, index) => {
			return {
				type: 'line',
				color:
					peer.companyId === companyId
						? currentCompanyColor
						: companyColors[index],
				name: peer.companyName,
				data: peer.sharePrice.map((d) => ({
					x: parseInt(d.date) * 1000,
					y: d.indexPrice,
					sharePrice: d.sharePrice,
					currencyCode: peer.currencyCode,
				})),
			};
		});

		const options: Highcharts.Options = {
			...chartOptions,
			// @ts-ignore
			series,
		};

		return (
			<div className="peers-share-price">
				<div className="peers-share-price__chart">
					<HighchartsReact
						constructorType="stockChart"
						highcharts={Highcharts}
						options={options}
						immutable
						updateArgs={[true, false, false]}
					/>
				</div>
				<div className="peers-share-price__legend">
					{series.map((s, index) => {
						return (
							<div className="peers-share-price__legend__item" key={index}>
								<div
									className="peers-share-price__legend__mark"
									// @ts-ignore
									style={{ backgroundColor: s.color }}
								/>
								<div className="peers-share-price__legend__value">{s.name}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}
