import React from 'react';

import { getUsers } from '../../api';
import { IUser } from '../../types';

export const { Provider, Consumer } = React.createContext<IUsersContext>({
	users: [],
	isLoading: true,
});

interface IUsersContextProps {
	children: any;
}

interface IUsersContext {
	users: IUser[];
	isLoading: boolean;
}

interface IUsersContextState {
	users: IUser[];
	isLoading: boolean;
}

class UsersContext extends React.Component<
	IUsersContextProps,
	IUsersContextState
> {
	state = {
		users: [],
		isLoading: true,
	};

	async componentDidMount() {
		try {
			const { data } = await getUsers();
			this.setState({
				users: data,
				isLoading: false,
			});
		} catch (error) {
			console.log('Error', error);
		}
	}

	render() {
		return <Provider value={{ ...this.state }}>{this.props.children}</Provider>;
	}
}

export default UsersContext;
