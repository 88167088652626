import React from 'react';
import { ApButtonMain } from '@alixpartners/ui-components';

import { IDefaultProps, TString } from '../../types/types';
import screenshot from './screenshot.png';
import './AccessNotification.scss';

interface IAccessNotificationProps extends IDefaultProps {
	title: TString;
	description?: TString;
	onClick?: (event: React.MouseEvent) => void;
}

export const AccessNotification: React.FC<IAccessNotificationProps> = () => {
	// Chantelle Melton, Kate Rizzuti, Karen Cangas, Jess Bermonsolo, Todd Anderson
	const sendTo = 'helpdesk@alixpartners.com';
	const ccList = [
		'cmelton@alixpartners.com',
		'krizzuti@alixpartners.com',
		'kcangas@alixpartners.com',
		'jbermensolocutler@alixpartners.com',
		'tjanderson@alixpartners.com',
	];
	const subject = 'Access request for BIC Company Profile Platform';
	const email = `Dear Helpdesk,%0D%0A%0D%0A I request access to the BIC Company Profile Platform: http://companies.alixip.com/ (apuse2praffirmwes01web)%0D%0A%0D%0A Assigned to role: Sec_AffirmPR_AZ_CHCConsultant%0D%0A%0D%0A Note: The BIC team CCd in this email is for the purpose of tracking requests only. No approval is required.%0D%0A%0D%0A
	Please refer to the following 'approval for new joiners' document:
	https://alixpartners.box.com/s/eyydmh8a24dk9zhfttdq11tvl1kk97s1 %0D%0A%0D%0A Please process and confirm.%0D%0A%0D%0A %0D%0A%0D%0A Thank you`;
	const mailLink = `mailto:${sendTo}?subject=${subject}&cc=${ccList.reduce(
		(cc, acc) => `${acc};${cc}`,
	)}&body=${email}`;

	// If you are the administrator, please click the link below to
	// complete the single sign on process. You will need to perform this
	// for yourself first, then you can add other users.

	return (
		<div className="access-page">
			<div className="access-page__wrapper">
				<div className="access-page__message-wrapper">
					<div className="access-page__title">
						Introducing the BIC Company Profile Platform
					</div>
					<div className="access-page__message-content">
						<div className="access-page__body-text">
							<h5>Our new firm-wide company profile and screening platform</h5>
							<p>
								Now available to all consultants and employees at AlixPartners.
								<br />
								<br />
								To request access, click on the button below to generate an
								email to Help Desk and your access will be granted shortly.
							</p>
							<ApButtonMain
								href={mailLink}
								rel="noopener noreferrer"
								target="_blank"
								as="a"
								style={{ width: '300px' }}
							>
								Request Access
							</ApButtonMain>
						</div>
						<div className="access-page__body-image">
							<img src={screenshot} alt="BIC Tool" />
						</div>
					</div>
				</div>
				<div>
					If you believe this is not an access issue please contact{' '}
					<a
						className="access-link"
						href={'https://alixpartners.service-now.com/'}
						rel="noopener noreferrer"
						target="_blank"
					>
						IT Helpdesk
					</a>
				</div>
			</div>
		</div>
	);
};

export const navigateToAccessDenied = () => {
	window.location.href = '/accessdenied';
};
