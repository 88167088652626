import React from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICompanySearchResult, IEngagement } from '../../types';
import { Logo } from '../Logo';

import './CompanyCard.scss';

interface ICompanyCardProps extends RouteComponentProps {
	companyData: ICompanySearchResult;
	isSelected?: boolean;
	index: number;
}

interface ICompanyHeaderProps {
	name: string;
	additionalNames: string[];
	website?: string;
}

interface IEngagementsTableProps {
	engagements: IEngagement[];
}

interface ICompanyCardState {}

export const CompanyHeader: React.FC<ICompanyHeaderProps> = (props) => {
	const { website, name, additionalNames } = props;

	return (
		<div className="header">
			{website && (
				<div className="header__logo-url">
					<Logo url={website} sml />
				</div>
			)}
			<div className="header__info">
				<div className="header__info__name">{name}</div>
				{additionalNames.length > 0 && (
					<>
						<div className="header__info__label">Also known as:</div>
						<div className="header__info__additional-names">
							{additionalNames.join(', ')}
						</div>
					</>
				)}
			</div>
			<div className="header__actions">
				<div className="header__actions__alert"></div>
				<div className="header__actions__links"></div>
			</div>
		</div>
	);
};

export const EngagementsTable: React.FC<IEngagementsTableProps> = (props) => {
	const { engagements } = props;

	return (
		<div className="eng-table">
			<div className="eng-table__header">
				<div className="eng-table__header__cell eng-table__header__cell--title">
					Engagement Name
				</div>
				<div className="eng-table__header__cell">Min Spend</div>
				<div className="eng-table__header__cell">Max Spend</div>
				<div className="eng-table__header__cell">Contracts</div>
				<div className="eng-table__header__cell">Transactions</div>
			</div>
			{engagements.map((eng) => {
				return (
					<div className="eng-table__row" key={eng.name}>
						<div className="eng-table__row__cell eng-table__row__cell--title">
							{eng.name}
						</div>
						<div className="eng-table__row__cell">{eng.minSpend}</div>
						<div className="eng-table__row__cell">{eng.maxSpend}</div>
						<div className="eng-table__row__cell">{eng.contracts}</div>
						<div className="eng-table__row__cell">{eng.transactions}</div>
					</div>
				);
			})}
		</div>
	);
};

class CompanyCardComponent extends React.Component<
	ICompanyCardProps,
	ICompanyCardState
> {
	render() {
		const {
			companyData: {
				companyId,
				name,
				additionalNames,
				website,
				engagements,
				classification,
			},
			history,
			isSelected,
		} = this.props;

		const duration = 200;
		const delay: number = 50 * this.props.index;

		const defaultStyle = {
			transition: `opacity ${duration}ms ${delay}ms ease-in, transform ${duration}ms ${delay}ms ease-out`,
			opacity: 0,
		};

		const transitionStyles = {
			entering: { opacity: 0, transform: 'translate(0, 50px)' },
			entered: { opacity: 1, transform: 'translate(0, 0)' },
			exiting: {},
			exited: {},
			unmounted: {},
		};

		return (
			<Transition in={true} appear timeout={duration}>
				{(state) => {
					return (
						<div
							className={classNames('company-card', {
								'company-card--selected': isSelected,
							})}
							style={{
								...defaultStyle,
								...transitionStyles[state],
							}}
							onClick={() => {
								history.push(`/companies/${companyId}/overview`);
							}}
						>
							<CompanyHeader
								name={name}
								additionalNames={additionalNames}
								website={website}
							/>
							{Object.values(classification).every((p: string) => p) && (
								<>
									<div className="company-card__separator"></div>
									<div className="company-card__taxonomy">
										<div className="company-card__taxonomy__label">
											Taxonomy:
										</div>
										<div className="company-card__taxonomy__data">
											{Object.values(classification)
												.filter((item) => typeof item === 'string')
												.join(' / ')}
										</div>
									</div>
								</>
							)}
							{engagements.length > 0 && (
								<EngagementsTable engagements={engagements} />
							)}
						</div>
					);
				}}
			</Transition>
		);
	}
}

export const CompanyCard = withRouter(CompanyCardComponent);
