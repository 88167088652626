import * as React from 'react';
import { ApIcon } from '@alixpartners/ui-components';

import './DebtSecondaryWidget.scss';

interface IDebtSecondaryWidgetProps {
	name: string;
	value: string;
	title: string;
	growth: number;
}

export const DebtSecondaryWidget: React.FC<IDebtSecondaryWidgetProps> = ({
	name,
	value,
	title,
	growth,
}) => {
	return (
		<div className="debt-secondary-widget">
			<div className="debt-secondary-widget__title">{`${title} - ${name}`}</div>
			<div className="debt-secondary-widget__data">{value}</div>
			<div className="debt-secondary-widget__separator" />
			<div className="debt-secondary-widget__change">
				<ApIcon
					className={`debt-secondary-widget__change-icon${
						growth < 0 ? ' debt-secondary-widget__change-icon--negative' : ''
					}`}
					iconName="trending_flat"
					iconColor="#5CB335"
				/>
				<div>{`${growth}% Y/Y`}</div>
			</div>
		</div>
	);
};
