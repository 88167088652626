import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
	ApSimpleTable,
	ApSimpleTableBody,
	ApSimpleTableBodyCell,
	ApSimpleTableHead,
	ApSimpleTableHeadCell,
	ApSimpleTableRow,
} from '@alixpartners/ui-components';

import { IProfitAndLossOverviewData } from '../../../types';
import { isEmpty, numberWithCommas, roundOneDecimal } from '../../../utils';

import './ProfitLossOverviewSection.scss';

interface IProfitLossOverviewSectionProps {
	data: IProfitAndLossOverviewData;
	companyName: string;
	currency: string;
}

export const ProfitLossOverviewSection: React.FC<IProfitLossOverviewSectionProps> = ({
	data,
	companyName,
	currency,
}) => {
	if (!data || isEmpty(data)) {
		return (
			<div className="profit-loss-section">
				<div className="profit-loss-section__empty">No data available</div>
			</div>
		);
	}

	const { cagR5Years } = data;

	const renderRow = (
		header: string,
		valueName: string,
		cagrValue: number,
		growthValueName?: string,
	) => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					className={`row-${valueName}`}
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellWidth="400px"
					cellTextColor="#1A1A1A"
				>
					{header}
				</ApSimpleTableBodyCell>
				{data.fiscalYearsData.map((d) => {
					return (
						<ApSimpleTableBodyCell
							className={`row-${valueName} text-align-right`}
							key={d.fiscalYear}
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							{growthValueName ? (
								<>
									<span>
										{numberWithCommas(roundOneDecimal(d[valueName]))}{' '}
									</span>
									{`(`}
									<span className={d[growthValueName] > 0 ? 'green' : 'red'}>
										{d[growthValueName] > 0 ? '▲' : '▼'}
										{`${Math.abs(roundOneDecimal(d[growthValueName]))}`}
									</span>
									{')'}
								</>
							) : (
								<span>{`${numberWithCommas(
									roundOneDecimal(d[valueName]),
								)}`}</span>
							)}
						</ApSimpleTableBodyCell>
					);
				})}
				<ApSimpleTableBodyCell
					className={`row-${valueName} text-align-right`}
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
				>
					{cagrValue}%
				</ApSimpleTableBodyCell>
			</ApSimpleTableRow>
		);
	};

	const renderMarginRow = (header: string, valueName: string) => {
		return (
			<ApSimpleTableRow>
				<ApSimpleTableBodyCell
					className={`row-${valueName}`}
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellTextColor="#1A1A1A"
					cellWidth="400px"
				>
					{header}
				</ApSimpleTableBodyCell>
				{data.fiscalYearsData.map((d) => {
					return (
						<ApSimpleTableBodyCell
							className={`row-${valueName} text-align-right`}
							key={d.fiscalYear}
							cellBorders={{
								bottom: true,
								left: false,
								right: false,
								top: false,
							}}
							cellTextColor="#1A1A1A"
						>
							{`${numberWithCommas(roundOneDecimal(d[valueName]))}%`}
						</ApSimpleTableBodyCell>
					);
				})}
				<ApSimpleTableBodyCell
					cellBorders={{
						bottom: true,
						left: false,
						right: false,
						top: false,
					}}
					cellWidth="200px"
				>
					{''}
				</ApSimpleTableBodyCell>
			</ApSimpleTableRow>
		);
	};

	return (
		<div className="profit-loss-section">
			<div className="profit-loss-section__container">
				<ApSimpleTable
					tableBorders={{
						bottom: true,
						left: true,
						right: true,
						top: true,
					}}
					tableHeight="420px"
					tableWidth="100%"
					className="ap-table-with-numeric-column"
				>
					<ApSimpleTableHead>
						<ApSimpleTableRow>
							<ApSimpleTableHeadCell
								cellTextColor="#737373"
								className=" numericColumn text-align-left"
							>
								{`${getSymbolFromCurrency(currency)}M`}
							</ApSimpleTableHeadCell>
							{data.fiscalYearsData.map((d) => {
								return (
									<ApSimpleTableHeadCell
										cellTextColor="#737373"
										key={d.fiscalYear}
										className=" numericColumn"
									>
										{d.fiscalYear}
									</ApSimpleTableHeadCell>
								);
							})}
							<ApSimpleTableHeadCell
								cellTextColor="#737373"
								cellWidth="200px"
								className=" numericColumn"
							>
								{`CAGR ${data.fiscalYearsData[0].fiscalYear} - ${
									data.fiscalYearsData[data.fiscalYearsData.length - 1]
										.fiscalYear
								}`}
							</ApSimpleTableHeadCell>
						</ApSimpleTableRow>
					</ApSimpleTableHead>
					<ApSimpleTableBody>
						{renderRow('Sales', 'sales', cagR5Years.sales, 'salesYoY')}
						{renderRow('Cost of sales', 'cogs', cagR5Years.cogs, 'cogsYoY')}
						{renderRow(
							'Gross Profit',
							'grossProfit',
							cagR5Years.grossProfit,
							'grossProfitYoY',
						)}
						{renderRow(
							'Selling, general and administrative expenses',
							'sga',
							cagR5Years.sga,
							'sgaYoY',
						)}
						{renderRow(
							'Operating Income',
							'operatingIncome',
							cagR5Years.operatingIncome,
							'operatingIncomeYoY',
						)}
						{renderRow(
							'Profit/(loss) for the year',
							'profitPerLossForTheYear',
							cagR5Years.profitPerLossForTheYear,
							'profitPerLossForTheYearYoY',
						)}
						{renderRow('EBITDA', 'ebitda', cagR5Years.ebitda, 'ebitdaYoY')}
					</ApSimpleTableBody>
				</ApSimpleTable>
				<br />
				<ApSimpleTable
					tableBorders={{
						bottom: true,
						left: true,
						right: true,
						top: true,
					}}
					tableHeight="420px"
					tableWidth="100%"
					className="ap-table-with-numeric-column"
				>
					<ApSimpleTableHead>
						<ApSimpleTableRow>
							<ApSimpleTableHeadCell
								cellTextColor="#737373"
								className=" numericColumn"
							>
								<div style={{ visibility: 'hidden' }}>empty</div>
							</ApSimpleTableHeadCell>
							{data.fiscalYearsData.map((d) => {
								return (
									<ApSimpleTableHeadCell
										cellTextColor="#737373"
										key={d.fiscalYear}
										className=" numericColumn"
									>
										{d.fiscalYear}
									</ApSimpleTableHeadCell>
								);
							})}
							<ApSimpleTableHeadCell
								cellWidth="200px"
								className=" numericColumn"
							>
								<div style={{ visibility: 'hidden' }}>empty</div>
							</ApSimpleTableHeadCell>
						</ApSimpleTableRow>
					</ApSimpleTableHead>
					<ApSimpleTableBody>
						{renderMarginRow('Revenue Growth', 'revenueGrowth')}
						{renderMarginRow('Gross Profit margin', 'grossProfitMargin')}
						{renderMarginRow('SG & A margin', 'sgaMargin')}
						{renderMarginRow(
							'Operating Income margin',
							'operatingIncomeMargin',
						)}
						{renderMarginRow('EBITDA margin', 'ebitdaMargin')}
					</ApSimpleTableBody>
				</ApSimpleTable>
			</div>
		</div>
	);
};
