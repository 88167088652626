import pptxgen from 'pptxgenjs';
import * as isoCountry from 'iso-3166-1-alpha-2';

import { numberWithCommas, roundOneDecimal } from '../utils';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TITLE_CONFIG,
	STICKER_CONFIG,
} from './common';

export const SECONDARY_TITLE_CONFIG: pptxgen.TextPropsOptions = {
	x: 0.3,
	y: 1.26,
	fontSize: 12,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'bottom',
	color: '383838',
	margin: [0, 0, 6, 0],
	paraSpaceAfter: 12,
};

export const SUMMARY_CONFIG: pptxgen.TextPropsOptions = {
	w: 9.4,
	h: 5,
	x: 0.3,
	y: 1.67,
	fontSize: 10,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '383838',
	margin: 0,
	// bullet: { marginPt: 14.5 },
	paraSpaceAfter: 3,
};

export const appendCompanySummarySlide = (
	deckInstance: pptxgen,
	companyName: string,
	yearFounded: number,
	city: string,
	country: string,
	tickerSymbol: string,
	exchangeName: string,
	currencyCode: string,
	marketCap: number,
	sharePrice: number,
	sharePrice52WeekHigh: number,
	countries: string[],
	latestYear: number,
	revenue: number,
	prevRevenue: number,
	grossProfit: number,
	prevGrossProfit: number,
	totalEbitda: number,
	prevEbitda: number,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(companyName, SLIDE_TITLE_CONFIG);
	slide.addText('Summary', { ...SECONDARY_TITLE_CONFIG, bold: true });

	const bulletTextConfig = { bullet: { marginPt: 14.5 }, breakLine: true };

	let summaryList: any[] = [
		{
			text: `Founded in ${yearFounded}, and headquartered in ${city}, ${isoCountry.getCountry(
				country.toUpperCase(),
			)}`,
			options: bulletTextConfig,
		},
	];

	if (tickerSymbol) {
		summaryList.push({
			text: `${companyName} is part of ${exchangeName}, under the ticker ${tickerSymbol}`,
			options: bulletTextConfig,
		});
	}

	const revenuPercentageChange = Math.round(
		Math.abs(((revenue - prevRevenue) / revenue) * 100),
	);

	const grossProfitMargin = Math.round((grossProfit / revenue) * 100);
	const prevGrossProfitMargin = Math.round(
		(prevGrossProfit / prevRevenue) * 100,
	);

	summaryList = [
		...summaryList,
		{
			text: `The group had a market capitalization of ${currencyCode} ${numberWithCommas(
				roundOneDecimal(marketCap),
			)} million and is trading at ${currencyCode} ${roundOneDecimal(
				sharePrice,
			)}, against a 52-week high of ${currencyCode} ${roundOneDecimal(
				sharePrice52WeekHigh,
			)}`,
			options: bulletTextConfig,
		},
		{
			text: `Its primary business operates in ${
				countries.length
			} geographic segments: ${countries
				.map((c) => isoCountry.getCountry(c.toUpperCase()))

				.join(countries.length === 1 ? '' : ', ')}`,
			options: bulletTextConfig,
		},
		{
			text: ' ',
			options: { breakLine: true },
		},
		{
			text: `Financial performance FY${latestYear}`,
			options: { bold: true, ...SECONDARY_TITLE_CONFIG },
		},
		{
			text: `The company reached ${currencyCode} ${numberWithCommas(
				roundOneDecimal(revenue),
			)} million in ${latestYear} vs ${currencyCode} ${numberWithCommas(
				roundOneDecimal(prevRevenue),
			)} million in the previous year (${
				revenue - prevRevenue < 0
					? revenuPercentageChange * -1
					: revenuPercentageChange
			}%)`,
			options: bulletTextConfig,
		},
		{
			text: `Gross profit ${
				grossProfit > prevGrossProfit ? 'reached' : 'fell to'
			} ${currencyCode} ${numberWithCommas(
				roundOneDecimal(grossProfit),
			)} million, with a gross profit margin of ${grossProfitMargin}%, ${Math.abs(
				grossProfitMargin - prevGrossProfitMargin,
			)}% ${
				grossProfitMargin > prevGrossProfitMargin ? 'higher' : 'lower'
			} than previous year`,
			options: bulletTextConfig,
		},
		{
			text: `EBITDA ${
				totalEbitda < prevEbitda ? 'decreased' : 'increased'
			} to ${currencyCode} ${numberWithCommas(
				roundOneDecimal(totalEbitda),
			)} million vs ${currencyCode} ${numberWithCommas(
				roundOneDecimal(prevEbitda),
			)} million in the previous year`,
			options: bulletTextConfig,
		},
		{
			text: ' ',
			options: { breakLine: true },
		},
		{
			text: `Key Takeaways`,
			options: { bold: true, ...SECONDARY_TITLE_CONFIG },
		},
	];

	slide.addText(summaryList, SUMMARY_CONFIG);

	slide.addText('Input required', {
		x: 0.3,
		y: 4.28,
		w: 2.6,
		h: 2.6,
		...STICKER_CONFIG,
	});
};
