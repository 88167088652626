import pptxgen from 'pptxgenjs';

import { ICompetitor, IPeersFinancialWidget } from '../types';

import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
	SLIDE_SECONDARY_TITLE_CONFIG,
} from './common';

export const appendPeersComparisonSlide = (
	deckInstance: pptxgen,
	// companyName: string,
	// ltm: number,
	companyId: number,
	competitors: ICompetitor[],
	peersFinancialData: IPeersFinancialWidget[],
) => {
	const currentCompanyFinancialData = peersFinancialData.find(
		(d) => d.companyId === companyId,
	);

	const {
		companyName,
		year,
	} = currentCompanyFinancialData as IPeersFinancialWidget;
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText(
		`COMPANY OUTSIDE IN ASSESSMENT`,
		// @ts-ignore
		Object.assign(SLIDE_TOP_LABEL, { w: 2.4, y: 0.12, x: 7.3 }),
	);
	slide.addText(`${companyName} Peers Comparison`, SLIDE_TITLE_CONFIG);

	slide.addText(`Total revenue 3Y CAGR (%, FY${year})`, {
		...{ ...SLIDE_SECONDARY_TITLE_CONFIG, ...{ fontSize: 10 } },
		x: 0.3,
		y: 1.26 + 0.2,
		w: 2.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(`EBITDA margin (%, FY${year})`, {
		...{ ...SLIDE_SECONDARY_TITLE_CONFIG, ...{ fontSize: 10 } },
		x: 3.5,
		y: 1.26 + 0.2,
		w: 2.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(`SG&A margin (%, FY${year})`, {
		...{ ...SLIDE_SECONDARY_TITLE_CONFIG, ...{ fontSize: 10 } },
		x: 6.7,
		y: 1.26 + 0.2,
		w: 2.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(`CAPEX % of Revenue`, {
		...{ ...SLIDE_SECONDARY_TITLE_CONFIG, ...{ fontSize: 10 } },
		x: 0.3,
		y: 4.04 + 0.2,
		w: 2.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	slide.addText(`Leverage (Net debt/EBITDA, FY${year})`, {
		...{ ...SLIDE_SECONDARY_TITLE_CONFIG, ...{ fontSize: 10 } },
		x: 3.5,
		y: 4.04 + 0.2,
		w: 2.6,
		h: 0,
		shape: deckInstance.ShapeType.line,
	});

	const peersData: IPeersFinancialWidget[] = [
		currentCompanyFinancialData as IPeersFinancialWidget,
		...peersFinancialData
			.filter((d) => d.companyId !== companyId)
			.sort((a, b) => {
				const textA = a.companyName.toUpperCase();
				const textB = b.companyName.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			}),
	];

	const {
		labels,
		valuesCagr,
		valuesEbitda,
		valuesSga,
		valuesCapex,
		valuesLeverage,
		// valuesPerEmployee,
	} = peersData.reduce(
		(acc: any, current: IPeersFinancialWidget) => {
			acc.labels.push(
				current.companyName
					.replace(/[aeiou]/g, '')
					.toUpperCase()
					.substr(0, 3),
			);
			acc.valuesCagr.push(Math.round(current.revenueCAGR * 10) / 10);
			acc.valuesEbitda.push(Math.round(current.ebitdaMargin * 10) / 10);
			acc.valuesSga.push(Math.round(current.sgaMargin * 10) / 10);
			acc.valuesCapex.push(
				Math.round(current.capexPercentageOfRevenue * 10) / 10,
			);
			acc.valuesLeverage.push(Math.round(current.leverage * 10) / 10);
			// acc.valuesPerEmployee.push(
			// 	Math.round(current.revenuePerEmployee * 10) / 10,
			// );
			return acc;
		},
		{
			labels: [],
			valuesCagr: [],
			valuesEbitda: [],
			valuesSga: [],
			valuesCapex: [],
			valuesLeverage: [],
			// valuesPerEmployee: [],
		},
	);

	const addChart = (
		x: number,
		y: number,
		values: number[],
		dataLabelFormatCode = '#0.0##\\%;-#0.0##\\%;;',
	) => {
		slide.addChart(
			'bar',
			[
				{
					name: 'Revenue',
					labels,
					values,
				},
			],
			{
				showDataTableHorzBorder: false,
				showDataTableVertBorder: false,
				x,
				y,
				h: 2.33,
				w: 2.92,
				border: { pt: 0, color: 'ffffff' },
				chartColors: [
					'0696A6',
					'032E45',
					'032E45',
					'032E45',
					'032E45',
					'032E45',
				],
				valGridLine: { style: 'none' },
				serAxisHidden: true,
				valAxisHidden: true,
				valAxisLineShow: false,
				serAxisLineShow: false,
				showLeaderLines: false,
				showValue: true,
				catAxisMajorTickMark: 'none',
				catAxisMinorTickMark: 'none',
				// @ts-ignore
				axisLineColor: 'ffffff',
				dataLabelColor: '383838',
				dataLabelFontFace: 'Verdana (Body)',
				dataLabelFontSize: 10,
				dataLabelPosition: 'outEnd',
				dataLabelFormatCode,
				catAxisLabelColor: '383838',
				catAxisLabelFontFace: 'Verdana (Body)',
				catAxisLabelFontSize: 10,
			},
		);
	};

	// Actual charts
	addChart(0.2, 1.52, valuesCagr);
	addChart(3.4, 1.52, valuesEbitda);
	addChart(6.6, 1.52, valuesSga);
	addChart(0.2, 4.3, valuesCapex);
	addChart(3.4, 4.3, valuesLeverage, '#0.0##\\x;-#0.0##\\x;;');
	// addChart(3.4, 4.3, valuesLeverage);
	// addChart(6.6, 4.3, valuesPerEmployee, '#.##;-#.##;;');

	// slide.addImage({
	// 	data: peersComparisonCargChart,
	// 	x: 0.2,
	// 	y: 1.52,
	// 	h: 2.33,
	// 	w: 2.92,
	// });

	// slide.addImage({
	// 	data: peersComparisonInventoryTurnChart,
	// 	x: 3.4,
	// 	y: 1.52,
	// 	h: 2.33,
	// 	w: 2.92,
	// });

	// slide.addImage({
	// 	data: peersComparisonSgaMarginChart,
	// 	x: 6.6,
	// 	y: 1.52,
	// 	h: 2.33,
	// 	w: 2.92,
	// });

	// slide.addImage({
	// 	data: peersComparisonMarketingMarginChart,
	// 	x: 0.2,
	// 	y: 4.3,
	// 	h: 2.33,
	// 	w: 2.92,
	// });

	// slide.addImage({
	// 	data: peersComparisonCashConversionChart,
	// 	x: 4.4,
	// 	y: 4.3,
	// 	h: 2.33,
	// 	w: 2.92,
	// });

	slide.addText(
		[
			{
				text: `${companyName
					.replace(/[aeiou]/g, '')
					.toUpperCase()
					.substr(0, 3)} = ${companyName}`,
				options: { breakLine: true },
			},
			...competitors
				.filter((c) => c.companyId !== companyId)
				.map((c) => ({
					text: `${c.name
						.replace(/[aeiou]/g, '')
						.toUpperCase()
						.substr(0, 3)} = ${c.name}`,
					options: { breakLine: true },
				})),
		],
		{
			x: 7.32,
			y: 4.24,
			w: 2.62,
			h: 2.39,
			fontSize: 10,
			fontFace: 'Verdana (Body)',
			color: '808083',
		},
	);
};
