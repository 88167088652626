import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import {
	IScoreData,
	ISummaryData,
	IProfitAndLossData,
	ITag,
	SectionType,
	IAnalysisComment,
	ICurrentUser,
	IDebtAnalysisData,
	ICashPositionData,
	IProfitAndLossOverviewData,
	IBalanceSheetData,
	ICashFlowData,
} from '../../types';

import {
	getAlixScreenScore,
	getSummary,
	getProfitAndLoss,
	getAllAnalysisTags,
	getSectionComments,
	getDebtAnalysis,
	getCashPosition,
	getProfitAndLossOverview,
	getBalanceSheet,
	getCashFlow,
} from '../../api';

import { AlixScreenScore } from './AlixScreenScore';
import { Section } from './Section';

import { SummarySection } from './SummarySection';
import { ProfitLossSection } from './ProfitLossSection';
import { DebtSection } from './DebtSection';
import { CashPositionSection } from './CashPositionSection';
import { ProfitLossOverviewSection } from './ProfitLossOverviewSection';
import { BalanceSheetSection } from './BalanceSheetSection';
import { CashflowSection } from './CashflowSection';
import { SectionAnalysis } from '../SectionAnalysis';

import './CompanyDashboard.scss';

interface ICompanyDashboardProps extends RouteComponentProps {
	companyId: number;
	companyName: string;
	currentUser: ICurrentUser;
	onPptDataLoaded(): void;
	isPptDataLoaded: boolean;
}

interface ICompanyAnalysisState {
	isLoadingScoreData: boolean;
	isLoadingSummary: boolean;
	isLoadingProfitAndLoss: boolean;
	isLoadingDebtAnalysis: boolean;
	isLoadingCashPosition: boolean;
	isLoadingAnalysisTags: boolean;
	isLoadingProfitLossOverview: boolean;
	isLoadingBalanceSheet: boolean;
	isLoadingCashFlowData: boolean;
	scoreData: IScoreData;
	summaryData: ISummaryData;
	profitLossData: IProfitAndLossData;
	debtAnalysisData: IDebtAnalysisData;
	cashPositionData: ICashPositionData;
	profitLossOverviewData: IProfitAndLossOverviewData;
	balanceSheetData: IBalanceSheetData[];
	cashFlowData: ICashFlowData[];
	analysisTags: ITag[];
	commentsSummary: IAnalysisComment[];
	commentsProfitLoss: IAnalysisComment[];
	commentsDebt: IAnalysisComment[];
	commentsCashPosition: IAnalysisComment[];
	commentsProftLossOverview: IAnalysisComment[];
	commentsBalanceSheet: IAnalysisComment[];
	commentsCashFlow: IAnalysisComment[];
}

class CompanyDashboardComponent extends React.Component<
	ICompanyDashboardProps,
	ICompanyAnalysisState
> {
	private isComponentMounted = false;

	public set isMounted(status: boolean) {
		this.isComponentMounted = status;
	}

	public get isMounted() {
		return this.isComponentMounted;
	}

	state = {
		isLoadingScoreData: true,
		isLoadingSummary: true,
		isLoadingProfitAndLoss: true,
		isLoadingDebtAnalysis: true,
		isLoadingCashPosition: true,
		isLoadingProfitLossOverview: true,
		isLoadingBalanceSheet: true,
		isLoadingCashFlowData: true,
		isLoadingAnalysisTags: true,
		scoreData: {} as IScoreData,
		summaryData: {} as ISummaryData,
		profitLossData: {} as IProfitAndLossData,
		debtAnalysisData: {} as IDebtAnalysisData,
		cashPositionData: {} as ICashPositionData,
		profitLossOverviewData: {} as IProfitAndLossOverviewData,
		balanceSheetData: [] as IBalanceSheetData[],
		cashFlowData: [] as ICashFlowData[],
		analysisTags: [] as ITag[],
		commentsSummary: [] as IAnalysisComment[],
		commentsProfitLoss: [] as IAnalysisComment[],
		commentsDebt: [] as IAnalysisComment[],
		commentsCashPosition: [] as IAnalysisComment[],
		commentsProftLossOverview: [] as IAnalysisComment[],
		commentsBalanceSheet: [] as IAnalysisComment[],
		commentsCashFlow: [] as IAnalysisComment[],
	};

	renderSectionComments = (
		sectionName: string,
		sectionComments: IAnalysisComment[],
	) => {
		const { currentUser, companyId } = this.props;
		const { analysisTags } = this.state;
		return (
			<div className="comparison__section__analysis">
				<SectionAnalysis
					currentUser={currentUser}
					companyId={companyId}
					sectionType={sectionName}
					analysisComments={sectionComments}
					analysisTags={analysisTags}
				/>
			</div>
		);
	};

	async componentDidMount() {
		const { companyId, isPptDataLoaded, onPptDataLoaded } = this.props;

		this.isMounted = true;

		if (!isPptDataLoaded) {
			try {
				onPptDataLoaded();
			} catch (error) {
				console.log(error);
			}
		}

		try {
			// @ts-ignore
			const result = await Promise.allSettled([
				getSummary(companyId).then(({ data: summaryData }) => {
					if (this.isMounted) {
						this.setState({ summaryData, isLoadingSummary: false });
					}
				}),
				getProfitAndLoss(companyId).then(({ data: profitLossData }) => {
					if (this.isMounted) {
						this.setState({ profitLossData, isLoadingProfitAndLoss: false });
					}
				}),
				getDebtAnalysis(companyId).then(({ data: debtAnalysisData }) => {
					if (this.isMounted) {
						this.setState({ debtAnalysisData, isLoadingDebtAnalysis: false });
					}
				}),
				getCashPosition(companyId).then(({ data: cashPositionData }) => {
					if (this.isMounted) {
						this.setState({ cashPositionData, isLoadingCashPosition: false });
					}
				}),
				getProfitAndLossOverview(companyId).then(
					({ data: profitLossOverviewData }) => {
						if (this.isMounted) {
							this.setState({
								profitLossOverviewData,
								isLoadingProfitLossOverview: false,
							});
						}
					},
				),
				getBalanceSheet(companyId).then(({ data: balanceSheetData }) => {
					if (this.isMounted) {
						this.setState({ balanceSheetData, isLoadingBalanceSheet: false });
					}
				}),
				getCashFlow(companyId).then(({ data: cashFlowData }) => {
					if (this.isMounted) {
						this.setState({ cashFlowData, isLoadingCashFlowData: false });
					}
				}),
				getAllAnalysisTags().then(({ data: analysisTagsData }) => {
					if (this.isMounted) {
						this.setState({
							analysisTags: analysisTagsData,
							isLoadingAnalysisTags: false,
						});
					}
				}),
				Promise.all([
					getSectionComments(companyId, SectionType.SUMMARY),
					getSectionComments(companyId, SectionType.PROFIT_AND_LOSS),
					getSectionComments(companyId, SectionType.DEBT),
					getSectionComments(companyId, SectionType.CASHPOSITION_AND_CASH_FLOW),
					getSectionComments(companyId, SectionType.PROFIT_AND_LOSS_OVERVIEW),
					getSectionComments(companyId, SectionType.BALANCE_SHEET),
					getSectionComments(companyId, SectionType.CASHFLOW),
					getAlixScreenScore(companyId),
				]).then(
					([
						{ data: commentsSummary },
						{ data: commentsProfitLoss },
						{ data: commentsDebt },
						{ data: commentsCashPosition },
						{ data: commentsProftLossOverview },
						{ data: commentsBalanceSheet },
						{ data: commentsCashFlow },
						{ data: scoreData },
					]) => {
						if (this.isMounted) {
							this.setState({
								commentsSummary,
								commentsProfitLoss,
								commentsDebt,
								commentsCashPosition,
								commentsProftLossOverview,
								commentsBalanceSheet,
								commentsCashFlow,
								scoreData,
								isLoadingScoreData: false,
							});
						}
					},
				),
			]);

			// Temp solution for error handling
			const promiseNames = [
				'Summary',
				'ProfitAndLoss',
				'DebtAnalysis',
				'CashPosition',
				'ProfitLossOverview',
				'BalanceSheet',
				'CashFlowData',
			];

			result.forEach((p: any, index: number) => {
				if (p.status === 'rejected') {
					if (promiseNames[index]) {
						// @ts-ignore
						this.setState({ [`isLoading${promiseNames[index]}`]: false });
					}
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({ isLoadingScoreData: false });
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		const { companyName } = this.props;
		const {
			isLoadingScoreData,
			isLoadingSummary,
			isLoadingProfitAndLoss,
			isLoadingDebtAnalysis,
			isLoadingCashPosition,
			isLoadingProfitLossOverview,
			isLoadingBalanceSheet,
			isLoadingCashFlowData,
			scoreData,
			summaryData,
			profitLossData,
			debtAnalysisData,
			cashPositionData,
			profitLossOverviewData,
			balanceSheetData,
			cashFlowData,
			commentsSummary,
			commentsProfitLoss,
			commentsDebt,
			commentsCashPosition,
			commentsProftLossOverview,
			commentsBalanceSheet,
			commentsCashFlow,
		} = this.state;

		if (isLoadingScoreData) {
			return <ApLoaderDefault />;
		}

		return (
			<div className="dashboard">
				<div className="dashboard__title">Overview:</div>
				<p>
					This dashboard gives an overview of the health and change within a
					business. It helps to identify a company's profitability and
					performance across a range of core financial categories.
				</p>
				<div className="dashboard__subtitle">AlixScreen Score:</div>
				<p>
					AlixScreen is an AlixPartners screening tool, used to identify
					underperforming companies across regions, sectors and industries. The
					score is based on a scoring system across of 5 categories: Sales,
					Profitability, Solvency, Working capital and Market data. Each
					category calculated based on changes in category KPI’s, and weighted
					to calculate the overall score. For more information about how the
					scores are calculated and how to adjust the weighted categories please
					see the{' '}
					<span
						className="link"
						onClick={() => {
							this.props.history.push('/control-panel');
						}}
					>
						control panel
					</span>
					.
				</p>
				<AlixScreenScore scoreData={scoreData} />
				<div className="dashboard__separator" />
				<Section title="Summary" isLoading={isLoadingSummary}>
					<SummarySection summaryData={summaryData} companyName={companyName} />
					{this.renderSectionComments(SectionType.SUMMARY, commentsSummary)}
				</Section>
				<Section title="Profit and Loss" isLoading={isLoadingProfitAndLoss}>
					<ProfitLossSection
						profitLossData={profitLossData}
						companyName={companyName}
					/>
					{this.renderSectionComments(
						SectionType.PROFIT_AND_LOSS,
						commentsProfitLoss,
					)}
				</Section>
				<Section
					title="Debt, Leverage and Coverage"
					isLoading={isLoadingDebtAnalysis}
				>
					<DebtSection data={debtAnalysisData} companyName={companyName} />
					{this.renderSectionComments(SectionType.DEBT, commentsDebt)}
				</Section>
				<Section
					title="Cash Position and Key Cash Flow Indicators"
					isLoading={isLoadingCashPosition}
				>
					<CashPositionSection
						data={cashPositionData}
						companyName={companyName}
					/>
					{this.renderSectionComments(
						SectionType.CASHPOSITION_AND_CASH_FLOW,
						commentsCashPosition,
					)}
				</Section>
				<Section title="P&L Overview" isLoading={isLoadingProfitLossOverview}>
					<ProfitLossOverviewSection
						data={profitLossOverviewData}
						companyName={companyName}
						currency={summaryData.currencyCode} // TODO: ?
					/>
					{this.renderSectionComments(
						SectionType.PROFIT_AND_LOSS_OVERVIEW,
						commentsProftLossOverview,
					)}
				</Section>
				<Section title="Balance Sheet" isLoading={isLoadingBalanceSheet}>
					<BalanceSheetSection
						data={balanceSheetData}
						companyName={companyName}
						currency={summaryData.currencyCode} // TODO: ?
					/>
					{this.renderSectionComments(
						SectionType.BALANCE_SHEET,
						commentsBalanceSheet,
					)}
				</Section>
				<Section title="Cashflow Overview" isLoading={isLoadingCashFlowData}>
					<CashflowSection
						data={cashFlowData}
						companyName={companyName}
						currency={summaryData.currencyCode} // TODO: ?
					/>
					{this.renderSectionComments(SectionType.CASHFLOW, commentsCashFlow)}
				</Section>
			</div>
		);
	}
}

export const CompanyDashboard = withRouter(CompanyDashboardComponent);
