import React from 'react';
import debounce from 'debounce-promise';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { ApIcon } from '@alixpartners/ui-components';

import { searchAllCompanies } from '../../api/';

import './CompanySelect.scss';

interface IOption {
	label: string;
	value: number;
}

interface ICompanySelectProps {
	value?: IOption[];
	onChange(value: any): void;
	exclude?: number[];
}

interface IOption {
	label: string;
	value: number;
}

interface ICompanySelectState {
	defaultOptions: IOption[] | boolean;
	defaultOptionsCopy: IOption[] | boolean;
	isLoading: boolean;
}

const CaretDownIcon = () => {
	return <ApIcon iconName="caret" />;
};

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<CaretDownIcon />
		</components.DropdownIndicator>
	);
};

export class CompanySelect extends React.Component<
	ICompanySelectProps,
	ICompanySelectState
> {
	state = {
		defaultOptions: [],
		defaultOptionsCopy: [],
		isLoading: false,
	};

	private isFullyMounted = false;

	public set isMounted(status: boolean) {
		this.isFullyMounted = status;
	}

	public get isMounted() {
		return this.isFullyMounted;
	}

	promiseOptions = (inputValue: string) => {
		if (!inputValue) {
			return null;
		}

		const exclude = this.props.exclude || [];
		return searchAllCompanies(inputValue, {
			pageNumber: 1,
			pageSize: 100,
		}).then(({ data }) => {
			if (data && data.results) {
				const options = data.results
					.filter((d) => !exclude.includes(d.companyId))
					.map((d) => {
						return { label: d.name, value: d.companyId };
					});
				if (this.isMounted) {
					this.setState({ defaultOptions: options });
				}
				return options;
			}
		});
	};
	// promiseOptions = (inputValue: string) => {
	// 	const exclude = this.props.exclude || [];
	// 	return search(inputValue, [], [], {
	// 		pageNumber: 1,
	// 		pageSize: 100,
	// 	}).then(({ data }) => {
	// 		if (data && data.results) {
	// 			const options = data.results
	// 				.filter((d) => !exclude.includes(d.companyId))
	// 				.map((d) => {
	// 					return { label: d.name, value: d.companyId };
	// 				});
	// 			if (this.isMounted) {
	// 				this.setState({ defaultOptions: options });
	// 			}
	// 			return options;
	// 		}
	// 	});
	// };

	handleChange = (option: IOption | null) => {
		const { defaultOptionsCopy } = this.state;
		if (!option) {
			this.setState({ defaultOptions: defaultOptionsCopy });
		} else {
			this.props.onChange(option);
		}
	};

	componentDidMount() {
		this.isMounted = true;
		// const exclude = this.props.exclude || [];

		// try {
		// 	searchAllCompanies('', {
		// 		pageNumber: 1,
		// 		pageSize: 100,
		// 	}).then(({ data }) => {
		// 		if (data && data.results) {
		// 			const options = data.results
		// 				.filter((d) => !exclude.includes(d.companyId))
		// 				.map((d) => {
		// 					return { label: d.name, value: d.companyId };
		// 				});
		// 			if (this.isMounted) {
		// 				this.setState({
		// 					defaultOptions: options,
		// 					defaultOptionsCopy: options,
		// 					isLoading: false,
		// 				});
		// 			}
		// 			return options;
		// 		}
		// 	});
		// } catch (error) {
		// 	console.log('error');
		// }
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	promiseOptionsDebounced = debounce(this.promiseOptions, 1000);

	render() {
		const { value } = this.props;
		const { defaultOptions, isLoading } = this.state;

		return (
			<AsyncSelect
				className="multi-select"
				classNamePrefix="multi-select"
				components={{ DropdownIndicator }}
				value={value}
				placeholder="Search for company..."
				onChange={this.handleChange}
				isClearable
				// @ts-ignore
				loadOptions={this.promiseOptionsDebounced}
				defaultOptions={defaultOptions}
				cacheOptions
				hideSelectedOptions
				isLoading={isLoading}
			/>
		);
	}
}
