import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import {
	withRouter,
	RouteComponentProps,
	Switch,
	Route,
} from 'react-router-dom';
import {
	ApButtonTab,
	ApButtonMain,
	ApLoaderDefault,
	ApModal,
} from '@alixpartners/ui-components';

import { KPIPanel } from '../KPIPanel';
import { CategoryWeighting } from '../CategoryWeighting';
import { InfoTooltip } from '../InfoTooltip';
import { getControlPanelData, generateCompanyScore } from '../../api';
import { ICategory, IGridCategory } from '../../types';

import './ScreeningContainer.scss';
import { CovidMsg } from '../CovidMsg';
import { reorderCategoryWeightings } from '../../utils';

interface IScreenContainerState {
	isError: boolean;
	isLoading: boolean;
	isLoadingSettings: boolean;
	isMarginFormValid: boolean;
	invalidMarginDifference: number;
	isKPIsDataValid: boolean;
	isModalOpen: boolean;
	categories: ICategory[];
}

// Change the orders below to change the order they are displayed in the Control Panel.
const CATEGORY_WEIGHTINGS_ORDER = [
	'Sales',
	'Profitability',
	'Solvency',
	'Working capital',
	'Market data',
];

class ScreeningContainer extends React.Component<
	RouteComponentProps,
	IScreenContainerState
> {
	state = {
		isError: false,
		isLoading: true,
		isLoadingSettings: false,
		isMarginFormValid: true,
		invalidMarginDifference: 0,
		isKPIsDataValid: true,
		isModalOpen: false,
		categories: [] as ICategory[],
	};

	async componentDidMount() {
		try {
			const { data } = await getControlPanelData();
			const reorderedData = reorderCategoryWeightings(
				data,
				CATEGORY_WEIGHTINGS_ORDER,
			);

			this.setState({
				isLoading: false,
				categories: reorderedData,
			});
		} catch (error) {
			this.setState({ isLoading: false });
			console.log(error);
		}
	}

	getModalText = (type: string) => {
		switch (type) {
			case 'error':
				return (
					<div className="control-modal__content">
						Sorry, there was an error saving your settings.
					</div>
				);
			case 'loading':
				return (
					<div className="control-modal__content">
						Your settings are currently being saved.
						<br />
						<br />
						<ApLoaderDefault />
					</div>
				);
			case 'success':
				return (
					<div className="control-modal__content">
						Your settings have been saved and can now be viewed within the
						Screening Dashboard.
					</div>
				);
		}
	};

	handleViewNow = () => {
		this.setState({ isModalOpen: false }, () => {
			this.props.history.push('/screening');
		});
	};

	handleCloseModal = () => {
		this.setState({ isModalOpen: false });
	};

	handleOpenModal = () => {
		this.setState({ isModalOpen: true });
	};

	handleApplySettings = async () => {
		const { categories } = this.state;

		this.setState({ isLoadingSettings: true });
		this.handleOpenModal();

		try {
			await generateCompanyScore(categories);
			this.setState({ isLoadingSettings: false });
		} catch (error) {
			this.setState({ isLoadingSettings: false, isError: true });
		}
	};

	handleMarginChange = (e: any) => {
		const { value, id } = e.target as HTMLInputElement;

		if (value.length > 3 || isNaN(+value)) return;

		this.setState((prevState) => {
			const updatedCategories = prevState.categories.map((c) => {
				if (c.categoryName === id) {
					return { ...c, categoryWeighting: +value };
				} else {
					return c;
				}
			});

			const totalMargin = updatedCategories.reduce((acc, item) => {
				return (acc += item.categoryWeighting);
			}, 0);

			return {
				categories: updatedCategories,
				isMarginFormValid: totalMargin === 100,
				invalidMarginDifference: 100 - totalMargin,
			};
		});
	};

	handleKPIsChange = (categories: IGridCategory[]) => {
		this.setState((prevProps) => {
			const formattedCategories = categories.reduce(
				(acc: ICategory[], item: IGridCategory) => {
					const {
						categoryName,
						dataItemId,
						description,
						hurdleRate,
						kpiWeighting,
						year1Change1Value,
						year1Change2Value,
						year2Change1Value,
						year2Change2Value,
					} = item;
					const exists = acc.find((c) => c.categoryName === item.categoryName);

					if (exists) {
						exists.kpis.push({
							dataItemId,
							description,
							hurdleRate,
							kpiWeighting,
							year1Change1Value,
							year1Change2Value,
							year2Change1Value,
							year2Change2Value,
						});
					} else {
						acc.push({
							categoryName,
							categoryWeighting: prevProps.categories.find(
								(c) => c.categoryName === categoryName,
							)!.categoryWeighting,
							kpis: [
								{
									dataItemId,
									description,
									hurdleRate,
									kpiWeighting,
									year1Change1Value,
									year1Change2Value,
									year2Change1Value,
									year2Change2Value,
								},
							],
						});
					}

					return acc;
				},
				[],
			);
			return {
				categories: [prevProps.categories[0], ...formattedCategories],
			};
		});
	};

	handleKPIsValidation = (isValid: boolean) => {
		this.setState({ isKPIsDataValid: isValid });
	};

	listCategories(categories: ICategory[]) {
		const cats = categories.slice(1);

		return cats.map((c, i) => {
			let separator = '';

			if (i !== cats.length - 2) separator = i !== cats.length - 1 ? ', ' : '';
			else separator = ' and ';

			return <span key={c.categoryName}>{c.categoryName + separator}</span>;
		});
	}

	render() {
		const { location, history } = this.props;
		const {
			categories,
			isError,
			isLoading,
			isLoadingSettings,
			isMarginFormValid,
			invalidMarginDifference,
			isKPIsDataValid,
			isModalOpen,
		} = this.state;
		const isActive = (path: string) => path === location.pathname;

		const {
			REACT_APP_POWERBI_REPORT_ID,
			REACT_APP_POWERBI_CTID = 'cf55ce10-837b-42cd-8154-e9a4dbd18039',
			REACT_APP_POWERBI_CONFIG = 'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
		} = process.env;

		if (isLoading)
			return <ApLoaderDefault className={'category-margins-loader'} />;

		return (
			<ScreenClassProvider>
				<div className="secondary-nav">
					<div className="wrapper">
						<section className="secondary-nav-container max-width">
							<div>
								<ApButtonTab
									isSelected={isActive('/screening')}
									onClick={() => {
										history.push('/screening');
									}}
								>
									Screening Dashboard
								</ApButtonTab>
								<ApButtonTab
									isSelected={isActive('/control-panel')}
									onClick={() => {
										history.push('/control-panel');
									}}
								>
									Control Panel
								</ApButtonTab>
							</div>
							<div className="apply-button-container">
								{isActive('/control-panel') && (
									<>
										<InfoTooltip
											message="Apply settings to your scoring model"
											id="apply-button-tooltip"
										/>
										<ApButtonMain
											disabled={!isMarginFormValid || !isKPIsDataValid}
											onClick={this.handleApplySettings}
										>
											Apply Settings
										</ApButtonMain>
									</>
								)}
							</div>
						</section>
						<ApModal
							className="control-modal"
							isOpen={isModalOpen}
							hasClosed={this.handleCloseModal}
							header={
								<div>
									{isLoadingSettings
										? 'Saving KPI Settings'
										: 'Saved New KPI Settings'}
								</div>
							}
							footer={() => (
								<div className="control-modal__footer">
									<ApButtonMain
										onClick={this.handleViewNow}
										disabled={isLoadingSettings || isError}
									>
										View Now
									</ApButtonMain>
								</div>
							)}
						>
							{this.getModalText(
								isLoadingSettings ? 'loading' : isError ? 'error' : 'success',
							)}
						</ApModal>
					</div>
				</div>
				<Switch>
					<Route
						exact={true}
						path={'/screening/:companyId?'}
						render={() => {
							const companyId = this.props.match;
							const companyQuery = companyId
								? `&filter=Company eq '${companyId}'`
								: '';
							return (
								<div className="wrapper wrapper-screening">
									<section className="screening-container">
										<iframe
											title="PowerBI Dashboard"
											src={`https://app.powerbi.com/reportEmbed?reportId=${REACT_APP_POWERBI_REPORT_ID}&autoAuth=true&ctid=${REACT_APP_POWERBI_CTID}&config=${REACT_APP_POWERBI_CONFIG}${companyQuery}`}
											frameBorder="0"
											allowFullScreen={true}
										></iframe>
									</section>
								</div>
							);
						}}
					/>
					<Route
						exact={true}
						path={'/control-panel'}
						render={() => (
							<section className="control-panel-container max-width">
								<CovidMsg
									title="Covid Adjustment"
									msg="Taking Covid into account, feel free to adapt our suggested weightings in the control panel settings as you see fit."
								/>
								<CategoryWeighting
									title={'Category Margins'}
									categories={categories}
									isValid={isMarginFormValid}
									invalidDifference={invalidMarginDifference}
									onChange={this.handleMarginChange}
								/>
								<KPIPanel
									title="Manage KPIs"
									data={categories.slice(1)}
									onChange={this.handleKPIsChange}
									onValidate={this.handleKPIsValidation}
									intro={() => (
										<>
											<div>
												The control panel is user input upon which the company
												risk score is applied. The score is made up of{' '}
												{categories.length - 1} categories:{' '}
												{this.listCategories(categories)}.
											</div>

											<div className="want-to-see">
												<strong>
													<i>Want to see how this is calculated?</i>
												</strong>
											</div>

											<ApButtonMain
												className="find-out-more"
												onClick={() =>
													window.open('bic_calculation.jpg', '_blank')
												}
											>
												Find Out More
											</ApButtonMain>

											<div>
												<strong>KPI Weighting</strong>: This refers to the
												weighting of the KPI “sub-categories” making up each
												category. I.e. EBITDA%, Net Profit % and ROCE make up
												the “Profitability” category. This must sum to 100
												across each category.
											</div>
											<div className="margin-top-8">
												<strong>Year 1 Low Risk / Year 1 High Risk</strong>: The
												one year % change hurdle rate applied to each KPI. This
												is used to define a low/high risk KPI, to which scores
												are allocated. Points: Point allocation, out of 100 to
												each year’s high risk / low risk categories.
											</div>
											<div className="margin-top-8">
												<strong>Hurdle rate</strong>: Minimum KPI value for the
												company to be considered for inclusion in the scoring
												system.
											</div>
											<div className="margin-top-8">
												<strong>Apply settings:</strong> Application of user
												inputs into scoring calculations.
											</div>

											<div className="adjust-msg">
												<br />
												<strong className="numeric-msg">
													<i>
														NOTE: The following numeric values are adjustable:
													</i>
												</strong>
											</div>
										</>
									)}
								/>
							</section>
						)}
					/>
				</Switch>
			</ScreenClassProvider>
		);
	}
}

export default withRouter(ScreeningContainer);
