import * as React from 'react';
import moment from 'moment';
import { ApLoaderDefault } from '@alixpartners/ui-components';

import { IAnalysisComment, ITag, ICurrentUser } from '../../types';
import { SectionAnalysis } from '../SectionAnalysis';
import { getAllAnalysisComments, getAllAnalysisTags } from '../../api';

import './CompanyAnalysis.scss';

interface ICompanyAnalysisProps {
	companyId: number;
	currentUser: ICurrentUser;
	onPptDataLoaded(): void;
	isPptDataLoaded: boolean;
}

interface ICompanyAnalysisState {
	isLoading: boolean;
	analysisComments: IAnalysisComment[];
	analysisTags: ITag[];
}

export class CompanyAnalysis extends React.Component<
	ICompanyAnalysisProps,
	unknown
> {
	state = {
		isLoading: true,
		analysisComments: [] as IAnalysisComment[],
		analysisTags: [] as ITag[],
	};

	async componentDidMount() {
		const { companyId, isPptDataLoaded, onPptDataLoaded } = this.props;

		if (!isPptDataLoaded) {
			try {
				onPptDataLoaded();
			} catch (error) {
				console.log(error);
			}
		}

		try {
			const [
				{ data: analysisCommentsData },
				{ data: analysisTagsData },
			] = await Promise.all([
				getAllAnalysisComments(companyId),
				getAllAnalysisTags(),
			]);
			const analysisComments = analysisCommentsData.sort((a, b) => {
				return moment.utc(b.createdOn).diff(moment.utc(a.createdOn));
			});
			this.setState({
				isLoading: false,
				analysisComments,
				analysisTags: analysisTagsData,
			});
		} catch (error) {
			console.log(error);
			this.setState({ isLoading: false });
		}
	}

	render() {
		const { isLoading, analysisComments, analysisTags } = this.state;
		const { companyId, currentUser } = this.props;

		if (isLoading) {
			return <ApLoaderDefault />;
		}

		return (
			<div className="analysis">
				<div className="analysis__title">Analysis</div>
				<div className="analysis__section">
					<SectionAnalysis
						currentUser={currentUser}
						companyId={companyId}
						analysisComments={analysisComments}
						analysisTags={analysisTags}
					/>
				</div>
			</div>
		);
	}
}
