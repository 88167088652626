import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from 'highcharts/modules/map';
import map from '@highcharts/map-collection/custom/world.geo.json';
import * as isoCountry from 'iso-3166-1-alpha-2';

import './GeographyChart.scss';

HighchartsMap(Highcharts);

interface IGeographyChartProps {
	name: string;
	countries: string[];
	keyCountries?: string[];
	headOffice: string;
	additionalNames: string[];
	subsidiaries: string[];
	acquisitions: string[];
}

// export const GeographyChart: React.FC<IGeographyChartProps> = ({
// 	name,
// 	countries,
// 	keyCountries,
// 	headOffice,
// 	additionalNames,
// 	subsidiaries,
// 	acquisitions,
// }) => {
// 	const formattedHeadOffice: string = headOffice && headOffice.toUpperCase();
// 	const formattedCountries: string[] = countries.map((c) => c.toUpperCase());
// 	const formattedKeyCountries: string[] = keyCountries
// 		? keyCountries.map((c) => c.toUpperCase())
// 		: [];

// 	const chartOptions: Highcharts.Options = {
// 		// @ts-ignore TS2322
// 		chart: {
// 			map: map,
// 			borderWidth: 0,
// 		},
// 		title: {
// 			text: '',
// 		},
// 		credits: {
// 			enabled: false,
// 		},
// 		legend: {
// 			enabled: false,
// 		},
// 		tooltip: {
// 			enabled: false,
// 		},
// 		mapNavigation: {
// 			enabled: false,
// 			enableMouseWheelZoom: false,
// 			enableTouchZoom: false,
// 			enableButtons: false,
// 		},
// 		//  plotOptions: {
// 		// 	series: {
// 		// 		point: {
// 		// 			events: {
// 		// 				mouseOut: (e: any) => {
// 		// 					if (e && e.target) {
// 		// 						// @ts-ignore
// 		// 						const el = Array.from(
// 		// 							document.querySelectorAll('.geography__country'),
// 		// 						).find((el: any) => {
// 		// 							// console.log(el.innerText);
// 		// 							return (
// 		// 								el.innerText ===
// 		// 								isoCountry.getCountry(e.target.code).toUpperCase()
// 		// 							);
// 		// 						});
// 		// 					}
// 		// 				},
// 		// 				mouseOver: (e: any) => {
// 		// 					if (e && e.target) {
// 		// 						// @ts-ignore
// 		// 						const el = Array.from(
// 		// 							document.querySelectorAll('.geography__country'),
// 		// 						).find((el: any) => {
// 		// 							// console.log(el.innerText);
// 		// 							return (
// 		// 								el.innerText ===
// 		// 								isoCountry.getCountry(e.target.code).toUpperCase()
// 		// 							);
// 		// 						});
// 		// 						console.log(el);
// 		// 					}
// 		// 				},
// 		// 			},
// 		// 		},
// 		// 	},
// 		// },
// 		// @ts-ignore TS2322
// 		series: [
// 			{
// 				data: formattedCountries.map((code) => ({ code })),
// 				joinBy: ['iso-a2', 'code'],
// 				nullColor: '#E6E6E6',
// 				color: '#6BABC7',
// 				borderWidth: 0,
// 				states: {
// 					hover: {
// 						enabled: false,
// 					},
// 				},
// 				dataLabels: {
// 					enabled: true,
// 					useHTML: true,
// 					allowOverlap: true,
// 					formatter: function () {
// 						if (
// 							formattedHeadOffice &&
// 							// @ts-ignore TS2339
// 							this.point.code === formattedHeadOffice
// 						) {
// 							return `<div class="hq-pin"><div /></div>`;
// 						}
// 					},
// 				},
// 			},
// 		],
// 	};

// 	const toUpperCase = (word: string) =>
// 		word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);

// 	const getTitle = () => {
// 		const keyActivities: string =
// 			formattedKeyCountries.length > 1
// 				? formattedKeyCountries
// 						.slice(0, -1)
// 						.map((country) => isoCountry.getCountry(country))
// 						.join(', ') +
// 				  ' and ' +
// 				  isoCountry.getCountry(
// 						formattedKeyCountries[formattedKeyCountries.length - 1],
// 				  )
// 				: isoCountry.getCountry(formattedKeyCountries[0]);

// 		return `${name} is present in ${countries.length} countries globally${
// 			keyActivities ? `, with key activities in ${keyActivities}` : '.'
// 		}`;
// 	};
// 	return (
// 		<div className="geography">
// 			<div className="geography__title">Geography</div>
// 			<div className="geography__sub-title">{getTitle()}</div>
// 			<div className="geography__chart">
// 				<HighchartsReact
// 					highcharts={Highcharts}
// 					options={chartOptions}
// 					constructorType={'mapChart'}
// 				/>
// 			</div>
// 			<div className="geography__label">Countries:</div>
// 			<div className="geography__countries">
// 				{formattedCountries.map((countryCode) => (
// 					<div key={countryCode} className="geography__country">
// 						{isoCountry.getCountry(countryCode)}
// 					</div>
// 				))}
// 			</div>
// 			<div className="geography__company-data">
// 				{headOffice && (
// 					<div className="geography__company-data__column">
// 						<div className="geography__label">Head office:</div>
// 						<p>{isoCountry.getCountry(formattedHeadOffice)}</p>
// 					</div>
// 				)}
// 				{additionalNames && additionalNames.length > 0 && (
// 					<div className="geography__company-data__column">
// 						<div className="geography__label">Also known as:</div>
// 						{additionalNames.length === 1 ? (
// 							<p>{toUpperCase(additionalNames[0])}</p>
// 						) : (
// 							additionalNames.map((name, index) => (
// 								<p key={name}>{`${toUpperCase(name)}${
// 									index !== additionalNames.length - 1 ? ',' : ''
// 								}`}</p>
// 							))
// 						)}
// 					</div>
// 				)}
// 				{subsidiaries && subsidiaries.length > 0 && (
// 					<div className="geography__company-data__column">
// 						<div className="geography__label">Subsidiaries:</div>
// 						{subsidiaries.length === 1 ? (
// 							<p>{toUpperCase(subsidiaries[0])}</p>
// 						) : (
// 							subsidiaries.map((name, index) => (
// 								<p key={name}>{`${toUpperCase(name)}${
// 									index !== subsidiaries.length - 1 ? ',' : ''
// 								}`}</p>
// 							))
// 						)}
// 					</div>
// 				)}
// 				{acquisitions && acquisitions.length > 0 && (
// 					<div className="geography__company-data__column">
// 						<div className="geography__label">Acquisitions:</div>
// 						{acquisitions.length === 1 ? (
// 							<p>{toUpperCase(acquisitions[0])}</p>
// 						) : (
// 							acquisitions.map((name, index) => (
// 								<p key={name}>{`${toUpperCase(name)}${
// 									index !== acquisitions.length - 1 ? ',' : ''
// 								}`}</p>
// 							))
// 						)}
// 					</div>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

interface IGeographyChartState {
	isLoading: boolean;
}

export class GeographyChart extends React.PureComponent<
	IGeographyChartProps,
	IGeographyChartState
> {
	state = {
		isLoading: true,
	};

	async componentDidMount() {
		await new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 200);
		});

		this.setState({ isLoading: false });
	}

	render() {
		const {
			name,
			countries,
			keyCountries,
			headOffice,
			additionalNames,
			subsidiaries,
			acquisitions,
		} = this.props;

		const formattedHeadOffice: string = headOffice && headOffice.toUpperCase();
		const formattedCountries: string[] = countries.map((c) => c.toUpperCase());
		const formattedKeyCountries: string[] = keyCountries
			? keyCountries.map((c) => c.toUpperCase())
			: [];

		const chartOptions: Highcharts.Options = {
			// @ts-ignore TS2322
			chart: {
				map: map,
				borderWidth: 0,
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			tooltip: {
				enabled: false,
			},
			mapNavigation: {
				enabled: false,
				enableMouseWheelZoom: false,
				enableTouchZoom: false,
				enableButtons: false,
			},
			// @ts-ignore TS2322
			series: [
				{
					data: formattedCountries.map((code) => ({ code })),
					joinBy: ['iso-a2', 'code'],
					nullColor: '#E6E6E6',
					color: '#6BABC7',
					borderWidth: 0,
					states: {
						hover: {
							enabled: false,
						},
					},
					dataLabels: {
						enabled: true,
						useHTML: true,
						allowOverlap: true,
						formatter: function () {
							if (
								formattedHeadOffice &&
								// @ts-ignore TS2339
								this.point.code === formattedHeadOffice
							) {
								return `<div class="hq-pin"><div /></div>`;
							}
						},
					},
				},
			],
		};

		const toUpperCase = (word: string) =>
			word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);

		const getTitle = () => {
			const keyActivities: string =
				formattedKeyCountries.length > 1
					? formattedKeyCountries
							.slice(0, -1)
							.map((country) => isoCountry.getCountry(country))
							.join(', ') +
					  ' and ' +
					  isoCountry.getCountry(
							formattedKeyCountries[formattedKeyCountries.length - 1],
					  )
					: isoCountry.getCountry(formattedKeyCountries[0]);

			return `${name} is present in ${countries.length} countries globally${
				keyActivities ? `, with key activities in ${keyActivities}` : '.'
			}`;
		};

		return (
			<div className="geography">
				<div className="geography__title">Geography</div>
				<div className="geography__sub-title">{getTitle()}</div>
				<div className="geography__chart">
					{!this.state.isLoading ? (
						<HighchartsReact
							highcharts={Highcharts}
							options={chartOptions}
							constructorType={'mapChart'}
							immutable
							updateArgs={[false, false, false]}	
						/>
					) : null}
				</div>
				<div className="geography__label">Countries:</div>
				<div className="geography__countries">
					{formattedCountries.map((countryCode) => (
						<div key={countryCode} className="geography__country">
							{isoCountry.getCountry(countryCode)}
						</div>
					))}
				</div>
				<div className="geography__company-data">
					{headOffice && (
						<div className="geography__company-data__column">
							<div className="geography__label">Head office:</div>
							<p>{isoCountry.getCountry(formattedHeadOffice)}</p>
						</div>
					)}
					{additionalNames && additionalNames.length > 0 && (
						<div className="geography__company-data__column">
							<div className="geography__label">Also known as:</div>
							{additionalNames.length === 1 ? (
								<p>{toUpperCase(additionalNames[0])}</p>
							) : (
								additionalNames.map((name, index) => (
									<p key={name}>{`${toUpperCase(name)}${
										index !== additionalNames.length - 1 ? ',' : ''
									}`}</p>
								))
							)}
						</div>
					)}
					{subsidiaries && subsidiaries.length > 0 && (
						<div className="geography__company-data__column">
							<div className="geography__label">Subsidiaries:</div>
							{subsidiaries.length === 1 ? (
								<p>{toUpperCase(subsidiaries[0])}</p>
							) : (
								subsidiaries.map((name, index) => (
									<p key={name}>{`${toUpperCase(name)}${
										index !== subsidiaries.length - 1 ? ',' : ''
									}`}</p>
								))
							)}
						</div>
					)}
					{acquisitions && acquisitions.length > 0 && (
						<div className="geography__company-data__column">
							<div className="geography__label">Acquisitions:</div>
							{acquisitions.length === 1 ? (
								<p>{toUpperCase(acquisitions[0])}</p>
							) : (
								acquisitions.map((name, index) => (
									<p key={name}>{`${toUpperCase(name)}${
										index !== acquisitions.length - 1 ? ',' : ''
									}`}</p>
								))
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
