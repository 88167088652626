import pptxgen from 'pptxgenjs';
import getSymbolFromCurrency from 'currency-symbol-map';
import { IChart } from '../types';
import {
	MASTER_SLIDE_DEFAULT,
	SLIDE_TOP_LABEL,
	SLIDE_TITLE_CONFIG,
} from './common';
import { numberWithCommas, roundOneDecimal } from '../utils';
import { cagr } from '../utils/formulas';

interface ITableMap {
	firstTable: string;
	secondTable: string;
	data: string[];
}

interface IFirstTableFieldsMap {
	displayName: string;
	APIName: string;
	data: number[];
	noCurrencySign?: boolean;
}

const highLightRowsMap: string[] = ['Gross profit', 'Operating Income'];

const tablesMap: ITableMap[] = [
	{ firstTable: 'Revenue', secondTable: 'Revenue Growth', data: [] },
	{ firstTable: 'Gross Profit', secondTable: 'Gross Profit margin', data: [] },
	{ firstTable: 'SG & A', secondTable: 'SG & A margin', data: [] },
	{
		firstTable: 'Operating Income',
		secondTable: 'Operating Income margin',
		data: [],
	},
	{ firstTable: 'EBITDA', secondTable: 'EBITDA margin', data: [] },
];

const firstTableFieldsMap: IFirstTableFieldsMap[] = [
	{
		displayName: 'Sales',
		APIName: 'Revenue',
		data: [],
	},
	{
		displayName: 'Cost of sales',
		APIName: 'COGS',
		data: [],
	},
	{
		displayName: 'Gross profit',
		APIName: 'Gross Profit',
		data: [],
	},
	{
		displayName: 'Selling, general and administrative expenses',
		APIName: 'SG & A',
		data: [],
	},
	{
		displayName: 'Operating Income',
		APIName: 'Operating Income',
		data: [],
	},
	{
		displayName: 'Profit/(loss) for the year',
		APIName: 'Net Income',
		data: [],
		noCurrencySign: true,
	},
	{
		displayName: 'EBITDA',
		APIName: 'EBITDA',
		data: [],
	},
];

const processChartData = (gridData: any[], latestYear: number) => {
	firstTableFieldsMap.map((row) => {
		const apiData = gridData.filter((item) => item.name === row.APIName)[0];
		return (row.data =
			apiData && apiData.data
				? apiData.data.slice(0, latestYear).slice(-5)
				: []);
	});
};

export const appendPLOverviewSlide = (
	deckInstance: pptxgen,
	chartData: IChart,
	currencyCode: string,
	latestYear: number,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT.title);
	slide.addText('P&L Overview', SLIDE_TITLE_CONFIG);
	slide.addText('Appendix', SLIDE_TOP_LABEL);

	const headerConfig = {
		fill: { color: '006176' },
		color: 'FFFFFF',
		bold: true,
		margin: [4, 7, 4, 7],
		paraSpaceAfter: 6,
		rowspan: 1,
	};

	const headerData = chartData.xAxis.categories;
	const lastYearIndex = headerData.lastIndexOf(latestYear.toString()) + 1;
	const headerLast5 = headerData.slice(0, lastYearIndex).slice(-5);
	const gridData = chartData.series.data;

	processChartData(gridData, latestYear);
	let revenueLast5: number[] = [];
	tablesMap.map((row) => {
		const calculatedValues: string[] = [];
		let rowData: any[] =
			gridData.filter((tableData) => tableData.name === row.firstTable) || [];
		rowData = rowData.length
			? rowData[0].data.slice(0, lastYearIndex).slice(-5)
			: [];
		if (row.firstTable === 'Revenue') {
			revenueLast5 = rowData;
		}
		if (row.secondTable === 'Revenue Growth') {
			calculatedValues.push('');
			rowData.map((value: any, index) => {
				if (index >= rowData.length - 1) {
					return false;
				}
				return calculatedValues.push(
					Math.round(((revenueLast5[index + 1] - value) / value) * 100) + '%',
				);
			});
		} else {
			rowData.map((value: any, index) =>
				calculatedValues.push(
					Math.round((value / revenueLast5[index]) * 100) + '%',
				),
			);
		}
		return (row.data = calculatedValues);
	});

	const headersRow = headerLast5.map((year) => {
		return { text: ` \n${year}`, options: headerConfig };
	});
	headersRow.unshift({
		text: ` \n${getSymbolFromCurrency(currencyCode)}M`,
		options: headerConfig,
	});
	headersRow.push({
		// text: `CAGR\n${
		// 	headerLast5[0] ? parseInt(headerLast5[0]) + 1 : headerLast5[0]
		// } - ${headerLast5[headerLast5.length - 1]}`,
		text: `CAGR ${headerLast5[0]} - ${headerLast5[headerLast5.length - 1]}`,
		options: headerConfig,
	});

	// calculate dynamically size of columns
	const colW = [2.7];
	const colsCount = firstTableFieldsMap[0].data.length - 1;
	for (let i = 0; i < colsCount + 1; i++) {
		colW.push(Math.round((4 / colsCount) * 100) / 100);
	}
	colW.push(1.2); // CAGR col

	slide.addTable(
		[
			headersRow,
			...firstTableFieldsMap.map((row) => {
				const options = highLightRowsMap.includes(row.displayName)
					? { fill: { color: 'cddef4' } }
					: {};
				const rowValues = [];
				rowValues.push({ text: row.displayName, options });
				const avg = cagr(row.data) || 0;
				row.data.map((value) =>
					rowValues.push({
						text: numberWithCommas(roundOneDecimal(value)),
						options,
					}),
				);
				rowValues.push({
					text: isNaN(avg) || !isFinite(avg) ? '0%' : avg + '%',
					options,
				});
				return rowValues;
			}),
		],
		{
			x: 0.3,
			y: 1.35,
			w: 9.5,
			border: { type: 'solid', pt: 0, color: 'FFFFFF' },
			colW: colW,
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7],
			valign: 'middle',
			align: 'left',
		},
	);

	slide.addTable(
		tablesMap.reduce((acc: any[], item) => {
			acc.push([
				{ text: item.secondTable },
				...item.data.map((i) => ({
					text: i,
				})),
			]);
			return acc;
		}, []),
		{
			x: 0.3,
			y: 4.6,
			w: 9.5,
			border: { type: 'dash', pt: 1, color: 'CFDDF1' },
			colW: colW.slice(0, colW.length - 1),
			// @ts-ignore
			rowH: [0.3],
			fontFace: 'Verdana (Body)',
			fontSize: 10,
			color: '383838',
			margin: [3, 0, 3, 7],
			valign: 'middle',
			align: 'left',
		},
	);
};
