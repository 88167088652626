import pptxgen from 'pptxgenjs';
import * as isoCountry from 'iso-3166-1-alpha-2';

import { numberWithCommas, roundOneDecimal } from '../utils';
import {
	MASTER_SLIDE_DEFAULT_WIDE,
	SLIDE_TITLE_CONFIG_WIDE,
	AP_LOGO,
} from './common';

export const SECONDARY_TITLE_CONFIG: pptxgen.TextPropsOptions = {
	x: 0.3,
	y: 1.26,
	fontSize: 14,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '383838',
	margin: [0, 0, 0, 0],
	paraSpaceAfter: 12,
};

export const SUMMARY_CONFIG: pptxgen.TextPropsOptions = {
	w: 5.5,
	h: 5.4,
	x: 0.4,
	y: 1.25,
	fontSize: 12,
	fontFace: 'Verdana (Body)',
	align: 'left',
	valign: 'top',
	color: '383838',
	margin: [0, 0, 0, 0],
	paraSpaceAfter: 12,
};

export const appendCompanySummarySlide = (
	deckInstance: pptxgen,
	companyName: string,
	yearFounded: number,
	city: string,
	country: string,
	tickerSymbol: string,
	exchangeName: string,
	currencyCode: string,
	marketCap: number,
	sharePrice: number,
	sharePrice52WeekHigh: number,
	countries: string[],
	latestYear: number,
	revenue: number,
	prevRevenue: number,
	grossProfit: number,
	prevGrossProfit: number,
	totalEbitda: number,
	prevEbitda: number,
) => {
	const slide = deckInstance.addSlide(MASTER_SLIDE_DEFAULT_WIDE.title);
	slide.addShape(deckInstance.ShapeType.rect, {
		x: 6.67,
		y: 0,
		w: 6.67,
		h: 7.5,
		fill: { color: '5CAC31' },
		line: { type: 'none' },
	});

	const SLIDE_INPUT_REQUIRED: pptxgen.TextPropsOptions = {
		h: 0.79,
		w: 5.07,
		x: 7.56,
		y: 2.11,
		fontSize: 24,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: '000000',
		margin: [0, 0, 0, 0],
	};

	const SLIDE_INPUT_REQUIRED_SMALL: pptxgen.TextPropsOptions = {
		h: 0.2,
		w: 3.98,
		x: 7.56,
		y: 2.54,
		fontSize: 12,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'top',
		bold: true,
		color: 'FFFFFF',
		margin: [0, 0, 0, 0],
	};

	slide.addText('KEY TAKEAWAYS', {
		h: 0.79,
		w: 5.07,
		x: 7.56,
		y: 1.28,
		fontSize: 16,
		fontFace: 'Verdana (Body)',
		align: 'left',
		valign: 'bottom',
		bold: true,
		color: 'FFFFFF',
		margin: [0, 0, 14.5, 0],
	});

	slide.addText('Input required', SLIDE_INPUT_REQUIRED);
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED, y: 3.05 });
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED, y: 3.99 });
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED, y: 4.93 });
	slide.addText('Input required', SLIDE_INPUT_REQUIRED_SMALL);
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED_SMALL, y: 3.48 });
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED_SMALL, y: 4.42 });
	slide.addText('Input required', { ...SLIDE_INPUT_REQUIRED_SMALL, y: 5.36 });

	slide.addText(companyName, SLIDE_TITLE_CONFIG_WIDE);
	const bulletTextConfig = { breakLine: true };

	let summaryList: any[] = [
		{
			text: `Founded in ${yearFounded}, and headquartered in ${city}, ${isoCountry.getCountry(
				country.toUpperCase(),
			)}`,
			options: bulletTextConfig,
		},
	];

	if (tickerSymbol) {
		summaryList.push({
			text: `${companyName} is part of ${exchangeName}, under the ticker ${tickerSymbol}`,
			options: bulletTextConfig,
		});
	}

	const revenuePercentageChange = Math.round(
		Math.abs(((revenue - prevRevenue) / revenue) * 100),
	);

	const ebitdaPercentageChange = Math.round(
		Math.abs(((totalEbitda - prevEbitda) / totalEbitda) * 100),
	);

	const grossProfitMargin = Math.round((grossProfit / revenue) * 100);
	const prevGrossProfitMargin = Math.round(
		(prevGrossProfit / prevRevenue) * 100,
	);

	summaryList = [
		{
			text: 'SUMMARY',
			options: { bold: true, ...SECONDARY_TITLE_CONFIG },
		},
		...summaryList,
		{
			text: `The group had a market capitalization of ${currencyCode} ${numberWithCommas(
				roundOneDecimal(marketCap),
			)} million and is trading at ${currencyCode} ${roundOneDecimal(
				sharePrice,
			)}, against a 52-week high of ${currencyCode} ${roundOneDecimal(
				sharePrice52WeekHigh,
			)}`,
			options: bulletTextConfig,
		},
		{
			text: `Its primary business operates in ${
				countries.length
			} geographic segments: ${countries
				.map((c) => isoCountry.getCountry(c.toUpperCase()))

				.join(countries.length === 1 ? '' : ', ')}`,
			options: bulletTextConfig,
		},
		{
			text: ' ',
			options: { breakLine: true },
		},
		{
			text: `FINANCIAL PERFORMANCE FY${latestYear}`,
			options: { bold: true, ...SECONDARY_TITLE_CONFIG },
		},
		{
			text: `The company generated ${currencyCode} ${numberWithCommas(
				roundOneDecimal(revenue),
			)} million revenue ${latestYear} vs ${currencyCode} ${numberWithCommas(
				roundOneDecimal(prevRevenue),
			)} million in the previous year (${
				revenue - prevRevenue < 0
					? revenuePercentageChange * -1
					: '+' + revenuePercentageChange
			}% yoy)`,
			options: bulletTextConfig,
		},
		{
			text: `Gross profit was ${currencyCode} ${numberWithCommas(
				roundOneDecimal(grossProfit),
			)} million, with a gross profit margin of ${grossProfitMargin}%, ${Math.abs(
				grossProfitMargin - prevGrossProfitMargin,
			)}% ${
				grossProfitMargin > prevGrossProfitMargin ? 'higher' : 'lower'
			} than previous year`,
			options: bulletTextConfig,
		},
		{
			text: `EBITDA ${
				totalEbitda < prevEbitda ? 'decreased' : 'increased'
			} to ${currencyCode} ${numberWithCommas(
				roundOneDecimal(totalEbitda),
			)} million vs ${currencyCode} ${numberWithCommas(
				roundOneDecimal(prevEbitda),
			)} million in the previous year (${
				totalEbitda - prevEbitda < 0
					? ebitdaPercentageChange * -1
					: '+' + ebitdaPercentageChange
			}% yoy)`,
			options: bulletTextConfig,
		},
	];

	slide.addText(summaryList, SUMMARY_CONFIG);

	slide.addImage({
		data: AP_LOGO,
		x: 10.91,
		y: 7.15,
		w: 1.4,
		h: 0.21,
	});
};
