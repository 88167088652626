import { assocPath, mergeDeepLeft } from 'ramda';
import { acquireToken } from '@alixpartners/ui-utils';
import {
	IFilter,
	IPagination,
	ICompanySearchResult,
	ICompanyData,
	ISummary,
	IPeople,
	IChart,
	ICategory,
	ICompetitorData,
	INews,

	// Watchlists
	IWatchlistListItem,
	ICompanyListItem,
	IWatchlist,
	IUser,
	IPerson,
	IProspect,
	IWatchlistProspectMeta,
	ITag,
	IWatchlistMeta,
	IAnalysisComment,
	IWatchlistComment,
	ITask,
	IWatchlistReport,
	IWatchlistReportRequiredData,
	IWatchlistCurrency,
	IWatchlistProspect,
	IProspectLinkedFiles,

	// Dashboard
	IScoreData,
	ISummaryData,
	IProfitAndLossData,

	// Comparison
	IPeersSharePriceWidget,
	IPeersRevenueYoYWidget,
	IPeersRevenueWidget,
	IPeersFinancialWidget,
	SectionType,
	TagType,

	// Financial widget
	IMaterialDebt,
	IDebtAnalysisData,
	ICashPositionData,
	IProfitAndLossOverviewData,
	IBalanceSheetData,
	ICashFlowData,
	IProspectDocument,
} from '../types';

import { defaultProps as engagementsSpendChartProps } from '../components/EngagementsSpendChart/props.mock';

const { REACT_APP_API_URL } = process.env;
const SEARCH = 'api/v1/company/search';
const SEARCH_ALL_COMPANIES = 'api/v1/Watchlist/search';
const COMPANY = 'api/v1/company';
const MYPERMISSIONS = 'api/mypermissions';
const POWERBI_PARAMS = 'api/v1/UserConfig/DefaultParametersAndScoreKpi';
const CONTROL_PANEL = 'api/v1/screening/ControlPanelParameters';
const GENERATE_SCORE = 'api/v1/screening/GenerateCompanyScore';
const NEWS = 'api/v1/company/news';
const WATCHLISTS = 'api/v1/Watchlist';
// const USERS = 'api/v1/Watchlist/users';
const WATCHLIST_USERS = 'api/v1/Watchlist/watchlistUsers';
const CONNECTION_ANALYTICS = 'api/v1/connectionanalytics/config';
const TAGS = 'api/v1/tags';
const ANALYSIS_COMMENTS = 'api/v1/AnalysisComments';
const COMPANY_WIDGET = 'api/v1/CompanyWidgets';
const ALL_TAGS = 'api/v1/tags/getAllTags';
const TASKS = 'api/v1/tasks';
const LINKED_FILES = 'api/v1/linkedFiles';

// Charts
const FINANCIAL_CHART_ID = 1;
const SHARE_PRICE_CHART_ID = 2;

const makeApiUrl = (path: string) => {
	return `${REACT_APP_API_URL}/${path}`;
};

const addAuthHeaders = (token: string, options = {}) => {
	return assocPath(['headers', 'Authorization'], `Bearer ${token}`, options);
};

const parseTextResponse = (textResponse: string) => {
	try {
		const jsonResponse = JSON.parse(textResponse);
		return jsonResponse;
	} catch {
		return textResponse;
	}
};

class ApiError extends Error {
	statusCode: number;
	constructor(statusCode: number, message: string) {
		super(message);
		this.statusCode = statusCode;
		Error.captureStackTrace(this, ApiError);
	}
}

const jsonRequest = async (
	url: string,
	options = {},
	n = 1,
	skipDefaultOptions = false,
): Promise<any> => {
	const defaultOptions = skipDefaultOptions
		? {}
		: {
				headers: { 'content-type': 'application/json' },
		  };

	try {
		const response = await fetch(url, mergeDeepLeft(defaultOptions, options));
		const textResponse = await response.text();
		const parserdResponse = parseTextResponse(textResponse);

		if (!response.ok) {
			throw new ApiError(
				response.status,
				parserdResponse.hasOwnProperty('errorMessage')
					? parserdResponse.errorMessage
					: `Error: ${textResponse}`,
			);
		}

		return {
			data: parserdResponse,
			status: response.status,
			headers: [...Array.from(response.headers)].reduce((acc, header) => {
				return { ...acc, [header[0]]: header[1] };
			}, {}),
		};
	} catch (error) {
		if (n === 1) {
			throw new ApiError(error.statusCode, error.message);
		} else if (
			error.statusCode === 500 ||
			error.statusCode === 502 ||
			error.statusCode === 503 ||
			error.message.includes('Failed to fetch')
		) {
			return await jsonRequest(url, options, n - 1);
		} else {
			throw new ApiError(error.statusCode, error.message);
		}
	}
};

const requestWithToken = async (
	url: string,
	defaults: any,
	options: any,
	skipDefaultOptions = false,
) => {
	return acquireToken().then((token) => {
		if (!token) {
			throw new Error(`ServerError (401)`);
		}
		const authDefaults = addAuthHeaders(token, defaults);
		return jsonRequest(
			url,
			mergeDeepLeft(authDefaults, options),
			1,
			skipDefaultOptions,
		);
	});
};

const postRequestArrayBuffer = async (url: string, options: any) => {
	const token = await acquireToken();

	if (!token) {
		throw new Error(`ServerError (401)`);
	}

	try {
		const response = await fetch(url, {
			headers: {
				'content-type': 'application/json-patch+json',
				Authorization: `Bearer ${token}`,
			},
			method: 'POST',
			...options,
		});

		const arrayBufferResponse = await response.arrayBuffer();

		if (!response.ok) {
			throw new ApiError(response.status, 'Erro fetching file');
		}

		return {
			data: arrayBufferResponse,
			status: response.status,
			headers: [...Array.from(response.headers)].reduce((acc, header) => {
				return { ...acc, [header[0]]: header[1] };
			}, {}),
		};
	} catch (error) {
		throw new ApiError(error.statusCode, error.message);
	}
};

const getRequest = (url: string, options: any = {}) => {
	const defaults = { method: 'GET' };
	return requestWithToken(url, defaults, options);
};

const postRequest = (
	url: string,
	options: any = {},
	skipDefaultOptions = false,
) => {
	const defaults = { method: 'POST' };
	return requestWithToken(url, defaults, options, skipDefaultOptions);
};

const deleteRequest = (url: string, options: any = {}) => {
	const defaults = { method: 'DELETE' };
	return requestWithToken(url, defaults, options);
};

const putRequest = (url: string, options: any = {}) => {
	const defaults = { method: 'PUT' };
	return requestWithToken(url, defaults, options);
};

const patchRequest = (url: string, options: any = {}) => {
	const defaults = { method: 'PATCH' };
	return requestWithToken(url, defaults, options);
};

// API to perform company search

export const search = (
	searchText = '',
	filters: IFilter[] = [],
	tags: ITag[] = [],
	pagination: IPagination = { pageNumber: 1, pageSize: 50 },
): Promise<{
	data: {
		filters: IFilter[];
		tags: ITag[];
		results: ICompanySearchResult[];
		pagination: IPagination;
	};
}> => {
	const url = makeApiUrl(SEARCH);

	const options = {
		body: JSON.stringify({
			searchText,
			filters,
			tagIds: tags.map((t) => t.tagId),
			pagination,
		}),
	};

	return postRequest(url, options);
};

interface IAllCompanySearchResult {
	companyId: number;
	name: string;
	website: string;
}

export const searchAllCompanies = (
	searchText: string,
	pagination: IPagination = { pageNumber: 1, pageSize: 50 },
): Promise<{
	data: {
		results: IAllCompanySearchResult[];
		pagination: IPagination;
	};
}> => {
	const url = makeApiUrl(SEARCH_ALL_COMPANIES);

	const options = {
		body: JSON.stringify({
			searchText,
			pagination,
		}),
	};

	return postRequest(url, options);
};

// API to provide company data

export const getCompanyData = (
	companyId: number,
): Promise<{ data: ICompanyData }> => {
	const url = makeApiUrl(`${COMPANY}/${companyId}`);

	return getRequest(url);
};

export const getEngagementsSpendData = (companyId: any): Promise<any> => {
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve({
				data: engagementsSpendChartProps,
			});
		}, 2500);
	});
};

export const getFinancialsData = (
	companyId: number,
): Promise<{ data: IChart }> => {
	const url = makeApiUrl(
		`${COMPANY}/${companyId}/chart/${FINANCIAL_CHART_ID}/`,
	);

	return getRequest(url);
};

export const getSharePriceData = (
	companyId: number,
): Promise<{ data: IChart }> => {
	const url = makeApiUrl(
		`${COMPANY}/${companyId}/chart/${SHARE_PRICE_CHART_ID}/`,
	);

	return getRequest(url);
};

export const getPeopleData = (
	companyId: number,
): Promise<{ data: IPeople[] }> => {
	const url = makeApiUrl(`${COMPANY}/${companyId}/people`);

	return getRequest(url);
};

export const getConnectionsData = (
	companyId: number,
): Promise<{ data: IPeople[] }> => {
	const url = makeApiUrl(`${COMPANY}/${companyId}/peopleConnections`);

	return getRequest(url);
};

export const getSummaryData = (
	companyId: number,
): Promise<{ data: ISummary }> => {
	const url = makeApiUrl(`${COMPANY}/${companyId}/summary`);

	return getRequest(url);
};

export const getCompetitorsData = (
	companyId: number,
	competitorIdList: number[],
): Promise<{ data: ICompetitorData[] }> => {
	const url = makeApiUrl(`${COMPANY}/${companyId}/competitor/financials`);
	const options = {
		body: JSON.stringify(competitorIdList),
	};
	return postRequest(url, options);
};

export const getFinancialReport = (
	companyId: number,
	reportType: number,
): Promise<{ data: any[] }> => {
	const url = makeApiUrl(
		`api/v1/report/getFinancialReport?companyId=${companyId}&reportType=${reportType}`,
	);

	return getRequest(url);
};

export const getMyPermissions = (): Promise<{ data: string[] }> => {
	const url = makeApiUrl(MYPERMISSIONS);

	return getRequest(url);
};

export const getControlPanelData = (): Promise<{ data: ICategory[] }> => {
	const url = makeApiUrl(CONTROL_PANEL);

	return getRequest(url);
};

export const generateCompanyScore = (
	categories: ICategory[],
): Promise<{ data: any }> => {
	const url = makeApiUrl(GENERATE_SCORE);
	const options = {
		body: JSON.stringify(categories),
	};

	return postRequest(url, options);
};

export const createWatchlist = (
	watchlistName: string,
	description: string,
	communities: number[],
	industries: number[],
): Promise<{ data: number }> => {
	const url = makeApiUrl(WATCHLISTS);
	const options = {
		body: JSON.stringify({
			watchlistName,
			description,
			communities,
			industries,
		}),
	};

	return postRequest(url, options);
};

export const getWatchlistIndustries = (): Promise<{
	data: {
		id: number;
		name: string;
	}[];
}> => {
	const url = makeApiUrl(`${WATCHLISTS}/industries`);

	return getRequest(url);
};

export const getWatchlistCurrencies = (): Promise<{
	data: IWatchlistCurrency[];
}> => {
	const url = makeApiUrl(`${WATCHLISTS}/currencies`);

	return getRequest(url);
};

export const editWatchlist = (
	watchlistId: number,
	watchlistName: string,
	description: string,
	communities: number[],
	industries: number[],
): Promise<{
	data: {
		statusCode: number;
	}[];
}> => {
	const url = makeApiUrl(`${WATCHLISTS}`);

	const options = {
		body: JSON.stringify({
			watchlistId,
			watchlistName,
			description,
			communities,
			industries,
		}),
	};

	return putRequest(url, options);
};

export const addWatchlistOwner = (
	watchlistId: number,
	userId: string,
): Promise<{ data: IPerson }> => {
	const url = makeApiUrl(`${WATCHLISTS}/${watchlistId}/user/${userId}`);

	return postRequest(url);
};

export const addCompanyOwner = (
	companyId: number,
	userId: string,
	userType: number,
): Promise<{ data: IPerson }> => {
	const url = makeApiUrl(
		`${WATCHLISTS}/company/${companyId}/user?userId=${userId}&userType=${userType}`,
	);

	return postRequest(url);
};

export const getCompanyTag = (companyId: number): Promise<{ data: ITag[] }> => {
	const url = makeApiUrl(`${TAGS}/getCompanyTags?companyId=${companyId}`);

	return getRequest(url);
};

export const getAllTags = (): Promise<{ data: ITag[] }> => {
	const url = makeApiUrl(`${TAGS}/getAllTags`);

	return getRequest(url);
};

export const addCompanyTag = (
	companyId: number,
	tagNames: string[],
): Promise<{ data: any }> => {
	const url = makeApiUrl(`${TAGS}/addTagsToCompany`);

	const options = {
		body: JSON.stringify({
			companyId,
			tagNames,
		}),
	};

	return putRequest(url, options);
};

export const addWatchlistCompany = (company: {
	watchlistId: number;
	companyId: number;
	owners: string[];
	status: string;
}): Promise<{ data: IWatchlistProspectMeta }> => {
	const url = makeApiUrl(`${WATCHLISTS}/company`);

	const options = {
		body: JSON.stringify(company),
	};

	return postRequest(url, options);
};

export const removeWatchlistCompany = (
	watchlistId: number,
	companyId: number,
): Promise<{ data: IProspect }> => {
	const url = makeApiUrl(`${WATCHLISTS}/${watchlistId}/company/${companyId}`);

	return deleteRequest(url);
};

export const deleteWatchlist = (
	id: number,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`${WATCHLISTS}/${id}`);

	return deleteRequest(url);
};

export const deleteWatchlistOwner = (
	watchlistId: number,
	userId: string,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`${WATCHLISTS}/${watchlistId}/user/${userId}`);

	return deleteRequest(url);
};

export const deleteCompanyOwner = (
	companyId: number,
	userId: string,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(
		`${WATCHLISTS}/company/${companyId}/user?userId=${userId}`,
	);

	return deleteRequest(url);
};

export const deleteCompanyTag = (
	tagId: number,
	companyId: number,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`${TAGS}/${tagId}/removeTagFromCompany/${companyId}`);

	return deleteRequest(url);
};

export const getUsers = (): Promise<{
	data: IUser[];
}> => {
	const url = makeApiUrl(WATCHLIST_USERS);

	return getRequest(url);
};

export const getWatchlists = (): Promise<{
	data: {
		otherWatchlist: IWatchlistListItem[];
		watchlistAssignedToUser: IWatchlistListItem[];
		companiesAssignedToUser: ICompanyListItem[];
		allWatchlistCompanies: ICompanyListItem[];
	};
}> => {
	const url = makeApiUrl(`${WATCHLISTS}/dashboard`);

	return getRequest(url);
};

export const getWatchlistMeta = (): Promise<{
	data: {
		watchlists: IWatchlistMeta[];
	};
}> => {
	const url = makeApiUrl(WATCHLISTS);

	return getRequest(url);
};

export const getConnectionAnalyticsData = (): Promise<{
	data: {
		webAppUrl: string;
	};
}> => {
	const url = makeApiUrl(CONNECTION_ANALYTICS);

	return getRequest(url);
};

export const updateWatchlist = (
	watchlistId: number,
	description: string,
	status: string,
): Promise<{
	data: { statusCode: number };
}> => {
	const url = makeApiUrl(WATCHLISTS);
	const options = {
		body: JSON.stringify({ watchlistId, description, status }),
	};

	return putRequest(url, options);
};

export const updateProspect = (
	watchlistId: number,
	companyId: number,
	status: string,
): Promise<{
	data: { statusCode: number };
}> => {
	const url = makeApiUrl(`${WATCHLISTS}/company`);
	const options = {
		body: JSON.stringify({ watchlistId, companyId, status }),
	};

	return putRequest(url, options);
};

export const getWatchlistById = (id: string): Promise<{ data: IWatchlist }> => {
	const url = makeApiUrl(`${WATCHLISTS}/${id}`);

	return getRequest(url);
};

export const getProspectById = (
	id: string,
	// watchlistId: string,
): Promise<{ data: IWatchlistProspect }> => {
	// const url = makeApiUrl(`${WATCHLISTS}/${watchlistId}/company/${id}`);
	const url = makeApiUrl(`${WATCHLISTS}/company/${id}`);

	return getRequest(url);
};

export const getCompanyNews = (
	companyName: string,
): Promise<{
	data: {
		readLink: string;
		totalEstimatedMatches: number;
		value: INews[];
		_type: string;
	};
}> => {
	const url = makeApiUrl(NEWS);
	const options = {
		body: JSON.stringify({ companyName }),
	};

	return postRequest(url, options);
};

export const setPowerBiParameters = (): Promise<{ data: any }> => {
	const url = makeApiUrl(POWERBI_PARAMS);

	return postRequest(url);
};

// Analysis Comments
export const getAllAnalysisComments = (
	companyId: number,
): Promise<{ data: IAnalysisComment[] }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/getCompanyAllAnalysisComments?companyId=${companyId}`,
	);

	return getRequest(url);
};

export const getSectionComments = (
	companyId: number,
	sectionType: string = SectionType.NONE,
): Promise<{ data: IAnalysisComment[] }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/getCompanyAnalysisComments?companyId=${companyId}&commentType=Section&sectionType=${sectionType}`,
	);

	return getRequest(url);
};

export const postAnalysisComments = (
	companyId: number,
	title: string,
	analysis: string,
	type: string,
	sectionType: string = SectionType.NONE,
	tags: string[],
): Promise<{ data: number }> => {
	const url = makeApiUrl(`${ANALYSIS_COMMENTS}/postAnalysisComment`);

	const options = {
		body: JSON.stringify({
			companyId,
			title,
			analysis,
			type,
			sectionType,
			tags,
		}),
	};

	return postRequest(url, options);
};

export const editAnalysisComment = (
	id: number,
	analysis: string,
	title: string,
	isObsolete = false,
	tags: string[],
): Promise<{ data: null }> => {
	const url = makeApiUrl(`${ANALYSIS_COMMENTS}/editAnalysisComment`);

	const options = {
		body: JSON.stringify({
			id,
			analysis,
			title,
			isObsolete,
			tags,
		}),
	};

	return putRequest(url, options);
};
export const markAnalysisCommentObsolete = (
	id: number,
): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/markAnalysisCommentObsolete?analysisId=${id}`,
	);

	return putRequest(url);
};
export const reviewComment = (id: number): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/markWatchlistCommentReviewed?analysisId=${id}`,
	);

	return putRequest(url);
};

export const deleteAnalysisComment = (id: number): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/deleteAnalysisComment?analysisId=${id}`,
	);

	return deleteRequest(url);
};

// Watchlist Comments
export const getWatchlistComments = (
	// watchlistId: number,
	companyId: number,
): Promise<{ data: IWatchlistComment[] }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/getWatchlistComments?companyId=${companyId}`,
		// `${ANALYSIS_COMMENTS}/getWatchlistComments?companyId=${companyId}&watchlistId=${watchlistId}`,
	);

	return getRequest(url);
};

export const postWatchlistComment = (
	// watchlistId: number,
	companyId: number,
	analysis: string,
	type: string,
): Promise<{ data: number }> => {
	const url = makeApiUrl(`${ANALYSIS_COMMENTS}/postWatchlistComment`);

	const options = {
		body: JSON.stringify({
			// watchlistId,
			companyId,
			analysis,
			type,
		}),
	};

	return postRequest(url, options);
};

export const editWatchlistComment = (
	id: number,
	analysis: string,
	type: string,
): Promise<{ data: null }> => {
	const url = makeApiUrl(`${ANALYSIS_COMMENTS}/editWatchlistComment`);

	const options = {
		body: JSON.stringify({
			id,
			analysis,
			type,
		}),
	};

	return putRequest(url, options);
};

export const deleteWatchlistComment = (id: number): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/deleteWatchlistComment?analysisId=${id}`,
	);

	return deleteRequest(url);
};

// Comparison widgets
export const getComparisonSharePrice = (
	companiesIdList: number[],
): Promise<{ data: IPeersSharePriceWidget[] }> => {
	const url = makeApiUrl(`${COMPANY_WIDGET}/getSharePrice`);

	const options = {
		body: JSON.stringify(companiesIdList),
	};

	return postRequest(url, options);
};

export const getComparisonRevenue = (
	companiesIdList: number[],
): Promise<{ data: IPeersRevenueWidget }> => {
	const url = makeApiUrl(`${COMPANY_WIDGET}/getRevenueComparison`);

	const options = {
		body: JSON.stringify(companiesIdList),
	};

	return postRequest(url, options);
};

export const getComparisonRevenueYoY = (
	companiesIdList: number[],
): Promise<{ data: IPeersRevenueYoYWidget }> => {
	const url = makeApiUrl(`${COMPANY_WIDGET}/getRevenueComparisonYoY`);

	const options = {
		body: JSON.stringify(companiesIdList),
	};

	return postRequest(url, options);
	// return new Promise((resolve, reject) => {
	// 	setTimeout(() => {
	// 		resolve({
	// 			data: {
	// 				revenueGrowthAverage: 300,
	// 				revenueGrowthMedian: 400,
	// 				ebitAverage: 100,
	// 				ebitMedian: 300,
	// 				data: [
	// 					{
	// 						currentYear: {
	// 							revenue: 100,
	// 							revenueGrowth: 200,
	// 							ebitMargin: 100,
	// 						},
	// 						previousYear: {
	// 							revenue: 200,
	// 							revenueGrowth: 300,
	// 							ebitMargin: 400,
	// 						},
	// 						companyName: 'Test',
	// 						companyId: 1,
	// 					},
	// 					{
	// 						currentYear: {
	// 							revenue: 200,
	// 							revenueGrowth: 400,
	// 							ebitMargin: 300,
	// 						},
	// 						previousYear: {
	// 							revenue: 300,
	// 							revenueGrowth: 500,
	// 							ebitMargin: 600,
	// 						},
	// 						companyName: 'Test 2',
	// 						companyId: 2,
	// 					},
	// 				],
	// 			},
	// 		});
	// 	}, 2500);
	// });
};

export const getComparisonFinancialData = (
	companiesIdList: number[],
): Promise<{ data: IPeersFinancialWidget[] }> => {
	const url = makeApiUrl(`${COMPANY_WIDGET}/getCompetitorBarChart`);

	const options = {
		body: JSON.stringify(companiesIdList),
	};

	return postRequest(url, options);
};

export const getAllAnalysisTags = (): Promise<{ data: ITag[] }> => {
	const url = makeApiUrl(`${ALL_TAGS}?type=${TagType.ANALYSIS_COMMENT}`);

	return getRequest(url);
};

// Tasks
export const getCompanyTasks = (
	companyId: number,
): Promise<{ data: ITask[] }> => {
	const url = makeApiUrl(`${TASKS}/getCompanyTasks?companyId=${companyId}`);

	return getRequest(url);
};

export const addCompanyTask = (
	companyId: number,
	dueDate: string,
	assignedToId: string,
	name: string,
	description: string,
	status: string,
): Promise<{ data: number }> => {
	const url = makeApiUrl(`${TASKS}/createTask`);

	const options = {
		body: JSON.stringify({
			companyId,
			dueDate,
			assignedToId,
			name,
			description,
			status,
		}),
	};

	return postRequest(url, options);
};

export const updateCompanyTaskStatus = (
	taskId: number,
	status: string,
): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${TASKS}/updateTaskStatus?id=${taskId}&status=${status}`,
	);

	return patchRequest(url);
};

export const deleteCompanyTask = (taskId: number): Promise<{ data: null }> => {
	const url = makeApiUrl(`${TASKS}/deleteTask?id=${taskId}`);

	return deleteRequest(url);
};

export const updateCompanyTask = (
	id: number,
	dueDate: string,
	assignedToId: string,
	name: string,
	description: string,
	status: string,
): Promise<{ data: number }> => {
	const url = makeApiUrl(`${TASKS}/editTask`);

	const options = {
		body: JSON.stringify({
			id,
			dueDate,
			assignedToId,
			name,
			description,
			status,
		}),
	};

	return putRequest(url, options);
};

// Watchlist export

export const getWatchlistReport = (
	watchlistId: number,
): Promise<{ data: IWatchlistReport }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/getWatchlistCommentReport?watchlistId=${watchlistId}`,
	);
	return getRequest(url);
};

export const markWatchlistCommentObsolete = (
	id: number,
): Promise<{ data: null }> => {
	const url = makeApiUrl(
		`${ANALYSIS_COMMENTS}/markWatchlistCommentObsolete?analysisId=${id}`,
	);

	return putRequest(url);
};

export const generateWatchlistReport = (
	requiredData: IWatchlistReportRequiredData,
): Promise<{ data: ArrayBuffer }> => {
	const url = makeApiUrl(`${ANALYSIS_COMMENTS}/generateWatchlistReport`);
	const options = {
		body: JSON.stringify({
			...requiredData,
		}),
	};

	return postRequestArrayBuffer(url, options);
};

// Financial widget
export const syncFinancialSummaryWithCapIQ = (
	companyId: number,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(
		`${WATCHLISTS}/company/syncFinancialSummaryWithCapIQ?companyId=${companyId}`,
	);

	return postRequest(url);
};

export const updateFinancialSummary = (
	companyId: number,
	calendarYear: number,
	fyMonth: number,
	currencyId: number,
	marketCap: number,
	totalRevenue: number,
	totalEBITDA: number,
	totalDebt: number,
	cash: number,
	revenueGrowth: number,
	ebitdaGrowth: number,
	totalDebtOverEBITDA: number,
	interestCoverage: number,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`${WATCHLISTS}/company/financialSummary`);
	const options = {
		body: JSON.stringify({
			companyId,
			calendarYear,
			fyMonth,
			currencyId,
			marketCap,
			totalRevenue,
			totalEBITDA,
			totalDebt,
			cash,
			revenueGrowth,
			ebitdaGrowth,
			totalDebtOverEBITDA,
			interestCoverage,
		}),
	};

	return postRequest(url, options);
};

export const updateMaterialDebt = (
	companyId: number,
	materialDebt: IMaterialDebt[],
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(
		`${WATCHLISTS}/company/materialDebt?companyId=${companyId}`,
	);
	const options = {
		body: JSON.stringify(materialDebt),
	};

	return postRequest(url, options);
};

// Dashboard
export const getAlixScreenScore = (
	companyId: number,
): Promise<{ data: IScoreData }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getAlixScreenScore?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getSummary = (
	companyId: number,
): Promise<{ data: ISummaryData }> => {
	const url = makeApiUrl(`${COMPANY_WIDGET}/getSummary?companyId=${companyId}`);
	return getRequest(url);
};

export const getProfitAndLoss = (
	companyId: number,
): Promise<{ data: IProfitAndLossData }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getProfitAndLoss?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getDebtAnalysis = (
	companyId: number,
): Promise<{ data: IDebtAnalysisData }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getDebtAnalysis?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getCashPosition = (
	companyId: number,
): Promise<{ data: ICashPositionData }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getCashPosition?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getProfitAndLossOverview = (
	companyId: number,
): Promise<{ data: IProfitAndLossOverviewData }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getProfitAndLossOverview?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getBalanceSheet = (
	companyId: number,
): Promise<{ data: IBalanceSheetData[] }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getBalanceSheet?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const getCashFlow = (
	companyId: number,
): Promise<{ data: ICashFlowData[] }> => {
	const url = makeApiUrl(
		`${COMPANY_WIDGET}/getCashFlow?companyId=${companyId}`,
	);
	return getRequest(url);
};

export const uploadProspectFile = (
	companyId: number,
	formData: FormData,
): Promise<{ data: IProspectDocument }> => {
	const url = makeApiUrl(`api/v1/company/${companyId}/documents/`);
	const options = {
		method: 'POST',
		body: formData,
	};

	return postRequest(url, options, true);
};

export const deleteProspectFile = (
	companyId: number,
	fileName: string,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`api/v1/company/${companyId}/documents/${fileName}`);

	return deleteRequest(url);
};

export const downloadProspectFile = (companyId: number, fileName: string) => {
	const url = makeApiUrl(`api/v1/company/${companyId}/documents/${fileName}`);
	return acquireToken().then((token) => {
		return fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
	});
};

// Linked files

export const getCompanyLinkedFiles = (): Promise<{
	data: IProspectLinkedFiles;
}> => {
	const url = makeApiUrl(`${LINKED_FILES}/getCompanyLinkedFiles`);
	return getRequest(url);
};

export const addCompanyLinkedFiles = (
	companyId: number,
	title: string,
	url: string,
	targetType: string,
): Promise<{ data: IProspectLinkedFiles }> => {
	const requestUrl = makeApiUrl(`${LINKED_FILES}/addLinkedFile`);

	const options = {
		body: JSON.stringify({
			companyId,
			title,
			url,
			targetType,
		}),
	};

	return postRequest(requestUrl, options);
};

export const deleteCompanyLinkedFiles = (
	linkedFileId: number,
): Promise<{ data: { statusCode: number } }> => {
	const url = makeApiUrl(`${LINKED_FILES}/removeLinkedFile/${linkedFileId}`);

	return deleteRequest(url);
};
