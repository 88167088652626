import React from 'react';
import { ApChip } from '@alixpartners/ui-components';

import { IFilter } from '../../types';

import './ActiveFilters.scss';

interface IActiveFiltersProps {
	activeFilters: IFilter[];
	onFilterRemove(filter: IFilter): void;
}

export const ActiveFilters: React.FC<IActiveFiltersProps> = props => {
	const { activeFilters, onFilterRemove } = props;

	return (
		<div className="active-filters">
			{activeFilters.map(filter => (
				<ApChip
					className="active-filters__custom-chip"
					key={filter.id}
					onClick={() => {
						onFilterRemove(filter);
					}}
				>
					{filter.value}
				</ApChip>
			))}
		</div>
	);
};
